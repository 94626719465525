import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import therapistaccess from './Pages/therapist-access';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
// import Card from './Pages/Card';
// import Checkout from './Pages/Checkout';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';
import livesession from './Pages/livesession';
import AdminshowTheri from './Pages/AdminshowTheri';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
// import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import "./bootsnav.css"
import "./bootstrap.min.css"
import "./responsive.css"
import "./style1.css"
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';
// import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';



// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
// import Shipping from './Pages/Shipping';
// import Privacy from './Pages/Privacy';
import MainUserSignup from './Pages/MainUserSignup';
import MainUserLogin from './Pages/MainUserLogin';
import forgetpassaccount from './Pages/forgetpassaccount';
import howitwork from './Pages/howitwork';
import whoitwork from './Pages/whoitwork';
import createtherispt from './Pages/createtherispt';
import createtherispt2 from './Pages/createtherispt2';
import createtherispt3 from './Pages/createtherispt3';
import createtherispt4 from './Pages/createtherispt4';
import createtherispt5 from './Pages/createtherispt5';
import Affiliate from './Pages/Affiliate';
import Reviews from './Pages/Reviews';
import FAQ from './Pages/FAQ';
import Privacy from './Pages/Privacy';
import Dashboard from './Pages/Dashboard';
import Chatting from './Pages/Chatting';
import selectTherispist from './Pages/selectTherispist';
import Sign1 from './Pages/Sign1';
import Sign2 from './Pages/Sign2';
import activtyplan from './Pages/activtyplan';
import tactivtyplan from './Pages/tactivtyplan';
import payment from './Pages/payment';
import interactions from './Pages/Interactions';
import CheckTherispistProfile from './Pages/CheckTherispistProfile';
import selectTherispistindivdiual from './Pages/selectTherispistindivdiual';
// import worksheet from './Pages/worksheet';
// import Worksheet11 from './Pages/worksheet11';
// import Worksheet111 from './Pages/worksheet111';
// import Worksheet112 from './Pages/worksheet112';
// import Worksheet113 from './Pages/worksheet113';
// import Worksheet21 from './Pages/worksheet21';
// import Worksheet22 from './Pages/worksheet22';
// import Worksheet23 from './Pages/worksheet23';
// import Worksheet31 from './Pages/worksheet31';
// import Worksheet32 from './Pages/worksheet32';
// import Worksheet33 from './Pages/worksheet33';
// import Worksheet41 from './Pages/worksheet41';
// import Worksheet42 from './Pages/worksheet42';
// import Worksheet43 from './Pages/worksheet43';

// import Worksheet51 from './Pages/worksheet51';

// import Worksheet61 from './Pages/worksheet61';
// import Worksheet62 from './Pages/worksheet62';

// import Worksheet71 from './Pages/worksheet71';
// import Worksheet72 from './Pages/worksheet72';


// import tWorksheet11 from './Pages/tworksheet11';
// import tWorksheet111 from './Pages/tworksheet111';
// import tWorksheet112 from './Pages/tworksheet112';
// import tWorksheet113 from './Pages/tworksheet113';
// import tWorksheet21 from './Pages/tworksheet21';
// import tWorksheet22 from './Pages/tworksheet22';
// import tWorksheet23 from './Pages/tworksheet23';
// import tWorksheet31 from './Pages/tworksheet31';
// import tWorksheet32 from './Pages/tworksheet32';
// import tWorksheet33 from './Pages/tworksheet33';
// import tWorksheet41 from './Pages/tworksheet41';
// import tWorksheet42 from './Pages/tworksheet42';
// import tWorksheet43 from './Pages/tworksheet43';

// import tWorksheet51 from './Pages/tworksheet51';

// import tWorksheet61 from './Pages/tworksheet61';
// import tWorksheet62 from './Pages/tworksheet62';

// import tWorksheet71 from './Pages/tworksheet71';
// import tWorksheet72 from './Pages/tworksheet72';

import Video from './Pages/video';
import Audio from './Pages/audio';




// import tdashboard from './Pages/tdashboard';



import tlogin from './Pages/tlogin';
import forgetpassaccountt from './Pages/forgetpassaccountt';
import tdashboard from './Pages/tdashboard';
import tchatting from './Pages/tchatting';
import selectUser from './Pages/selectUser';
import selectUser2 from './Pages/selectUser2';
import tlivesession from './Pages/tlivesession';
import specificUser from './Pages/specificUser';

import tworksheet from './Pages/tworksheet';
import whatwedo from './Pages/whatwedo';
import whychooseus from './Pages/whychooseus';
import team from './Pages/team';
import AllTherapist from './Pages/AllTherapist';
import businessDashboard from './Pages/businessDashboard';
import businesspaymentselect from './Pages/businesspaymentselect';
import termCondition from './Pages/termCondition';
import onlyshowtherapist from './Pages/onlyshowtherapist';
import tprofilechange from './Pages/tprofilechange';
import tjournal from './Pages/tjournal';
import journal from './Pages/journal';
import AllClient from './Pages/AllClient';
import AllTherapistpass from './Pages/AllTherapistpass';
import AllClientpass from './Pages/AllClientpass';
import business from './Pages/business';
import whyjoinus from './Pages/whyjoinus';
import g from './Pages/g';



// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';




function App() {
  const whatappsClick = ()=>{
    console.log("efef");
  }
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/g' component={g} />
          <Route exact path='/contact-us' component={Contact} />
          <Route exact path='/admin/all-therapist' component={AllTherapist} />
          <Route exact path='/admin/all-client' component={AllClient} />
          <Route exact path='/admin/all-therapist/pass/sec-1' component={AllTherapistpass} />
          <Route exact path='/admin/all-client/pass/sec-1' component={AllClientpass} />
          {/* <Route exact path='/checkout' component={Checkout} /> */}
          <Route exact path='/therapist/change-profile' component={tprofilechange} />
          <Route exact path='/business-dashboard' component={businessDashboard} />
          <Route exact path='/business-dashboard-payment' component={businesspaymentselect} />
          {/* <Route exact path='/worksheet' component={worksheet} /> */}
          <Route exact path='/therapist/all' component={selectTherispist} />
          <Route exact path='/only-show-therapist' component={onlyshowtherapist} />
          <Route exact path='/admin/therisp' component={AdminshowTheri} />
          <Route exact path='/therapist/indivdiual' component={selectTherispistindivdiual} />
          <Route exact path='/therapist/journal' component={tjournal} />
          <Route exact path='/journal' component={journal} />
          <Route exact path='/business' component={business} />
          <Route exact path='/why-join-us' component={whyjoinus} />
          {/* <Route exact path='/cart' component={Card} /> */}
          <Route exact path='/terms-condition' component={termCondition} />


          <Route exact path='/:categoriesName/video-call' component={Video} />
          <Route exact path='/:categoriesName/audio-call' component={Audio} />

          {/* <Route exact path='/worksheet1/1' component={Worksheet11} />
          <Route exact path='/worksheet11/1' component={Worksheet111} />
          <Route exact path='/worksheet11/2' component={Worksheet112} />
          <Route exact path='/worksheet11/3' component={Worksheet113} />

          <Route exact path='/worksheet2/1' component={Worksheet21} />
          <Route exact path='/worksheet2/2' component={Worksheet22} />
          <Route exact path='/worksheet2/3' component={Worksheet23} />

          <Route exact path='/worksheet3/1' component={Worksheet31} />
          <Route exact path='/worksheet3/2' component={Worksheet32} />
          <Route exact path='/worksheet3/3' component={Worksheet33} />

          <Route exact path='/worksheet4/1' component={Worksheet41} />
          <Route exact path='/worksheet4/2' component={Worksheet42} />
          <Route exact path='/worksheet4/3' component={Worksheet43} />

          <Route exact path='/worksheet5/1' component={Worksheet51} />

          <Route exact path='/worksheet6/1' component={Worksheet61} />
          <Route exact path='/worksheet6/2' component={Worksheet62} />

          <Route exact path='/worksheet7/1' component={Worksheet71} />
          <Route exact path='/worksheet7/2' component={Worksheet72} />





          <Route exact path='/therapist/worksheet1/1' component={tWorksheet11} />
          <Route exact path='/therapist/worksheet11/1' component={tWorksheet111} />
          <Route exact path='/therapist/worksheet11/2' component={tWorksheet112} />
          <Route exact path='/therapist/worksheet11/3' component={tWorksheet113} />

          <Route exact path='/therapist/worksheet2/1' component={tWorksheet21} />
          <Route exact path='/therapist/worksheet2/2' component={tWorksheet22} />
          <Route exact path='/therapist/worksheet2/3' component={tWorksheet23} />

          <Route exact path='/therapist/worksheet3/1' component={tWorksheet31} />
          <Route exact path='/therapist/worksheet3/2' component={tWorksheet32} />
          <Route exact path='/therapist/worksheet3/3' component={tWorksheet33} />

          <Route exact path='/therapist/worksheet4/1' component={tWorksheet41} />
          <Route exact path='/therapist/worksheet4/2' component={tWorksheet42} />
          <Route exact path='/therapist/worksheet4/3' component={tWorksheet43} />

          <Route exact path='/therapist/worksheet5/1' component={tWorksheet51} />

          <Route exact path='/therapist/worksheet6/1' component={tWorksheet61} />
          <Route exact path='/therapist/worksheet6/2' component={tWorksheet62} />

          <Route exact path='/therapist/worksheet7/1' component={tWorksheet71} />
          <Route exact path='/therapist/worksheet7/2' component={tWorksheet72} /> */}


          {/* <Route exact path='/tworksheet1/1' component={Worksheet2} /> */}
          <Route exact path='/login' component={MainUserLogin} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/therapist/login' component={tlogin} />
          <Route exact path='/therapist/dashboard' component={tdashboard} />
          <Route exact path='/therapist/chatting' component={tchatting} />
          <Route exact path='/therapist/select-user' component={selectUser} />
          <Route exact path='/therapist/select-user2' component={selectUser2} />
          <Route exact path='/therapist/check-user' component={specificUser} />
          <Route exact path='/therapist/live_chat' component={tlivesession} />

          {/* <Route exact path='/therapist/worksheet' component={tworksheet} /> */}




          <Route exact path='/payment' component={payment} />
          <Route exact path='/team' component={team} />
          <Route exact path='/whatwedo' component={whatwedo} />
          <Route exact path='/whychooseus' component={whychooseus} />
          <Route exact path='/interactions' component={interactions} />
          <Route exact path='/create_account' component={MainUserSignup} />
          <Route exact path='/signup-1' component={Sign1} />
          <Route exact path='/signup-2' component={Sign2} />
          <Route exact path='/about' component={About} />
          <Route exact path='/chatting' component={Chatting} />
          <Route exact path='/live_chat' component={livesession} />
          <Route exact path='/activity_plan' component={activtyplan} />
          <Route exact path='/tactivity_plan' component={tactivtyplan} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/review' component={Reviews} />
          <Route exact path='/affiliate' component={Affiliate} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/CheckTherispistProfile1' component={CheckTherispistProfile} />
          <Route exact path='/create_account_therapist' component={createtherispt} />
          <Route exact path='/create_account_therapist2' component={createtherispt2} />
          <Route exact path='/create_account_therapist3' component={createtherispt3} />
          <Route exact path='/create_account_therapist4' component={createtherispt4} />
          <Route exact path='/create_account_therapist5' component={createtherispt5} />
          <Route exact path='/how-it-work' component={howitwork} />
          <Route exact path='/who-it-work' component={whoitwork} />
          <Route exact path='/therapist-access' component={therapistaccess} />
          <Route exact path='/password_forgotten' component={forgetpassaccount} />
          <Route exact path='/password_forgotten-therapist' component={forgetpassaccountt} />
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName' component={Categories} />
          <Route exact path='/categories/:categoriesName/:subCate/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:subCate/all-products/:productName/:productId' component={SubCategoriesProductDetails} /> */}

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;



































// import React , {useState , useEffect} from 'react';
// import { Link  ,useHistory } from 'react-router-dom';

// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import x1 from '../assets/images/x1.jpg';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

 
// import logo from '../assets/images/logo.png';
// // import Head1 from "../assets/images/header-bg-rpt.jpg"
// // import SearchIcon from '@material-ui/icons/Search';
// // import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
// // import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// // import LocationOnIcon from '@material-ui/icons/LocationOn';
// // import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// // import AddAlertIcon from '@material-ui/icons/AddAlert';
// // import AddAlertIcon from '@mui/icons-material/AddAlert';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';


// import { useSelector, useDispatch } from 'react-redux';


// const Header =(props)=> {


//     const [search , setSearch] = useState("")
//     const [DataPart2 , setDataPart2] = useState([])
//     const [UserDatat , setUserDatat] = useState({})
//     const [userHeader , setuserHeader] = useState(false)
//     const [menu1 , setmenu1] = useState(false)
//     const [adminHeader , setadminHeader] = useState(false)
//     const [displayMenu , setdisplayMenu] = useState(false)
//     const [MainLine , setMainLine] = useState("")
//     const [MainForum , setMainForum] = useState(false)
//     const [MainCategories , setMainCategories] = useState([])
//     const [SubCategories , setSubCategories] = useState([])
//     const [SubClassCategories , setSubClassCategories] = useState([])
//     const [fvalue , setfvalue] = useState([])
//     const [fvalue1 , setfvalue1] = useState([])
//     const [fg , setfg] = useState(true)
//     const [abc , setabc] = useState(false)
//     const [bht , setbht] = useState("")
//     const [mainUser , setmainUser] = useState({})

//     const [hide1 , sethide1] = useState(false)
//     const [hide2 , sethide2] = useState(false)

//     const [dis1 , setdis1] = useState(false)
//     const [dis2 , setdis2] = useState(false)
   
//     const [di1 , setdi1] = useState(true)
//     const [di2 , setdi2] = useState(true)
//     const [di3 , setdi3] = useState(true)
  
  
//     let history = useHistory();


//     useEffect(() =>{
//         if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
       
       
      
//         const inter = setInterval(()=>{
     

//           if ( JSON.parse(localStorage.getItem("check Head")) ){
//             setdis1(true)
//           }
//           else{
//             setdis1(false)
//           }
//           if(JSON.parse(localStorage.getItem("check Head 1"))){
//             setdis2(true)
//           }
//           else{
//             setdis2(false)
//           }

          

//         },500)
        
     
//         return () => {
//           clearInterval(inter);
        
//         }
//     },[])
  
    


//     const  setLogout1 = () =>{
//       console.log("dsf")
//       localStorage.removeItem("check Head")
//       localStorage.removeItem("check Head 1")
//       localStorage.removeItem("user main 1")
//       history.push("/login")
//      sethideshow3()
//      sethide2(!hide2)
//   }
//     const  setLogout = () =>{
//       console.log("dsf")
//       localStorage.removeItem("check Head")
//       localStorage.removeItem("check Head 1")
//       localStorage.removeItem("user main therapist")
//       history.push("/therapist/login")
//      sethideshow3()
//      sethide2(!hide2)
//   }
//     const  sethideshow = () =>{
//       console.log("dsf")
//       setdi1(!di1)
      


//   }
//     const  sethideshow1 = () =>{
//       console.log("dsf")
//       setdi2(!di2)
//       sethide1(false)
      


//   }
//     const  sethideshow3 = () =>{
//       console.log("dsf")
//       setdi3(!di3)
//       sethide2(false)



//   }
//         return (
// <>
//         <ToastContainer />



//             {! dis1 && ! dis2 ?
 
//         <>
           
//             {/* End Main Top */}
//             {/* Start Main Top */}
//             <header className="main-header" style={{height : "60px",   
//     width: "100%"}}>
//               {/* Start Navigation */}
//               <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
//                 <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
//                   <div className='row'>

//                   {/* Start Header Navigation */}
//                   <div className="navbar-header">
//                    {/* <div style={{position: "absolute",
//     right: "5%",
//     top: "-9%"}}>
//                     <button className="navbar-toggler" style={{ color : "rgb(247, 207, 71)" , borderColor : "rgb(247, 207, 71)",top: "-9px",borderRadius:"10px",padding:"8px 14px;"}} type="button">
//                       Join
//                     </button>
//                     <button className="navbar-toggler" style={{ color : "white" , borderColor : "white",top: "-9px",border:"none"}} type="button">
//                      Login
//                     </button>
//                   </div> */}
//                     <button className="navbar-toggler" style={{position: "absolute",
//     right: "5%", color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>sethideshow()}>
//                       <MenuIcon/>
//                     </button>
//                     <a className="navbar-brand" href="/" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "76px",height : "62px"}} /></a>

//                    </div>

//                   {/* End Header Navigation */}
//                   {/* Collect the nav links, forms, and other content for toggling */}
                  
//                     {/* <ul className="nav navbar-nav ml-auto chage-mobile-head"  style={{  color : "white" ,position:"absolute",right:"0px" }}>
                  
//                       <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
//                       <li className="nav-item"><Link className="nav-link" to="/teams">Teams</Link></li>
//                       </ul>
//                    */}
//                   <div className={di1 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} id="navbar-menu" style={{}}>
//                   {/* <div className="collapse navbar-collapse" className={di1 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} id="navbar-menu ffjfj" style={{}}> */}
//                     <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0px" }}>
//                     {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , }}> */}
                     
                     
                     
//                       {/* <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/how-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>HOW IT WORKS </a></a></li> */}




//                       {/* <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
//                       <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}


//                       {/* <li className="nav-item"><a className="nav-link the456 efkekej"><a href="/contact-us"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Contact Us</a></a></li> */}



//                       {/* <li className="nav-item"><a className="nav-link the456 efkekej"><a href="/therapist/login"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>THERAPIST login</a></a></li> */}
//                       {/* <li className="nav-item"><a className="nav-link the456" ><Link to="/about" style={{color  :"white"}}>Why</Link></a></li> */}
//                       {/* <li className="dropdown">
//                         <a className="nav-link dropdown-toggle arrow the456" data-toggle="dropdown"  style={{fontSize : "12px" , fontWeight : "500",color: "white"}}>Essentials</a>
//                         <ul className="dropdown-menu">
//                         <li><a href="/about" style={{color :  "white"}}>About Us</a></li>
//                           <li><a href="/review" style={{color :  "white"}}>Reviews</a></li>
//                           <li><a href="/faq" style={{color :  "white"}}>FAQ</a></li>
//                           <li><a href="/privacy" style={{color :  "white"}}>Privacy Policy</a></li>
//                           </ul>
//                       </li> */}
//                       {/* <li className="nav-item"><a className="nav-link the456" ><Link to="/therapist-access" style={{color  :"white"}}>Join as a THERAPIST</Link></a></li> */}
//                       {/* <li className="nav-item kjjk" style={{position :"absolute" ,top  :"20px" , right  : "18%" , color : "grey"}}><a className="nav-link" style={{padding : "0px  10px"}}><a href="/signup-1"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Client login</a></a></li>
//                       <li className="nav-item kjjk1" style={{position :"absolute" ,top  :"20px"  , right  : "2%" , color : "grey"}}><a className="nav-link" style={{padding : "0px  10px"}}><a href="/therapist-access"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>THERAPIST login</a></a></li> */}
//                       {/* <li className="nav-item"><a className="nav-link" href="gallery.html">Gallery</a></li> */}
//                       <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
//                       <li className="nav-item"><Link className="nav-link" to="/teams">Teams</Link></li>
//                       <li className="nav-item"><Link className="nav-link" to="/review">Reviews</Link></li>
//                       <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
                      
                      
                      
                      
                      
                      
                      
//                       <li className="nav-item"onClick={()=>sethideshow()} ><a className="nav-link the456 efkekej" ><a href="/login"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Client login</a></a></li>
//                     </ul>
//                   </div>
//                   {/* /.navbar-collapse */}
//                   {/* Start Atribute Navigation */}
//                   {/* <div className="attr-nav">
//                     <ul>
                     
//                       <li className="side-menu">
//                         <Link to="/cart">
//                         <ShoppingCartIcon    /> 
//                           <span className="badge">{DataPart2.length}</span>
//                           <p>My Cart</p>
//                         </Link>
//                       </li>
//                     </ul>
//                   </div> */}
//                   {/* End Atribute Navigation */}
//                 </div>
//               </div>
//                 {/* Start Side Menu */}
                
//                 {/* End Side Menu */}
//               </nav>

//               {/* End Navigation */}
//             </header>
//             {/* End Main Top */}
//             {/* Start Top Search */}
//             {/* {!abc
//             ? 
//             <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
//               <div className="container">
//                 <div className="input-group">
//                   <span className="input-group-addon"><SearchIcon /></span>
//                   <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
//                   <span className="input-group-addon close-search"><ClearIcon/></span>
//                 </div>
//               </div>
//             </div>
//             : ""

//             } */}
//             </>

// :""
//         }
//             {! dis2 &&dis1 && JSON.parse(localStorage.getItem("user main therapist"))  ?
 
//         <>
           
//             {/* End Main Top */}
//             {/* Start Main Top */}
//             <header className="main-header" style={{height : "60px",   
//     width: "100%"}}>
//               {/* Start Navigation */}
//               <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
//                 <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
//                   <div className='row'>

//                   {/* Start Header Navigation */}
//                   <div className="navbar-header">
//                     <button className="navbar-toggler" style={{position: "absolute" ,right: "5%",  color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation"  onClick={()=>sethideshow1()}>
//                       <MenuIcon/>
//                     </button>
//                     <Link className="navbar-brand" to="/therapist/dashboard" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "105px",height : "72px"}} /></Link>
//                   </div>
//                   {/* End Header Navigation */}
//                   {/* Collect the nav links, forms, and other content for toggling */}
//                   <div className={di2 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} style={{}}>
//                     <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,}}>
//                     {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , }}> */}
//                       <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" ><Link to="/therapist/worksheet1/1" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Getting to Know You </Link></a></li>
//                       <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" ><Link to="/therapist/dashboard" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Therapy </Link></a></li>
//                       {/* <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" ><Link to="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
//                       <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" ><Link to="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}
//                       <li className="nav-item"   onClick={()=>sethideshow1()} ><a className="nav-link the456 efkekej" ><Link to="/therapist/journal"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Journaling</Link></a></li>
//                       <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej"><Link to="/tactivity_plan"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Activity Plan</Link></a></li>
//                      </ul>


//                     <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0"}} onClick={()=>sethide1(!hide1)}>
//                       <li className="nav-item"><a className="nav-link the456 efkekej"><img src={JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto ? JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto  : x1} alt="" style={{width:"40px",height:"40px",borderRadius:"50px"}}/></a></li>
//                         </ul>
//                   </div>

//                   {/* /.navbar-collapse */}
//                   {/* Start Atribute Navigation */}
//                   {/* <div className="attr-nav">
//                     <ul>
                     
//                       <li className="side-menu">
//                         <Link to="/cart">
//                         <ShoppingCartIcon    /> 
//                           <span className="badge">{DataPart2.length}</span>
//                           <p>My Cart</p>
//                         </Link>
//                       </li>
//                     </ul>
//                   </div> */}
//                   {/* End Atribute Navigation */}
//                 </div>
//               </div>
//                 {/* Start Side Menu */}
                
//                 {/* End Side Menu */}


//                 {hide1
// ?
// <div style={{width:"150px",border:"1px solid white",height:"100px",position:"absolute",top:"68px",right:"5px",background:"rgb(25, 18, 43)"}}>

// <Link   onClick={()=>sethideshow1()} to="/therapist/change-profile" className="nav-link the456" style={{  
//   color: "white",
//   // padding: "5px 14px",
//   // width: "85px",
//   margin: "2px 0px",
//   // background: "black",
//   fontWeight: "500",
//   fontSize: "14px",
//   textTransform: "uppercase",
//   cursor:"pointer"
//   // position: "absolute",
//   // right: "0px",
//   // top:"53px",
//   // zIndex:99999
  
// }}>Edit Profile </Link>
// <a className="nav-link the456" onClick={()=>setLogout()}  style={{  
//        color: "white",
//     // padding: "5px 14px",
//     // width: "85px",
//     margin: "2px 0px",
//     // background: "white",
//     fontWeight: "500",
//     fontSize: "14px",
//     textTransform: "uppercase",
//     // position: "absolute",
//     // right: "0px",
//     // top:"53px",
//     // zIndex:99999
//     cursor:"pointer"

//     }}> LogOut</a>
// </div>
//      :""           }
//               </nav>

//               {/* End Navigation */}
//             </header>
//             {/* End Main Top */}
//             {/* Start Top Search */}
//             {/* {!abc
//             ? 
//             <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
//               <div className="container">
//                 <div className="input-group">
//                   <span className="input-group-addon"><SearchIcon /></span>
//                   <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
//                   <span className="input-group-addon close-search"><ClearIcon/></span>
//                 </div>
//               </div>
//             </div>
//             : ""

//             } */}
//             </>

// :""
//         }
//             { dis2 && JSON.parse(localStorage.getItem("user main 1"))   ?
 
//         <>
           
//             {/* End Main Top */}
//             {/* Start Main Top */}
//             <header className="main-header" style={{height : "60px",   
//     width: "100%"}}>
//               {/* Start Navigation */}
//               <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
//                 <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
//                   <div className='row'>

//                   {/* Start Header Navigation */}
//                   <div className="navbar-header">
//                     <button className="navbar-toggler" style={{position: "absolute" ,right: "5%",  color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation"  onClick={()=>sethideshow3()}>
//                       <MenuIcon/>
//                     </button>
//                     <Link className="navbar-brand" to="/dashboard" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "105px",height : "72px"}} /></Link>
//                   </div>
//                   {/* End Header Navigation */}
//                   {/* Collect the nav links, forms, and other content for toggling */}
//                   <div className={di3 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} style={{}}>
//                     <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,}}>
//                     {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , }}> */}
//                       <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/worksheet1/1" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Getting to Know You </Link></a></li>
//                       <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/dashboard" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Therapy </Link></a></li>
//                       {/* <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
//                       <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}
//                       <li className="nav-item"  onClick={()=>sethideshow3()} ><a className="nav-link the456 efkekej" ><Link to="/journal"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Journaling</Link></a></li>
//                       <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej"><Link to="/activity_plan"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Activity Plan</Link></a></li>
//                      </ul>


//                     <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0"}}  onClick={()=>sethide2(!hide2)}>
//                       <li className="nav-item"><a className="nav-link the456 efkekej"><img src={x1} alt="" style={{width:"40px",height:"40px",borderRadius:"50px"}}/></a></li>
//                         </ul>
//                   </div>
//                   {/* /.navbar-collapse */}
//                   {/* Start Atribute Navigation */}
//                   {/* <div className="attr-nav">
//                     <ul>
                     
//                       <li className="side-menu">
//                         <Link to="/cart">
//                         <ShoppingCartIcon    /> 
//                           <span className="badge">{DataPart2.length}</span>
//                           <p>My Cart</p>
//                         </Link>
//                       </li>
//                     </ul>
//                   </div> */}
//                   {/* End Atribute Navigation */}
//                 </div>
//               </div>
//                 {/* Start Side Menu */}
                
//                 {/* End Side Menu */}
//                 {hide2
// ?
// <div style={{width:"150px",border:"1px solid white",height:"100px",position:"absolute",top:"68px",right:"5px",background:"rgb(25, 18, 43)"}}>

// <Link  onClick={()=>sethideshow3()} to="/dashboard" className="nav-link the456" style={{  
//   color: "white",
  
//   margin: "2px 0px",
//   fontWeight: "500",
//   fontSize: "14px",
//   textTransform: "uppercase",
 
//   cursor:"pointer"

// }}>Therapy </Link>
// <a className="nav-link the456" onClick={()=>setLogout1()}  style={{  
//        color: "white",
//     // padding: "5px 14px",
//     // width: "85px",
//     margin: "2px 0px",
//     // background: "white",
//     fontWeight: "500",
//     fontSize: "14px",
//     textTransform: "uppercase",
//     // position: "absolute",
//     // right: "0px",
//     // top:"53px",
//     // zIndex:99999
//     cursor:"pointer"

//     }}> LogOut</a>
// </div>
//      :""           }
//               </nav>

//               {/* End Navigation */}
//             </header>
//             {/* End Main Top */}
//             {/* Start Top Search */}
//             {/* {!abc
//             ? 
//             <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
//               <div className="container">
//                 <div className="input-group">
//                   <span className="input-group-addon"><SearchIcon /></span>
//                   <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
//                   <span className="input-group-addon close-search"><ClearIcon/></span>
//                 </div>
//               </div>
//             </div>
//             : ""

//             } */}
//             </>

// :""
//         }

// </>
//         )
// }

// export default Header;