import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
import ban27 from '../assets/images/ban-27.jpeg';

import ClearIcon from '@material-ui/icons/Clear';


// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [data , setdata] = useState([])

    // const [email ,  setemail ] = useState("")
    const [name ,  setname ] = useState("")
    const [Email ,  setEmail ] = useState("")
    const [pass ,  setpass ] = useState("")
    const [Date ,  setDate ] = useState("")
    const [StartTime ,  setStartTime ] = useState("")
    const [EndTime ,  setEndTime ] = useState("")
    const [Description ,  setDescription ] = useState("")
    const [user ,  setuser ] = useState("")
    // const [Country ,  setCountry ] = useState("")
    // const [phone ,  setphone ] = useState("+92")
    // const [code ,  setcode ] = useState("")
    // const [gy ,  setgy ] = useState(true)
    // const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    // const [changer1 ,  setchanger1 ] = useState(false)
    // const [changer2 ,  setchanger2 ] = useState("")
    const [Product_Sub_Catagories ,  setProduct_Sub_Catagories ] = useState("Exercise")


    useEffect(() =>{

        // window.scrollTo(0, 0)

      
        // fetch("https://asad-backend-two-lemon.vercel.app/all-activity",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  }
        // })
        // .then(res5=>res5.json())
        // .then(res6=>{
        //     console.log(res6)
        //     let A = []
        //     res6.map((res1,i)=>{
        //         if(res1.Userid === JSON.parse(localStorage.getItem("user main 1"))._id &&  res1.theripistId ===JSON.parse(localStorage.getItem("user main 1")).TheripistId && res1.Status){
        //             A.push(res1)
        //         }
        //     })
        //     setdata(A)
        // })
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
    
     
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

     
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])








 const deleteProduct = (id,i) =>{
    fetch("https://asad-backend-two-lemon.vercel.app/delete-activity",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            id
        })
    })
    .then(res11=>res11.json())
    .then((res12)=>{ 
        const newData = data.filter(data=> data._id !== id)
        setdata(newData)
    })
    // fetch("https://asad-backend-two-lemon.vercel.app/deleteActivity/"+id ,{
    //   method: "DELETE" , 
    //   headers: {
    //     "Content-Type" : "application/json" ,
    //   }
    // })
    // .then(res=>res.json())
    // .then(res2=>{
        // const newData = data.filter(data=> data._id !== id)
        //   setdata(newData)
    // })
  }


 const SubmitDataMessage = () =>{
  setdispaly1(false)
  // this.setState({displayNone : false})
//   const date1 = new Date()

//     let day= date1.getDate() 
//     let month= (date1.getUTCMonth()+1)
//     let year= date1.getUTCFullYear()
//     year = year - 2000

//     if(date1.getDate() < 10) day = "0"+ date1.getDate()
//     if(date1.getUTCMonth()+1 < 10) month = "0"+ (date1.getUTCMonth()+1)

//     let c = day +""+month+ ""+year

    let a=[]
  if(data)
   a=[...data,{user,Description}]
  else
   a=[{user,Description}]
   setdata(a)
   setuser("")
                                            setDescription("")
                                // fetch("https://asad-backend-two-lemon.vercel.app/create-activity",{
                                //             method: "POST",
                                //             headers :  {
                                //                 "Content-Type" : "application/json" , 
                                //             } ,
                                //             body : JSON.stringify({
                                //                 name,
                                //                 Name : user,
                                //                 Description,
                                //             })
                                //         })
                                //         .then(res11=>res11.json())
                                //         .then((res12)=>{ 
                                //             if(res12)
                                //             setdata([...data,res12])
                                //             setuser("")
                                //             setDate("")
                                //             setStartTime("")
                                //             setEndTime("")
                                //             setProduct_Sub_Catagories("")
                                //             setDescription("")


                                //             console.log(res12)
                                //             if(!res12.Error){
                                //                 setdispaly1(true)
                                //                 swal("Success");
                                //                 this.setState({
                                //                         mainline: "",
                                //                     })
                                //             }
                                //             else{
                                //                 setdispaly1(true)
                                                
                                //                 swal(res12.Error);
                                //             }
                                //         })
                                //         .catch(err=>{
                                //             swal("Success");
                                //             setdispaly1(true)
                                //     }) 
                                    }
                                   
                                    

                                    


 const SubmitDataMessage1 = () =>{
  setdispaly1(false)
  // this.setState({displayNone : false})
//   const date1 = new Date()

//     let day= date1.getDate() 
//     let month= (date1.getUTCMonth()+1)
//     let year= date1.getUTCFullYear()
//     year = year - 2000

//     if(date1.getDate() < 10) day = "0"+ date1.getDate()
//     if(date1.getUTCMonth()+1 < 10) month = "0"+ (date1.getUTCMonth()+1)

//     let c = day +""+month+ ""+year

//     let a=[]
//   if(data)
//    a=[...data,{user,Description}]
//   else
//    a=[{user,Description}]
//    setdata(a)
                                fetch("https://asad-backend-two-lemon.vercel.app/create-business-data",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                                name,
                                                Email,
                                                data
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(res12)
                                            setdata([])
                                            setname("")
                                            setDate("")
                                            setStartTime("")
                                            setEndTime("")
                                            setProduct_Sub_Catagories("")
                                            setuser("")
                                            setDescription("")


                                            console.log(res12)
                                            if(!res12.Error){
                                                setdispaly1(true)
                                                swal("Success");
                                                this.setState({
                                                        mainline: "",
                                                    })
                                                    props.history.push("/business-dashboard-payment")
                                                }
                                                else{
                                                    setdispaly1(true)
                                                    
                                                swal(res12.Error);
                                            }
                                        })
                                        .catch(err=>{
                                            swal("Success");
                                            setdispaly1(true)
                                            props.history.push("/business-dashboard-payment")
                                    }) 
                                    }
                                   
                                    

                                    


        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         

   <h1 style={{padding : "80px 10px 10px 10px",  fontSize : "30px"  ,fontWeight : "400"}}><center>
   Business Dashboard
 

</center></h1>
   <p className="rgjrgou2" style={{padding : "10px 10px 10px 10px",  fontSize : "15px"  ,fontWeight : "400",width:"60%",margin:"auto"}}><center>
   From here you control which team members should have access to our online therapy services by adding them from below. Once your list is complete, simply select the subscription plan that suits your needs the best, paying for all team members with one invoice, once a month. 

</center></p>
<div className="form-group rgjrgou2" style={{width: "50%",margin: "auto"}}>
   
                    <label>Company Name</label>
                    <input type="text"  value={name} onChange={(e)=>setname(e.target.value)}  className="form-control" placeholder="Enter Business Name" />
                </div>
                <br />
<div className="form-group rgjrgou2" style={{width: "50%",margin: "auto"}}>
   
                    <label>Company Email</label>
                    <input type="text"  value={Email} onChange={(e)=>setEmail(e.target.value)}  className="form-control" placeholder="Enter Business Email" />
                </div>
<div className="container-fuild rgjrgou" style={{width : "40%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px rgb(25,18,43)"}}>
           
         <center>

                    <h4 style={{color : "rgb(61, 61, 61)" ,fontSize : "23px"  ,fontWeight : "500"}}>Add Team Member</h4>
         </center>
                    <p style={{ fontSize : "13px" }}>Add Your First Team Members
Write or paste your team member's names and email addresses in the field below.</p>
              
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Name</label>
                    <input type="text"  value={user} onChange={(e)=>setuser(e.target.value)}  className="form-control" placeholder="Enter Name" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Email</label>
                    <input type="text"  value={Description} onChange={(e)=>setDescription(e.target.value)}  className="form-control" placeholder="Enter Email" />
                </div>
            <br/>
                    <button  onClick={()=>SubmitDataMessage()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)"}}>Create</button>
            
               
        </div>

     
<center>
<h1  className='the456' style={{fontSize : "45px",  padding  : "20px 20px 30px 20px"}}>My Teams

</h1>

</center>


{data.length > 0 ? data.map((res,i)=>{
    return (
        

            <div className="site-blocks-1 backk" style={{margin : "20px auto"}}>
                    <div className="container jhhjhh">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0" style={{ margin  : '0px auto',   padding: "0px",boxShadow: "0px 2px 6px rgb(25,18,43)"}} data-aos="fade-up" data-aos-delay="">
                                {/* <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "25px",marginTop : "20px" ,  width : "20px" , fontWeight : "bold", color : "rgb(170, 31, 130)"}}  >Hello</span>
                        </div> */}
                                <div className="text" style={{width : "100%"}}>
                                <center>
                                    <p style={{background : "rgb(25,18,43)" ,color : "white",fontSize : "35px",}}>{res.user} <span>                <ClearIcon id="myDiv3456767"  style={{color : "white",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>deleteProduct(res._id,i)}/></span> </p>
                                </center>
                                {/* <div style={{display : "flex" , justifyContent : "space-between" , padding : "10px" , color : "white"  ,fontWeight :"500"}}>
                                    <p style={{color : "black"}}>Name : {res.user}</p>
                                    <p style={{color : "black"}}>Date {res.Date}</p>
                                </div> */}
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500", padding : "10px 12px"}}>{res.Description}



</p>
                                
                            </div>
                      
                            </div>
                         
                        </div>
                    </div>
                </div>

    )
})
: <center>
    <h1>
        <b>
       " No Teams "
        </b>
    </h1>
    <br />
    <br />
    <br />
</center>
}





<br />
<center>
<button  onClick={()=>SubmitDataMessage1()} className="btn btn-primary btn-block rgjrgou3" style={{width: "20%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)",padding:"10px 0px"}}>Submit</button>
</center>

<br />



            {/* <div className="site-blocks-1 backk" style={{margin : "20px auto"}}>
                    <div className="container jhhjhh">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0" style={{ margin  : '0px auto',   padding: "0px",boxShadow: "0px 2px 6px rgb(25,18,43)"}} data-aos="fade-up" data-aos-delay="">
                                
                                <div className="text" style={{width : "100%"}}>
                                <center>
                                    <p style={{background : "rgb(25,18,43)" ,color : "white",fontSize : "45px",}}>Umer <span>                <ClearIcon id="myDiv3456767"  style={{color : "white",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} /></span> </p>
                                </center>
                                <div style={{display : "flex" , justifyContent : "space-between" , padding : "10px" , color : "black"  ,fontWeight :"500"}}>
                                    <p>Start : 12:00</p>
                                    <p>End : 12:00</p>
                                    <p>Date 20/4/22</p>
                                </div>
                                    <p  className='the456' style={{fontSize: "17px",minWidth: "100%",
    fontWeight: "500", padding : "10px 12px"}}>Choose your areas



</p>
                                
                            </div>
                      
                            </div>
                         
                        </div>
                    </div>
                </div> */}











            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;