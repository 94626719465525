import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


class           About extends Component {
    state = {
        data : []
        ,        data1 : [],

    }
    componentDidMount(){
        window.scrollTo(0, 0)
        fetch("https://asad-backend-two-lemon.vercel.app/all-therispist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            this.setState({data : res6})
            fetch("https://asad-backend-two-lemon.vercel.app/allUser",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           }
          })
          .then(res15=>res15.json())
          .then(res16=>{
  
  
  
            let a = []
  
              this.setState({data : res6})
              res6.map((res,z)=>{
                res16.map((res1,b)=>{
                  if(res._id === res1.TheripistId){
                    a.push(res1) 
                  }
                })
             })
             console.log(a)
   
             this.setState({data1 : a})
  
  
  
              })
              })

    }

    
     AccountforApproval = (e,id,email,i) =>{
        console.log(e,id,email,i)
                 if(e){
                    fetch("https://asad-backend-two-lemon.vercel.app/ApprovalTher",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : true,
                  id ,
                  email
                })
              })
              .then((res)=>res.json())
              .then((res2)  =>{
                  if (res2 !== null && !res2.Error  ){
                      

                        swal("SucessFully Change Reload"  )
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
                 else{
                    fetch("https://asad-backend-two-lemon.vercel.app/ApprovalTher",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : false,
                  id ,
                  email
                })
              })
              .then((res4)=>res4.json())
              .then((res22)  =>{
                  // console.log(res22)
                  if (res22 !== null && !res22.Error  ){
                      
                        swal("SucessFully Change Reload"  )

                    
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
              }


               deleteProduct = (id) =>{
           
                fetch("https://asad-backend-two-lemon.vercel.app/deletePost1",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                    } ,
                  body : JSON.stringify({
                    id
                  }),
              })
              .then(res=>res.json())
              .then((res2)=>{ 
                console.log(res2)    
                    const newData = this.state.data.filter(data=> data._id !== res2._id)
                 this.setState({data :newData })
               
              })
        
        
        
              }
        
         


    render() {
        return (
            <div>
            <center>
               <table style={{width: "300%",padding : "0px 30px",
    margin: "50px 100px",textAlign : "center",fontWeight : "bold"}}>
  <tr>
    <th>Sno .</th>
    <th>Full Name</th>
    <th>Profile </th>
    <th>Email</th>
    <th>Client</th>
    <th>Phone</th>
    <th>City</th>
    <th>Country</th>
    <th>Address</th>
    <th>Gender   </th>
    <th>StateProvince   </th>
    <th>TimeZone   </th>
    <th>Zippostal   </th>
    <th>DateOfBirth   </th>
    <th>Therapist Type   </th>
    <th>Credential Type   </th>
    <th>Initials of your credentials   </th>
    <th>currentworksituation   </th>
    <th>would you prefer   </th>
    <th>Summary of your education   </th>
    <th>Upload Your Certificate   </th>
    <th>selected_specialities   </th>
    <th>selected_treatment_orientation   </th>
    <th>Approval  </th>
    <th>Delete  </th>

  </tr>
{
    this.state.data.map((res,i)=>{
        return (
            <tr key={i}>
    <td>{i+1}</td>
    <td>{res.Title} {res.fname} {res.lname}</td>
    <td><img src={res.ProfilePhoto} alt="" style={{width : "200px", height : "200px",borderRadius : "150px",objectFit : "cover"}}/> </td>
    <td style={{padding  : "10px 10px"}}>{res.email}</td>
    <td style={{padding  : "10px 20px"}}>
    {this.state.data1.length !== 0 ?
     this.state.data1.map((res1,j)=>{
      if(res1.TheripistId === res._id){
         return (
          <>
             <span>
     { res1.Name}
          
        </span>
        <br />
        <span>
      {res1.email}
          
        </span>
        <hr />
        <br />
          </>
     
      )
      
    }
    // else{
    //   return (
        
    //    "  No Client"
    //     )
    //   }
      
    })
    
    
    :  " No Client"
    }
   
    </td>
    <td style={{padding  : "10px 10px"}}>{res.TaxpayerIdentificationNumber}</td>
  
    <td style={{padding  : "10px 10px"}}>{res.City}</td>
    <td style={{padding  : "10px 10px"}}>{res.Country}</td>
    <td style={{padding  : "10px 10px"}}>{res.StreetAddress}</td>
    <td style={{padding  : "10px 10px"}}>{res.Gender}</td>
    <td style={{padding  : "10px 10px"}}>{res.StateProvince}</td>
    <td style={{padding  : "10px 10px"}}>{res.TimeZone}</td>
    <td style={{padding  : "10px 10px"}}>{res.Zippostal}</td>
    <td style={{padding  : "10px 10px"}}>{res.DateOfBirth}</td>
    <td>{res.TherapistType}</td>
    <td>{res.CredentialType}</td>
    <td>{res.Initialsofyourcredentials}</td>
    <td>{res.currentworksituation}</td>
    <td>{res.MonFriwouldyouprefer}</td>
    <td>{res.Summaryofyoureducation}</td>
    {/* <td>{res.Summaryofyoureducation}</td> */}
    <td><img src={res.UploadYourCertificate} alt="" style={{width : "200px", height : "200px",objectFit : "cover"}}/> </td>
    <td>{
    res.selected_specialities.map((res1,g)=>{
        return (
            <>
        {
          res1  
        } <br />
        </> 
        )
       
    })
    }</td>
    <td>{
    res.selected_treatment_orientation.map((res1,g)=>{
        return (
            <>
        {
          res1  
        } <br />
        </> 
        )
       
    })
    }</td>
    <td style={{padding  : "10px 10px",width : "150px"}}>{res.isApprovl ? 
    <> 
    <span style={{border :  "none" , background  :  "yellow" , borderRadius : "10px"}}>Aprroved</span>
    <br />
    <button onClick={()=>this.AccountforApproval(false,res._id,res.email,i)}>Not Aprroved</button>
    </>
    : 
    <> 
    <span style={{border :  "none" , background  :  "red" , borderRadius : "10px"}}>Not Aprroved</span> 
    <br />
    <button onClick={()=>this.AccountforApproval(true,res._id,res.email,i)}>Aprroved</button>
    </>
    
    }</td>
    <td>                                        <DeleteForeverIcon style={{border : "3px solid #e7acac" , borderRadius : "10px" }} onClick={()=>this.deleteProduct(res._id)}/>
</td>
  </tr>
            
        )
    })
}
  
 
</table> 
            </center>
            
        
          </div>
        )
    }
}

export default About;