import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"


import video1 from '../assets/images/vid.mp4';
import key2 from '../assets/images/key2.jpg';
import key3 from '../assets/images/key3.jpg';
import key4 from '../assets/images/key4.jpg';
import key5 from '../assets/images/key5.jpg';
import key6 from '../assets/images/key6.jpg';
import key7 from '../assets/images/key7.jpg';
import key8 from '../assets/images/key8.jpg';
import key9 from '../assets/images/key9.jpg';
import key1 from '../assets/images/key1.jpg';
import key10 from '../assets/images/key10.jpg';
import key11 from '../assets/images/key11.jpg';



import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
      window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       
 },[])

        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
           





        {/* <center>
        <h2  style={{color : "black",fontWeight : "bold"}}>

           <b>How to Use TAPITDABIT </b> 
           </h2>
        
<div className="container-fuild" style={{width :"91%", margin :  "auto"}}><div className="row" >

  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you1 ? <iframe src={youtube123.you1} className="youtube-link-responsive" width="100%" height="595px" title="YouTube video player" frameborder="0" allow="fullscreen;"  ></iframe>  :  ""}
  </div>

  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you2 ? <iframe src={youtube123.you2} frameborder="0" className="youtube-link-responsive" width="100%" height="595px" allow="fullscreen;"></iframe>  :  ""}
  </div>
  
  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
  {youtube123.you3 ? <iframe src={youtube123.you3} frameborder="0" className="youtube-link-responsive" width="100%" height="595px" allow="fullscreen;"></iframe>  :  ""}
  </div>
  </div>
  </div>
</center> */}








<br />
<br />



<center>

<section className="bg0 p-t-63 p-b-6">
          <div className="container dlfkflkl" style={{width:"40%",margin:"auto"}}>
            <div className="p-b-10">
              <h1  style={{color : "black",fontSize:"25px",fontWeight : "bold"}}>
              Connect & Electrify
              </h1>
             {/* <br /> */}
            </div>
            
           
            <div className="row isotope-grid fcjdfgljkk">
       
                <div className="col-sm-3 col-md-3 col-lg-3 p-b-35 isotope-item women" style={{padding :  "10px 10px"}}>
                <div className="block2">
             
                 
                  <h4 style={{color : "black",fontWeight : '600',fontSize:"12px"}}><a href="#Notes1">Key Notes</a>

</h4>
                  {/* <p style={{color : "black",cursor : "pointer"}} onClick={()=>props.history.push("/all-shop")}>Shop Now</p> */}
                </div>
              </div>
                <div className="col-sm-3 col-md-3 col-lg-3 p-b-35 isotope-item women" style={{padding :  "10px 10px"}}>
                <div className="block2">
             
                 
                  <h4 style={{color : "black",fontWeight : '600',fontSize:"12px"}}><a href="#Notes2">Workshops</a>

</h4>
                  {/* <p style={{color : "black",cursor : "pointer"}} onClick={()=>props.history.push("/all-shop")}>Shop Now</p> */}
                </div>
              </div>
                <div className="col-sm-3 col-md-3 col-lg-3 p-b-35 isotope-item women" style={{padding :  "10px 10px"}}>
                <div className="block2">
                
           
                 
                  <h4 style={{color : "black",fontWeight : '600',fontSize:"12px"}}><a href="#Notes3">Trips</a>
</h4>
             </div>
              </div>
                
            
                <div className="col-sm-3 col-md-3 col-lg-3 p-b-5 isotope-item women" style={{padding :  "10px 10px"}}>
                <div className="block2">
             
                  <h4 style={{color : "black",fontWeight : '600',fontSize:"12px"}}><a href="#Notes4">Camps</a>
</h4>
             </div>
              </div>
                
            

            </div>

          </div>
        </section>


</center>

{/* <br /> */}










<center>

<section className="bg0 p-t-0 p-b-60">
          <div className="container">
            {/* <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold"}}>
              How it works
              </h1>
             <p> <b>Go contactless in three simple steps !</b>  </p>
            </div> */}
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-12 col-md-12 col-lg-12 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
             
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><video muted autoPlay loop  style={{height: "400px",objectFit: "cover",width: "100%"}}>
                        <source src={video1}/>
                        </video></Link>
                    {/* <Link ><img src={cat1} alt="IMG-PRODUCT"  style={{height: "200px",objectFit: "cover",width: "200px"}}/></Link> */}
                  </div>
                  <br/>
                  {/* <p style={{color : "black",cursor : "pointer"}} onClick={()=>props.history.push("/all-shop")}>Shop Now</p> */}
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Explore Cast Of Minds unique savoir-faire, and timeless Spirit of Mental Health awareness

</h4>
                </div>
              </div>
               
            

            </div>

          </div>
        </section>


</center>


<br />



<center>

<section className="bg0 p-t-63 p-b-60" id="Notes1">
          <div className="container">
            <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold",textAlign:"left"}}>
              Key Notes
              </h1>
             {/* <br /> */}
             <p style={{color : "black",fontWeight : "200",textAlign:"left"}}> <b>Dedicated to study the human mind and use that understanding to help individuals, couples and families strengthen their relationships, overcome challenges and enjoy a higher quality of life with help of empathetic and ethical professionals.</b>  </p>
            </div>
            

<br />






            <Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={key4} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={key9} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>
             
             
</Carousel>













           
            <div className="row isotope-grid">
       
                {/* <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
             
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={key4} alt="IMG-PRODUCT"  style={{height: "500px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
                 
                </div>
              </div>
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={key9} alt="IMG-PRODUCT"   style={{height: "500px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
                 
             </div> */}
        
                {/* <div className="col-sm-4 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={key3} alt="IMG-PRODUCT"   style={{height: "400px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
//                   <br/>
//                   <h4 style={{color : "black",fontWeight : 'bold'}}>Read about the Cast Of Minds exhibitions, Workshops, and mental health trips and camps.
// </h4>
             </div>
              </div> */}
                
            

            </div>

          </div>
        </section>


</center>
<br />
<center>

<section className="bg0 p-t-63 p-b-60" id="Notes2">
          <div className="container">
            <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold",textAlign:"left"}}>
              Workshops
              </h1>
             {/* <br /> */}
             <p style={{color : "black",fontWeight : "200",textAlign:"left"}}> <b>The workshops will help the client To better understand the emotion and identify them as well as learn interventions to regulate, activate, and transform emotional states.
                </b>  </p>
            </div>
            
           <br />
            <Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={key6} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={key7} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>
             
             
</Carousel>

           

          </div>
        </section>


</center>
<br />
<center>

<section className="bg0 p-t-63 p-b-60" id="Notes3">
          <div className="container">
            <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold",textAlign:"left"}}>
              Mental Health Trips              </h1>
             {/* <br /> */}
             <p style={{color : "black",fontWeight : "200",textAlign:"left"}}> <b>It is scientifically proven that traveling can help reduce stress, boost self-esteem, enhance creativity, allow you to learn about new cultures and allow you to learn more about yourself. A desire to explore new lands is an integral part of human history, and it's important that we keep that tradition alive to maximise quality time and encourage mutual participation.</b>  </p>
            </div>
            <br />
           
            <Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={key1} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={key10} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>
             
             
</Carousel>

       

          </div>
        </section>


</center>
<br />
<center>

<section className="bg0 p-t-63 p-b-60" id="Notes4">
          <div className="container">
            <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold",textAlign:"left"}}>
              Mental Health Camps              </h1>
             {/* <br /> */}
             <p style={{color : "black",fontWeight : "200",textAlign:"left"}}> <b>Our Mental health camps will help form a tight-knit community and create an environment where all campers feel welcome to share their thoughts and opinions thus will boost higher confidence and self esteem as well as give a window to explore the world in a new and exciting way.</b>  </p>
            </div>
            <br />
           
            <Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={key11} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={key8} alt="" className='rferlkerlkerkl' style={{height: "450px",objectFit: "cover",width: "50%"}}/>

                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                      </Carousel.Item>
             
             
</Carousel>

        
          </div>
        </section>


</center>
<br />

<center>

<section className="bg0 p-t-63 p-b-60" style={{ background : "rgb(247, 207, 71)",padding:"30px 0px"}}>
          <div className="container">
            <div className="p-b-10">
              <p  style={{color : "black",fontWeight : "bold"}}>
              NEWSLETTER
              </p>
             <br />
             <p style={{color : "black",fontWeight : "500",fontSize:"34px"}}> <b>THE LATEST NEWS FROM THE<br/> WORLD OF CASTOFMINDS

</b>  </p>
            </div>
            
           
            <a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "20px 0px", background :"rgb(25 18 43)",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"}}><Link to="/" style={{color : "white"}}>Subscribe</Link></a>


             <p style={{color : "black",fontWeight : "500",fontSize:"17px"}}> <b>Be the first to learn about Cast Of Minds latest events, and Keynotes.


</b>  </p>
          </div>
        </section>


</center>


{/* <br />

<center>

<section className="bg0 p-t-63 p-b-60">
          <div className="container">
            <div className="p-b-10">
              <h1  style={{color : "black",fontWeight : "bold",textAlign:"left"}}>
              How it works
              </h1>
             <p style={{color : "black",fontWeight : "500",textAlign:"left"}}> <b>Go contactless in three simple steps !</b>  </p>
            </div>
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-4 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
             
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={cat1} alt="IMG-PRODUCT"  style={{height: "300px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Get a TAPITDABIT product
that works best for you
</h4>
                </div>
              </div>
                <div className="col-sm-4 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={cat2} alt="IMG-PRODUCT"   style={{height: "300px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Activate your product
to your profile when it arrives
</h4>
             </div>
              </div>
                
            
                <div className="col-sm-4 col-md-4 col-lg-4 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={cat2} alt="IMG-PRODUCT"   style={{height: "300px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
                  <br/>
                  <h4 style={{color : "black",fontWeight : 'bold'}}>Activate your product
to your profile when it arrives
</h4>
             </div>
              </div>
                
            

            </div>

          </div>
        </section>


</center>








 */}































            {/* <Carousel>
            <Carousel.Item interval={2000}>
                                <img src={ban39} alt="" className='efeeee'/>

                                  <Carousel.Caption>
                                  <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                            <div className="row">
                                            <div className="col-md-12">
                        <h1 className="ther1 m-b-20" style={{ marginBottom : "40px" ,fontSize : "45px" , color : "white",fontFeatureSettings: "kern ,  liga , pnum"}}><strong>Interactions beyond imaginations


 </strong></h1>
                        <p className="m-b-40 change-text-size" style={{fontSize : "23px" ,  textAlign: "left", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.



</p>
<a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "240px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a>
                      </div>
                                            </div>
                                          </div>
                                  </Carousel.Caption>
                                </Carousel.Item>
              
                        <Carousel.Item interval={2000}>
                                <img src={ban36} alt="" className='efeeee'/>

                                  <Carousel.Caption>
                                  <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                            <div className="row">
                                            <div className="col-md-12">
                        <h1 className="ther1 m-b-20" style={{ marginBottom : "40px" ,fontSize : "45px" , color : "white",fontFeatureSettings: "kern ,  liga , pnum"}}><strong>Interactions beyond imaginations


 </strong></h1>
                        <p className="m-b-40 change-text-size" style={{fontSize : "23px" ,  textAlign: "left", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.



</p>
<a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "240px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a>
                      </div>
                                            </div>
                                          </div>
                                  </Carousel.Caption>
                                </Carousel.Item>
                        <Carousel.Item interval={2000}>
                                <img src={ban37} alt="" className='efeeee'/>

                                  <Carousel.Caption>
                                  <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                            <div className="row">
                                            <div className="col-md-12">
                        <h1 className="ther1 m-b-20" style={{ marginBottom : "40px" ,fontSize : "45px" , color : "white",fontFeatureSettings: "kern ,  liga , pnum"}}><strong>Interactions beyond imaginations


 </strong></h1>
                        <p className="m-b-40 change-text-size" style={{fontSize : "23px" ,  textAlign: "left", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.



</p>
<a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "240px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a>
                      </div>
                                            </div>
                                          </div>
                                  </Carousel.Caption>
                                </Carousel.Item>
                        <Carousel.Item interval={2000}>
                                <img src={ban38} alt="" className='efeeee'/>

                                  <Carousel.Caption>
                                  <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                            <div className="row">
                                            <div className="col-md-12">
                        <h1 className="ther1 m-b-20" style={{ marginBottom : "40px" ,fontSize : "45px" , color : "white",fontFeatureSettings: "kern ,  liga , pnum"}}><strong>Interactions beyond imaginations


 </strong></h1>
                        <p className="m-b-40 change-text-size" style={{fontSize : "23px" ,  textAlign: "left", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.



</p>
<a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "240px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a>
                      </div>
                                            </div>
                                          </div>
                                  </Carousel.Caption>
                                </Carousel.Item>
                       
                     
                      
      </Carousel> */}
     


















































{/* 

<center>
<h1 style={{fontSize : "45px",  padding  : "40px 20px 60px 20px"}}>What you’ll get from Castofminds Interactions

</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><AirportShuttleIcon  className="icon-truck" style={{fontSize : "55px", color : "black"}}/></span>
                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Flexibility

</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>There’s no hassle with going to an office. Instead you can work with all of your clients from the comfort of your home, during hours that suits you.

</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                                <div className="icon mr-4 align-self-start">
                                    <span><ReplayIcon    style={{fontSize : "55px", color : "black"}}  /></span>
                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Additional income

</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>Your work at castofminds.com can be a major source of income or just a supplement to your current day-to-day therapy job.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span><PaymentIcon    style={{fontSize : "55px", color : "black"}}  /></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Top Quality Therapy
</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>You’ll be using a therapy platform that is based on well-researched therapy treatments. Helping your clients via worksheets, messages and live sessions (video, voice or text chat).



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span><PaymentIcon    style={{fontSize : "55px", color : "black"}}  /></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>No hassle

</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>We’ll take care of acquiring clients, billing, administrations etc so that you can focus on your clients 100%.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span><PaymentIcon    style={{fontSize : "55px", color : "black"}}  /></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Easy notification system

</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>From your dashboard and email notifications you can easily see what clients are expecting your feedback and upcoming chats with clients.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span><ContactSupportIcon    style={{fontSize : "55px", color : "black"}}  /></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Secure & confidential

</h2>
                                    <p style={{fontSize: "20px",
    fontWeight: "400" , color :"black"}}>All the communication is anonymously stored on our secure server, where only you and your client can access it.



</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;