import React , { useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';

const Header =(props)=> {
  

  let history = useHistory();
  useEffect(() =>{
    window.scrollTo(0, 0)
  },[])
        return (
            <div style={{background:"rgb(25, 18, 43)"}}>
          

         
          <br />
<br />





          <div class="container">
  <div class="title"  style={{width :  "100%"}}>
    <div><h1 className='h11' style={{color :"white"}}>About Cast Of Minds </h1>
    </div>
  </div>
  <div class="details"  style={{color :"white",width :  "100%",fontWeight:"400",fontSize:"18px"}}>
    <p>Welcome to Cast of Minds, where mental wellness transcends boundaries and embraces innovation. We’re not just a service provider; we’re your global beacon of hope, illuminating the path to a healthier mind.
<br />
<br />
Imagine a universe where every thought and emotion is nurtured to perfection. At Cast of Minds, our world-class experts are your guides, turning mental health challenges into stepping stones towards resilience and strength.
<br />
<br />

Dive into a vibrant tapestry of personalized therapy and revolutionary mental health resources. Cast of Minds isn’t just about care—it’s about transforming your mental landscape into a masterpiece of well-being.</p>
<Link className="btn" onClick={()=>history.push("/team")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "transparent" ,  color : "#F7CF47 "  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>Meet the team   <span style={{paddingLeft:"20px",fontSize: "19px",color :""}}> ► </span>   </Link>
  </div>
</div>

<br />









{/* <center>

<section className="bg0 p-t-63 p-b-60" style={{ background : "rgb(25,18,43)",padding:"30px 0px"}}>
          <div className="container">
            <div className="p-b-10">
              <br />
              <p  style={{color : "white",fontWeight : "bold",fontSize:"14px"}}>
              POCKET DYNAMOS

              </p>
              <div style={{width:"19px",height:"5px",background:"white",transform:"rotate(170deg)"}}></div>
             <br />
             <p style={{color : "white",fontWeight : "500",fontSize:"34px"}}> <b>We're designers, directors, strategists,
<br/> and awkward dancers.

</b>  </p>
             <p style={{color : "white",fontWeight : "200",fontSize:"14px"}}> <b>We have over a decade of experience in the creative industry, producing exciting experiences for brands that are as smart, as they are effective.



</b>  </p>
            </div>
            
          
          </div>
        </section>


</center>


<br />
<br />
<center>

<section className="bg0 p-t-63 p-b-60" id="Notes3">
          <div className="container">
            
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
             
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                   <p style={{fontSize:"11px",fontWeight:"bold",textAlign:"left",color:"rgb(25, 18, 43)"}}>Asad Vichi – CD / CO-FOUNDER
</p>
                   <p style={{fontSize:"46px",fontWeight:"bold",textAlign:"left",color:"rgb(25, 18, 43)"}}>Excalibur  Complex.
<br />
</p>
                   <p style={{fontSize:"17px",fontWeight:"bold",textAlign:"left",color:"black"}}>A hands-on creative with more than 4 years design and development experience.

<br />


</p>
                   <p style={{fontSize:"14px",fontWeight:"500",textAlign:"left",color:"black"}}>Asad has been responsible for bringing technical and design concepts to life for cast of minds. He is an equity trader and a web developer since past 3 years who is to become a psychologist. When Asad isn't busy educating people on the difference between Spot  and Futures, he can be found taking part in competitive cricket competitions across the city.

  <div style={{display:"flex",width:"130px",justifyContent: "space-between",margin:"25px 0px 10px 0px"}}>
                          <li><a href="https://www.facebook.com/kmelectronics/"><LocalPostOfficeIcon style={{fontSize:"17px"}}/></a></li>
                          <li><a href="https://www.instagram.com/kmelectronics/"><InstagramIcon style={{fontSize:"17px"}} /></a></li>
                          <li><a href="https://www.linkedin.com/in/kmelectronics/"><LinkedInIcon style={{fontSize:"17px"}} /></a></li>
                          <li><a href="https://twitter.com/kmelectronics/"><TwitterIcon style={{fontSize:"17px"}} /></a></li>
                        
  </div>


</p>
                  </div>
                
                </div>
              </div>
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={key13} alt="IMG-PRODUCT"   style={{height: "350px",objectFit: "contain",width: "100%"}}/></Link>
                  </div>
              
             </div>
              </div>
            

            </div>

          </div>
        </section>


</center>

<br />
<br />
<center>

<section className="bg0 p-t-63 p-b-60" id="Notes3">
          <div className="container">
            
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
             
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                   <p style={{fontSize:"11px",fontWeight:"bold",textAlign:"left",color:"rgb(25, 18, 43)"}}>Maliha Aslam – COO / CO-FOUNDER
</p>
                   <p style={{fontSize:"46px",fontWeight:"bold",textAlign:"left",color:"rgb(25, 18, 43)"}}>Excalibur Complex.
<br />
</p>
                   <p style={{fontSize:"17px",fontWeight:"bold",textAlign:"left",color:"black"}}>A Psychology Professional with more than 4 years experience in the field of Organizational Counseling.

<br />

</p>
                   <p style={{fontSize:"14px",fontWeight:"500",textAlign:"left",color:"black"}}>Maliha is a Licensed Professional Counselor with over 4 years experience in the field of Organizational Counseling. She is responsible for key operational activities at Cast of Minds. She provide counseling, therapy and social services for all age groups addressing a variety of mental, emotional, behavioral and social issues. Outside of work Maliha can be found teaching and exploring.

  <div style={{display:"flex",width:"130px",justifyContent: "space-between",margin:"25px 0px 10px 0px"}}>
                          <li><a href="https://www.facebook.com/kmelectronics/"><LocalPostOfficeIcon style={{fontSize:"17px"}}/></a></li>
                          <li><a href="https://www.instagram.com/kmelectronics/"><InstagramIcon style={{fontSize:"17px"}} /></a></li>
                          <li><a href="https://www.linkedin.com/in/kmelectronics/"><LinkedInIcon style={{fontSize:"17px"}} /></a></li>
                          <li><a href="https://twitter.com/kmelectronics/"><TwitterIcon style={{fontSize:"17px"}} /></a></li>
                        
  </div>


</p>
                  </div>
                
                </div>
              </div>
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    <Link ><img src={key12} alt="IMG-PRODUCT"   style={{height: "500px",objectFit: "cover",width: "100%"}}/></Link>
                  </div>
              
             </div>
              </div>
            

            </div>

          </div>
        </section>


</center>




 */}

          </div>
        )
    }


export default Header;