import React ,{ useState, useEffect} from 'react'
// import "./style.css"
import { ToastContainer, toast } from 'react-toastify';

import {Link} from "react-router-dom"
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';
// import x1 from '../assets/images/ban-13.jpeg'
import SendIcon from '@material-ui/icons/Send';
import x1 from '../assets/images/x1.jpg'

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ClearIcon from '@material-ui/icons/Clear';

// import logo from './images/img1.jpg'
// import logo from '../assets/images/ban-13.jpeg';

const AllProduct = (props) => {
  
    const [UserData , setUserData] = useState({})
    
    const [UserM , setUserM] = useState({})
    const [idproduct , setidproduct] = useState("")
    const [message , setmessage] = useState("")
    const [allmessage , setallmessage] = useState([])
    const [allshowmessage , setallshowmessage] = useState([])
    const [allvendor , setallvendor] = useState([])
    const [onevendor , setonevendor] = useState([])
    const [dis123 , setdis123] = useState(false)
    const [dis1 , setdis1] = useState(true)
    const [photo, setphoto] = useState("") 

    useEffect(() =>{
      if (JSON.parse(localStorage.getItem("user main therapist") ) && JSON.parse(localStorage.getItem("For Detail") )){
        let UserData1 = JSON.parse(localStorage.getItem("user main therapist") )
        window.scrollTo(0, 0)
        setUserData(UserData1)
        console.log(UserData1)

        
        fetch("https://asad-backend-two-lemon.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist") )._id,{
          method: "GET",
          headers :  {
            "Content-Type" : "application/json" , 
          } ,
      })
      .then(res151=>res151.json())
      .then(res161=>{
        console.log(res161)
        setUserData(res161)
        localStorage.setItem("user main therapist" , JSON.stringify(res161))
        
      })
       UserData1 = JSON.parse(localStorage.getItem("user main therapist")) 
        fetch("https://asad-backend-two-lemon.vercel.app/allUser",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res15=>res15.json())
      .then(res16=>{
        console.log(res16,UserData1)
          const A = []
          if(UserData1.ChatName){
             UserData1.ChatName.map((res8,i)=>{
               res16.map((res9,j)=>{
                 console.log(res8,res9)
                 if(res8.user_id === res9._id){
                  A.push(res9)
                }
              })
            })
          }
          console.log(A)
          setallvendor(A)
          if(A.length > 0)
          setonevendor([JSON.parse(localStorage.getItem("For Detail") )])
          
              
      })

        fetch("https://asad-backend-two-lemon.vercel.app/AllUserShowMessage",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1)
            const Chat1 = res1.filter((user,j)=>{
              console.log(user.vendor_id === JSON.parse( localStorage.getItem("user main therapist"))._id)
              if( user.vendor_id === JSON.parse( localStorage.getItem("user main therapist"))._id)  {
                return user
                }
              })
              console.log(Chat1)
                console.log(res1)
            // setallshowmessage(Chat1)
            setallmessage(res1)
            setallchanger(JSON.parse(localStorage.getItem("For Detail") ),1,Chat1)
        })

        
        
      }
      else{
        props.history.push("/login")
        
      }
      const inter1 = setInterval(()=>{
        if(JSON.parse(localStorage.getItem("user main therapist"))){
          fetch("https://asad-backend-two-lemon.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } 
        })
        .then(res5=>res5.json())
        .then(res6=>{
          console.log(res6)
          if(res6.Notification){
            toast("New Message");
            fetch("https://asad-backend-two-lemon.vercel.app/changeNoti/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
            method: "POST",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
         body : JSON.stringify({
                   
         })
        })
        .then(res5=>res5.json())
        .then(res6=>{

        })
          }
  
        })
         }
       
        
      },7000)
      
     
      const inter = setInterval(()=>{

        fetch("https://asad-backend-two-lemon.vercel.app/AllUserShowMessage",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1)
            const Chat1 = res1.filter((user,j)=>{
              console.log(user.vendor_id === JSON.parse( localStorage.getItem("user main therapist"))._id)
              if( user.vendor_id === JSON.parse( localStorage.getItem("user main therapist"))._id)  {
                return user
                }
              })
              console.log(Chat1)
                console.log(Chat1)
            // setallshowmessage(Chat1)
            setallmessage(res1)


            const Chat2 = Chat1.filter((user,j)=>{
              // console.log(user.vendor_id, JSON.parse(localStorage.getItem("user main therapist"))._id , user.user_id ,  onevenderdetail._id)
              if( user.vendor_id ===  JSON.parse(localStorage.getItem("user main therapist"))._id && user.user_id ===  JSON.parse(localStorage.getItem("For Detail") )._id)  {
                return user
              }
            })
            setallshowmessage(Chat2)

        })

      },6000)
      return () => {
        clearInterval(inter);
        clearInterval(inter1);
      
      }     },[])


      const setphoto1 = (e)=>{
        console.log(e)
       setphoto(e.target.files[0]) 
      //  setphoto2(e.target.files[0]) 
        
    }
  
  

     const setMess1 = (e ) =>{

      e.preventDefault()
console.log(dis1)
if(dis1){
  
  setdis1(false)
  
  
        console.log(message === "")
        // if(message === ""){
        //   swal("Type a Message");
        //   return
        // }
        if(message === ""){
          if(photo === ""){
            setdis1(true)

            swal("Type a Message");
            return
          }
          else{

            const data = new FormData()
            data.append("file", photo)
            data.append("upload_preset", "castofminds")
            data.append("cloud_name", "dskcqccr4")
            fetch("https://api.cloudinary.com/v1_1/dskcqccr4/image/upload",{ 
                method : "POST",
                body : data , 
            })
            .then(res=>res.json())
            .then((res2)=>{

            let date = new Date()
            let hour= date.getHours()
            let minnte= date.getMinutes()
    
    
            if(date.getHours() < 10) hour = "0"+ date.getHours()
            if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())
    
            let c =hour+ ":"+minnte
    
            console.log(hour+ ":"+minnte,c, )
            const msgDetails = {
                message : message , 
                photo  :res2.url ? res2.url : "", 
                user_id :onevendor[0]._id ,
                vendor_id :JSON.parse( localStorage.getItem("user main therapist"))._id ,
                // product_id :idproduct ,
                user : false,
                vendor : true,
                time : c
            }
    
    
            let v = 0
    
                  fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
          method: "POST",
          headers :  {
              "Content-Type" : "application/json" , 
          } ,
          body : JSON.stringify({
             ...msgDetails
          }),
      })
      .then(res5=>res5.json())
      .then((res8)=>{ 
          if(res8.message){
            setUserData({
              ...UserData ,
              ChatName : res8.message
            })
            localStorage.setItem("user main therapist" , JSON.stringify({
              ...UserData ,
              ChatName : res8.message
            }))
    
          }
      })
    
    
      fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                           ...msgDetails
                        }),
                    })
                    .then(res5=>res5.json())
                    .then((res8)=>{ 
                        if(res8.Error){
                            console.log("Message")
                        }
                    })
    
    
    
            console.log(msgDetails)
            fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser1",{
            method: "POST",
            headers :  {
                "Content-Type" : "application/json" , 
            } ,
            body : JSON.stringify({
               ...msgDetails
            }),
        })
        .then(res=>res.json())
        .then((res2)=>{ 
            console.log(res2)    
            setallshowmessage([...allshowmessage , msgDetails])
                setallmessage([...allmessage , msgDetails])   
            setdis1(true)
          setphoto("")
          setmessage("")
         
        })
        .catch(err=>{
          setdis1(true)
    
          console.log(err)}
          )
    
    
        })
            
    






          }
        }
        else{
          let date = new Date()
          let hour= date.getHours()
          let minnte= date.getMinutes()
  
  
          if(date.getHours() < 10) hour = "0"+ date.getHours()
          if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())
  
          let c =hour+ ":"+minnte
  
          console.log(hour+ ":"+minnte,c, )
          const msgDetails = {
              message : message , 
              user_id :onevendor[0]._id ,
              photo  : "", 

              vendor_id :JSON.parse( localStorage.getItem("user main therapist"))._id ,
              // product_id :idproduct ,
              user : false,
              vendor : true,
              time : c
          }
  
  
          let v = 0
  
                fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        if(res8.message){
          setUserData({
            ...UserData ,
            ChatName : res8.message
          })
          localStorage.setItem("user main therapist" , JSON.stringify({
            ...UserData ,
            ChatName : res8.message
          }))
  
        }
    })
  
  
    fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
                      method: "POST",
                      headers :  {
                          "Content-Type" : "application/json" , 
                      } ,
                      body : JSON.stringify({
                         ...msgDetails
                      }),
                  })
                  .then(res5=>res5.json())
                  .then((res8)=>{ 
                      if(res8.Error){
                          console.log("Message")
                      }
                  })
  
  
  
          console.log(msgDetails)
          fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser1",{
          method: "POST",
          headers :  {
              "Content-Type" : "application/json" , 
          } ,
          body : JSON.stringify({
             ...msgDetails
          }),
      })
      .then(res=>res.json())
      .then((res2)=>{ 
          console.log(res2)    
          setallshowmessage([...allshowmessage , msgDetails])
              setallmessage([...allmessage , msgDetails])   
          setdis1(true)
        setphoto("")
        setmessage("")
       
      })
      .catch(err=>{
        setdis1(true)
  
        console.log(err)}
        )
  
  
          
        }
      }
    }
     const setMess21 = ( ) =>{
      console.log(dis1)
      if(dis1){
        
        setdis1(false)
        
        
              console.log(message === "")
              // if(message === ""){
              //   swal("Type a Message");
              //   return
              // }
              if(message === ""){
                if(photo === ""){
                  swal("Type a Message");
                  return
                }
                else{
      
                  const data = new FormData()
                  data.append("file", photo)
                  data.append("upload_preset", "castofminds")
                  data.append("cloud_name", "dskcqccr4")
                  fetch("https://api.cloudinary.com/v1_1/dskcqccr4/image/upload",{ 
                      method : "POST",
                      body : data , 
                  })
                  .then(res=>res.json())
                  .then((res2)=>{
      
                  let date = new Date()
                  let hour= date.getHours()
                  let minnte= date.getMinutes()
          
          
                  if(date.getHours() < 10) hour = "0"+ date.getHours()
                  if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())
          
                  let c =hour+ ":"+minnte
          
                  console.log(hour+ ":"+minnte,c, )
                  const msgDetails = {
                      message : message , 
                      photo  :res2.url ? res2.url : "", 
                      user_id :onevendor[0]._id ,
                      vendor_id :JSON.parse( localStorage.getItem("user main therapist"))._id ,
                      // product_id :idproduct ,
                      user : false,
                      vendor : true,
                      time : c
                  }
          
          
                  let v = 0
          
                        fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
                method: "POST",
                headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                body : JSON.stringify({
                   ...msgDetails
                }),
            })
            .then(res5=>res5.json())
            .then((res8)=>{ 
                if(res8.message){
                  setUserData({
                    ...UserData ,
                    ChatName : res8.message
                  })
                  localStorage.setItem("user main therapist" , JSON.stringify({
                    ...UserData ,
                    ChatName : res8.message
                  }))
          
                }
            })
          
          
            fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
                              method: "POST",
                              headers :  {
                                  "Content-Type" : "application/json" , 
                              } ,
                              body : JSON.stringify({
                                 ...msgDetails
                              }),
                          })
                          .then(res5=>res5.json())
                          .then((res8)=>{ 
                              if(res8.Error){
                                  console.log("Message")
                              }
                          })
          
          
          
                  console.log(msgDetails)
                  fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser1",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
                  body : JSON.stringify({
                     ...msgDetails
                  }),
              })
              .then(res=>res.json())
              .then((res2)=>{ 
                  console.log(res2)    
                  setallshowmessage([...allshowmessage , msgDetails])
                      setallmessage([...allmessage , msgDetails])   
                  setdis1(true)
          
                setmessage("")
                setphoto("")

              })
              .catch(err=>{
                setdis1(true)
          
                console.log(err)}
                )
          
          
              })
                  
          
      
      
      
      
      
      
                }
              }
              else{
                let date = new Date()
                let hour= date.getHours()
                let minnte= date.getMinutes()
        
        
                if(date.getHours() < 10) hour = "0"+ date.getHours()
                if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())
        
                let c =hour+ ":"+minnte
        
                console.log(hour+ ":"+minnte,c, )
                const msgDetails = {
                    message : message , 
                    user_id :onevendor[0]._id ,
                    photo  : "", 
      
                    vendor_id :JSON.parse( localStorage.getItem("user main therapist"))._id ,
                    // product_id :idproduct ,
                    user : false,
                    vendor : true,
                    time : c
                }
        
        
                let v = 0
        
                      fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
              method: "POST",
              headers :  {
                  "Content-Type" : "application/json" , 
              } ,
              body : JSON.stringify({
                 ...msgDetails
              }),
          })
          .then(res5=>res5.json())
          .then((res8)=>{ 
              if(res8.message){
                setUserData({
                  ...UserData ,
                  ChatName : res8.message
                })
                localStorage.setItem("user main therapist" , JSON.stringify({
                  ...UserData ,
                  ChatName : res8.message
                }))
        
              }
          })
        
        
          fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
                            method: "POST",
                            headers :  {
                                "Content-Type" : "application/json" , 
                            } ,
                            body : JSON.stringify({
                               ...msgDetails
                            }),
                        })
                        .then(res5=>res5.json())
                        .then((res8)=>{ 
                            if(res8.Error){
                                console.log("Message")
                            }
                        })
        
        
        
                console.log(msgDetails)
                fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser1",{
                method: "POST",
                headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                body : JSON.stringify({
                   ...msgDetails
                }),
            })
            .then(res=>res.json())
            .then((res2)=>{ 
                console.log(res2)    
                setallshowmessage([...allshowmessage , msgDetails])
                    setallmessage([...allmessage , msgDetails])   
                setdis1(true)
        
              setmessage("")
              setphoto("")

            })
            .catch(err=>{
              setdis1(true)
        
              console.log(err)}
              )
        
        
                
              }
            }

    }



      const setChangeRoute = () =>{
  props.history.push("/login")
  localStorage.removeItem("AdminData")
  localStorage.removeItem("AllUserDetails")
  localStorage.removeItem("Finance")
  localStorage.removeItem("OrderrDetails")
  localStorage.removeItem("Admin")

}



const setallchanger = (onevenderdetail,i,data5)=>{
  console.log(data5)
  console.log(UserData)

  setonevendor([onevenderdetail])
  const Chat1 = data5.filter((user,j)=>{
    console.log(user.vendor_id, JSON.parse(localStorage.getItem("user main therapist"))._id , user.user_id ,  onevenderdetail._id)
    if( user.vendor_id ===  JSON.parse(localStorage.getItem("user main therapist"))._id && user.user_id ===  onevenderdetail._id)  {
      return user
    }
  })
  
  console.log(Chat1)
  // setidproduct( JSON.parse(localStorage.getItem("user main therapist")).ChatName[i].product_id)
  //     console.log( JSON.parse(localStorage.getItem("user main therapist")).ChatName[i].product_id)

  setallshowmessage(Chat1)







  const fg =  JSON.parse(localStorage.getItem("user main therapist")).ChatName.map((res78,y)=>{
    if(y === i ){
       return {
      ...res78 , new : false
    }
    }
    else return res78
  })
  setUserData({
    ... JSON.parse(localStorage.getItem("user main therapist")) ,
    ChatName : fg
  })
  localStorage.setItem("User" , JSON.stringify({
    ... JSON.parse(localStorage.getItem("user main therapist")) ,
    ChatName : fg
  })) 

  fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchangerinClick",{
      method: "POST",
      headers :  {
          "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({
         k :  fg , user_id :  JSON.parse( localStorage.getItem("user main therapist"))._id
      }),
  })
  .then(res5=>res5.json())
  .then((res8)=>{ 
    console.log(res8)
        })
}




    return (


    



      <div className="fgjgfgjkjl">
             <div className="container container4994" style={{padding :  "0px"}}>
        

        {/* <div className="leftSide rtrtrtt">
            <div className="header">
                <div>
                <h5>All Clients</h5>
                </div>
              
              </div>
             
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                        <div className="block" onClick={()=>setallchanger(res,i)}>
                        <div className="imgbx">
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        <div className="details">
                        { JSON.parse(localStorage.getItem("user main therapist")).ChatName[i].new &&UserData.ChatName[i].new ? 
                          <div className="listHead" style={{flexDirection : "column"}}>
                            <h4>{res.Name}</h4>

                        <p  style={{color : "black" , fontWeight : "bold" , fontSize : "10px",}}>New message .</p>
                          </div>
                        
                        :
                        <div className="listHead">
                            <h4>{res.Name}</h4>

                          </div>
                        
                      }
                        
                        </div>
                        {UserData.ChatName[i].new && UserData.ChatName[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                           :
                           ""

                           }
                      </div> 
                    )
                })
                    
                }
              
              </div>
        </div>

    {! dis1  ?
  <div className="leftSide rtrtrtt2323" style={{maxWidth : "157px"}}>
            <div className="header">
                <div>
                <p style={{ padding: "16px 25px 1px 9px",fontSize: "50px"}} onClick={()=>setdis1(dis1 ? false : true)}>≡</p>
                </div>
              
              </div>
             
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                        <div className="block" onClick={()=>setallchanger(res,i)}>
                        <div className="imgbx">
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        <div className="details">
                        {UserM.Chat[i].new && UserM.Chat[i].new ? 
                          <div className="listHead" style={{flexDirection : "column"}}>
                            <h4>{res.Name}</h4>

                        <p  style={{color : "black" , fontWeight : "bold" , fontSize : "10px",}}>New message .</p>
                          </div>
                        
                        :
                        <div className="listHead">
                            <h4>{res.Name}</h4>

                          </div>
                        
                      }
                        
                        </div>
                        {UserData.ChatName[i].new && UserData.ChatName[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                           :
                           ""

                           }
                      </div> 
                    )
                })
                    
                }
              
              </div>
        </div>


: 
          




        <div className="leftSide rtrtrtt2323" style={{maxWidth : "77px"}}>
            <div className="header">
                <div>
                <p style={{ padding: "16px 25px 1px 9px",fontSize: "50px"}} onClick={()=>setdis1(dis1 ? false : true)}>≡</p>
                </div>
              
              </div>
         
              <div className="chatlist">
                {allvendor.map((res,i)=>{
                    return(
                        <div className="block" onClick={()=>setallchanger(res,i)}  style={{padding : "10px"}}>
                        <div className="imgbx" style={{margin : "0px"}}>
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        {UserData.ChatName[i].new && UserData.ChatName[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                           :
                           ""

                           }
                      </div> 
                    )
                })
                    
                }
              
              </div>
        </div>
        } */}
      
        <div className="rightSide">
          <div className="header">
          {
            onevendor.map((res,i)=>{
                    return(
                
                
                <div className="imgText">
              <div className="userimg">
                <img src={x1} alt="Foto Usuário" className="cover" />
              </div>
              <h4 style={{color  :"white",marginTop: "13px"}}>{res.Name} <br /> 
              </h4>
            </div>
              )
            })
            
          }
          </div>
          <div className="chatBox">
              {allshowmessage.map((res,i)=>{
                  if(!res.user){
                    if(res.message) {
                      return(
                        <div className="message my_message">
                                <p>
                               <img src={JSON.parse(localStorage.getItem("user main therapist")) && JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto ? JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginRight:"15px"}}/>
  
                                  {res.message}  {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}
                                {/* <br /> */}
                                {/* <span>12:15</span> */}
                                </p>
                        </div>
  
                  ) 

                    }

                  else{
                    return(
                    <div className="message my_message">
                            <p>
                           <img src={JSON.parse(localStorage.getItem("user main therapist")) && JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto ? JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginRight:"15px"}}/>

                           <img src={res.photo} alt="" className="change-mob-pho"  style={{maxWidth: "350px",
    maxHeight: "450px",
    objectFit: "cover",
}}/>
 {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}
                            {/* <br /> */}
                            {/* <span>12:15</span> */}
                            </p>
                    </div>

              )  
                  
                 
                  }




                  }
                  else{
                    if(res.message) {

                    return(

                        <div className="message frnd_message">
                            <p>{res.message} 
                            <img src={onevendor[0] && onevendor[0].ProfilePhoto ? onevendor[0].ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginLeft:"15px"}}/>
                            {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}

                            {/* <br /> */}
                            {/* <span>16:15</span> */}
                            </p>
                        </div>
                    )
                    }
                    
                   else {

                    return(

                        <div className="message frnd_message">
                            <p>
                           <img src={res.photo} alt="" className="change-mob-pho"  style={{maxWidth: "350px",
    maxHeight: "450px",
    objectFit: "cover",
}}/>
                            <img src={onevendor[0] && onevendor[0].ProfilePhoto ? onevendor[0].ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginLeft:"15px"}}/>
                            {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}

                            {/* <br /> */}
                            {/* <span>16:15</span> */}
                            </p>
                        </div>
                    )
                    }


                  }
                 
              })

              }
         
          </div>
        
          {/* <form> */}
          <form onSubmit={(e)=>{setMess1(e)}}>
          <div className="chatbox_input">
          <div class="fab_field" style={{minWidth:"90%"}}>
  
<div style={{display : "flex",minWidth:"100%"}}>
    {/* <a id="fab_send" class="fab"  onClick={()=>setMess1()}><SendIcon/></a> */}
    <a id="fab_camera" class="fab"><PhotoCameraIcon for="files" style={{marginTop:"11px" , marginRight:"5px"}}/>  <input type="file" id="files" style={{opacity : 0 ,width: "29px",position: "absolute",zIndex: 1,left: '4px'}}  onChange={(e)=>setphoto1(e)} /></a>
    {/* <textarea id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message" value={photo.name}  style={{width : "90%"}}></textarea> */}
    {photo ? 
 <>
  <input type="text"  id="chatSend" value={photo.name}   />

  <ClearIcon style={{marginTop : "10px"}}  onClick={()=>{
setphoto("")

}}/>

</>
:
<input type="text"  id="chatSend"  placeholder="Type a message ..." value={message} onChange={(e)=>setmessage(e.target.value)} />

}
</div>
{/* <textarea id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message" value={message} onChange={(e)=>setmessage(e.target.value)}  ></textarea> */}
  </div>
            {/* <input type="text" placeholder="Type a message ..." value={message} onChange={(e)=>setmessage(e.target.value)}  /> */}
            <button type="submit" style={{border:"none",background:"transparent"}}><SendIcon name="paper-plane-outline"/></button>
          </div>
        </form>
        </div>
      </div> 
        </div>

    )
}

export default AllProduct
