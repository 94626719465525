import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";
import swal from 'sweetalert';

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
// import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import ban23 from '../assets/images/ban-23.jpg';
// import ban24 from '../assets/images/ban-24.jpg';
// import ban25 from '../assets/images/ban-25.jpeg';
// import ban26 from '../assets/images/ban-26.jpeg';
import ban31 from '../assets/images/ban-31.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)
    const [theripist , settheripist] = useState([])

    // const [product1 , setproduct1] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )

      fetch("https://asad-backend-two.vercel.app/all-therispist",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
    })
    .then(res=>res.json())
    .then(res1=>{
      console.log(res1)
        settheripist(res1)
    })
       
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])




   const AccountforApproval = (e,id,email,i) =>{
      console.log(e,id,email,i)
               if(e){
                  fetch("https://asad-backend-two.vercel.app/Approval",{
              method: 'POST' , 
              headers :  {
                "Content-Type" : "application/json" , 
              } , 
              body : JSON.stringify({
                Approved  : true,
                id ,
                email
              })
            })
            .then((res)=>res.json())
            .then((res2)  =>{
                if (res2 !== null && !res2.Error  ){
                      // console.log(res2);
                   

                      swal("SucessFully Change Reload"  )
                }
                else{
                  swal("Try Again"  )
                }
      
              })
      
               }
      
               else{
                  fetch("https://asad-backend-two.vercel.app/Approval",{
              method: 'POST' , 
              headers :  {
                "Content-Type" : "application/json" , 
              } , 
              body : JSON.stringify({
                Approved  : false,
                id ,
                email
              })
            })
            .then((res4)=>res4.json())
            .then((res22)  =>{
                // console.log(res22)
                if (res22 !== null && !res22.Error  ){
                      // console.log(res22);
                     
                      swal("SucessFully Change Reload"  )

                  
                }
                else{
                  swal("Try Again"  )
                }
      
              })
      
               }
      
            }

    
    

    
        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
            {/* End Top Search */}
            {/* Start Slider */}
     
            <div className="col-xl-12 col-lg-12 col-md-12">
                    <table className="table table-hover" style={{background : "white" , borderRadius : "10px"}}>
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">fname</th>
                          <th scope="col">lname</th>
                          <th scope="col">email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">StreetAddress</th>
                          <th scope="col">City</th>
                          <th scope="col">Country</th>
                          <th scope="col">pass</th>
                          <th scope="col">Title</th>
                          <th scope="col">Gender</th>
                          <th scope="col">StateProvince</th>
                          <th scope="col">TimeZone</th>
                          <th scope="col">Zippostal</th>
                          <th scope="col">CompanyName</th>
                          {/* <th scope="col">TaxpayerIdentificationNumber</th> */}
                          <th scope="col">DateOfBirth</th>
                          <th scope="col">Initialsofyourcredentials</th>
                          <th scope="col">ProfilePhoto</th>
                          <th scope="col">UploadYourCertificate</th>
                          <th scope="col">IdentityDocumentPhoto</th>
                          <th scope="col">TherapistType</th>
                          <th scope="col">CredentialType</th>
                          <th scope="col">Summaryofyoureducation</th>
                          <th scope="col">disciplinaryactions</th>
                          <th scope="col">licensingstates</th>
                          <th scope="col">MonFriwouldyouprefer</th>
                          <th scope="col">hoursperweek</th>
                          <th scope="col">currentworksituation</th>
                          <th scope="col">selected_specialities</th>
                          <th scope="col">selected_therapy_types</th>
                          <th scope="col">selected_treatment_orientation</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        theripist.map((res,i)=>{
                              return <>
                              <tr key={i}>
                                        <th>{i+1}</th>
                                        <td>{res.fname}</td>
                                        <td>{res.lname}</td>
                                        <td>{res.email}</td>
                                        <td>{res.TaxpayerIdentificationNumber}</td>
                                        <td>{res.StreetAddress}</td>
                                        <td>{res.City}</td>
                                        <td>{res.Country}</td>
                                        <td>{res.pass}</td>
                                        <td>{res.Title}</td>
                                        <td>{res.Gender}</td>
                                        <td>{res.StateProvince}</td>
                                        <td>{res.TimeZone}</td>
                                        <td>{res.Zippostal}</td>
                                        <td>{res.CompanyName}</td>
                                        {/* <td>{res.TaxpayerIdentificationNumber}</td> */}
                                        <td>{res.DateOfBirth}</td>
                                        <td>{res.Initialsofyourcredentials}</td>
                                        <td><img src={res.ProfilePhoto} alt="" style={{width:"200px",height:"200px",borderRadius:"50px"}}/></td>
                                        <td><img src={res.UploadYourCertificate} alt="" style={{width:"200px",height:"200px",borderRadius:"50px"}}/></td>
                                        <td><img src={res.IdentityDocumentPhoto} alt="" style={{width:"200px",height:"200px",borderRadius:"50px"}}/></td>
                                        <td>{res.TherapistType}</td>
                                        <td>{res.CredentialType}</td>
                                        <td>{res.Summaryofyoureducation}</td>
                                        <td>{res.disciplinaryactions}</td>
                                        <td>{res.licensingstates}</td>
                                        <td>{res.MonFriwouldyouprefer}</td>
                                        <td>{res.hoursperweek}</td>
                                        <td>{res.currentworksituation}</td>
                                        <td>{res.selected_specialities}</td>
                                        <td>{res.selected_therapy_types}</td>
                                        <td>{res.selected_treatment_orientation}</td>
                                        {/* {res.isApproval ?
                                        <td> 
                                          <button style={{border :  "none" , background  :  "yellow" , borderRadius : "10px"}}>Active</button>
                                          <p onClick={()=>StatusApproval(false,res._id,res.Email)} style={{color : "skyblue" , cursor : "pointer"}}>Click For Non-Active</p> 
                                        </td>
                                        :
                                        <td> 
                                          <button style={{border :  "none" , background  :  "red" , borderRadius : "10px"}}>Non-Active</button>
                                          <p  onClick={()=>StatusApproval(true,res._id,res.Email)} style={{color : "skyblue" , cursor : "pointer"}}>Click For Active</p> 
                                        </td>

                                        } */}
                                          <td>{res.isApprovl ? 
    <> 
    <span style={{border :  "none" , background  :  "yellow" , borderRadius : "10px"}}>Aprroved</span>
    <button onClick={()=>AccountforApproval(false,res._id,res.email,i)}>Not Aprroved</button>
    </>
    : 
    <> 
    <span style={{border :  "none" , background  :  "red" , borderRadius : "10px"}}>Not Aprroved</span> 
    <button onClick={()=>AccountforApproval(true,res._id,res.email,i)}>Aprroved</button>
    </>
    
    }</td>
                                    </tr>
                                   
                                    </>
                          })
                      }

                      {
                        theripist.length === 0 
                        ? 
                        <tr>
                          <th scope="col" colSpan="10" style={{textAlign : "center"}}>No data for showing</th>
                        </tr>
                        :
                         ""
                      }
                          <tr>
                          <th scope="col"></th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Url</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Action</th>
                        </tr>
                      <tr>
                       
                        </tr>
                      </tbody>
                    </table>
                  </div>
          </div>
        )
    
}

export default Home;