import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import tb2 from '../assets/images/tb2.png';
import tb3 from '../assets/images/tb3.png';

import tb7 from '../assets/images/tb7.png';
import tb9 from '../assets/images/tb9.png';
import tb10 from '../assets/images/tb10.png';
import tb11 from '../assets/images/tb11.png';

import ban1111 from '../assets/images/ban-11.jpeg';

import ban10 from '../assets/images/ban-10.JPG';

import ban20 from '../assets/images/ban-20.JPG';
import ban21 from '../assets/images/ban-21.JPG';
import ban46 from '../assets/images/ban-46.jpeg';

import "react-multi-carousel/lib/styles.css";





import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
        window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }


 },[])

        return (
            
            <div>
           


           <Carousel  style={{background : "rgb(25, 18,43)"}}>
              
              <Carousel.Item interval={2000} style={{background : "rgb(25, 18,43)"}}>
                      <img src={ban46} alt="" className='efeeee' style={{opacity: 0.2}}/>

                        <Carousel.Caption>
                        <div className="container"  style={{top : "-353px", position : "absolute",width : "100%"}}>
                                  <div className="row">
                                  <div className="col-md-12">
              {/* <h1 className="m-b-20 the456" style={{ marginBottom : "40px" ,fontSize : "45px" , color : "white"}}><strong>Happy employees is
<br /> key for business success


</strong></h1> */}
<center>
  <p className="m-b-40 change-text-size the456" style={{fontSize : "20px", fontWeight :"bold" ,  textAlign: "center", marginBottom : "10px"    ,color  :"#F7CF47",textTransform : "uppercase" }}>Happy employees is
 key for business success
</p> 
<p className="m-b-40 change-text-size the456" style={{fontSize : "45px" ,  textAlign: "center", marginBottom : "10px" }}>Elevate Your Business


</p>  
<p className="m-b-40 change-text-size the456" style={{fontSize : "20px" ,  textAlign: "center", marginBottom : "40px" }}>Don’t let your working life seep stress into the enjoyment and fulfillment of life.

</p>  
</center>

              <p  style={{ textAlign: "center"}}><Link to="/business-dashboard"><a className="btn hvr-hover" style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"bold" , border : "1px solid white"}}>Get Started</a></Link></p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
              {/* <Carousel.Item interval={2000} style={{background : "rgb(25, 18,43)"}}>
                      <img src={ban24} alt="" className='efeeee' />

                        <Carousel.Caption>
                        <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
                                  <p className="m-b-40 change-text-size the456" style={{fontSize : "35px", fontWeight :"500" ,  textAlign: "left", marginBottom : "40px" }}>Happy employees is
<br /> key for business success

</p> 
              <p className="m-b-40 change-text-size the456" style={{fontSize : "23px" ,  textAlign: "left", marginBottom : "40px" }}>Don’t let your working life seep stress into the enjoyment and fulfillment of life.



</p>
              <p  style={{ textAlign: "left"}}><a className="btn hvr-hover" href="#" style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"bold" , border : "1px solid white"}}>Get Started</a></p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item> */}
         
</Carousel>
<div className="main-top" style={{background : "rgb(25 18 43)"}}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
         
          <div className="text-slid-box">
            <div id="offer-box" className="carouselTicker">
              <ul className="offer-box">
                <li className='the456' style={{textAlign : "center",fontSize:  "18px",padding: "17px 10px 1px 11px",  fontWeight : "bold"}}>
                  <marquee behavior="" direction=""
                   scrollAmount="12">
                    Life Long Discount: up to 33% off depending on no. of employees. Business pricing »


                  </marquee>
                </li>
             
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




























{/* 

<center>
<h3 className='the456' style={{fontWeight : "500", fontSize : "30px",  padding  : "40px 20px 0px 20px"}}>So how will we help your employees?

</h3>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                               
                                <div className="text">
                      
                                    <p className='the456' style={{fontSize: "20px",
    fontWeight: "400"}}>Our whole operation is based on cognitive behavioral therapy (CBT), which is one of the most commonly used psychotherapeutic approaches for treating mental health problems. CBT helps you to identify, challenge and overcome your dysfunctional thoughts, behaviors and emotions. 
    <br />
    <br />

    Checkout the video and Dr. Elizabeth Lombardo, PhD will tell you all about what’s included in your subscription. Or read below for details.

</p>
<br />
<center>

<p  className='the456' ><a className="btn hvr-hover" href="#" style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"500" , border : "1px solid white",  padding : "8px 16px"}}>Get Started</a></p>
</center>

                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                              
                                <div className="text">
                             <img src={ban1111} alt="" style={{width : "300px" , height: "300px", borderRadius :"40px"}}/>
                                </div>
                            </div>
                            
             
                        </div>
                    </div>
                </div>



<br /> */}
{/* 
<center>
<h1 className='the456' style={{fontSize : "45px",  padding  : "40px 20px 60px 20px"}}>Mental health care at work is nothing but an investment


</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container" >
                        <div className="row">
                        <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                              
                              <div className="text">
                           <img src={ban19} alt="" style={{width : "330px" , height: "400px", borderRadius :"40px", objectFit : "cover"}}/>
                              </div>
                          </div>
                          
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                               
                                <div className="text">
                                    <br />
                                    <br />
                      
                                    <p className='the456' style={{fontSize: "20px",
    fontWeight: "bold"}}>Improve your team’s wellbeing, transform the cultur while increasing profit.

    <br />
    <br />


</p>
<br />
<center>

<p  className='the456'><a className="btn hvr-hover" href="#" style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"500" , border : "1px solid white",  padding : "8px 16px"}}>Get Started</a></p>
</center>

                                </div>
                            </div>
                           
             
                        </div>
                    </div>
                </div> */}



<center>
<h1 className='the456' style={{fontSize : "45px",  padding  : "40px 20px 0px 20px",background  :"rgb(25 18 43)",color : "white"}}>Mental health care at work is nothing but an investment


</h1>

</center>

<div className="site-section site-section-sm site-blocks-1 backk" style={{background  :"rgb(25 18 43)",color : "white"}}>
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                                
                                <div className="text">
                                    <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>$1 trillion


</h2>
                                    <p className='the456'  style={{fontSize: "17px",
    fontWeight: "500"}}>Depression and anxiety cost the global economy an estimated $1 trillion each year in lost productivity



</p>
                                </div>
                            </div>
<div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
   
    <div className="text">
        <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>1 of 5 adults suffer

</h2>
        <p className='the456'  style={{fontSize: "20px",
fontWeight: "500"}}>Nearly one in five U.S. adults live with a mental illness. More than 300 million people suffer from depression globally



</p>
    </div>
</div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                               
                                <div className="text">
                                    <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>1 in 4 never tell


</h2>
                                    <p className='the456'  style={{fontSize: "17px",
    fontWeight: "500"}}>Due to the stigma around mental health illness, only one in four employees disclose their mental health problems to their employer




</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                               
                                <div className="text">
                                    <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>25 lost work days


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Every year 25 work days are lost due to mental health illness. That’s more than any other condition like physical illness or injuries



</p>
                                </div>
                            </div>
<div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
  
    <div className="text">
        <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>Lost productivity


</h2>
        <p  className='the456' style={{fontSize: "17px",
fontWeight: "500"}}>The bigest issue is not the days your employees don’t come to work. It’s the days they do. Mental health problems seriously decreases your employees productivity



</p>
    </div>
</div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                              
                                <div className="text">
                                    <h2 className="the456 text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>4 times the return


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Studies show that for every US$ 1 put into treatment for common mental illness, there is a return of US$ 4 in improved health and productivity



</p>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>






{/* 

            <div className="box-add-products">
              <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj" src={ban20} alt="" style={{    minWidth: "100%"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-add-products">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj1" src={ban21} alt="" style={{    minWidth: "100%",objectFit: "cover"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>


 */}








{/* 






            <center>
   <div className="site-section site-section-sm site-blocks-1 backk"  style={{margin:  "30px 0px" , padding  : "100px 0px 100px 0px"}}>
  
<br />
<br />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban16} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>Therapist </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban22} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h3 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>Live sessions </h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban33} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>worksheets </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban44} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>Sections</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban55} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>Activity plan</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban00} alt="" style={{width : "74px" , height:  "74px",  borderRadius : "20px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600" , fontSize : "15px"}}>Messages</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                <a className="nav-link" style={{ color : "white", border : "1px solid white",padding : "8px  14px" , width : "180px", margin : "20px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>Learn More</Link></a>
                </div>



</center>
         

 */}




<center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
   {/* <div className="site-section site-section-sm site-blocks-1 backk" style={{background  :`url(${ban35})`}}> */}
     <h1 className='the456' style={{fontSize : "36px",  padding  : "10px 20px 60px 20px", fontWeight :"500"}}>Experience the world class and complete therapy toolbox :
</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb7} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Therapist </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb10} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Live sessions </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb9} alt="" style={{width : "90px" , height:  "100px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>worksheets </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb3} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Messages</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb11} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>MindFullness</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb2} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Activity plan</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                <a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "20px 0px", background :"rgb(25 18 43)",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"}}><Link to="/business-dashboard" style={{color : "white"}}>Learn More</Link></a>
                </div>



</center>
           






<center>
<h1 style={{fontSize : "45px",  padding  : "40px 20px 60px 20px",background  :"rgb(25 18 43)",color : "white"}}>3 Step Process To Get Started

</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" style={{background  :"rgb(25 18 43)",color : "white"}}> 
                    <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >1 </span>
                        </div>
                                <div className="text">
                                <h2 className="text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>Sign Up


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Create an account, add in all your information.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="100">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-refresh2"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >2 </span>
                         </div>
                                <div className="text">
                                <h2 className="text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>Subscription plan


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Choose the subscription plan that best suits your needs. Depending on no. of employees you get up to 33% off.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >3 </span>

                                </div>
                                <div className="text">
                                <h2 className="text-uppercase" style={{color :  "white" , fontWeight : "600" , fontSize : "24px"}}>Get Connected


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Get connected with several diverse experts and professional along with every single material that can help you with your mental health.



</p>
                                </div>
                            </div>
                            
                          
                          
                        </div>
<center>

                <a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "50px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/business-dashboard" style={{color : "white"}}>Get Started</Link></a>
</center>
                    </div>
                </div>








{/* 
<center>
<h1 className='the456' style={{fontSize : "30px",  padding  : "40px 20px 60px 20px"}}>Cast of minds has been reviewed and approved by the best

</h1>

</center>
<Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={ban11} alt="" className='efeeee1'/>

                        <Carousel.Caption>
                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
                                    <img src={ban13} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
              <h1 className="ther1s m-b-20 the456" style={{ paddingBottom : "30px"}}> "I love the fact that I get feedback on the worksheets every day, sometimes several times a day. I prefer the online therapy to a traditional therapy, because you don't have to wait for feedback."
                  </h1>
              <p className="m-b-40 the456" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>By : <strong>Abigail Posteen</strong> , United States

</p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
              
             
</Carousel>

 */}











{/* 
<center>
<h1 style={{fontSize : "45px",  padding  : "40px 20px 60px 20px"}}>3 Step Process To Get Started

</h1>

</center> */}

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container" >
                        <div className="row">
                            <div className="col-md-12 col-lg-10 d-lg-flex mb-lg-0 pl-4" style={{ margin :  "auto",   padding: "22px 0px"}} data-aos="fade-up" data-aos-delay="">

                                <div className="text">
                                <h1 className="m-b-20 the456" style={{ paddingBottom : "30px", fontSize : "30px"  ,textAlign : "center",  fontWeight : "600"}}> Any questions? Just ask, we’re here to help you!

</h1>
<h3 className="ther1s m-b-20 the456" style={{ paddingBottom : "30px"}}> Are you unsure if we are the right fit for you or do you have any questions about our services?



</h3>
<center>
                    <a className="nav-link" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "20px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>Get Started</Link></a>
            
                  
                                    <img src={ban10} alt=""style={{marginBottom :  "30px",width : "300px" , height : "300px"   , objectFit : "cover"}}/>
                                 </center> </div>
                            </div>
                       
                          
                          
                        </div>

                    </div>
                </div>




{/*






                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
              <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px", fontSize : "45px"}}> Any questions? Just ask, we’re here to help you!

                  </h1>
              <h3 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}> Are you unsure if we are the right fit for you or do you have any questions about our services?



                  </h3>
                    <center>
                    <a className="nav-link" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "50px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>Get Started</Link></a>
            
                    </center>
                                    <img src={ban10} alt=""style={{marginBottom :  "30px",width : "300px" , height : "300px"  , borderRadius : "50%" , objectFit : "cover"}}/>
            </div>
                                  </div>
                                </div>
                */}






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;