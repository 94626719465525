import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"


import ban35 from '../assets/images/ban-35.jpeg';

import tb1 from '../assets/images/tb1.png';
import tb2 from '../assets/images/tb2.png';
import tb3 from '../assets/images/tb3.png';
import tb4 from '../assets/images/tb4.png';
import tb5 from '../assets/images/tb5.png';
import tb6 from '../assets/images/tb6.png';

import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
      window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       
 },[])

        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
            {/* End Top Search */}
            {/* Start Slider */}
            <div className='seflkweflk'>
            <Carousel>
              
              <Carousel.Item interval={2500}>
              <img src={ban35} alt="" className='efeeee'/>

                        <Carousel.Caption>
                        <div className="container wopdkdopk"  style={{top : "-504px", position : "absolute",width : "100%"}}>
                                  <div className="row">
                                  <div className="col-md-12">
            
<center>
<p className="m-b-40 change-text-size the456 change-visible" style={{fontSize : "20px", fontWeight :"bold" ,  textAlign: "center", marginBottom : "20px",textTransform : "uppercase",color  :"#F7CF47" }}>Join the Castofminds family now


</p> 

<p className="m-b-40 change-text-size the456 rdgergkjrg" style={{fontSize : "20px", fontWeight :"500" ,  textAlign: "left",  textAlign: "center", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.

</p> 
</center>


              <p  style={{ textAlign: "center"}}>
                <a className="btn"  style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"bold" , border : "1px solid white", margin :"0px 20px",borderRadius : "10px",fontSize: "16px"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a> <br />

                </p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
    
          
         
</Carousel>
</div>

            {/* <Carousel>
              
                        <Carousel.Item interval={1000000}>
                                <img src={ban35} alt="" className='efeeee'/>

                                  <Carousel.Caption>
                                  <div className="container ther change-align"  style={{top : "-420px", position : "absolute"}}>
                                            <div className="row">
                                            <div className="col-md-12">
                        <h1 className="ther1 m-b-20" style={{ marginBottom : "40px" ,fontSize : "50px" , color : "white",fontFeatureSettings: "kern ,  liga , pnum"}}><strong>Join the Castofminds family now


 </strong></h1>
                        <p className="m-b-40 change-text-size" style={{fontSize : "25px" ,  textAlign: "left", marginBottom : "40px" }}>You decide how much you want to work <br /> & when you’re available. We’ll take care <br /> of the client referrals & billing details.



</p>
<a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "240px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/create_account_therapist" style={{color : "white"}}>Start Application</Link></a>
                      </div>
                                            </div>
                                          </div>
                                  </Carousel.Caption>
                                </Carousel.Item>
                     
                      
      </Carousel> */}
     




<center>
<h1 style={{fontSize : "45px",  padding  : "40px 20px 60px 20px"}}>Why should I join cast of minds

</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                  <span><img src={tb2} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Flexibility

</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>There’s no hassle with going to an office. Instead you can work with all of your clients from the comfort of your home, during hours that suits you.

</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-refresh2"></span> */}
                                    <span><img src={tb6} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>
                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Additional income

</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>Your work at castofminds.com can be a major source of income or just a supplement to your current day-to-day therapy job.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb3} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Top Quality Therapy
</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>You’ll be using a therapy platform that is based on well-researched therapy treatments. Helping your clients via worksheets, messages and live sessions (video, voice or text chat).



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb5} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>No hassle

</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>We’ll take care of acquiring clients, billing, administrations etc so that you can focus on your clients 100%.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
    <span><img src={tb4} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Easy notification system

</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>From your dashboard and email notifications you can easily see what clients are expecting your feedback and upcoming chats with clients.



</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb1} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black"}}>Secure & confidential

</h2>
                                    <p style={{ fontSize: "18px",
    fontWeight: "400" , color : "black"}}>All the communication is anonymously stored on our secure server, where only you and your client can access it.



</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            {/* <h1 style={{padding : "40px 10px",  fontSize : "35px"  ,fontWeight : "bold"}}><center>
            Our toolbox has been reviewed and approved by the best





</center></h1> */}
{/* 
<Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={ban11} alt="" className='efeeee1'/>

                        <Carousel.Caption>
                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
                                    <img src={ban1} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
              <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}> " When I started this program 3 months ago, I had no idea that I would learn so much about myself. My counselor Lisa has been amazing- I am so lucky to have been matched with her. The program worksheets have been helpful in identifying my issues and guiding me through alternative ways of thinking. Lisa follows up with my worksheet responses, and i...
               "   </h1>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>By : <strong>Lisa Neuweg (LCPC)</strong> , United States

</p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={ban11} alt="" className='efeeee1'/>

                        <Carousel.Caption>
                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
                                    <img src={ban1} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
              <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}> " When I started this program 3 months ago, I had no idea that I would learn so much about myself. My counselor Lisa has been amazing- I am so lucky to have been matched with her. The program worksheets have been helpful in identifying my issues and guiding me through alternative ways of thinking. Lisa follows up with my worksheet responses, and i...
               "   </h1>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px", color : "grey"}}>Review for: <strong>Lisa Neuweg (LCPC)</strong> , United States

</p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
                      <img src={ban11} alt="" className='efeeee1'/>

                        <Carousel.Caption>
                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12">
                                    <img src={ban1} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
              <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}>" When I started this program 3 months ago, I had no idea that I would learn so much about myself. My counselor Lisa has been amazing- I am so lucky to have been matched with her. The program worksheets have been helpful in identifying my issues and guiding me through alternative ways of thinking. Lisa follows up with my worksheet responses, and i...
               "  </h1>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px", color : "grey"}}>Review for: <strong>Lisa Neuweg (LCPC)</strong> , United States

</p>
            </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
             
</Carousel> */}






{/* 









            <h1 style={{padding : "40px 10px",  fontSize : "35px"  ,fontWeight : "bold"}}><center>

            Frequently Asked Questions




</center></h1>


            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                                
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "bold"}}>Am I qualified to join as a therapist?


</h2>
                                    <p style={{fontSize: "19px",
    fontWeight: "600"}}>Here are the requirements for registering: 
    <ul className="thr" style={{listStyle: "circle"}}>

        <li>
 You have CBT (Cognitive Behavioral Therapy) as therapy orientation. 
        </li>
        <li>MA, MS, MSW, Psy. or PhD in counseling, social work,  clinical psychology or related mental health areas. </li>
        <li>Minimum of 3 years of and/or 2000 hours of providing counselling services. </li>
        <li>US practitioners need to be licensed by a state board to provide   counseling (LCSW, LMFT, LPC, PsyD or similar). </li>
        <li>US practitioners need a Possession of National Provider Identifier (NPI) Number. </li>
        <li>UK/Ireland practitioners need to be fully accredited with either   BACP, BABCP, UKCP, HCPC or IACP. </li>
    	<li> Professional malpractice liability insurance policy, minimum    coverage limits of $1,000,000/$3,000,000 or similar   for your country. </li>
        <li>No disciplinary actions in the past 10 years. </li>
        <li>We do not accept interns or if you require supervision. </li>
    </ul>
 <br />
<br />

 <b style={{fontWeight  : "bold" , fontSize :"23px"}}>
    Can I register as a therapist from any country?
 </b>
            <p style={{fontWeight  : "500" , fontSize :"20px"}}>Currently you can register as a therapist from the United States, Canada, United Kingdom and Australia.
</p>
<br />
<br />
 
 <b style={{fontWeight  : "bold" , fontSize :"23px"}}>
 Is this a part-time or full-time commitment?

 </b>
            <p style={{fontWeight  : "500" , fontSize :"20px"}}>This can be your major source of income or just a supplement to your current job, depending on the number of clients we can provide you with and how much you can work.
<br />
<br />
Many therapists use our platform as a complement to their existing face-to-face therapy.



</p>


</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                                
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Do you offer video, voice and text chats?

</h2>
<p style={{fontWeight  : "500" , fontSize :"20px"}}> <b>Yes! </b>  Live sessions are available as video, voice only and text chat.
</p>
<br />
 <b style={{fontWeight  : "bold" , fontSize :"23px"}}>
 Is this secure and confidential? Are you HIPAA compliant?

 </b>
<br />
<br />

<ul className="thr" style={{listStyle: "circle" ,fontSize: "19px",
    fontWeight: "600"}}>

<li>
You have CBT (Cognitive Behavioral Therapy) as therapy orientation. 
</li>
<li>MA, MS, MSW, Psy. or PhD in counseling, social work,  clinical psychology or related mental health areas. </li>
<li>Minimum of 3 years of and/or 2000 hours of providing counselling services. </li>
<li>US practitioners need to be licensed by a state board to provide   counseling (LCSW, LMFT, LPC, PsyD or similar). </li>
<li>US practitioners need a Possession of National Provider Identifier (NPI) Number. </li>
<li>UK/Ireland practitioners need to be fully accredited with either   BACP, BABCP, UKCP, HCPC or IACP. </li>
<li> Professional malpractice liability insurance policy, minimum    coverage limits of $1,000,000/$3,000,000 or similar   for your country. </li>
<li>No disciplinary actions in the past 10 years. </li>
<li>We do not accept interns or if you require supervision. </li>
</ul>
<br />
<br />
 <b style={{fontWeight  : "bold" , fontSize :"23px"}}>
    Can I register as a therapist from any country?
 </b>
            <p style={{fontWeight  : "500" , fontSize :"20px"}}>Currently you can register as a therapist from the United States, Canada, United Kingdom and Australia.
</p>
<br />
<br />
 <b style={{fontWeight  : "bold" , fontSize :"23px"}}>
    Can I register as a therapist from any country?
 </b>
            <p style={{fontWeight  : "500" , fontSize :"20px"}}>Currently you can register as a therapist from the United States, Canada, United Kingdom and Australia.
</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>








































            {/* <div className="categories-shop">
              <div className="container-fuild"  style={{width : "90%", margin : "auto"}}>
                <div className="row">
                {
                  MainCatogories.map((res,i)=>{
                          return (
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" onClick={()=>props.history.push("/categories/"+res.MainCategories)}>
                    <div className="shop-cat-box">
                      <img className="img-fluid img123" src={res.photo} alt=""  />
                      <a className="btn hr-btn" style={{height : "37px",background :"rgb(175, 220, 200)"}} href="#">{res.MainCategories}</a>
                    </div>
                  </div>
                          )
                      })
                }
                 
                </div>
              </div>
            </div> */}
            {/* End Categories */}















            {/* <div className="box-add-products">
              <div className="container-fuild">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj" src={add5} alt="" style={{    minWidth: "100%"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-add-products">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj1" src={add6} alt="" style={{    minWidth: "100%",objectFit: "cover"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
















            {/* <div className="box-add-products">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid" src={add1} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid" src={add2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Start Products  */}
            



            {/* <div className="products-box">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="title-all text-center">
                      <h1>Top &amp; Products</h1>
                    </div>
                  </div>
                </div>
              
                <div className="row special-list" style={{height : "auto"}}>
                  {product.map((res,i)=>{
                    return (
                      <div className="col-lg-3 col-md-3 col-sm-6 special-grid best-seller change-pixel">
                      <div className="products-single fix" style={{borderRadius :  "10px"}}>
                        <div className="box-img-hover" >
                          <div className="type-lb">
                            <p className="sale" style={{background :"rgb(175, 220, 200)"}}>Sale
  
                            {res.Product_Price_Discounted ?
                                                      <span> {(100 - (res.Product_Price_Discounted *100) /  res.Product_Price).toFixed(2)}  % </span>
                                                      : ""
                                                    }
                            </p>
                          </div>
                          <img src={res.Product_Image_Upload} className="img-fluid change-img-size" alt="Image" style={{height : "158px"}} onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}/>
                                                    <span className="jnjljlnj" style={{padding : "0px 8px 3px 8px" , borderRadius : "30px" , fontSize : "23px" ,
                              position: "relative",
                              // right: "-122px",
                              right: "-74%",
                              bottom: "14px",
                              background: "transparent" ,
                              fontWeight : "bold",
                              color  :  "rgb(175, 220, 200)" ,
                              border : "3px solid rgb(175, 220, 200)"
                          }}
                          onClick={()=>addtocartproduct(res)}>✢</span>
                          <div className="mask-icon"   onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}>
                           
                            <a className="cart" style={{background :"rgb(175, 220, 200)"}}>Details</a>
  
                          </div>
                        </div>
                        <div className="why-text">
                          {res.Product_Price_Discounted ? 
                              <>
                          <p style={{fontWeight: "bold",  color : "black"}}>Rs. {res.Product_Price_Discounted}
                          <span style={{marginLeft:"10px" , color : "red"   , textDecoration: "line-through"}}>Rs.{res.Product_Price}</span>
                          </p>
                              </>
  
                              :
                              <p style={{fontWeight: "bold",  color : "black"}}>Rs. {res.Product_Price}
                          </p>
  
                          }
    <h4 onClick={()=>props.history.push("/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id)}>{res.Product_Name}</h4>
                        </div>
                      </div>
                    </div>
                    )
                  })


                  }


                 
                </div>
              </div>
            </div> */}
            {/* End Products  */}


           



{/* 
<div className="">
              <div className="container-fuild" style={{margin : "auto" ,  width : "95%"}}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj" src={add3} alt="" style={{    minWidth: "100%"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="offer-box-products">
                      <img className="img-fluid dfkj1" src={add4} alt="" style={{    minWidth: "100%",objectFit: "cover"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}



            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;