import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import x1 from '../assets/images/x1.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
import logo from '../assets/images/logo.png';
// import Head1 from "../assets/images/header-bg-rpt.jpg"
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
// import AddAlertIcon from '@mui/icons-material/AddAlert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import { useSelector, useDispatch } from 'react-redux';


const Header =(props)=> {


    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [menu1 , setmenu1] = useState(false)
    const [adminHeader , setadminHeader] = useState(false)
    const [displayMenu , setdisplayMenu] = useState(false)
    const [MainLine , setMainLine] = useState("")
    const [MainForum , setMainForum] = useState(false)
    const [MainCategories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [fvalue1 , setfvalue1] = useState([])
    const [fg , setfg] = useState(true)
    const [abc , setabc] = useState(false)
    const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})

    const [hide1 , sethide1] = useState(false)
    const [hide2 , sethide2] = useState(false)

    const [dis1 , setdis1] = useState(false)
    const [dis2 , setdis2] = useState(false)
   
    const [di1 , setdi1] = useState(true)
    const [di2 , setdi2] = useState(true)
    const [di3 , setdi3] = useState(true)
  
  
    let history = useHistory();


    useEffect(() =>{
        if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
       
       
      
        const inter = setInterval(()=>{
     

          if ( JSON.parse(localStorage.getItem("check Head")) ){
            setdis1(true)
          }
          else{
            setdis1(false)
          }
          if(JSON.parse(localStorage.getItem("check Head 1"))){
            setdis2(true)
          }
          else{
            setdis2(false)
          }

          

        },500)
        
     
        return () => {
          clearInterval(inter);
        
        }
    },[])
  
    


    const  setLogout1 = () =>{
      console.log("dsf")
      localStorage.removeItem("check Head")
      localStorage.removeItem("check Head 1")
      localStorage.removeItem("user main 1")
      history.push("/login")
     sethideshow3()
     sethide2(!hide2)
  }
    const  setLogout = () =>{
      console.log("dsf")
      localStorage.removeItem("check Head")
      localStorage.removeItem("check Head 1")
      localStorage.removeItem("user main therapist")
      history.push("/therapist/login")
     sethideshow3()
     sethide2(!hide2)
  }
    const  sethideshow = () =>{
      console.log("dsf")
      // setdi1(!di1)
      if(di1 === true){
        setdi1(false)
        
        }
        else{
        setdi1(true)
        
      }


  }
    const  sethideshow1 = () =>{
      console.log("dsf")
      setdi2(!di2)
      sethide1(false)
      


  }
    const  sethideshow3 = () =>{
      console.log("dsf")
      setdi3(!di3)
      sethide2(false)



  }
        return (
<>
        <ToastContainer />



            {! dis1 && ! dis2 ?
 
        <>
           
            {/* End Main Top */}
            {/* Start Main Top */}
            <header className="main-header" style={{height : "60px",   
    width: "100%"}}>
              {/* Start Navigation */}
              <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
                <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
                  <div className='row'>

                  {/* Start Header Navigation */}
                  <div className="navbar-header">
                   <div style={{position: "absolute",
    right: "5%",
    top: "-4%"}}>
                    <Link to="/signup-1"><button className="navbar-toggler" style={{ color : "rgb(247, 207, 71)" , borderColor : "rgb(247, 207, 71)",top: "-9px",borderRadius:"10px",padding:"8px 14px"}} type="button">
                      Join
                    </button></Link>
                    <Link to="/login"><button className="navbar-toggler" style={{ color : "white" , borderColor : "white",top: "-9px",border:"none"}} type="button">
                     Login
                    </button></Link>
                    <button className="navbar-toggler" style={{
    //                   position: "absolute",
    // right: "5%",
     color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>sethideshow()}>
                      <MenuIcon/>
                    </button>
                  </div>
                    <a className="navbar-brand" href="/" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "76px",height : "62px"}} /></a>

                   </div>

                  {/* End Header Navigation */}
                  {/* Collect the nav links, forms, and other content for toggling */}
                  
                    {/* <ul className="nav navbar-nav ml-auto chage-mobile-head"  style={{  color : "white" ,position:"absolute",right:"0px" }}>
                  
                      <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                      <li className="nav-item"><Link className="nav-link" to="/teams">Teams</Link></li>
                      </ul>
                   */}
                  <div className={di1 ? "collapse navbar-collapse efwfewfef1" : "collapse navbar-collapse efwfewfef"} id="navbar-menu" style={{background: "rgb(25, 18, 43)"}}>
                  {/* <div className="collapse navbar-collapse" className={di1 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} id="navbar-menu ffjfj" style={{}}> */}
                    <ul className="nav navbar-nav chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0px" }}>
                    {/* <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0px" }}> */}
                 
                 
                 
                    {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , }}> */}
                     
                     
                     
                      {/* <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/how-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>HOW IT WORKS </a></a></li> */}




                      {/* <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
                      <li className="nav-item"><a className="nav-link the456 efkekej" ><a href="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}


                      {/* <li className="nav-item"><a className="nav-link the456 efkekej"><a href="/contact-us"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Contact Us</a></a></li> */}



                      {/* <li className="nav-item"><a className="nav-link the456 efkekej"><a href="/therapist/login"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>THERAPIST login</a></a></li> */}
                      {/* <li className="nav-item"><a className="nav-link the456" ><Link to="/about" style={{color  :"white"}}>Why</Link></a></li> */}
                      {/* <li className="dropdown">
                        <a className="nav-link dropdown-toggle arrow the456" data-toggle="dropdown"  style={{fontSize : "12px" , fontWeight : "500",color: "white"}}>Essentials</a>
                        <ul className="dropdown-menu">
                        <li><a href="/about" style={{color :  "white"}}>About Us</a></li>
                          <li><a href="/review" style={{color :  "white"}}>Reviews</a></li>
                          <li><a href="/faq" style={{color :  "white"}}>FAQ</a></li>
                          <li><a href="/privacy" style={{color :  "white"}}>Privacy Policy</a></li>
                          </ul>
                      </li> */}
                      {/* <li className="nav-item"><a className="nav-link the456" ><Link to="/therapist-access" style={{color  :"white"}}>Join as a THERAPIST</Link></a></li> */}
                      {/* <li className="nav-item kjjk" style={{position :"absolute" ,top  :"20px" , right  : "18%" , color : "grey"}}><a className="nav-link" style={{padding : "0px  10px"}}><a href="/signup-1"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Client login</a></a></li>
                      <li className="nav-item kjjk1" style={{position :"absolute" ,top  :"20px"  , right  : "2%" , color : "grey"}}><a className="nav-link" style={{padding : "0px  10px"}}><a href="/therapist-access"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>THERAPIST login</a></a></li> */}
                      {/* <li className="nav-item"><a className="nav-link" href="gallery.html">Gallery</a></li> */}
                      <li className="nav-item change-padd-nav change-nav-hide" onClick={()=>sethideshow()}><Link className="change-padd-nav1 nav-link" to="/signup-1"  style={{ color : "rgb(247, 207, 71)" , borderColor : "rgb(247, 207, 71)",borderRadius:"10px",padding  :"4px 14px",border:"1px solid",margin: "4px 10px"}}>Join</Link></li>
                      <li className="nav-item change-padd-nav change-nav-hide" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/login"  style={{ borderBottom:"1px solid rgb(247, 207, 71)",width:"67px" }}>Login</Link></li>
                      <li className="nav-item change-padd-nav change-nav-hide" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link"  style={{     borderLeft: "3px solid rgb(247, 207, 71)",padding:"0px"}}></Link></li>

                      <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/">Home</Link></li>
                      {/* <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/business">Business</Link></li> */}
                      <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/why-join-us">Why Join us</Link></li>
                      <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/about">About</Link></li>
                      {/* <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/review">Reviews</Link></li> */}
                      {/* <li className="nav-item change-padd-nav change-padd-nav2" onClick={()=>sethideshow()} ><Link className="change-padd-nav1 nav-link" to="/contact-US">Contact Us</Link></li> */}
                      
                      
                      
                      
                      
                      
                      
                      {/* <li className="nav-item"onClick={()=>sethideshow()} ><a className="nav-link the456 efkekej" ><Link to="/login"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Client login</Link></a></li> */}
                    </ul>
                  </div>
                  {/* /.navbar-collapse */}
                  {/* Start Atribute Navigation */}
                  {/* <div className="attr-nav">
                    <ul>
                     
                      <li className="side-menu">
                        <Link to="/cart">
                        <ShoppingCartIcon    /> 
                          <span className="badge">{DataPart2.length}</span>
                          <p>My Cart</p>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  {/* End Atribute Navigation */}
                </div>
              </div>
                {/* Start Side Menu */}
                
                {/* End Side Menu */}
              </nav>

              {/* End Navigation */}
            </header>
            {/* End Main Top */}
            {/* Start Top Search */}
            {/* {!abc
            ? 
            <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
              <div className="container">
                <div className="input-group">
                  <span className="input-group-addon"><SearchIcon /></span>
                  <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
                  <span className="input-group-addon close-search"><ClearIcon/></span>
                </div>
              </div>
            </div>
            : ""

            } */}
            </>

:""
        }
            {! dis2 &&dis1 && JSON.parse(localStorage.getItem("user main therapist"))  ?
 
        <>
           
            {/* End Main Top */}
            {/* Start Main Top */}
            <header className="main-header" style={{height : "60px",   
    width: "100%"}}>
              {/* Start Navigation */}
              <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
                <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
                  <div className='row'>

                  {/* Start Header Navigation */}
                  <div className="navbar-header">
                    <button className="navbar-toggler" style={{position: "absolute" ,right: "5%",  color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation"  onClick={()=>sethideshow1()}>
                      <MenuIcon/>
                    </button>
                    <Link className="navbar-brand" to="/therapist/dashboard" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "105px",height : "72px"}} /></Link>
                  </div>
                  {/* End Header Navigation */}
                  {/* Collect the nav links, forms, and other content for toggling */}
                  <div className={di2 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} style={{background: "rgb(25, 18, 43)"}}>
                    <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" }}>
                    {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , top:"7%"}}> */}
                      <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" style={{height:"45px"}} ><Link to="/therapist/worksheet1/1" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Getting to Know You </Link></a></li>
                      <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" style={{height:"45px"}} ><Link to="/therapist/dashboard" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Therapy </Link></a></li>
                      {/* <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" style={{height:"45px"}} ><Link to="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
                      <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" style={{height:"45px"}} ><Link to="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}
                      <li className="nav-item"   onClick={()=>sethideshow1()} ><a className="nav-link the456 efkekej" style={{height:"45px"}} ><Link to="/therapist/journal"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Journaling</Link></a></li>
                      <li className="nav-item"   onClick={()=>sethideshow1()}><a className="nav-link the456 efkekej" style={{height:"45px"}}><Link to="/tactivity_plan"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Activity Plan</Link></a></li>
                     </ul>


                    <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0",top:"7%"}} onClick={()=>sethide1(!hide1)}>
                      <li className="nav-item"><a className="nav-link the456 efkekej" style={{height:"45px",border:"none"}}><img src={JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto ? JSON.parse(localStorage.getItem("user main therapist")).ProfilePhoto  : x1} alt="" style={{width:"40px",height:"40px",borderRadius:"50px"}}/></a></li>
                        </ul>
                  </div>

                  {/* /.navbar-collapse */}
                  {/* Start Atribute Navigation */}
                  {/* <div className="attr-nav">
                    <ul>
                     
                      <li className="side-menu">
                        <Link to="/cart">
                        <ShoppingCartIcon    /> 
                          <span className="badge">{DataPart2.length}</span>
                          <p>My Cart</p>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  {/* End Atribute Navigation */}
                </div>
              </div>
                {/* Start Side Menu */}
                
                {/* End Side Menu */}


                {hide1
?
<div style={{width:"150px",border:"1px solid white",height:"100px",position:"absolute",top:"68px",right:"5px",background:"rgb(25, 18, 43)"}}>

<Link   onClick={()=>sethideshow1()} to="/therapist/change-profile" className="nav-link the456" style={{  
  color: "white",
  // padding: "5px 14px",
  // width: "85px",
  margin: "2px 0px",
  // background: "black",
  fontWeight: "500",
  fontSize: "14px",
  textTransform: "uppercase",
  cursor:"pointer"
  // position: "absolute",
  // right: "0px",
  // top:"53px",
  // zIndex:99999
  
}}>Edit Profile </Link>
<a className="nav-link the456" onClick={()=>setLogout()}  style={{  
       color: "white",
    // padding: "5px 14px",
    // width: "85px",
    margin: "2px 0px",
    // background: "white",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "uppercase",
    // position: "absolute",
    // right: "0px",
    // top:"53px",
    // zIndex:99999
    cursor:"pointer"

    }}> LogOut</a>
</div>
     :""           }
              </nav>

              {/* End Navigation */}
            </header>
            {/* End Main Top */}
            {/* Start Top Search */}
            {/* {!abc
            ? 
            <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
              <div className="container">
                <div className="input-group">
                  <span className="input-group-addon"><SearchIcon /></span>
                  <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
                  <span className="input-group-addon close-search"><ClearIcon/></span>
                </div>
              </div>
            </div>
            : ""

            } */}
            </>

:""
        }
            { dis2 && JSON.parse(localStorage.getItem("user main 1"))   ?
 
        <>
           
            {/* End Main Top */}
            {/* Start Main Top */}
            <header className="main-header" style={{height : "60px",   
    width: "100%"}}>
              {/* Start Navigation */}
              <nav className="navbar navbar-expand-lg navbar-light navbar-default bootsnav hjjhjh" style={{background: "rgb(25 18 43)"}}>
                <div className="container-fuild" style={{margin  :"auto" , width   : "90%"}}>
                  <div className='row'>

                  {/* Start Header Navigation */}
                  <div className="navbar-header">
                    <button className="navbar-toggler" style={{position: "absolute" ,right: "5%",  color : "white" , borderColor : "white",top: "-9px"}} type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation"  onClick={()=>sethideshow3()}>
                      <MenuIcon/>
                    </button>
                    <Link className="navbar-brand" to="/dashboard" style={{padding : "0px" ,  height: "57px"}}><img src={logo} className="logo" alt="" style={{objectFit  :"cover", width: "105px",height : "72px"}} /></Link>
                  </div>
                  {/* End Header Navigation */}
                  {/* Collect the nav links, forms, and other content for toggling */}
                  <div className={di3 ? "collapse navbar-collapse" : "collapse navbar-collapse efwfewfef"} style={{background: "rgb(25, 18, 43)"}}>
                    <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,}}>
                    {/* <ul className="nav navbar-nav ml-auto" data-in="fadeInDown" data-out="fadeOutUp"  style={{position: "absolute" ,right:  "5%",  color : "white" , }}> */}
                      <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/worksheet1/1" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Getting to Know You </Link></a></li>
                      <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/dashboard" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Therapy </Link></a></li>
                      {/* <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/who-it-work" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Business</a></a></li>
                      <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej" ><Link to="/interactions" style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Interactions</a></a></li> */}
                      <li className="nav-item"  onClick={()=>sethideshow3()} ><a className="nav-link the456 efkekej" ><Link to="/journal"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Journaling</Link></a></li>
                      <li className="nav-item"  onClick={()=>sethideshow3()}><a className="nav-link the456 efkekej"><Link to="/activity_plan"  style={{color  :"white",fontSize : "12px" , fontWeight : "500"}}>Activity Plan</Link></a></li>
                     </ul>


                    <ul className="nav navbar-nav ml-auto chage-mobile-head" data-in="fadeInDown" data-out="fadeOutUp"  style={{  color : "white" ,position:"absolute",right:"0"}}  onClick={()=>sethide2(!hide2)}>
                      <li className="nav-item"><a className="nav-link the456 efkekej"><img src={x1} alt="" style={{width:"40px",height:"40px",borderRadius:"50px"}}/></a></li>
                        </ul>
                  </div>
                  {/* /.navbar-collapse */}
                  {/* Start Atribute Navigation */}
                  {/* <div className="attr-nav">
                    <ul>
                     
                      <li className="side-menu">
                        <Link to="/cart">
                        <ShoppingCartIcon    /> 
                          <span className="badge">{DataPart2.length}</span>
                          <p>My Cart</p>
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  {/* End Atribute Navigation */}
                </div>
              </div>
                {/* Start Side Menu */}
                
                {/* End Side Menu */}
                {hide2
?
<div style={{width:"150px",border:"1px solid white",height:"100px",position:"absolute",top:"68px",right:"5px",background:"rgb(25, 18, 43)"}}>

<Link  onClick={()=>sethideshow3()} to="/dashboard" className="nav-link the456" style={{  
  color: "white",
  
  margin: "2px 0px",
  fontWeight: "500",
  fontSize: "14px",
  textTransform: "uppercase",
 
  cursor:"pointer"

}}>Therapy </Link>
<a className="nav-link the456" onClick={()=>setLogout1()}  style={{  
       color: "white",
    // padding: "5px 14px",
    // width: "85px",
    margin: "2px 0px",
    // background: "white",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "uppercase",
    // position: "absolute",
    // right: "0px",
    // top:"53px",
    // zIndex:99999
    cursor:"pointer"

    }}> LogOut</a>
</div>
     :""           }
              </nav>

              {/* End Navigation */}
            </header>
            {/* End Main Top */}
            {/* Start Top Search */}
            {/* {!abc
            ? 
            <div className="top-search" style={{background : "rgb(175, 220, 200)"}}>
              <div className="container">
                <div className="input-group">
                  <span className="input-group-addon"><SearchIcon /></span>
                  <input type="text" className="form-control" placeholder="Search" style={{background : "rgb(175, 220, 200)"}}/>
                  <span className="input-group-addon close-search"><ClearIcon/></span>
                </div>
              </div>
            </div>
            : ""

            } */}
            </>

:""
        }

</>
        )
}

export default Header;