import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

// import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
import ban10 from '../assets/images/ban-10.JPG';
import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban27 from '../assets/images/ban-27.jpeg';
// import ban23 from '../assets/images/ban-23.jpg';
// import ban24 from '../assets/images/ban-24.jpg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';


import tb1 from '../assets/images/tb1.png';
import tb2 from '../assets/images/tb2.png';
import tb3 from '../assets/images/tb3.png';
import tb4 from '../assets/images/tb4.png';
import tb5 from '../assets/images/tb5.png';
import tb6 from '../assets/images/tb6.png';
import tb7 from '../assets/images/tb7.png';
import tb8 from '../assets/images/tb8.png';
import tb9 from '../assets/images/tb9.png';
import tb10 from '../assets/images/tb10.png';
import tb11 from '../assets/images/tb11.png';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
        window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       


        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

       
 },[])

        return (
            
            <div>
           

<center>
<h1 className='the456' style={{fontSize : "30px",  padding  : "40px 20px 60px 20px"}}>How Does Castofminds work?
</h1>

</center>

            {/* <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "2px 10px"}} data-aos="fade-up" data-aos-delay=""> */}
                               
                                <div className="text">
                      <center>

                                    <p style={{margin:"0px 40px",fontSize: "16px",color  : "black",
    fontWeight: "400"}}  className='the456'>
        
        Cast of Minds is a place which brings you a stunning cast of psychologists that are well trained, experienced and professionals in their domain. Bringing you not only just online therapy sessions but also a complete tool box kit that helps you throughout your journey of unveiling your cast of mind and discovering your truest ecstatic potential.

    <br />
    {/* <br /> */}

    {/* From online therapy sessions to activity kits and everything in between, Cast Of Minds makes sure you have every right to your privacy and confidentiality no matter who or where you are. A diverse team of international psychologists are here at one click. */}
    {/* <br /> */}

</p>
                      </center>
<br />
<center>

<p ><a className="btn hvr-hover"  onClick={()=>props.history.push("/signup-1")} style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"500" , border : "1px solid white",  padding : "8px 16px"}}>Get Started</a></p>
</center>

                                </div>
                            {/* </div>
                           
                    </div>
                </div>
                </div> */}




{/* 

                <center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
           <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban16} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px"}}>Therapist </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban22} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px" }}>Live sessions </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban33} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px" }}>worksheets </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban44} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px" }}>Sections</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban55} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px" }}>Activity plan</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban00} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "16px" }}>Messages</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



</center> */}
           



           <center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
   {/* <div className="site-section site-section-sm site-blocks-1 backk" style={{background  :`url(${ban35})`}}> */}
     <h1 className='the456' style={{fontSize : "26px",  padding  : "10px 20px 60px 20px", fontWeight :"500"}}>Experience the world class and complete therapy toolbox :
</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb7} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Therapist </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb10} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Live sessions </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb9} alt="" style={{width : "90px" , height:  "100px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>worksheets </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb3} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Messages</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb11} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>MindFullness</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={tb2} alt="" style={{width : "90px" , height:  "90px"}} /></span>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Activity plan</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                <a className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "20px 0px", background :"rgb(25 18 43)",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"}}><Link to="/signup-1" style={{color : "white"}}>Learn More</Link></a>
                </div>



</center>
           




{/* 
<center>
<h1  className='the456' style={{fontSize : "40px",  padding  : "40px 20px 60px 20px"}}>What You'll get from the Cast of Minds!
</h1>


<h1 className='the456' style={{fontSize : "20px",  padding  : "20px 20px 20px 20px" , textAlign : "center"}}>From online therapy sessions to activity kits and everything in between, Cast Of Minds makes sure you have every right to your privacy and confidentiality no matter who or where you are. A diverse team of international psychologists are here at one click.
</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="">
                                
                                <div className="text">
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>QUALIFIED THERAPIST


</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Your personal therapist will be by your side - from start to finish. Guiding you to a happier you through the sections, worksheets, unlimited messaging and live sessions (available as video, voice only or text chat). You can do this from wherever you are in the world.


</p>
                                </div>
                            </div>
<div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
   
    <div className="text">
        <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>LIVE SESSIONS
</h2>
        <p  className='the456' style={{fontSize: "17px",
fontWeight: "500"}}>There are several worksheets in each section. In each worksheet you will answer questions and get valuable hands-on tools and tips from your therapist on a daily basis.


</p>
    </div>
</div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                               
                                <div className="text">
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>WORKSHEETS

</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>There are several worksheets in each section. In each worksheet you will answer questions and get valuable hands-on tools and tips from your therapist on a daily basis.




</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="100">
                               
                                <div className="text">
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>SECTIONS

</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>Each section includes all the information and hands-on tools that you need to identify, challenge and overcome your problems. The sections are available as video , audio and/or text.


</p>
                                </div>
                            </div>
<div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
  
    <div className="text">
        <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>ACTIVITY PLAN

</h2>
        <p  className='the456' style={{fontSize: "17px",
fontWeight: "500"}}>This is where you add and schedule the things that will add extra light into your life! The activity plan also serves as a written agreement with yourself. It greatly increases the chances of you carrying out your plans!


</p>
    </div>
</div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "42px 10px"}} data-aos="fade-up" data-aos-delay="200">
                              
                                <div className="text">
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight : "500" , fontSize : "25px"}}>MESSAGING

</h2>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500"}}>In addition to communicating with your therapist through the worksheets and the live sessions, you can always send them a message with any questions or thoughts.



</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div> */}





<center>
<h1 className='the456' style={{fontWeight : "500", fontSize : "25px",  padding  : "40px 20px 60px 20px"}}>Why should I choose cast of minds ?
</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin : "auto"}}>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "12px 10px"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                    <span><img src={tb1} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"80px"}}/></span>
                        </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Certified and licensed therapists

</h2>
                                    <p className='the456' style={{fontSize: "14px",
    fontWeight: "400"}}>We’re not like other therapy websites! You get all the tools and support you need to be happier - now and in the future.

</p>
                                </div>
                            </div>
                           
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "12px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb6} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Light on pocket</h2>
                                    <p className='the456' style={{fontSize: "14px",
    fontWeight: "400"}}>Your therapist is here for you whenever needed, Monday-Friday. You pay much less than you would with a face-to-face therapist.

</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "12px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb5} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"80px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Cancel anytime you like
</h2>
                                    <p className='the456' style={{fontSize: "14px",
    fontWeight: "400"}}>We respect your privacy! There is no risk of exposing yourself face-to-face in the therapist's office or waiting room.

</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "12px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb2} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"80px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Confidentiality our top priority
</h2>
                                    <p className='the456' style={{fontSize: "14px",
    fontWeight: "400"}}>All your information is transferred end-to-end encrypted to our secure server, where only you and your therapist can access it.

</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" style={{    padding: "12px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span><img src={tb8} alt=""  style={{fontSize : "65px", color : "rgb(170, 31, 130)" ,width : "60px" , height :"60px"}}/></span>

                                </div>
                                <div className="text">
                                    <h2 className="text-uppercase" style={{color :  "black", fontWeight : "bold"}}>Time saver and convenient
</h2>
                                    <p className='the456' style={{fontSize: "14px",
    fontWeight: "400"}}>There is no hassle with traveling or keeping an appointment. You can get help from wherever you are in the world. All you need is a computer, tablet or mobile phone.

</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>







<center>
<h1  className='the456' style={{fontSize : "35px",  padding  : "40px 20px 10px 20px",background  :"rgb(25 18 43)",color : "white"}}>How do I get started?

</h1>

</center>

            <div className="site-section site-section-sm site-blocks-1 backk" style={{background  :"rgb(25 18 43)"}}>
                    <div className="container-fuild jhhjhh" style={{width : "90%" ,margin:"auto"}}>
                        <div className="row">


                        <div className="col-md-12 col-lg-12 d-lg-flex mb-lg-0 pl-4" style={{    padding: "02px 10px"}} data-aos="fade-up" data-aos-delay="">

                        <Carousel>
            
              <Carousel.Item interval={2000}>
                      <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "#F7CF47"}}  >1 </span>
                        </div>
                                <div className="text">
                            
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Choose your areas of concern to get matched with your therapist.



</p>
                                </div>
                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
              <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-refresh2"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "#F7CF47"}}  >2 </span>
                         </div>
                                <div className="text">
                             
                                    <p   className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Choose the subscription plan that best suits your needs, starting at just $20/week.



</p>
                                </div>
                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
              <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "#F7CF47"}}  >3 </span>

                                </div>
                                <div className="text">
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Get instant access to your program, worksheets, diary, activity plan and more.



</p>
                                </div>
                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
              <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "#F7CF47"}}  >4 </span>

                                </div>
                                <div className="text">
                               
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Start communicating with your therapist through worksheets, messages and live video, voice or text chat sessions.



</p>
                                </div>
                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
              <Carousel.Item interval={2000}>
              <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-help"></span> */}
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "#F7CF47"}}  >5 </span>

                                </div>
                                <div className="text">
                                  
                                    <p   className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>With the help of the tools and the support of your therapist, you will start getting happier, day by day.



</p>
                                </div>
                        <Carousel.Caption>
                       
                        </Carousel.Caption>
                      </Carousel.Item>
            
             
             
</Carousel>

</div>


{/* 
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >1 </span>
                        </div>
                                <div className="text">
                            
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Choose your areas of concern to get matched with your therapist.



</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="100">
                                <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >2 </span>
                         </div>
                                <div className="text">
                             
                                    <p   className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Choose the subscription plan that best suits your needs, starting at just $20/week (20% discount first month).



</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >3 </span>

                                </div>
                                <div className="text">
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Get instant access to your online therapy program, including sections, worksheets, diary, activity plan and more.



</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >4 </span>

                                </div>
                                <div className="text">
                               
                                    <p    className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>Start communicating with your therapist through worksheets, messages and live video, voice or text chat sessions.



</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px"}} data-aos="fade-up" data-aos-delay="200">
                                <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "55px" ,  width : "20px" , fontWeight : "bold", color : "white"}}  >5 </span>

                                </div>
                                <div className="text">
                                  
                                    <p   className='the456' style={{fontSize: "14px",color : "white",
    fontWeight: "500"}}>With the help of the tools and the support of your therapist, you will start getting happier, day by day.



</p>
                                </div>
                            </div> */}
                            {/* <div className="col-md-4 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4 dfdjkfjk" style={{  position: "absolute",right: "3%"}} data-aos="fade-up" data-aos-delay="200">
                            <center>
                            <Carousel>
              
              <Carousel.Item interval={2000}>
                            <img src={ban27} alt="" style={{width : "400px" , height: "500px", borderRadius :"40px"}} className="wwduiouo"/>
                      </Carousel.Item>
              
                            </Carousel>

                            </center>

                            </div> */}
                        </div>
                    </div>
                </div>































                {/* <Carousel>
              
              <Carousel.Item interval={2000}>
                      <img src={ban11} alt="" className='efeeee1'/>

                        <Carousel.Caption>
                        <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12"> */}
             <center style={{margin:"10px 0px"}}>
                <h1 className="ther1s m-b-20 the456" style={{ paddingBottom : "30px", fontSize : "40px"}}> Any questions? Just ask, we’re here to help you!

                  </h1>
              <h3 className="ther1s m-b-20 the456" style={{ paddingBottom : "30px"}}> Are you unsure if we are the right fit for you or do you have any questions about our services?



                  </h3>
                  <a href="mailto:contact@castofminds.com">   <img src={ban10} alt=""style={{marginBottom :  "30px",width : "300px" , height : "240px"  , objectFit : "cover"}}/>
                  </a>
             </center>
              
            {/* </div>
                                  </div>
                                </div>
                        </Carousel.Caption>
                      </Carousel.Item>
         
             
</Carousel> */}






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;