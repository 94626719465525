import React,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import firebase from './firebase'
import  "firebase/auth";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";


const Signup = (props) => {
    const [email ,  setemail ] = useState("")
    const [pass ,  setpass ] = useState("")
    const [user ,  setuser ] = useState("")
    const [Country ,  setCountry ] = useState("")
    const [phone ,  setphone ] = useState("+92")
    const [code ,  setcode ] = useState("")
    const [gy ,  setgy ] = useState(true)
    const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    const [changer1 ,  setchanger1 ] = useState(true)
    const [changer2 ,  setchanger2 ] = useState("")




    useEffect(() => {
      window.scrollTo(0, 0)

      if (JSON.parse(localStorage.getItem("set user value"))){
            setuser(JSON.parse(localStorage.getItem("set user value")).user)
            setemail(JSON.parse(localStorage.getItem("set user value")).email)
            setpass(JSON.parse(localStorage.getItem("set user value")).pass)
            setphone(JSON.parse(localStorage.getItem("set user value")).phone)
      }
        
      
           }, [])
  

const postData1 = () =>{
  // e.preventDefault()
  if(code.length === 0){
      swal("Enter The Correct Code !" )
         return
      }
      if(code == changer2)
      {
        let a = JSON.parse(localStorage.getItem("create-user-1")) 
        let b = JSON.parse(localStorage.getItem("create-user-2")) 
        let c = JSON.parse(localStorage.getItem("create-user-3")) 
        let d = JSON.parse(localStorage.getItem("create-user-4")) 
          
    fetch("https://asad-backend-two.vercel.app/signup-user-1",{
      method: 'POST' , 
      headers :  {
        "Content-Type" : "application/json" , 
      } , 
      body : JSON.stringify({
        Category : a.Category,
        Gender : b.Gender,
        Session : b.Session,
        TheripistId : c.TheripistId,
        // TheripistId : c.TheripistId,
        payment : d.payment,
        Name: user  ,
        email  ,
        pass  ,
        Country  ,
      })
    })
    .then((res)=>res.json())
    .then((res2)  =>{
        console.log(res2)
        if (res2 !== null && !res2.Error  ){
          swal("SucessFully Create An Account"  )
              props.history.push("/login")
              localStorage.removeItem("create-user-1")
              localStorage.removeItem("create-user-2")
              localStorage.removeItem("create-user-3")
              localStorage.removeItem("create-user-4")
            }
            else if (res2.Error){
              swal(res2.Error )
              props.history.push("/signup-user")
        }
        else{
          swal("Email & Password are Incorrect Plz Try Again !"  )
        }
    })
    .catch((err)=>console.log(err))
      }
      else
      {
        swal("Code Not Correct"  )
      }
    
  }



    const postData3 = () =>{

      
  
  
      if(user.length === 0){
        swal("Enter The Name !"  )
        return
      }
     
      if(email.length === 0){
        // if (email === "projectpharma874@gmail.com")
        swal("Enter The Correct Email !" )
           return
        }

          if(pass.length === 0){
              swal("Enter The Password !"  )
                 return
               }
   
    if(Country.length === 0){
        swal("Enter The Country !"  )
           return
         }
 
    
  
        //  console.log(email)
  
         fetch("https://asad-backend-two.vercel.app/logIn-send-code",{
           method: 'POST' , 
          headers :  {
            "Content-Type" : "application/json" , 
          } , 
          body : JSON.stringify({
             email
          })
        })
        .then((res)=>res.json())
        .then((res2)  =>{
          console.log(res2)
          if (res2 !== null && !res2.Error  ){
            setchanger2(res2.Ma)
            setchanger1(false)
            // console.log(code , res2.Ma)
            // setInterval(()=>{
            //   window.scrollTo(0, 0)
            // },1000)
  

            }
            else if(res2.Error){
              swal(res2.Error )
            }
            else{
              swal("Code Not Correct"  )
            }
          // console.log(res2)
        })
        .catch((err)=>console.log(err))
      }
  
  
  










  return (
    <>
    { changer1 ?
       <div className="container-fuild rgjrgou" style={{width : "30%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px rgb(25,18,43)"}}>
           
         
                <div style={{width: "90%",margin: "auto"}}>
                    <h4 style={{color : "rgb(61, 61, 61)"}}>Create Your Account</h4>
                    <h6 style={{color : "rgb(122, 122, 122)"}}>Already have an Account?  <span style={{textDecoration: "underline",color : "rgb(225, 80, 94)"}}> <Link to="/login" style={{color : "rgb(25,18,43)"}}> Login Now</Link></span></h6>
                </div>
                <br />
                {/* </center> */}

                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>User name</label>
                    <input type="text"  value={user} onChange={(e)=>setuser(e.target.value)}  className="form-control" placeholder="Enter Username" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Country </label>
                    {/* <label>Phone <span style={{color : "blue" , paddingLeft : "50px" , fontWeight : "bold"}} onClick={()=>onSignInSubmit()} >Click for OTP</span></label> */}
                    <input type="text"  value={Country} onChange={(e)=>setCountry(e.target.value)}  className="form-control" placeholder="Enter Country" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Email address</label>
                    <input type="email"  value={email} onChange={(e)=>setemail(e.target.value)}  className="form-control" placeholder="Enter email" />
                </div>
                <br />
                {/* <div id="recaptcha-container"></div> */}
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Password</label>
                    <input type="password"   value={pass} onChange={(e)=>setpass(e.target.value)}  className="form-control" placeholder="Enter password" />
                </div>
            <br/>
                    <button  onClick={()=>postData3()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)"}}>Sign Up</button>
            
               
        </div>



:


<div className="container-fuild rgjrgou" style={{width : "30%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px rgb(25,18,43)"}}>
           

<div style={{width: "90%",margin: "auto"}}>
    <h4 style={{color : "rgb(61, 61, 61)"}}>Create Your Account</h4>
    {/* <h6 style={{color : "rgb(122, 122, 122)"}}>Already have an Account?  <span style={{textDecoration: "underline",color : "rgb(225, 80, 94)"}}> <Link to="/user/login" style={{color : "rgb(25,18,43)"}}> Login Now</Link></span></h6> */}
</div>
<br />
{/* </center> */}

<div className="form-group">
        <label>Code here</label>
        <input type="number"  value={code} onChange={(e)=>setcode(e.target.value)}  className="form-control" placeholder="Check Email" />
    </div>
<br />

    <button  onClick={()=>postData1()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)"}}>Verify</button>


</div>

    }
    </>
  )
}
export default Signup