// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// // import aboutBlog from '../assets/images/blog_1.jpg';
// // import person1 from '../assets/images/person_1.jpg';
// // import person2 from '../assets/images/person_2.jpg';
// // import person3 from '../assets/images/person_3.jpg';
// // import person4 from '../assets/images/person_4.jpg';
// import Carousel from 'react-bootstrap/Carousel';

// import ban1 from '../assets/images/banner-01.jpg';
// import ban31 from '../assets/images/ban-31.jpeg';
// import ban32 from '../assets/images/ban-32.jpeg';

// import ban11 from '../assets/images/ban-1.JPG';
// class About extends Component {
//     state = {

//     }
//     componentDidMount(){
//         window.scrollTo(0, 0)
//     }
//     render() {
//         return (
//             <div>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "44px"  ,fontWeight : "bold"}}><center>
//                   Privacy Policy for CRN Solutions AB

// </center></h1>
//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   CRN Solutions safeguards your personal integrity

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
//     {/* <center> */}
//     CRN Solutions AB, corp. reg. no. 556855-7275, Bruksgatan 36, 263 39 Höganäs, Sweden, (the ”Company”) respects your privacy and is committed to maintaining a high level of security and integrity regarding your personal data. The Company is also committed to ensuring that processing is carried out in accordance with applicable data protection legislation.
// <br />
// <br />
// Do not hesitate to contact the Company should you have any questions regarding the Company's privacy protection. The Company’s contact information is set out under the section “Contact Information”.
// <br />
// <br />

// The definitions of this privacy policy shall have the meaning set forth in the Online-Therapy.com Terms and Conditions, Terms and Conditions for Affiliates and Counselor Agreement.
//     {/* </center> */}
// </p>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Data Controller

// </center></h1>


// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
//     The Company is the data controller of your personal data and is therefore responsible for ensuring that your personal data is processed correctly and securely in accordance with applicable legislation.


// </p>


//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Which personal data does the Company process?

// </center></h1>


// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// Personal data means any information that directly or indirectly relates to a natural, living person. Accordingly, personal data is information about you and your person, e.g. your name, your contact information, pictures of you and your IP-address.
// <br /><br />
// Processing means any operation which is performed on personal data, such as collection, storage, use, adaption or disclosure.

// </p>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Counselors

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to contact you and to fulfil its undertakings towards you as a Counselor.

// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, personal identity number, gender, address, telephone number and email address.

// </li>
//     <li>Title and initials of your credentials.

// </li>
//     <li>Information that you provide to the Company by email, via the Company’s social media or by other channels of communication. This also includes communication through means such as text chats, messages and worksheets between you and the User.

// </li>
//     <li>Payment and purchase history as well as payment information, such as PayPal e-mail address and Stripe account name.

// </li>
//     <li>Information regarding surveys, complaints and warranty matters.

// </li>
//     <li>Website and social media links.

// </li>
//     <li>Technical data, such as IP address, MAC address, URL, unique device ID, network and device performance, browser, language and identification settings, geographic location, operating system, other information from cookies or similar mechanisms (device information).

// </li>
//     <li>Your picture.

// </li>
// </ul>
// </p>


//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Users

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to contact you and to fulfil its undertakings toward you as a User.
// <br />
// This also includes you as an employee/member at a company/organization that has registered a business account at the website.
// <br />
// The Company may process special categories of data of you as a User. The legislator has determined that special categories of personal data shall be further protected. As a main principle, special categories of personal data shall not be processed, however, there are a few exemptions. Private health information is one category of such data which as a main principle is prohibited to be processed. However, the Company must process such data in order to provide its services to you as a User. Based on the exemption that special categories of data may be processed in order to provide health care, the Company is entitled to process health care information you have provided the Company.
// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//    <li>Personal information and contact information, such as name, personal identity number, address, telephone number and email address.

// </li>
//    <li>Information that you provide to the Company by email, via the Company’s social media or by other channels of communication. This includes communication, such as text chats, messages and worksheets between you and the Counselor.

// </li>
//    <li>Information regarding surveys, complaints and warranty matters.

// </li>
//    <li>Technical data, such as IP address, MAC address, URL, unique device ID, network and device performance, browser, language and identification settings, geographic location, operating system, other information from cookies or similar mechanisms (device information).

// </li>
//    <li>Private health information

// </li>
// </ul>
// </p>



//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Emergency Contacts

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// If the User has provided the Company with an Emergency Contact, the Company collects and stores the following information about you as an Emergency Contact, which the Company needs to contact you in case of an emergency.


// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Contact information such as name, telephone number, and relationship with the User.

// </li>
   
// </ul>
// </p>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Suppliers, partners and affiliates

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to contact you and to fulfil its undertakings towards you as a supplier, partner or affiliate (such as but not limited to computer programmers, sales representatives etc.).
// <br />
// This also includes contact persons to companies that have registered a business account with the Company. However, employees/members obtaining Counselor Services through a business account are covered by “Users” above.



// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, address, telephone number, email address, title, position and employer.

// </li>
//     <li>Payment information.

// </li>
   
// </ul>
// </p>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Company representatives for e.g. suppliers, partners and affiliates

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to contact you in your capacity as a representative for a company or organization.




// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, address, telephone number, email address, title, position and employer.

// </li>
//     <li>Information that you provide to the Company by email, via the Company’s social media or by other channels of communication.

// </li>
//     <li>Where applicable, information regarding phone calls with the Company’s customer service.

// </li>
//     <li>Where applicable, payment information.

// </li>
   
// </ul>
// </p>

//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Potential Counselors, suppliers, partners and affiliates and company representatives for potential Counselors, suppliers, partners and affiliates

// </center></h1>





// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you in your role as a potential Counselor, supplier, partner or affiliate to the Company or in your role as a representative for a company that is a potential supplier or partner to the Company. The Company needs such information to contact you and to fulfil its undertakings toward you as a potential Counselor, supplier, partner or affiliate or in your capacity as a representative for a potential Counselor, supplier, partner or affiliate.





// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, address, telephone number, email address, title, position and employer.


// </li>
   
   
// </ul>
// </p>
//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Visitors of the Company’s website

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// In connection with visits to the Company’s website, the Company collects the following information about you that the Company needs to be able to improve, streamline, simplify and develop our website.


// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Technical data, such as IP address, MAC address, URL, unique device ID, network and device performance, browser, language and identification settings, geographic location, operating system, other information from cookies or similar mechanisms (device information).


// </li>
   
   
// </ul>
// </p>


//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Recruitment (job applicants at the Company)

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to be able to recruit the right persons for positions with the Company.



// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, address, telephone number, email address, title, position and employer.
// </li>
//    <li>Information in cover letter and CV.

// </li>
//    <li>Where applicable, your picture.

// </li>
//    <li>Other information that you provide the Company in connection with recruitment.

// </li>
   
// </ul>
// </p>
//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   From where do the company collect your personal data?

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// With respect to Users and Counselors and potential Counselors, your personal data is usually collected directly from you.
// <br />
// Regarding existing or potential suppliers, partners and affiliates or its company representatives, your personal data is usually collected from the company or organization that you represent, but also, in certain cases, directly from you, e.g. by email, our social media or other channels of communication or in connection with events or meetings. This is also the case if you are an employee/member at a company/organization that has registered a business account at the website.

// </p>














//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   The Company’s processing of your personal data

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The purposes for which the Company intends to process your personal data and the legal basis for the respective processing activities are stated in the tables below.





// </p>




//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Users, Counselors, suppliers, partners or affiliates (including company representatives)

// </center></h1>

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{border : "1px solid black" , padding : "3px 5px"}} data-aos="fade-up" data-aos-delay="">
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin : "auto",color :  "black" , fontWeight :"600", fontSize : "16px" , textAlign : "center"}}>Purpose	 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px", textAlign : "center"}}>Legal basis</h2>
//                                 {/* </div> */}
//                             </div>



//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To fulfil legal requirements, such as but not limited to health care requirements, security requirements and accounting requirements.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for compliance with the Company’s legal obligations.

// </h2>
//                                 </div>
//                             </div>



//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To provide and maintain the Platform, including to monitor the usage of the Platform.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User or the Counselor.

// </h2>
//                                 </div>
//                             </div>



//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To manage your account as well as to manage your registration as a User or Counselor.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User or Counselor.

// </h2>
//                                 </div>
//                             </div>



//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To be able to contact you regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User or Counselor.

// </h2>
//                                 </div>
//                             </div>




//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To enable marketing and communication about the Company's brand and the Company's products (e.g. mailing of newsletters and other marketing materials, invitations to the Company's events, meetings and other gatherings etc.).
// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to market its brand, its products and other similar products to you as a customer or to the company that you represent (legitimate interest).
// </h2>
//                                 </div>
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To carry out surveys regarding the Platform.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to evaluate, develop and improve its brand, its service and its marketing (legitimate interest).

// </h2>
//                                 </div>
//                             </div>






//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To ensure payment and analyze purchase history in order to offer Users and Counselors the right services and marketing.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User or Counselor.
// <br />
// The processing is also necessary for the Company’s legitimate interest to offer Users or Counselors relevant marketing with regard to the customers previous purchases (legitimate interest).</h2>
//                                 </div>
//                             </div>






//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To ensure payments to the Counselor in connection with providing the Counselor Services.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the Counselor.

// </h2>
//                                 </div>
//                             </div>






//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To receive payments from Users in connection with purchases of the Company’s products, services and service.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User.

// </h2>
//                                 </div>
//                             </div>






//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To be able to respond to and compensate Users and Counselors in connection with complaints and warranty matters.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the performance of the agreement with the User or Counselor.

// </h2>
//                                 </div>
//                             </div>







//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To be able to keep in touch with a representative of the supplier or partner.

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to keep in touch with you in order to fulfil its obligations under the agreement with its supplier or partner, i.e. the company that you represent (legitimate interest).

// </h2>
//                                 </div>
//                             </div>









//                         </div>
//                     </div>
//                 </div>



// </center>




// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Potential future Counselors, suppliers or partners

// </center></h1>

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{border : "1px solid black" , padding : "3px 5px"}} data-aos="fade-up" data-aos-delay="">
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin : "auto",color :  "black" , fontWeight :"600", fontSize : "16px" , textAlign : "center"}}>Purpose	 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px", textAlign : "center"}}>Legal basis</h2>
//                                 {/* </div> */}
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To enable marketing and communication about the Company's brand and the Platform (e.g. mailing of newsletters and other marketing materials, invitations to the Company's events, meetings and other gatherings etc.).

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to market its brand, its service and other similar products to you or to the company that you represent (legitimate interest).

// </h2>
//                                 </div>
//                             </div>








//                             </div>
//                     </div>
//                 </div>



// </center>








// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Emergency Contacts

// </center></h1>

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{border : "1px solid black" , padding : "3px 5px"}} data-aos="fade-up" data-aos-delay="">
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin : "auto",color :  "black" , fontWeight :"600", fontSize : "16px" , textAlign : "center"}}>Purpose	 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px", textAlign : "center"}}>Legal basis</h2>
//                                 {/* </div> */}
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To be able to contact you in case of an emergency.


// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company's legitimate interest in being able to initiate appropriate action in case of emergency (legitimate interest).


// </h2>
//                                 </div>
//                             </div>








//                             </div>
//                     </div>
//                 </div>



// </center>







// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Visitors of the Company’s website

// </center></h1>

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{border : "1px solid black" , padding : "3px 5px"}} data-aos="fade-up" data-aos-delay="">
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin : "auto",color :  "black" , fontWeight :"600", fontSize : "16px" , textAlign : "center"}}>Purpose	 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px", textAlign : "center"}}>Legal basis</h2>
//                                 {/* </div> */}
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>To ensure the operation of the Company's website and application.
// <br />
// To be able to develop the Company's website and to better adapt the website based on how it is used.


// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to improve, streamline, simplify and develop its website and to attract more Users/Counselors/partners and to increase the number of recurring Users/Counselors/partners (legitimate interest).


// </h2>
//                                 </div>
//                             </div>









//                             </div>
//                     </div>
//                 </div>



// </center>





// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Recruitment (job applicants at the Company)

// </center></h1>

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" style={{border : "1px solid black" , padding : "3px 5px"}} data-aos="fade-up" data-aos-delay="">
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin : "auto",color :  "black" , fontWeight :"600", fontSize : "16px" , textAlign : "center"}}>Purpose	 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px", textAlign : "center"}}>Legal basis</h2>
//                                 {/* </div> */}
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>TTo, in connection with recruitment, be able to decide who is best suited for a position with the Company and to ensure that the relevant person has the necessary skills.




// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The processing is necessary for the Company’s legitimate interest to recruit the right employees and ensure that skilled people work for the Company (legitimate interest).



// </h2>
//                                 </div>
//                             </div>









//                             </div>
//                     </div>
//                 </div>



// </center>










// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   Recruitment (job applicants at the Company)

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company collects and stores the following information about you that the Company needs to be able to recruit the right persons for positions with the Company.



// <ul style={{padding : "0px 40px" ,listStyle : "inherit" }}>
//     <li>Personal information and contact information, such as name, address, telephone number, email address, title, position and employer.
// </li>
//    <li>Information in cover letter and CV.

// </li>
//    <li>Where applicable, your picture.

// </li>
//    <li>Other information that you provide the Company in connection with recruitment.

// </li>
   
// </ul>
// </p>
//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   How long does the Company store your personal data?

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// Your personal data is stored as long as there is a need to preserve them in order to fulfil the purposes for which the data was collected in accordance with this Privacy Policy. Thereafter, your personal data will be deleted.
// <br />
// We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. Some personal data will, for the purpose of complying with applicable accounting legislation, be stored for seven years, counting from the end of the calendar year during which the financial year, to which the information pertained, was terminated.
// <br />

// Contact information regarding company representatives is stored during such time the Company considers that the information is necessary to maintain the relationship with the company/organization. Deletion shall take place when the Company becomes aware that the information is no longer adequate or relevant for the purpose, or at the request of the contact person.
// <br />

// For more information about how long the Company stores specific personal data, please contact the Company. Contact information is provided under section "Contact Information" below.
// <br />


// </p>


//                   <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
//                   With whom does the Company share your personal data?

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company does not disclose personal data to third parties, except when necessary to fulfil a legal obligation or to fulfil the Company's obligations to you and/or partners. Situations when your personal data must be disclosed to third parties are listed in the table below.
// <br />
// Your personal data will not be sold to third parties for marketing purposes.



// </p>















// {/* 
// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Recruitment (job applicants at the Company)

// </center></h1> */}

// <center>
//    <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px"}}>Third party		 </h2>
//                                 {/* </div> */}
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 {/* <div className="icon mr-4 align-self-start"> */}
//                                     <h2 className="text-uppercase the456" style={{margin :"auto",color :  "black" , fontWeight :"600", fontSize : "16px"}}>Reason for third-party disclosure
// </h2>
//                                 {/* </div> */}
//                             </div>





//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Suppliers of cloud solutions




// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay=""  style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Personal data may be transferred to suppliers of cloud solutions since the Company stores certain information in cloud solutions.




// </h2>
//                                 </div>
//                             </div>







//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Service providers

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The Company may share personal data with service providers to monitor and analyze the use of the Platform, to show advertisements to you, to help support and maintain the Platform, to contact you, to advertise on third party websites to you after you visited our Platform or to be able to process payments.

// </h2>
//                                 </div>
//                             </div>







//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Suppliers and partners

// </h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>The Company may disclose personal data to suppliers and/or partners, if the suppliers and/or partners need your personal data to fulfil their undertakings toward the Company.

// </h2>
//                                 </div>
//                             </div>







//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Authorities</h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Personal data may be disclosed to authorities when necessary for compliance with the Company’s legal obligations.

// </h2>
//                                 </div>
//                             </div>







//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Sale</h2>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{border : "1px solid black" , padding : "3px 5px"}}>
//                                 <div className="icon mr-4 align-self-start">
//                                     <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>If the Company intends to transfer all or part of its business, personal data may be disclosed to a potential buyer.

// </h2>
//                                 </div>
//                             </div>










//                             </div>
//                     </div>
//                 </div>



// </center>










// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Regarding payments

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company may provide you with paid products and/or services. In that case, the Company may use third-party services for payment processing (e.g. payment providers).
// <br />
// The Company will not store or collect your payment card details. That information is provided directly to our third-party payment providers whose use of your personal information is governed by their privacy policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
// <br />

// The Company uses the following payment providers:
// <br />

// Stripe: Their Privacy Policy can be viewed here »
// <br />

// PayPal: Their Privacy Policy can be viewed here »
// <br />


// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Transfer of personal data to third countries

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company may transfer your personal data to countries outside the EU/EEA.
// <br />
// If personal data is transferred to a country outside the EU/EEA, the Company will take measures to ensure that the personal data continues to be protected and will also take the necessary measures to ensure a legal transfer of the personal data to countries outside the EU/EEA.
// <br />
// Such necessary measures consist of ensuring that the third country to which the personal data is transferred, is subject to a decision from the European Commission that it ensures an adequate level of protection or taking appropriate protection measures, for example Binding Corporate Rules (BCR) or Standard Contractual Clauses (SCC).
// <br />
// All communication between clients and counselors is end-to-end encrypted. All databases are encrypted at rest with industry-leading encryption. Further, all private health information is hosted behind a 3-tiered web application, with multiple checks and controls to keep your data safe.


// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Social media

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// Regarding personal data that occurs and is processed on social media, such as Facebook, Instagram, Youtube and LinkedIn, we refer users to the policy provided by the respective service providers for information on how each service provider processes personal data. In the Company’s view, the purpose of the processing is that you shall be able to interact and maintain contact with the Company via social media, in order to contribute to good relationships with Users, Counselors, customers and partners and to make the Company's customer service and product widely accessible through several different channels. The processing is necessary for the purposes of the Company’s legitimate interest to market its brand and its products to existing and potential customers and to partners (legitimate interest).



// </p>

// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Your rights

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// As the data controller, the Company is responsible for ensuring that your personal data is processed in accordance with applicable legislation.
// <br />
// The Company will, at your request or on its own initiative, rectify, erase or complete any information found to be inaccurate, incomplete or misleading.
// <br />

// You have the right to request access to and rectification or erasure of your personal data (e.g., if such erasure is required by applicable law), request restriction of the processing of your personal data and object to the processing, as permitted by applicable personal data legislation (e.g. if you contest the accuracy of the personal data or if the processing is unlawful but you oppose the erasure of the personal data and request restriction of its use instead). The Company will notify each recipient to whom the personal data has been disclosed in accordance with what is set out above under “With whom does the company share your personal data?” regarding any rectifications or erasures of personal data as well as of restriction of processing of data according to this section “Your Rights”.
// <br />

// Under certain conditions, you have the right to data portability, i.e., a right to receive your personal data in a structured, commonly used and machine-readable format and the right to transmit those data to another controller.
// <br />

// If you do not want the Company to process your personal data for direct marketing purposes, you have the right to object to such processing at any time. When the Company has received your objection, the Company will cease the processing of your personal data for such marketing purposes.
// <br />

// You have the right, through a written and signed application, to obtain free of charge a register extract from the Company regarding which personal data are stored about you, the purposes of the processing and to which recipients the data has been or shall be transferred. You also have the right to obtain information about the envisaged period for which the personal data will be stored or the criteria used to determine this period. You also have the right to receive information about your other rights as specified in this paragraph “Your Rights”.
// <br />

// We look forward to hearing from you if you have any complaints regarding the Company’s processing of your personal data, in order to correct our processing if necessary. You also have the right to file complaints regarding the Company's processing of your personal data with the Swedish Authority for Privacy Protection.


// </p>




// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Security of your personal data

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// You should always be able to feel safe when you provide us with your personal data. Therefore, the Company has implemented the security measures that are necessary to protect your personal data against unauthorized access, alteration and destruction. The Company will not disclose your personal data, other than as expressly provided by this Privacy Policy.
// <br />
// The Company encrypts your data in transit and at rest on its servers, and all private communication with your counselor is end-to-end encrypted. The Company stores all private health information on servers with full HIPAA compliance.



// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Cookies

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company uses cookie-like techniques in order to provide certain functions on the Company’s website www.online-therapy.com and to improve the website and to deliver a better and more personal service. The information is stored in the form of a file comprising encrypted login data.
// <br />
// The Company may gather and analyze information regarding usage of our website, including domain name, the number of hits, the pages visited, previous/subsequent sites visited and length of user session. This information may be gathered by using cookies. The Company uses cookies and similar technologies to track usage of the website and to address security issues. Further, the Company may also use cookies to store your preferences relating to use of our website. A cookie is a small amount of data, which often includes a unique identifier that is sent to your computer, tablet, phablet, cell phone or other electronic device (a "Device") browser from a website's computer and is stored on your Device's hard drive. Each website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other sites. Many sites do this whenever a user visits their website in order to track traffic flows.
// <br />
// Cookies record information about your preferences and allow the Company to modify the Platform to your interests. During the course or any visit to the Platform, the pages you see, along with a cookie, are downloaded to your Device. Many websites do this, because cookies enable website publishers to do useful things such as finding out whether the Device (and probably its user) has visited the website before. This is done on a repeat visit by checking for the cookie left there on the previous visit. Information supplied by cookies can help the Company to analyze the profile of our visitors in order to provide you with a better user experience.


// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// reCAPTCHA


// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company uses reCAPTCHA which is a service provided by Google Inc. reCAPTCHA is used by the Company to protect its website from for example automated spam or other attacks. Google’s Privacy Policy and Terms of Service apply to reCAPTCHA.


// </p>


// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// If you do not share your personal data with the company



// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// If you do not share your personal data with the Company, the Company will not be able to fulfil its legal or contractual obligations towards you.



// </p>




// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// California Consumer Privacy Act (CCPA)



// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company recognizes California’s specific privacy rights of the Company’s Users in that State. California Users should be aware that the Company does not sell User data to third parties. Further, the Company is a medical records retention company. As such, almost all personal data is kept in encrypted storage as a medical record, including all User created transcripts. Under State Law, the Company shall retain such records for at least seven years. The CCPA is not generally applicable to medical information governed by the California Confidentiality of Medical Information Act (CMIA) or protected health information collected by a covered entity or business associate governed by the privacy, security, and breach notification rules of the Health Insurance Portability and Accountability Act (HIPAA) and Health Information Technology for Economic and Clinical Health (HITECH) Act of 2009.
// <br />
// According to Section 1798.83 of the California Civil Code (also known as the “Shine the Light Law”), residents of California are entitled to request, once a year, if the Company have shared their personal information (non-medical record data only) with other companies for direct marketing purposes during the preceding calendar year. To request a copy of the information disclosure provided by the Company, please contact us on Online-Therapy.com at the “contact us”-link on the website. Please allow reasonable time for a response.
// <br />
// If you are a California resident under the age of 18, and a registered user of any site where this policy is posted, California Business and Professions Code Section 22581 permits you to request and obtain removal of content or information you have publicly posted on our site. The Company does not have User below the age of 13 and does not typically allow Users to publicly post information. However, if you feel you publicly posted information on the Platform and you are between the ages of 13 and 17, please contact us on Online-Therapy.com at the “contact us”-link on the website. Please allow reasonable time for a response. Please be aware that such a request does not ensure complete or comprehensive removal of the data/content you have posted and that there may be circumstances in which the law does not require or even allow removal of data, specifically medical data, even if requested.
// <br />
// California Right to Know. You may request access to the specific pieces of personal data we have collected about you in the last 12 months. You may also request additional details about our information practices, including the categories of personal data we have collected about you, the sources of such collection, the categories of personal data we share for a business or commercial purpose, and the categories of third parties with whom we share your personal data. You may make these requests by contacting us on Online-Therapy.com at the “contact us”-link on the website. Please allow reasonable time for a response.
// <br />
// California Designated Agent. You may designate an agent to make a request on your behalf. That agent must have access to your account in order for us to verify the request.
// <br />
// California Non-Discrimination. The Company will never discriminate against you, including by denying or providing a different level of service should you choose to exercise your rights under the CCPA.




// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// CalOPPA


// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// Our Service does not respond to Do Not Track (DNT) signals. However, some third party websites do keep track of your browsing activities. If you are visiting such websites, you can set your preferences in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser.





// </p>



// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Changes

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Company reserves the right to change this Privacy Policy at any time. In the event of changes to this Privacy Policy, the Company will publish the amended Privacy Policy on www.online-therapy.com with information on when the changes will come into effect and may also notify customers and partners in an appropriate manner.






// </p>

// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Links

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// The Platform may contain links to other websites that are not operated by the Company. If you click on a third party link, you will be directed to that third party's site. The Company strongly advise you to review the privacy policy of every site you visit. The Company has no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.


// </p>


// <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
// Contact information

// </center></h1>

// <p style={{padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
// Do not hesitate to contact the Company if you have any questions about this Privacy Policy, the processing of your personal data or if you wish to exercise your rights under this Privacy Policy or applicable legislation.



// </p>
























//             {/* End About Page */}
//             {/* Start Instagram Feed  */}
            
//           </div>
//         )
//     }
// }

// export default About;

























import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
import Carousel from 'react-bootstrap/Carousel';

import ban1 from '../assets/images/banner-01.jpg';
import ban31 from '../assets/images/ban-31.jpeg';
import ban32 from '../assets/images/ban-32.jpeg';

import ban11 from '../assets/images/ban-1.JPG';
class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>

                  <h1 style={{padding : "80px 10px 0px 10px",  fontSize : "44px"  ,fontWeight : "bold"}}><center>
                  Privacy Policy

</center></h1>


<p style={{color :"black",padding : "30px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
    {/* <center> */}
    This policy is intended to help you understand what data we collect, how we use it, and your rights related to it. For purposes of this policy and unless otherwise specified, “data” includes data that is linked to one person or household including things like name, email address, phone numbers, device ID, Third-Party identifiers, contact information, communications with therapists using our digital communication platform (the “Platform”) to provide services (“therapists”), and IP address. Some jurisdictions might consider this to be “personal data,” “personally identifiable information,” or “sensitive personal data” in certain circumstances. When you use and access our app or website, you accept and agree to both the Terms and Conditions and this Privacy Policy, including that we’ll share certain data with service providers.<br /><br />
The Company is the data controller of your personal data and is therefore responsible for ensuring that your personal data is processed correctly and securely in accordance with applicable legislation. <br /><br />
The purpose of this Policy is to explain the technical aspects of data Processing in a simple and clear way. Please feel free to email contact@castofminds.com if you have any questions about this Policy, or any suggestions for us to improve it.

</p>

                  <h1 style={{padding : "60px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Do you collect, store, or Process my data?

</center></h1>


<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
In this policy, we refer generally to activities done with data as “Processing.” Examples of Processing include collecting, storing, and using data. The categories of data which we Process are listed below. We Process this data to do things like operate the Platform and make sure you’re able to use our services effectively. We may also Process data to send you periodic emails or text messages. You can opt out of receiving texts or marketing communications at any time. Additionally, provided you opt-in and agree, we may Process and share some data with Third Parties for advertising purposes. You can find more details in the relevant sections of this policy.
<br />
We Process depends on how you’re using our website, app, or the Platform. We explain below the specific data we Process. 

<br />






<br />

<b>Visitor data:</b> When you visit the website, we Process information like the particular pages visited or which features you interacted with, the amount of time on the website, information about the type of device and browser you’re using, and IP address. We may Process your Third-Party identifier or advertising ID (if available based on the settings of your device) and will share it if you opt-in.
<br />
<br />

<b>On boarding data: </b>When an account is created with the Platform, the user fills out a questionnaire. We Process the information used to complete this questionnaire. Some of this information may include identifiers like email address, phone number, chosen name (first name or nickname), and physical address.
<br />
<br />

<b>Account Registration Data: </b>Once a user registers with the Platform, we Process data such as the account name the user selects, the email that they use, their age, phone number, emergency contact details, and whether a user verifies their email address. We also assign each user (including therapists) who creates an account a sequentially-generated user ID.
<br />
<br />

<b>Transaction Data: </b>We Process data about payment transactions on the Platform such as whether a user completed payment for our services, signed up for services using a trial offer, canceled or ended a trial, received a discount or financial aid, or received any extensions or refunds.
<br />
<br />

<b>Checkout Flow Data: </b>We Process data about whether a user registers for the Platform or pays for access to the Platform services.
<br />
<br />

<b>Therapist Data: </b>In order to identify, match, credential, re-credential, run checks and pay therapists, we Process therapist information such as the therapist’s name, bank account information, gender, date of birth, governmental identification numbers, e-mail address, phone number, address, license information and areas of interest/expertise, education, and job history. 
<br />
<br />

<b>Therapy Quality Data: </b>We Process client feedback about their therapist, ratings and reviews of their therapist, actions regarding switching therapist or quitting therapy, and the reason selected by the client, We Process therapist session availability, session cancellations and no-shows.

<br />
<br />
<b>Therapist Engagement Data: </b>We Process data about therapist logins to the Platform, the number of live sessions conducted by a therapist, number of messages and words exchanged by a therapist, number of worksheets shared by a therapist, and number of journal entries shared with a therapist.
<br />
<br />
<b>Therapy Communications Data: </b>We Process communications and related information users share with their therapist to facilitate the therapy. This includes messages with therapists, worksheets, attendance, and journal entries. Users may also choose to utilize our transcribe feature to assist with dictation, otherwise, we do not record the video or audio sessions with therapists.




</p>





                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Why do you collect and Process my data?

</center></h1>


<p style={{color :"black",padding : "20px 30px 30px 30px",  fontSize : "13px"  ,fontWeight : "500"}}>
We Process some data to connect you with therapy services: In order for us to connect you with therapy services on our Platform, we need to be able to facilitate information sharing between you and your therapist so that you can get the help you need from them. We also Process data like your therapist preferences, your state (if applicable), and your country to determine what therapist to suggest to you based on applicable licensing requirements.
<br />
<br />
We Process some data to communicate with you, verify your identity and secure your account: For example, we need to make sure that if you ask a question or have a concern about the Platform, we’re able to respond to you and provide an answer.
<br />
<br />
We Process some data to monitor the Platform and make sure quality services are being provided to you: For example, we track if a live session occurred, was canceled or if the therapist did not show up, to ensure that timely services are being delivered to you. We also track ratings, reviews, complaints and other client feedback to ensure the quality of therapists on our Platform. If you consent, a licensed therapist who is employed as part of the cast of minds Clinical Operations Team may review correspondence with your therapist for quality assurance purposes, For example, if you raise a concern about your therapist, or we have concerns about a specific therapist’s clinical care.
<br />
<br />
We Process some data to personalize your web or app experience: For example, if you identify as a religious person and would prefer a therapist who utilizes faith-based practices, we Process that information to allow us to match you with an appropriate therapist that can meet these needs. Another example is if you state that you would like help with anxiety, we may recommend content and features that would be helpful to you, such as anxiety-related group sessions.
<br />
<br />
We Process some data to offer you new features and make the services more convenient for you: For example, we may use some of your data to determine which products and features to roll out to you. We may also Process data to know when you have already seen a “pop-up” on the Platform and do not need to be shown it anymore. Another example of this is when we use your IP address to help conveniently auto-populate your state (if applicable) and country, or assist you with providing your address when completing information about your emergency contact.
<br />
<br />
We Process some data to comply with laws: For example, a court might subpoena information from us where we would be required to share certain information requested in the subpoena. This is not unique to cast of minds and is applicable to in-person therapy as well. Keep in mind that, as a general rule, we defer to your chosen therapist to decide to produce (or not produce) any psychotherapy notes or messages you have had with them. Many jurisdictions have strict rules governing therapist/client relationships and the confidentiality requirements associated with that. We encourage you to discuss with your therapist early on if you have concerns about their disclosure obligations.
<br />
<br />
We Process some data to protect your safety and the safety of others: For example, if we have reason to believe that you or any other person may be in immediate danger or your privacy infringed upon, we may use the information to investigate or reach out to you or the appropriate authorities if it is legally appropriate/permitted to do so.
<br />
<br />
We Process some data to help us understand how you use our service and improve it: For example, we might assess the usage of various features and decide to invest more resources in features that are popular and/or decide to remove features that aren’t providing value to our members. We might also ask you for feedback about how we can improve our Platform or what you like or don’t like about the therapist that is matched to you.

</p>

                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Who can see the interactions I have with my therapist?
</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
You and your therapist are able to see the messages you send, the worksheets you submit or the journal entries you submit (if you opt in to sharing journal entries). A licensed therapist who is employed as part of the Cast of minds Clinical Operations Team may review correspondence with your therapist for quality assurance purposes, For example, if you raise a concern about your therapist, or we have concerns about a specific therapist’s clinical care.
<br />
<br />
In addition, our internal Legal or Trust and Safety teams may review correspondence for specific accounts if we have a reason to believe that there is a security, legal, fraud or abuse issue occurring on that specific account.
<br />
<br />
Messages with your therapist are not shared with any Third Party, and your live sessions are not recorded. We also do not share when you send a message, or have a session with your therapist, with any Third Party.

</p>




                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  How do you keep my data secure?

</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
We apply industry standards and strive to apply best practices to prevent any unauthorized access and disclosure. Internet-based services carry inherent security risks, but our systems infrastructure, encryption technology, operation and processes are all designed, built, and maintained with your security and privacy in mind. 
<br />
<br />
Cast of Minds has an experienced team of data security professionals whose job it is to make sure we use secure technology to protect your data. We have security staff who test internal security at Cast of Minds to try and anticipate threat actors and security staff that act defensively and build processes and infrastructure to prevent incidents and attacks. We have numerous robust security practices such as:
<br />
<br />
All the messages between a member and their therapist are secure and encrypted by 256-bit encryption.
<br />
<br />
Our browsing encryption system (SSL) follows modern best practices.
<br />
<br />
Our databases are encrypted and scrambled rendering them useless in the unlikely event that they are stolen or inappropriately retrieved.
<br />
<br />
We have robust monitoring and alerting systems and procedures in place that include both automated systems and humans (for example, there are always at least one security personnel active in our 365/24/7 on-call rotation).
</p>

                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Regarding payments

</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
The Company may provide you with paid products and/or services. In that case, the Company may use third-party services for payment processing (e.g. payment providers).
<br />
<br />
The Company will not store or collect your payment card details. That information is provided directly to our third-party payment providers whose use of your personal information is governed by their privacy policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. The company at the moment uses visa and MasterCard payment gateways for all its users.

</p>
                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500"}}><center>
                  Contact information
</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "16px"  ,fontWeight : "bold"}}>
Do not hesitate to contact the Company if you have any questions about this Privacy Policy, the processing of your personal data or if you wish to exercise your rights under this Privacy Policy or applicable legislation.
<br />
Cast of Minds 
<br />
Corporate registration number: 4220104917965
<br />
Email address: contact@castofminds.com

</p>


               



















            
          </div>
        )
    }
}

export default About;