import React, { useEffect,useState } from 'react';
import {Link} from "react-router-dom"
import InstagramIcon from '@material-ui/icons/Instagram';

import LinkedInIcon from '@material-ui/icons/LinkedIn';

import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';



// import FacebookIcon from '@mui/icons-material/Facebook';
const Home = ( props ) => {
 
  const [dis1 , setdis1] = useState(false)
  const [dis2 , setdis2] = useState(false)




  useEffect(() =>{
    const inter = setInterval(()=>{

    if ( JSON.parse(localStorage.getItem("check Head")) ){
      setdis1(true)
    }
    else{
      setdis1(false)
    }
    if(JSON.parse(localStorage.getItem("check Head 1"))){
      setdis2(true)
    }
    else{
      setdis2(false)
    }
    },1000)
        
    return () => clearInterval(inter);
                

  },[])

        return (
            <>
          <header className='header8'>
      <h1 style={{fontSize: "3rem",fontFamily: '"National 2 Compressed", sans-serif',
    fontWeight: "800"}}>OUR CLIENTS</h1>
      <p style={{maxWidth:"800px",textAlign:"center",margin:"auto",fontWeight:"500",}}>We're proud to partner with the best in the business. Organisations that understand people and profit aren't competing priorities. Together we're on a mission to build healthy, happy and high-performing cultures.</p>
    </header>
  
    <section class="clients5">
      {/* <h2>Our Partners</h2> */}
      <ul>
        <li><img src="uber-logo.png" alt="Uber"/></li>
        <li><img src="gibson-dunn-logo.png" alt="Gibson"/></li>
        <li><img src="kearney-logo.png" alt="Kearney"/></li>
        <li><img src="entain-logo.png" alt="Entain"/></li>
        <li><img src="samsung-logo.png" alt="Samsung"/></li>
        <li><img src="shearman-logo.png" alt="Shearman"/></li>

      </ul>
    </section>
            <center>

      <h2  style={{fontSize: "3rem",fontFamily: '"National 2 Compressed", sans-serif',
    fontWeight: "800"}}>Unmind Works</h2>
            </center>
    <section class="stats4">
      <ul>
        <li>
          <span>16%</span>
          <p>less presenteeism among users of our sleep Shorts</p>
        </li>
        <li>
          <span>90%</span>
          <p>of leaders report behavioural change after completing our manager training</p>
        </li>
        <li>
          <span>92%</span>
          <p>of staff have a better understanding of wellbeing after using Unmind</p>
        </li>
      </ul>
    </section>



    <center>
<h2  style={{fontSize: "1rem",fontFamily: '"National 2 Compressed", sans-serif',
fontWeight: "bold"}}>READ OUR BLOG

</h2>
      </center>
    <section className="blog-posts">

            <article className="blog-post">
              <img src="https://via.placeholder.com/300x200" alt="Blog Post Image"/>

                <h2>Mental Health Awareness</h2>
                <p>Reflecting on Mental Health Awareness Month: Looking Around and Within
                </p>
                <span>Posted on 31st May, 2024</span>
            </article>
            <article className="blog-post">
              <img src="https://via.placeholder.com/300x200" alt="Blog Post Image"/>

                <h2>Embracing Mindfulness</h2>
                <p>Embracing Mindfulness: A Path to Inner Peace and Well-Being
                </p>
                <span>Posted on 31st May, 2024</span>
            </article>
            <article className="blog-post">
              <img src="https://via.placeholder.com/300x200" alt="Blog Post Image"/>

                <h2>The Power of Risk</h2>
                <p>The Power of Risk: Why Embracing Uncertainty is Essential for Success</p>
                <span>Posted on June 11, 2024</span>
            </article>
        </section> 
        <center>
          
        <a className="btn" onClick={()=>props.history.push("/contact-us")} style={{background : "transparent" ,  color : "rgb(25 18 43)" , fontWeight :"500" , border : "3px solid #F7CF47", margin :"20px 20px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>Read more</a>
          </center> 

            </>
        )
    
}

export default Home;