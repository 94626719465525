import React , { useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';



// import key13 from '../assets/images/whyjoinus (1).jpeg';
// import key14 from '../assets/images/whyjoinus (2).jpeg';
import a1 from '../assets/images/Cast of Minds (8).png';
import a2 from '../assets/images/a1.jpg';


const Header =(props)=> {
  

  let history = useHistory();
  useEffect(() =>{
    window.scrollTo(0, 0)
  },[])
        return (
            <div style={{background:"rgb(25, 18, 43)"}}>
            
            {/* <header className='header1'>

  </header> */}

  <main className='main1'>
    <br />
    <br />
    <h2 className='ppp3' style={{fontSize: "4.0625rem",fontFamily: '"Lexend,sans-serif',
    fontWeight: "800",color:"white",maxWidth: "1460px",margin:"auto"}}>"Empowering Minds, Nurturing Growth, Together."</h2>
   <br />
    <p className='ppp1' style={{maxWidth: "1460px",margin:"auto"}}>With us, you're never on your own. Cast of Minds is filled with people who
    get it.</p>
    <br />
    
    <Link className="btn" onClick={()=>history.push("/signup-1")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "#F7CF47" ,  color : "white "  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>JOIN NOW   <span style={{paddingLeft:"10px",fontSize: "19px",color :"white"}}> ► </span>   </Link>
    <br />
    <br />
    <br />
    <img src={a1} style={{width:"100%",borderRadius:"10px"}} ></img>
    <br />
    <br />
    <br />
    <br />
    <h2  style={{fontSize: "1.5rem",
    fontWeight: "700",color:"white"}}>We're Here When You Need Us</h2>
      <br />
    <ul className='uul'>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Reach Out Anytime:</strong> Support is always just a click away, day or night.</li>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Tools for Your Journey:</strong> Practical resources to guide your path to better mental health.</li>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Be Yourself, Safely:</strong> A welcoming space where you can truly be you.</li>
    </ul>
    {/* <br /> */}
    
    <Link className="btn" onClick={()=>history.push("/signup-1")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "#F7CF47" ,  color : "white "  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>JOIN NOW   <span style={{paddingLeft:"10px",fontSize: "19px",color :"white"}}> ► </span>   </Link>
    <br />
    <br />
    <br />
    <img src={a2} style={{width:"100%",borderRadius:"10px"}}></img>

    <br />
    <br />
    <br />    <h2  style={{fontSize: "1.5rem",
    fontWeight: "700",color:"white"}}>Ready to Get Started?</h2>
    <br />
    <p className='ppp2'>Joining is easy and free! Just follow these simple steps:</p>
    {/* <br /> */}
    
    {/* <Link className="btn" onClick={()=>history.push("/signup-1")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "transparent" ,  color : "#F7CF47 "  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>JOIN NOW   <span style={{paddingLeft:"20px",fontSize: "19px",color :""}}> ► </span>   </Link> */}
    <br /> 
       <ol className='uul'>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Sign Up:</strong> Provide a few details to get started. We'll send you an email to confirm your account.</li>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Verify Your Account:</strong> Check your email and click the verification link to activate your account.</li>
      <li style={{fontWeight:"400"}}><strong style={{fontWeight:"700"}}>Customize Your Experience:</strong> Let us know what kind of support you're looking for and take a quick tour of Cast of Minds.</li>
    </ol>
    <br/>
{/* <center>

    <img src={key14} alt="" style={{width:"100%"}}/>
</center> */}
    <br/>


    <h2 style={{fontWeight:"600",color:"white"}}>Everyone needs a place to talk</h2>
    <p className='ppp1'>Life can feel overwhelming. Cast of Minds offers a safe, anonymous space to share your feelings 24/7, free from judgment.</p>
    <br/>

    <h2 style={{fontWeight:"600",color:"white"}}>Supportive and safe</h2>
    <p className='ppp1'>Your mental health is important. With support from professionals and empathetic peers, our community helps you navigate tough times in a confidential setting.</p>
    <br/>

    <h2 style={{fontWeight:"600",color:"white"}}>Accessible anytime, anywhere</h2>
    <p className='ppp1'>No schedule can dictate when you might need support. Cast of Minds is always available, accessible from any internet-connected device.</p>
    <br/>

    <h2 style={{fontWeight:"600",color:"white"}}>Professional resources</h2>
    <p className='ppp1'>You don't have to face mental health challenges alone. We provide easily understandable resources, including articles, tools, and courses designed by mental health experts.</p>
    <br/>

    <h2 style={{fontWeight:"600",color:"white"}}>A caring community</h2>
    <p className='ppp1'>Join a diverse group of individuals each with their own stories. In Cast of Minds, learn from others and discover new ways to manage your emotions and improve your mental health.</p>
    <br/>
    <Link className="btn" onClick={()=>history.push("/signup-1")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "#F7CF47" ,  color : "white "  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>JOIN NOW   <span style={{paddingLeft:"10px",fontSize: "19px",color :"white"}}> ► </span>   </Link>
    {/* <Link to="/sign" class="button7">Get Started</Link> */}
    </main>
          </div>
        )
    
}

export default Header;