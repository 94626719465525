import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class           About extends Component {
    state = {
        data : [],
        data1 : [],

    }
    componentDidMount(){
        window.scrollTo(0, 0)
        fetch("https://asad-backend-two-lemon.vercel.app/allUser",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            this.setState({data : res6})
            fetch("https://asad-backend-two-lemon.vercel.app/all-therispist",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           }
          })
          .then(res15=>res15.json())
          .then(res16=>{

            
            let a = []
            res6.map((res,z)=>{
             res16.map((res1,b)=>{
                 if(res.TheripistId === res1._id){
                    a.push(res1) 
                }
             })
            })

            console.log(a)
            this.setState({data1 : a})


            })
            })

    }

    
     AccountforApproval = (e,id,email,i) =>{
        console.log(e,id,email,i)
                 if(e){
                    fetch("https://asad-backend-two-lemon.vercel.app/Approval",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : true,
                  id ,
                  email
                })
              })
              .then((res)=>res.json())
              .then((res2)  =>{
                  if (res2 !== null && !res2.Error  ){
                      

                        swal("SucessFully Change Reload"  )
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
                 else{
                    fetch("https://asad-backend-two-lemon.vercel.app/Approval",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : false,
                  id ,
                  email
                })
              })
              .then((res4)=>res4.json())
              .then((res22)  =>{
                  // console.log(res22)
                  if (res22 !== null && !res22.Error  ){
                      
                        swal("SucessFully Change Reload"  )

                    
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
              }


              deleteProduct = (id) =>{
           
                fetch("https://asad-backend-two-lemon.vercel.app/deletePost2",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                    } ,
                  body : JSON.stringify({
                    id
                  }),
              })
              .then(res=>res.json())
              .then((res2)=>{ 
                console.log(res2)    
                    const newData = this.state.data.filter(data=> data._id !== res2._id)
                 this.setState({data :newData })
               
              })
        
        
        
              }
        

    render() {
        return (
            <div>
            <center>
               <table style={{width: "200%",padding : "0px 30px",
    margin: "50px 100px",textAlign : "center",fontWeight : "bold"}}>
  <tr>
    <th>Sno .</th>
    <th>Full Name</th>
    <th>Email</th>
    <th>Theripist</th>
    <th>Password</th>
    <th>Country</th>
    <th>Gender   </th>
    <th>payment   </th>
    <th>meet   </th>
    <th>Session   </th>
    <th>Approval  </th>
    <th>Delete  </th>
  </tr>
{
    this.state.data.map((res,i)=>{
        return (
            <tr key={i}>
    <td>{i+1}</td>
    <td>{res.Name}</td>
    <td>{res.email}</td>
    <td>
        <span>
      {this.state.data1.length !== 0 ? this.state.data1[i].fname : ""}
          
        </span>
        <br />
        <span>
      {this.state.data1.length !== 0 ?this.state.data1[i].email: ""}
          
        </span>
      </td>
    <td>{res.pass}</td>
    <td>{res.Country}</td>
    <td>{res.Gender}</td>
    <td>Tier {res.payment}</td>
    <td>{res.meet}</td>
    <td>{res.Session}</td>
    <td>{
    res.Category.map((res1,g)=>{
        return (
            <>
        {
          res1  
        } <br />
        </> 
        )
       
    })
    }</td>
    <td>{res.isApprovl ? 
    <> 
    <span style={{border :  "none" , background  :  "yellow" , borderRadius : "10px"}}>Aprroved</span>
    <br />
    <button onClick={()=>this.AccountforApproval(false,res._id,res.email,i)}>Not Aprroved</button>
    </>
    : 
    <> 
    <span style={{border :  "none" , background  :  "red" , borderRadius : "10px"}}>Not Aprroved</span> 
    <br />
    <button onClick={()=>this.AccountforApproval(true,res._id,res.email,i)}>Aprroved</button>
    </>
    
    }</td>
     <td>                                        <DeleteForeverIcon style={{border : "3px solid #e7acac" , borderRadius : "10px" }} onClick={()=>this.deleteProduct(res._id)}/>
</td>
  </tr>
            
        )
    })
}
  
 
</table> 
            </center>
            
        
          </div>
        )
    }
}

export default About;