import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
import ban27 from '../assets/images/ban-27.jpeg';

import ClearIcon from '@material-ui/icons/Clear';


// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [data , setdata] = useState([])

    // const [email ,  setemail ] = useState("")
    const [name ,  setname ] = useState("")
    const [pass ,  setpass ] = useState("")
    const [Date ,  setDate ] = useState("")
    const [StartTime ,  setStartTime ] = useState("")
    const [EndTime ,  setEndTime ] = useState("")
    const [Description ,  setDescription ] = useState("")
    const [user ,  setuser ] = useState("")
    // const [Country ,  setCountry ] = useState("")
    // const [phone ,  setphone ] = useState("+92")
    // const [code ,  setcode ] = useState("")
    // const [gy ,  setgy ] = useState(true)
    // const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    // const [changer1 ,  setchanger1 ] = useState(false)
    // const [changer2 ,  setchanger2 ] = useState("")
    const [Product_Sub_Catagories ,  setProduct_Sub_Catagories ] = useState("Exercise")


    useEffect(() =>{

        // window.scrollTo(0, 0)

        // fetch("https://asad-backend-two.vercel.app/all-activity",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  }
        // })
        // .then(res5=>res5.json())
        // .then(res6=>{
        //     console.log(res6)
        //     let A = []
        //     res6.map((res1,i)=>{
        //         if(res1.Userid === JSON.parse(localStorage.getItem("user main 1"))._id &&  res1.theripistId ===JSON.parse(localStorage.getItem("user main 1")).TheripistId && res1.Status){
        //             A.push(res1)
        //         }
        //     })
        //     setdata(A)
        // })
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
    
     
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

     
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])








 const deleteProduct = (id,i) =>{
    fetch("https://asad-backend-two.vercel.app/delete-activity",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            id
        })
    })
    .then(res11=>res11.json())
    .then((res12)=>{ 
        const newData = data.filter(data=> data._id !== id)
        setdata(newData)
    })
    // fetch("https://asad-backend-two.vercel.app/deleteActivity/"+id ,{
    //   method: "DELETE" , 
    //   headers: {
    //     "Content-Type" : "application/json" ,
    //   }
    // })
    // .then(res=>res.json())
    // .then(res2=>{
        // const newData = data.filter(data=> data._id !== id)
        //   setdata(newData)
    // })
  }


 const SubmitDataMessage = () =>{
  setdispaly1(false)
  // this.setState({displayNone : false})
//   const date1 = new Date()

//     let day= date1.getDate() 
//     let month= (date1.getUTCMonth()+1)
//     let year= date1.getUTCFullYear()
//     year = year - 2000

//     if(date1.getDate() < 10) day = "0"+ date1.getDate()
//     if(date1.getUTCMonth()+1 < 10) month = "0"+ (date1.getUTCMonth()+1)

//     let c = day +""+month+ ""+year

    let a=[]
  if(data)
   a=[...data,{user,Description}]
  else
   a=[{user,Description}]
   setdata(a)
   setuser("")
                                            setDescription("")
                                // fetch("https://asad-backend-two.vercel.app/create-activity",{
                                //             method: "POST",
                                //             headers :  {
                                //                 "Content-Type" : "application/json" , 
                                //             } ,
                                //             body : JSON.stringify({
                                //                 name,
                                //                 Name : user,
                                //                 Description,
                                //             })
                                //         })
                                //         .then(res11=>res11.json())
                                //         .then((res12)=>{ 
                                //             if(res12)
                                //             setdata([...data,res12])
                                //             setuser("")
                                //             setDate("")
                                //             setStartTime("")
                                //             setEndTime("")
                                //             setProduct_Sub_Catagories("")
                                //             setDescription("")


                                //             console.log(res12)
                                //             if(!res12.Error){
                                //                 setdispaly1(true)
                                //                 swal("Success");
                                //                 this.setState({
                                //                         mainline: "",
                                //                     })
                                //             }
                                //             else{
                                //                 setdispaly1(true)
                                                
                                //                 swal(res12.Error);
                                //             }
                                //         })
                                //         .catch(err=>{
                                //             swal("Success");
                                //             setdispaly1(true)
                                //     }) 
                                    }
                                   
                                    

                                    


 const SubmitDataMessage1 = () =>{
  setdispaly1(false)
  // this.setState({displayNone : false})
//   const date1 = new Date()

//     let day= date1.getDate() 
//     let month= (date1.getUTCMonth()+1)
//     let year= date1.getUTCFullYear()
//     year = year - 2000

//     if(date1.getDate() < 10) day = "0"+ date1.getDate()
//     if(date1.getUTCMonth()+1 < 10) month = "0"+ (date1.getUTCMonth()+1)

//     let c = day +""+month+ ""+year

//     let a=[]
//   if(data)
//    a=[...data,{user,Description}]
//   else
//    a=[{user,Description}]
//    setdata(a)
                                fetch("https://asad-backend-two.vercel.app/create-business-data",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                                name,
                                                data
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(res12)
                                            setdata([])
                                            setname("")
                                            setDate("")
                                            setStartTime("")
                                            setEndTime("")
                                            setProduct_Sub_Catagories("")
                                            setuser("")
                                            setDescription("")


                                            console.log(res12)
                                            if(!res12.Error){
                                                setdispaly1(true)
                                                swal("Success");
                                                this.setState({
                                                        mainline: "",
                                                    })
                                            }
                                            else{
                                                setdispaly1(true)
                                                
                                                swal(res12.Error);
                                            }
                                        })
                                        .catch(err=>{
                                            swal("Success");
                                            setdispaly1(true)
                                    }) 
                                    }
                                   
                                    

                                    


        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         


        <header className='header1'>
      <h1>Subscribe</h1>
      {/* <div class="toggle">
        <label>Annually </label>
        <div class="toggle-btn">
          <input type="checkbox" class="checkbox1" id="checkbox" />
          <label class="sub" id="sub" for="checkbox">
            <div class="circle"></div>
          </label>
        </div>
        <label> Monthly</label>
      </div> */}
    </header>
    <div class="cards1">
      <div class="card1 shadow1">
        <ul class="ul1">
          <li class="pack">Tier 1</li>
          <li id="basic" class="price bottom-bar">$ 999<span style={{fontSize:"17px",paddingTop:"40px"}}>/monthly</span></li>
          <li class="bottom-bar">Complete Therapy Tool Box
</li>
          <li class="bottom-bar">Upto 10 Team members
</li>
          <li class="bottom-bar">Exclusive Psychology tests<br/> tailored for your team</li>
          <li class="bottom-bar"> On demand in house therapy<br/> and performance boosting workshops</li>
          <li><button class="btn1">Learn More</button></li>
        </ul>
      </div>
      <div class="card1 active">
        <ul class="ul1">
          <li class="pack">Tier 2</li>
          <li id="professional" class="price bottom-bar">$ 2499<span style={{fontSize:"17px",paddingTop:"40px"}}>/monthly</span></li>
          <li class="bottom-bar">Complete Therapy Tool Box
</li>
          <li class="bottom-bar">Upto 25 Team members</li>
          <li class="bottom-bar">Exclusive Psychology tests<br/> tailored for your team
</li>
<li class="bottom-bar"> On demand in house therapy<br/> and performance boosting workshops</li>

          <li><button class="btn1 active-btn">Learn More</button></li>
        </ul>
      </div>
      <div class="card1 shadow1">
        <ul class="ul1">
          <li class="pack">Tier 3</li>
          <li id="master" class="price bottom-bar">$ 4999<span style={{fontSize:"17px",paddingTop:"40px"}}>/monthly</span></li>
          <li class="bottom-bar">Complete Therapy Tool Box
</li>
          <li class="bottom-bar">Upto 50 Team members</li>
          <li class="bottom-bar">Exclusive Psychology tests<br/> tailored for your team
</li>
<li class="bottom-bar"> On demand in house therapy<br/> and performance boosting workshops</li>

          <li><button class="btn1">Learn More</button></li>
        </ul>
      </div>
    </div>



<br />
<br />
<center>
    <p style={{fontSize:"13px",fontWeight:"500"}}>If your business requirements doesn't fit any of the above tier<br/> feel free to reach us at: atcastofminds@gmail.com for tailored plans for you and your team, ​We're thrilled to welcome you!</p>
    </center>
<br />
<br />






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;