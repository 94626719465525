import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class           About extends Component {
    state = {
        data : []
    }
    componentDidMount(){
        window.scrollTo(0, 0)
        fetch("https://asad-backend-two.vercel.app/allUser",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            this.setState({data : res6})
           console.log(res6)
            })

    }

    
     AccountforApproval = (e,id,email,i) =>{
        console.log(e,id,email,i)
                 if(e){
                    fetch("https://asad-backend-two.vercel.app/Approval",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : true,
                  id ,
                  email
                })
              })
              .then((res)=>res.json())
              .then((res2)  =>{
                  if (res2 !== null && !res2.Error  ){
                      

                        swal("SucessFully Change Reload"  )
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
                 else{
                    fetch("https://asad-backend-two.vercel.app/Approval",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Approved  : false,
                  id ,
                  email
                })
              })
              .then((res4)=>res4.json())
              .then((res22)  =>{
                  // console.log(res22)
                  if (res22 !== null && !res22.Error  ){
                      
                        swal("SucessFully Change Reload"  )

                    
                  }
                  else{
                    swal("Try Again"  )
                  }
        
                })
        
                 }
        
              }


              deleteProduct = (id) =>{
           
                fetch("https://asad-backend-two.vercel.app/deletePost2",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                    } ,
                  body : JSON.stringify({
                    id
                  }),
              })
              .then(res=>res.json())
              .then((res2)=>{ 
                console.log(res2)    
                    const newData = this.state.data.filter(data=> data._id !== res2._id)
                 this.setState({data :newData })
               
              })
        
        
        
              }
        

    render() {
        return (
            <div>
            <center>
               <table style={{width: "100%",padding : "0px 30px",
    margin: "50px 100px",textAlign : "center",fontWeight : "bold"}}>
  <tr>
    <th>Sno .</th>
    <th>Full Name</th>
    <th>Email</th>
    <th>Password</th>

  </tr>
{
    this.state.data.map((res,i)=>{
        return (
            <tr key={i}>
    <td>{i+1}</td>
    <td>{res.Name}</td>
    <td>"{res.email}"</td>
    <td>"{res.pass}"</td>
    
  </tr>
            
        )
    })
}
  
 
</table> 
            </center>
            
        
          </div>
        )
    }
}

export default About;