import React ,{useEffect , useState,useRef} from 'react'
import {Link} from "react-router-dom"




// import video1 from '../assets/images/COM_1.png';
// import video2 from '../assets/images/COM_1_.png';

import key13 from '../assets/images/IMG_2899.PNG';
import key14 from '../assets/images/Capture1.PNG';





const Home = ( props ) => {
  // const videoEl = useRef(null);

    const [t1 , sett1] = useState(0)
    const [sliderWidth, setSliderWidth] = useState(50);
    const [count , setcount] = useState(270000)
    const [count1 , setcount1] = useState(0)
    const [count2 , setcount2] = useState(0)
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
      window.scrollTo(0, 0)


      counter(250000, 275789)
      counter1(-1000, 50)
      counter2(-1000, 303)


    //   let a = 0
    //   setInterval(() => {

    //     a = a + 1000
    //     sett1(a)        
        
    // }, 100);

      // document.querySelector('video').play();
      // videoEl &&
      // videoEl.current &&
      // videoEl.current.play().catch(error => {
      //   console.error("Error attempting to play", error);
      // });
        // window.scrollTo(0, 0)
        localStorage.removeItem("check Head")
        localStorage.removeItem("check Head 1")
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       
 },[])


 
 const updateSlider = () => {
  document.getElementById('slider-fill').style.width = `${sliderWidth}%`;
  document.getElementById('slider-value').textContent = sliderWidth;
  document.getElementById('slider-value').style.left = `${sliderWidth}%`;
};

const handleSliderLeftClick = () => {
  setSliderWidth(sliderWidth - 10);
  if (sliderWidth < 10) {
    setSliderWidth(0);
  }
  updateSlider();
};

const handleSliderRightClick = () => {
  setSliderWidth(sliderWidth + 10);
  if (sliderWidth > 90) {
    setSliderWidth(100);
  }
  updateSlider();
};

 const counter = (minimum, maximum) => {
  for (let count3 = minimum; count3 <= maximum; count3++) {
      setTimeout(() => {
          setcount(count3)
      }, 1000);
  }
}
 const counter1 = (minimum, maximum) => {
  for (let count4 = minimum; count4 <= maximum; count4++) {
      setTimeout(() => {
          setcount1(count4)
      }, 10);
  }
}
//  const counter2 = (minimum, maximum) => {
//   for (let count5 = minimum; count5 <= 5; count5++) {
//       setTimeout(() => {
//           setcount2(count2+60)
//       }, 1000);
//   }
// }

 const counter2 = (minimum, maximum) => {
  for (let count5 = minimum; count5 <= maximum; count5++) {
      setTimeout(() => {
          setcount2(count5)
      }, 10);
  }
}








        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
<div style={{background:"rgb(25, 18, 43)"}}>

<br />
<br />
<br />
<br />

        <div class="container change-size-main-page"  style={{width :  "70%"}}>
  <div class="titles" >
    <div><h1 className='h11  change-size-main-page1' style={{fontFamily:"Lexend,sans-serif",fontSize: "4.0625rem",
    lineHeight: "1em",
    fontWeight: "700",color:"white"}}>First Aid Kit For Your <br /> Mental Health</h1>
    </div>
  </div>
  <div class="details details1">
    <p  style={{width :  "100%",color :"black",fontWeight:"400",fontSize: "1.25rem",
    fontWeight: "400",fontFamily:"Lexend,sans-serif",color:"white"}}>Platform to connect with worldwide Professional Therapists</p>
  <Link className="btn" onClick={()=>props.history.push("/signup-1")} style={{fontFamily:"Lexend,sans-serif", fontWeight:"bold",background : "#F7CF47" ,  color : "white"  , border : "3px solid #F7CF47", margin :"20px 0px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>Join Now   <span style={{paddingLeft:"20px",fontSize: "19px",color :""}}> ► </span>   </Link>
  </div>
</div>

<div style={{textAlign: "end"}}>
<img src={key14} alt=""  style={{position: "relative",
    right: "0px",height: "300px"}}/>
</div>


</div>












{/* 

                                <img src={video1} alt="" className='efeeee fdgurdgjh change-desktop'/>
                                <img src={video2} alt="" className='efeeee fdgurdgjh change-mobile'/>

                               
   

                        
                          <a className="btn button-change1" onClick={()=>props.history.push("/signup-1")} style={{background : "transparent" ,  color : "white" , fontWeight :"500" , border : "3px solid #F7CF47", margin :"0px 0px",width: "150px",borderRadius : "4px",fontSize: "16px", position: "absolute",
        top: "413px",
        left: "12%"}}>Get Started</a> */}

                


         <section className="bg0 p-t-63 p-b-60" id="Notes3" style={{background:"#18122B"}}>
          <div className="container">
            
            
           
            <div className="row isotope-grid">
       
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women change-padding-home-section" style={{padding :  "60px 0px"}}>
                <div className="block2">
             
                  <div className="block2-pic hov-img0" style={{marginTop : "60px",display: "flex",
    justifyContent: "center"}}>
                  
                   <p className='change-size-section' style={{fontSize:"39px",fontWeight:"600",textAlign:"left",color:"white",width: "94%"}}>Quality Therapy, Accessible to Everyone Throughout 

<br />
                   <span style={{color:"#F7CF47"}}>

                   the Globe
                   </span>.

</p>

                  </div>
                
                </div>
              </div>
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women change-padding-home-section" style={{padding :  "50px 60px 50px 0px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "30px"}}>
                    {/* <Link ><img src={key13} alt="IMG-PRODUCT"   style={{height: "350px",objectFit: "contain",width: "100%"}}/></Link> */}
                    <div class="stats">
                      <div class="stat">
                        <h2><span class="green">{count}</span></h2>
                        <p>Messages, chat, phone, video sessions</p>
                      </div>
                      <div class="stat">
                        <h2><span class="green">{count1}</span></h2>
                        <p>Credentialled therapists ready to help</p>
                      </div>
                      <div class="stat nostat">
                        <h2><span class="green">{count2}</span></h2>
                        <p>People got help</p>
                      </div>
                    </div>
                  </div>
              
             </div>
              </div>
            

            </div>

          </div>
        </section>




        <div class="container2">
  <h1 className='hh1'>Why Mental wellbeing matters</h1>
  <p className='pp'>Mental ill-health is bad for your people and your business.</p>
  <p className='pp'>Find out by moving the slider to your employee headcount.</p>

  <div className="slider">
        <div className="slider-fill" id="slider-fill"></div>
        <div className="slider-value" id="slider-value">{sliderWidth}</div>
      </div>

      <div className="slider-buttons">
        <div className="slider-button" id="slider-left" onClick={handleSliderLeftClick}>◄
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"black"}} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0.708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1.708 0z"/>
          </svg> */}
        </div>
        <div className="slider-button" id="slider-right" onClick={handleSliderRightClick}>►
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{color:"black"}} fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1.708 0l6 6a.5.5 0 0 1 0.708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg> */}
        </div>
      </div>

      <div className="info-box">
        <div className="info-item">
          <h2 style={{fontWeight:"bold"}}>{sliderWidth/5}</h2>
          <p>employees are struggling with a mental health issue</p>
        </div>
        <div className="info-item">
          <h2 style={{fontWeight:"bold"}}>{sliderWidth/10}</h2>
          <p>employees who are considering castofminds</p>
        </div>
        
      </div>

      <div className="footer">
        <p>Estimates based on leading industry and academic data from Deloitte, Health and Safety Executive and peer reviewed studies.</p>
      </div>
    </div>

{/* <br />
<br /> */}


         <center>


         <section className="bg0 p-t-63 p-b-60 changes-mobile-view-p1" id="Notes3" style={{background:"#18122B"}}>
          <div className="container">
            
            
          <div className="row isotope-grid">
       
       <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women change-padding-home-section" style={{padding :  "60px 0px"}}>
       <div className="block2">
    
         <div className="block2-pic hov-img0" style={{marginTop : "60px",display: "flex",flexDirection: "column",
justifyContent: "center"}}>
         
          <p className='change-size-section3' style={{fontSize:"2.5rem",fontWeight:"500",textAlign:"left",color:"white",width: "472px",lineHeight:"normal"}}>Get Matched with a Experienced and Trusted Therapist Today

</p>
<br />
          <p className='change-size-section2' style={{fontSize:"17px",fontWeight:"300",textAlign:"left",color:"white"}}>
          At Cast of Minds, we offer access to a global network of certified and highly experienced
therapists you can rely on. Our professionals are adept at addressing a wide range of issues,
including depression, anxiety, relationship challenges, trauma, grief, and more. We provide the
same high standards of care you would expect from in-person sessions, coupled with the
flexibility and convenience of remote communication. Access the exceptional support you need,
exactly when you need it, with Cast of Minds.




</p>

<a className="btn" onClick={()=>props.history.push("/only-show-therapist")} style={{background : "transparent" ,  color : "white" , fontWeight :"500" , border : "3px solid #F7CF47", margin :"20px 20px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>Get matched to a therapist</a>

         </div>
       
       </div>
     </div>
     
                <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
           
                  <div className="block2-pic hov-img0" style={{marginTop : "60px"}}>
                    <Link ><img src={key13} alt="IMG-PRODUCT"   style={{objectFit: "contain",width: "100%"}}/></Link>
                  </div>
              
             </div>
              </div>
            

            </div>

          </div>
        </section>


</center>

         <center>


         <section className="bg0 p-t-63 p-b-60 changes-mobile-view-p2" id="Notes3" style={{background:"#18122B"}}>
          <div className="container">
            
            
          <div className="row isotope-grid">
          <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women" style={{padding :  "10px 30px"}}>
                <div className="block2">
                
                <p className='change-size-section3' style={{fontSize:"2.5rem",fontWeight:"500",textAlign:"left",color:"white",width: "472px",lineHeight:"normal"}}>Get Matched with a Experienced and Trusted Therapist Today

</p>
                  <div className="block2-pic hov-img0">
                    <Link ><img src={key13} alt="IMG-PRODUCT"   style={{objectFit: "contain",width: "100%"}}/></Link>
                  </div>
              
             </div>
              </div>
       <div className="col-sm-6 col-md-6 col-lg-6 p-b-35 isotope-item women change-padding-home-section" style={{padding :  "60px 0px"}}>
       <div className="block2">
    
         <div className="block2-pic hov-img0" style={{marginTop : "60px",display: "flex",flexDirection: "column",
justifyContent: "center"}}>
         
       
<br />
          <p className='change-size-section2' style={{fontSize:"17px",fontWeight:"300",textAlign:"left",color:"white"}}>
          At Cast of Minds, we offer access to a global network of certified and highly experienced
therapists you can rely on. Our professionals are adept at addressing a wide range of issues,
including depression, anxiety, relationship challenges, trauma, grief, and more. We provide the
same high standards of care you would expect from in-person sessions, coupled with the
flexibility and convenience of remote communication. Access the exceptional support you need,
exactly when you need it, with Cast of Minds.




</p>

<a className="btn" onClick={()=>props.history.push("/only-show-therapist")} style={{background : "transparent" ,  color : "white" , fontWeight :"500" , border : "3px solid #F7CF47", margin :"20px 20px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>Get matched to a therapist</a>

         </div>
       
       </div>
     </div>
     
              
            

            </div>

          </div>
        </section>


</center>






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;