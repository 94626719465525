import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
// import ban11 from '../assets/images/ban-1.JPG';
import ban13 from '../assets/images/ban-13.jpeg';
import ban34 from '../assets/images/ban-34.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';



const Home = ( props ) => {

    const [currentworksituation, setcurrentworksituation] = useState("") 
    const [true1, settrue1] = useState(true) 

  
    useEffect(() =>{

        // window.scrollTo(0, 0)
        window.scrollTo(0, 0)

      
        if(JSON.parse(localStorage.getItem("create_therpist_2")) 
        ){
        
        }else{
           swal("Fill All the Feild")
           props.history.push("/create_account_therapist")
        }
          
        
        if(
        JSON.parse(localStorage.getItem("create_therpist_3"))
        ){
        
        }else{
           swal("Fill All the Feild")
           props.history.push("/create_account_therapist2")
        }
        
        
        if(
        JSON.parse(localStorage.getItem("create_therpist_4"))
        ){
        
        }else{
           swal("Fill All the Feild")
           props.history.push("/create_account_therapist3")
        }
        if(
        JSON.parse(localStorage.getItem("create_therpist_5"))
        ){
        
        }else{
           swal("Fill All the Feild")
           props.history.push("/create_account_therapist4")
        }
        
        
// if(JSON.parse(localStorage.getItem("create_therpist_2")) &&
// JSON.parse(localStorage.getItem("create_therpist_3")) &&
// JSON.parse(localStorage.getItem("create_therpist_4")) &&
// JSON.parse(localStorage.getItem("create_therpist_5")) 
// ){

// }else{
//    swal("Fill All the Feild")
//    props.history.push("/create_account_therapist")
// }



        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      



        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

//         fetch("https://kmelectronics-database1.herokuapp.com/AllProduct",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     }
//    })
//    .then(res5=>res5.json())
//    .then(res6=>{
//     //    console.log(res6);
//     const pro = []
//     res6.map((res7,i)=>{
//         //    console.log(res7.Product_Popular );
//         if ( res7.Arrival){
//             //    console.log(res7);
//             //         // return setproduct1(...product1 , res3)
//             pro.push(res7)
//         }
//     })
//     // setproduct(pro.reverse())
//     setproduct(pro.slice(0,5))
//     setdis(true)
//    })
 
 },[])

 
 const SubmitDataMessage = () =>{
if(currentworksituation){
    if(JSON.parse(localStorage.getItem("create_therpist_2")) &&JSON.parse(localStorage.getItem("create_therpist_3")) ){ 
    
    settrue1(false)
console.log("wdkjjk")
    let a = JSON.parse(localStorage.getItem("create_therpist_2")) 
let b = JSON.parse(localStorage.getItem("create_therpist_3")) 
let c = JSON.parse(localStorage.getItem("create_therpist_4")) 
let d = JSON.parse(localStorage.getItem("create_therpist_5")) 

       
fetch("https://asad-backend-two.vercel.app/create-theripost-account",{
    method: 'POST' , 
   headers :  {
     "Content-Type" : "application/json" , 
   } , 
   body : JSON.stringify({
    fname  :  a.fname,
    lname :  a.lname,
    email :  a.email,
    // CompanyName :  a.CompanyName,
    StreetAddress :  a.StreetAddress,
    City :  a.City,
    Country :  a.Country,
    // Website :  a.Website,
    // Facebook :  a.Facebook,
    // Twitter :  a.Twitter,
    // Instagram :  a.Instagram,
    // YouTube :  a.YouTube,
    pass :  a.pass,
    Title :  a.Title,
    Gender :  a.Gender,
    StateProvince :  a.StateProvince,
    TimeZone :  a.TimeZone,
    Zippostal :  a.Zippostal,
    CompanyName :  a.CompanyName,
    TaxpayerIdentificationNumber :  a.TaxpayerIdentificationNumber,
    DateOfBirth :  a.DateOfBirth,
    Initialsofyourcredentials :  a.Initialsofyourcredentials,
    ProfilePhoto :  b.ProfilePhoto,
    UploadYourCertificate :  c.UploadYourCertificate,
    IdentityDocumentPhoto :  c.IdentityDocumentPhoto,
    TherapistType: c.TherapistType,
    CredentialType: c.CredentialType,
    Summaryofyoureducation:currentworksituation,
    disciplinaryactions:d.disciplinaryactions,
    licensingstates:d.licensingstates,
    MonFriwouldyouprefer:d.MonFriwouldyouprefer,
    hoursperweek:d.hoursperweek,
    currentworksituation:d.currentworksituation,
    selected_specialities:d.selected_specialities,
    selected_therapy_types:d.selected_therapy_types,
    selected_treatment_orientation:d.selected_treatment_orientation,

    // IDType: c.IDType,
    // InsuranceExpires: c.InsuranceExpires,
    // InsuranceCompany: c.InsuranceCompany,



   })
 })
 .then((res)=>res.json())
 .then((res2)  =>{

     localStorage.removeItem("create_therpist_2")
     localStorage.removeItem("create_therpist_3")

     localStorage.removeItem("create_therpist_4")

     localStorage.removeItem("create_therpist_5")

    swal("Thankyou for choosing cast of minds, we have received your Application, You will be notified about your selection within 48hours on your respective email address with all the details")
    settrue1(true)
    props.history.push("/therapist/login")

 })
 .catch((err)=>{
     console.log("Reload")
     settrue1(true)

 })
}

  else{
    props.history.push("/create_account_therapist")

  }     
}
else{
    swal("Enter Summary of your education")
}
                    
                     
        
                    }   

        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
      
      

      








<br />
<br />


<h1 style={{padding : "0px 10px 0px 10px",  fontSize : "35px"  }}><center>

Step 5/5:Personal Statement

<br />
</center></h1>

<br />
                             <center>

                  <p style={{color :  "black" , fontWeight : "400"}}>  This text must be 100% original and not duplicated.
                   <br />
This text must be at least 500 characters long
                      </p>  
                      <br />    
                         {/* <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>Requirements:
                 
                         </h2> */}
</center>



                     <div className="container" style={{border : "none"}}>

                         <div className="row">
                             <div className="col-md-12">
                             <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Summary of your education, work experience and therapy philosophy ?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={currentworksituation} onChange={(e)=>setcurrentworksituation(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        
                                        
                                        {true1
                                        ?
                                        <div className="form-group row">
                                        <div className="col-lg-4" style={{  margin : "20px auto", }}>
                                        <button onClick={()=>SubmitDataMessage()} className="nav-link" style={{ color : "white",padding : "5px  10px" , width : "200px",background :"rgb(25 18 43)",fontWeight : "600", textAlign  : 'center', fontSize : "18px", textTransform: "uppercase"}}  type="submit">Save & Continue</button>

                                        </div>
                                    </div>
                                        :
                                        <div className="form-group row">
                                        <div className="col-lg-4" style={{  margin : "20px auto", }}>
                                        <button className="nav-link" style={{ color : "white",padding : "5px  10px" , width : "200px",background :"rgb(25 18 43)",fontWeight : "600", textAlign  : 'center', fontSize : "18px", textTransform: "uppercase"}}  type="submit">Save & Continue</button>

                                        </div>
                                    </div>
                                        }
                                         
                                         {/* <div className="form-group row">
                                             <div className="col-lg-6"  style={{margin : "auto"}}>
                                                 <input className="btn btn-primary btn-lg btn-block" value="Contiune" />
                                             </div>
                                         </div> */}
                                    </div>
                                 </div>
                             </div>
                            
                         </div>
                     </div>











            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;