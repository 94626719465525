import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
import Carousel from 'react-bootstrap/Carousel';

import ban1 from '../assets/images/banner-01.jpg';
import ban31 from '../assets/images/ban-31.jpeg';
import ban32 from '../assets/images/ban-32.jpeg';

import ban11 from '../assets/images/ban-1.JPG';
class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>

                  <h1 style={{padding : "80px 10px 0px 10px",  fontSize : "44px"  ,fontWeight : "bold"}}><center>
                  Terms and Conditions

</center></h1>



<p style={{color :"black",padding : "30px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
    {/* <center> */}
    The following are the Terms and Conditions (the "Agreement") which govern your access and use of our online platform through which therapy will be provided. This website is owned and operated by Cast of Minds located at B/3 Villa St, Ghazi Salahuddin Road, Karachi, Pakistan (contact@castofminds.com). <br />
By accessing or using the Platform, you are entering into this Agreement. You should read this Agreement carefully before starting to use the Platform. If you do not agree to be bound to any term of this Agreement, you must not access the Platform. <br />
When the terms "we", "us", "our" or similar are used in this Agreement, they refer to any company that owns and operates the Platform and the term “You” refers to the individual user of the platform.

</p>

                  <h1 style={{padding : "60px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  The Therapists and Therapist Services

</center></h1>


<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
The Platform may be used to connect you with a Therapist who will provide services to you through the Platform ("Therapist Services").

</p>

<p style={{color :"black",padding : "20px 30px 30px 30px",  fontSize : "13px"  ,fontWeight : "500"}}>
2.1. The Therapists are independent providers who are neither our employees nor agents nor representatives. The Platform's role is limited to enabling the Therapist Services. The Therapists themselves are responsible for the performance of the Therapy Services. If you feel the Therapist Services provided by the Therapist do not fit your needs or expectations, you may change to a different Therapist who provides services through the Platform. While we have a large database of Therapists, you should note that because of licensure requirements that differ from jurisdiction to jurisdiction, not all Therapists available on our database will be available for you to match with, or at any particular time, or for any set period of time. If a Therapist you have been connected with stops using the Platform at any time after you have been connected, we will send an email to notify you that your Therapist is no longer on the Platform and that you have the opportunity to match with a new Therapist. <br />
2.2. While we hope the Therapist Services are beneficial to you, you understand, agree and acknowledge that they may not be the appropriate solution for everyone's needs and that they may not be appropriate for every particular situation and/or may not be a complete substitute for a face-to-face examination and/or care in every particular situation. <br />
2.3. If you are thinking about suicide or if you are considering harming yourself or others or if you feel that any other person may be in any danger or if you have any medical emergency, you must immediately call your local emergency services number and notify the relevant authorities. The platform is not designed for use in any of the aforementioned cases and the therapists cannot provide the assistance required in any of the aforementioned cases. <br />
2.4. The platform is not intended for the provision of clinical diagnosis requiring an in-person evaluation and you should not use it if you need any official documentation or approvals for purposes such as, but not limited to, court-ordered therapy or emotional service dog certification. It is also not intended for any information regarding which drugs or medical treatment may be appropriate for you, and you should disregard any such advice if delivered through the platform. <br />
2.5. Do not disregard, avoid, or delay in obtaining in-person care from your doctor or other qualified professional because of information or advice you received through the platform.

</p>


                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Your Account:

</center></h1>


<p style={{color :"black",padding : "20px 30px 30px 30px",  fontSize : "13px"  ,fontWeight : "500"}}>
3.1. In order to use the Platform, you must sign up and establish an individual user account, which includes providing specific information. When establishing your account, you must also provide your personal contact information as well as a close family member’s or friend’s contact information (“Emergency Contact”), which the Counselor shall use in case of a mental health crisis or other emergencies where knowledge of your location is necessary.<br />
3.2. You undertake not to create more than one account or an account for anyone other than yourself without prior permission from the other person. Further, you undertake not to use the account of any other person for any reason. <br />
3.3. You are always responsible for maintaining the confidentiality of your account password and username (if any) and any other security information related to your account. In the event of any unauthorized use of your account or any other concern for breach of your account security, you undertake to immediately notify the Company. The Company will not be liable for any loss or damage that you incur as a result of someone else accessing using your account, either with or without your knowledge. You are recommended to frequently change and take extra care to protect your password.<br />
3.4. You are solely and fully liable and responsible for all activities performed using your account. The Company will hold you liable and responsible for any damage or loss incurred as a result of the use of your account by any person whether authorized by you or not, and you agree to indemnify and hold the Company harmless for any such damage or loss.

</p>

                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Your Subscription
</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
The Company collects and stores the following information about you that the Company needs to contact you and to fulfil its undertakings towards you as a Counselor.

</p>

<p style={{color :"black",padding : "20px 30px 30px 30px",  fontSize : "13px"  ,fontWeight : "500"}}>
4.1. We offer different subscription options that you can choose; including billing that can occur weekly, every (4) four weeks, or quarterly. Any type of subscription you choose will continue and automatically renew until you cancel the membership. By choosing a recurring Membership Service, you acknowledge that such paid services have a recurring payment and you accept responsibility for all recurring charges prior to cancellation. <br />
4.2. You can cancel the subscription to the service at any time for any reason. Your membership must be canceled before it renews in order to avoid the next billing cycle. <br />
4.3. We reserve the right to change our subscription or adjust prices of our services. Any changes to your membership services will only take effect following proper notice to you.

</p>


                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Intellectual Property

</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
5.1. All content available on or through the Platform shall be the property of the Company or its licensors, and shall be protected by copyright, trademark, patent, trade secret and other intellectual property law. <br />
5.2. The Platform is subject to various factors such as, but not limited to, software, hardware and tools, either the Company’s or those owned and/or operated by the Company’s contractors and suppliers.<br />
5.3. You undertake not to reproduce, retransmit, distribute, disseminate, sell, publish, broadcast, or circulate the content received through the Platform to any third party. All software and accompanying documentation made available for download through the Platform is the copyrighted work of the Company or its licensors. Any copy made of information obtained through the Platform must include all applicable copyright notices.<br />
5.4. The Platform is protected by copyright, trademark, and other laws of both Pakistan and foreign countries. The Company’s trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company. Any kind of abuse will be reported.

</p>

                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
                  Payment
</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
6.1. Regardless of any payments made, the Company shall not be considered as your direct provider of therapy services. Solely the Counselor shall be considered as such provider. <br />
6.2. You shall only use credit cards or other payment means (“Payment Means”) which you are duly and fully authorized to use. All payment related information you have provided and will provide in the future, to or through the Platform, shall be accurate, current and correct and will continue to be accurate, current and correct. By providing the Company with your credit card information, you authorize the Company to bill and charge your credit card. <br />
6.3. You shall pay all fees and charges associated with your account on a timely basis and according to the fee schedule, the terms and the rates as published on the Platform. By providing the Company with your Payment Means you authorize the Company to bill and charge you through the Payment Means. Further, you shall maintain valid Payment Means information in your account information. <br />
6.4. The Company provides professional services, which are paid to the Counselor through a Counselor Agreement between the Company and the Counselor. Refunds for unused time are normally not granted since the Counselors charge for time which is scheduled to be used – as other medical professionals. The Company will together with the Counselor review refund requests on a case-by-case basis. In case the refund request is based on any special or legal circumstances, such as but not limited to medical, death, disability or court orders, the Company will make accommodations. In case refund is not granted or possible, the Company will be able to provide other accommodations, such but not limited to helping you find a new Counselor, extending your subscription at no or a reduced cost and providing credit for future Counselor Services.

</p>
                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500"}}><center>
                  Modifications, Termination, Interruption and Disruptions to the Platform
</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
7.1. You understand, agree and acknowledge that we may modify, suspend, disrupt or discontinue the Platform, any part of the Platform or the use of the Platform, whether to all clients or to you specifically, at any time with or without notice to you. You agree and acknowledge that we will not be liable for any of the aforementioned actions or for any losses or damages that are caused by any of the aforementioned actions. <br />
7.2. The Platform depends on various factors such as software, hardware and tools, either our own or those owned and/or operated by our contractors and suppliers. While we make commercially reasonable efforts to ensure the Platform's reliability and accessibility, you understand and agree that no platform can be 100% reliable and accessible and so we cannot guarantee that access to the Platform will be uninterrupted or that it will be accessible, consistent, timely or error-free at all times.

</p>


                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500"}}><center>
                  Notices</center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
The Company may notify or in other ways communicate with you regarding the Terms and Conditions or any aspects of the Platform, by posting it online or by e-mail or regular mail to the address you provided when creating your account. The date of receipt shall be the date on which such notice is given. Notices sent to the Company must be sent by email to contact@castofminds.com.

</p>



                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500"}}><center>
                  Privacy and Security
                  
                  
                  </center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
9.1. Protecting and safeguarding any information you provide through the Platform is extremely important to us. Information about our security and privacy practices can be found on our Privacy Policy available at https://www.castofminds.com/privacy (The 'Privacy Policy'). <br />
9.2. By agreeing to this agreement and/or by using the platform, you are also agreeing to the terms of the privacy policy. The privacy policy is incorporated into and deemed a part of this agreement. The same rules that apply regarding changes and revisions of this agreement also apply to changes and revisions of the privacy policy.

</p>
                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500"}}><center>
                  Governing Law and Disputes
                  
                  
                  </center></h1>





<p style={{color :"black",padding : "20px 20px 30px 20px",  fontSize : "13px"  ,fontWeight : "500"}}>
10.1. These Terms and Conditions shall be governed by the substantive law of Pakistan. <br />
10.2. The courts of Pakistan shall have exclusive jurisdiction over any dispute, controversy or claim arising out of or in connection with the Terms and Conditions, or the breach, termination or invalidity thereof. <br />
10.3. If any provision of this Agreement is held by a court of competent jurisdiction to be illegal, invalid, unenforceable, or otherwise contrary to law, the remaining provisions of this Agreement will remain in full force and effect.

</p>



               























            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;