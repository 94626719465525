import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import ban23 from '../assets/images/ban-23.jpg';
// import ban24 from '../assets/images/ban-24.jpg';
// import ban25 from '../assets/images/ban-25.jpeg';
// import ban26 from '../assets/images/ban-26.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [data , setdata] = useState([])
    useEffect(() =>{

        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main 1")) ){
        props.history.push("/login")
    }else{
        // setdata(JSON.parse(localStorage.getItem("user main 1")) )
        fetch("https://asad-backend-two.vercel.app/all-therispist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            console.log(res6)
            let A = []
            res6.map((res1,i)=>{
                if(res1._id ===JSON.parse(localStorage.getItem("user main 1")).TheripistId){
                    A.push(res1)
                }
            })
            setdata(A)
        })
    }
       

        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
     




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])


        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         

   <h1 style={{padding : "80px 10px 10px 10px",  fontSize : "38px"  ,fontWeight : "400"}}><center>
   Live Sessions

</center></h1>
<div style={{width : "52%" ,  height : "auto"  ,borderRadius : "10px" , boxShadow: "0px 2px 6px rgb(25,18,43)" , margin :"70px auto"}} className="oiuoiip">
   <h1 style={{padding : "20px 10px 10px 10px",  fontSize : "23px" , color  :"rgb(25,18,43)" ,fontWeight : "400"}}><center>
   Worksheet reply needed before scheduling a live session!
</center></h1>

   <p style={{padding : "20px 10px 0px 10px",  fontSize : "15px"  ,fontWeight : "400" , color : "black"}}><center>
   One live session a 45 minutes/week with me is included in your subscription. To be able to schedule a live session, you first need to reply to the worksheet:<Link to="/worksheet1/1"> Getting to know you »</Link>
</center></p>


    <Carousel style={{height  :"367px"}}>
              
              <Carousel.Item interval={2298000}>
                      <img src={ban11} alt="" style={{height : "367px"}}/>

                        <Carousel.Caption>
                        {/* <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12"> */}
                                    <img src={data[0] && data[0].ProfilePhoto } alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
              {/* <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}> "I love the fact that I get feedback on the worksheets every day, sometimes several times a day. I prefer the online therapy to a traditional therapy, because you don't have to wait for feedback."
                  </h1> */}
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>By : <strong>{data[0] && data[0].Title} {data[0] && data[0].fname} {data[0] && data[0].lname}</strong> , {data[0] && data[0].City} {data[0] && data[0].Country}</p>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>Your therapist type is {data[0] && data[0].TherapistType}</p>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>meet link : <b style={{ color : "black"}}> {JSON.parse(localStorage.getItem("user main 1")) && JSON.parse(localStorage.getItem("user main 1")).meet}</b> </p>
              {/* <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>Change therapist »</p> */}
            {/* </div> */}
                                  {/* </div>
                                </div> */}
                        </Carousel.Caption>
                      </Carousel.Item>
            
</Carousel>

</div>
        




            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;