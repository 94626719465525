import React, { useEffect,useState } from 'react';
import {Link} from "react-router-dom"
import InstagramIcon from '@material-ui/icons/Instagram';

import LinkedInIcon from '@material-ui/icons/LinkedIn';

import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';



// import FacebookIcon from '@mui/icons-material/Facebook';
const Home = ( props ) => {
 
  const [dis1 , setdis1] = useState(false)
  const [dis2 , setdis2] = useState(false)




  useEffect(() =>{
    const inter = setInterval(()=>{

    if ( JSON.parse(localStorage.getItem("check Head")) ){
      setdis1(true)
    }
    else{
      setdis1(false)
    }
    if(JSON.parse(localStorage.getItem("check Head 1"))){
      setdis2(true)
    }
    else{
      setdis2(false)
    }
    },1000)
        
    return () => clearInterval(inter);
                

  },[])

        return (
            <>
            {! dis1 && ! dis2 ?
 
 
 <>
 <footer>
              <div className="footer-main">
                <div className="container">
                  {/* <div className="row"> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-top-box">
                        <h3>Business Time</h3>
                        <ul className="list-time">
                          <li>Monday - Saturday: 12.00 Pm to 11.00 Pm</li>  <li>Sunday: <span>Closed</span></li>
                        </ul>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4 col-md-12 col-sm-12">
                      <div className="footer-top-box">
                        <h3>Newsletter</h3>
                        <form className="newsletter-box">
                          <div className="form-group">
                            <input className type="email" name="Email" placeholder="Email Address*" />
                          </div>
                        </form>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-top-box">
                        <h3>Social Media</h3>
                        <p>STAY CONNECTED</p>
                        <ul>
                          <li><a href="https://www.facebook.com/kmelectronics/"><FacebookIcon/></a></li>
                          <li><a href="https://www.instagram.com/kmelectronics/"><InstagramIcon /></a></li>
                          <li><a href="https://www.linkedin.com/in/kmelectronics/"><LinkedInIcon /></a></li>
                          <li><a href="https://www.youtube.com/channel/UCUNCCx2Cvz0zajt3r-mat_w"><YouTubeIcon/></a></li>
                          <li><a href="https://twitter.com/kmelectronics/"><TwitterIcon /></a></li>
                        
                        </ul>
                      </div>
                    </div> */}
                  {/* </div>
                  <hr /> */}
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-widget">
                        <h4>About US </h4>
                        <p style={{color : "white" ,fontSize : "14px"}}>Cast of Minds aspires to become a centrifugal force for all the minds.
                         Creating a benevolent network for everyone struggling to keep pace with life.  </p> 
                      
  <div style={{display:"flex",width:"130px",justifyContent: "space-between",margin:"0px 0px 10px 0px"}}>
                          <li> <a href="mailto:contact@castofminds.com"><LocalPostOfficeIcon style={{fontSize:"20px",color:"white"}}/></a></li>
                          <li><a href="https://www.instagram.com/thecastofminds/"><InstagramIcon style={{fontSize:"20px",color:"white"}} /></a></li>
                          <li><a href="https://www.linkedin.com/company/cast-of-minds/"><LinkedInIcon style={{fontSize:"20px",color:"white"}} /></a></li>
                        
  </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="footer-link">
                        <h4>UseFul Links</h4>
                        <ul >
                          {/* <li><Link to="/contact"> ->     How it Works</Link></li> */}
                          {/* <li><Link to="/about"> ->     About Us</Link></li> */}
                          {/* <li><Link to="/who-it-work"> ->     Business</Link></li> */}


                          {/* <li><Link to="/interactions"> ->     Interactions</Link></li> */}




                          <li><Link to="/why-join-us"> ->     Why Join Us</Link></li>
                          <li><Link to="/about"> ->     About</Link></li>
                          <li><Link to="/review"> ->     Reviews</Link></li>
                          <li><Link to="/faq"> ->    FAQs</Link></li>
                          <li><Link to="/terms-condition"> ->     Terms & Conditions</Link></li>
                          {/* <li><Link to="/"> ->     Terms &amp; Conditions</Link></li> */}
                          <li><Link to="/privacy"> ->     Privacy Policy</Link></li>
                          {/* <li><Link to="/about"> ->     Site Map</Link></li> */}
                          {/* <li><Link to="/">Delivery Information</a></li> */}
                          <li><Link to="/contact-us"> ->     Contact Us</Link></li>
                          <li><Link to="/therapist/login"> ->     Therapist login</Link></li>
                        </ul>
                      </div>    
                    </div>
                    {/* <div className="col-lg-4 col-md-12 col-sm-12">
                      <div className="footer-link-contact">
                        <h4>Contact Us</h4>
                        <ul  style={{color : "white" ,fontSize : "17px"}}>
                         
                          <li>
                            <p style={{color : "white" ,fontSize : "17px"}}><a  style={{color : "white" ,fontSize : "17px"}} href="https://www.linkedin.com/company/cast-of-minds/"><LinkedInIcon/> LinkedIn</a> </p>
                          </li>
                          <li>
                            <p style={{color : "white" ,fontSize : "17px"}}><a  style={{color : "white" ,fontSize : "17px"}} href="https://instagram.com/thecastofminds?igshid=YmMyMTA2M2Y="><InstagramIcon /> Instagram</a></p>
                          </li>
                          
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </footer>
            {/* End Footer  */}
            {/* Start copyright  */}
            <div className="footer-copyright">
              <p className="footer-company">All Rights Reserved.Castofminds.com </p> 
                {/* <a href="https://webocreators.com">Webo Creator</a>*/}
            </div>
            </>

: ""
}
           
            </>
        )
    
}

export default Home;