import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"


import "react-multi-carousel/lib/styles.css";
// import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
  
    const [theripist , settheripist] = useState([])
  
    useEffect(() =>{
        window.scrollTo(0, 0)

        if(! JSON.parse( localStorage.getItem("create-user-1") )){
            props.history.push("/signup-1")
        }
        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://asad-backend-two-lemon.vercel.app/all-therispist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1)
            let a = []
            let b = JSON.parse( localStorage.getItem("create-user-1") ).Category
            res1.map((res2,i)=>{
                if(res2.isApprovl){
                    res2.selected_specialities.map((res4,j)=>{
                    // console.log(res4 , JSON.parse( localStorage.getItem("create-user-1") ).Category )
                    console.log("dffd",res2.Gender ,"fsefds",JSON.parse( localStorage.getItem("create-user-2") ).Gender )
                    b.map((res5,g)=>{

                        if(res4 === res5){
                        // if(res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[0] ||JSON.parse( localStorage.getItem("create-user-1") ).Category[1]&&res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[1]||JSON.parse( localStorage.getItem("create-user-1") ).Category[2]&&res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[2]){
                            // if(res2.Gender === JSON.parse( localStorage.getItem("create-user-2") ).Gender){
                                a.push(res2)
                                // console.log(res4 , JSON.parse( localStorage.getItem("create-user-1") ).Category[0])
                            // }
                        }
                    })
                })
                }
                
            })
            if(a.length === 0){
                res1.map((res2,i)=>{
                    if(res2.isApprovl){
                        res2.selected_specialities.map((res4,j)=>{
                        // console.log(res4 , JSON.parse( localStorage.getItem("create-user-1") ).Category )
                        // console.log(res2,res2.Gender ,JSON.parse( localStorage.getItem("create-user-2") ).Gender )
                        b.map((res5,g)=>{
    
                            if(res4 === res5){
                            // if(res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[0] ||JSON.parse( localStorage.getItem("create-user-1") ).Category[1]&&res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[1]||JSON.parse( localStorage.getItem("create-user-1") ).Category[2]&&res4 === JSON.parse( localStorage.getItem("create-user-1") ).Category[2]){
                                if(res2.Gender === JSON.parse( localStorage.getItem("create-user-2") ).Gender){
                                    a.push(res2)
                                    // console.log(res4 , JSON.parse( localStorage.getItem("create-user-1") ).Category[0])
                                }
                            }
                        })
                    })
                    }
                    
                }) 
            }
            console.log(a)
            let uniqueChars = [...new Set(a)];
            let db = uniqueChars.sort(() => (Math.random() > .5) ? 1 : -1);
            if(db.length === 0){
                settheripist([])

            }else{

                settheripist([db[0]])
            }
            console.log(db)
            // settheripist(uniqueChars)
        })

        
 },[])


 const setChanger1 = (res)=>{
    localStorage.setItem("create-user-3", JSON.stringify({
        TheripistId : res._id ,
        })) 
        setTimeout(()=>{
            props.history.push("/payment")
        },2000)
  
 }



 const setChanger2 = (res)=>{

 }



        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
            {/* End Top Search */}
            {/* Start Slider */}
     





            <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500",textTransform:"uppercase"}}><center>
            Our Top Pick for You!

</center></h1>

<p className='the456' style={{padding : "20px 20px 30px 20px",color : "black",  fontSize : "13px"  ,fontWeight : "500"}}>
Below is the available therapist sorted after how well they match your profile. Feel free to pick anyone you prefer. Our therapists are highly qualified with a wide range of credentials that have been manually verified by our staff members.


</p>

<hr />



{theripist.length > 0 && theripist.map((res,i)=>{
    return (
        <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px" , margin : "auto"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                    <img src={res.ProfilePhoto} alt=""  style={{width : "100px"  ,  height : "100px"  , borderRadius : "100px"}}/>
                        </div>
                                <div className="text">
                                <h1 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "600" , fontSize : "24px"}}>{res.Title} {res.fname} {res.lname}


</h1>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "18px"}}>{res.TherapistType}


</h2>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "17px"}}>{res.City}, {res.Country}
{/* United States */}


</h2>
                                    <p  className='the456' style={{fontSize: "12px",textAlign: "justify",
    fontWeight: "500"}}>
        {res.Summaryofyoureducation}
    
</p>
                  <div>
                    
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500",lineHeight  :"17px",    display: "flex",
    flexWrap: "wrap"}}>
      {
        res.selected_specialities.map((res1,j)=>{
            return ( 
                <>
                <span style={{background : "rgb(25 18 43)" , borderRadius : "15px" , color : "white",fontSize : "10px", fontWeight : "bold", padding  :"9px 10px" , margin : "8px 1px",width: "fit-content"}}>{res1}</span>
                </>
            )
        })
      }
      {/* {
        res.selected_treatment_orientation.map((res1,j)=>{
            return ( 
                <>
                <span style={{background : "rgb(25 18 43)" , borderRadius : "15px" , color : "white",fontSize : "10px", fontWeight : "bold", padding  :"9px 10px" , margin : "8px 1px",width: "fit-content"}}>{res1}</span>
                </>
            )
        })
      } */}


</p>
<center>

                                <a onClick={()=>setChanger1(res)} className="nav-link the456" style={{ background : "rgb(247, 207, 71)" ,  color : "white",padding: "2px 16px", fontWeight :"bold" , border : "1px solid white",width: "100px",fontSize: "16px"}}><Link style={{color : "white"}}>PICK ME</Link></a>
</center>
<center>

<a className="nav-link the456" style={{ color : "rgb(247, 207, 71)" , margin : "10px 0px",fontWeight : "600", fontSize : "15px", textTransform: "uppercase"}}><Link to="/therapist/all" style={{color : "black", borderBottom : "1px solid black",}}>If You Want to Change The therapist Click here</Link></a>

</center>
                    </div>                
                                </div>
                            </div>
                          
                          
                            
                          
                          
                        </div>
                        {/* <center> */}

                                {/* <div style={{display : "flex" , justifyContent : "center"}}> */}
                                {/* <div style={{display : "flex" , justifyContent : "center"}}> */}
                                {/* <a onClick={()=>setChanger2(res)}  className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link style={{color : "white"}}>View Profile</Link></a> */}
                                {/* <a onClick={()=>setChanger1(res)} className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/payment" style={{color : "white"}}>PiCk me</Link></a>
                                <a onClick={()=>setChanger2(res)}  className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>View Profile</Link></a> */}

                                {/* </div> */}
                        {/* </center> */}

                    </div>
                </div>
//         <div className="site-section site-section-sm site-blocks-1 backk" >
//                     <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
//                         <div className="row">
//                             <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px" , margin : "auto"}} data-aos="fade-up" data-aos-delay="">
//                                 <div className="icon mr-4 align-self-start">
//                                     {/* <span className="icon-truck"></span> */}
//                                     <img src={res.ProfilePhoto} alt=""  style={{width : "100px"  ,  height : "100px"  , borderRadius : "100px"}}/>
//                         </div>
//                                 <div className="text">
//                                 <h1 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "600" , fontSize : "24px"}}>{res.Title} {res.fname} {res.lname}


// </h1>
//                                 <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "20px"}}>{res.TherapistType}


// </h2>
//                                 <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "20px"}}>{res.City}, {res.Country}



// </h2>
//                                     <p  className='the456' style={{fontSize: "17px",
//     fontWeight: "500"}}>
//         {res.Summaryofyoureducation}



// </p>
//                                 </div>
//                             </div>
                          
                          
                            
                          
                          
//                         </div>
//                         <center>

//                                 <div style={{display : "flex" , justifyContent : "center"}}>
//                                 <a onClick={()=>setChanger1(res)} className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link style={{color : "white"}}>PiCk me</Link></a>
//                                 <a onClick={()=>setChanger2(res)}  className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link style={{color : "white"}}>View Profile</Link></a>
                             
//                                 </div>
//                         </center>

//                     </div>
//                 </div>
    )
     
})
}
           

{/* <hr /> */}

          

{/* <hr /> */}

          
                        {/* <center>

                                <a className="nav-link the456" style={{ color : "rgb(247, 207, 71)" , margin : "10px 0px",fontWeight : "600", fontSize : "15px", textTransform: "uppercase"}}><Link to="/therapist/all" style={{color : "black", borderBottom : "1px solid black",}}>If You Want to Change The therapist Click here</Link></a>

                        </center>




<h1 style={{padding : "80px 10px 30px 10px",  fontSize : "30px"  ,fontWeight : "500",textTransform:"uppercase"}}><center>
            Our Top Pick for You!

</center></h1>

<center>

<p   style={{fontSize: "12px",textAlign: "center",
    fontWeight: "500"}}>
     If You Want to Change The therapist Click here
        



</p>
</center>




<div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px" , margin : "auto"}}>
                          
                                <div className="text">
                                <h1 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "600" , fontSize : "24px"}}>kjnkjnkjn


</h1>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "18px"}}>jknkjnkjnkjn


</h2>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "17px"}}>jknjkkkkkkkkkjknkjn


</h2>
                                    <p  className='the456' style={{fontSize: "12px",textAlign: "justify",
    fontWeight: "500"}}>
jkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk    
</p>


                               
                                 
<center>

                                <a className="nav-link the456" style={{ background : "rgb(247, 207, 71)" ,  color : "white",padding: "2px 16px", fontWeight :"bold" , border : "1px solid white",width: "100px",fontSize: "16px"}}><Link style={{color : "white"}}>PICK ME</Link></a>
</center>



<center>

<p   style={{fontSize: "12px",textAlign: "center",
    fontWeight: "500"}}>
     If You Want to Change The therapist Click here
        



</p>
</center>
                    </div>                
                                </div>
                            </div>
                          
                          
                            
                  
                    
                    </div>
                </div> */}





            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;