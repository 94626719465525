import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
// import Carousel from 'react-bootstrap/Carousel';

// import ban1 from '../assets/images/banner-01.jpg';
// import ban31 from '../assets/images/x1.jpg';
// import ban32 from '../assets/images/ban-32.jpeg';

import "./review.css"

// import ban11 from '../assets/images/ban-1.JPG';
class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
{/* <div style={{background : "rgb(25,18,43)" ,  height : "200px"  , width : "100%" }}>
  <center>
    <h3 className='the456' style={{color : "white" , paddingTop : "50px" , fontSize : "37ox"}}>
      Reviews
    </h3>
  </center>
</div> */}







<br/>


<div class="container">
        <h2 style={{textAlign:"center"}}> Castofminds reviews </h2>
        <p class="description">These quotes represent a few of the many positive reviews that we have received for therapists who work with Castofminds. If you would like to review your therapist, please send your review to contact@Castofminds.com.</p>
    
        <div class="reviewSection">

        <div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Daniel K.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Therapy with Cast of Minds has been a game-changer for me. My therapist provided a non-judgmental space for me to process difficult emotions and work through challenges. I've seen significant improvements in my mental health and overall well-being."</p>
        <p>May 05, 2024</p>
    </article>
</div>

        <div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Ayesha Khan</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"I recently started online therapy with Cast of Minds and I am thoroughly impressed with their services. My therapist, Dr. Farah Ahmed, has been incredibly supportive and understanding. The convenience of having sessions from home has been a game-changer for my busy schedule. The platform is user-friendly and secure, ensuring my privacy at all times. I highly recommend Cast of Minds to anyone seeking professional mental health support online."</p>
        <p>Feb 10, 2023</p>
    </article>
</div>
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Alex C.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★
        </ul>
    </div>
    <article>
        <p class="review">"I've tried therapy before, but Cast of Minds stands out for their personalized approach and commitment to client care. My therapist took the time to understand my unique experiences and provided tailored strategies for managing stress. I'm grateful for their expertise and support."</p>
        <p>Mar 15, 2024</p>
    </article>
</div>
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Bilal Siddiqui</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"I've been attending in-person therapy sessions at Cast of Minds for the past six months, and the experience has been life-changing. The clinic is welcoming and has a calming atmosphere, which immediately puts you at ease. My therapist, Dr. Asad Malik, has been phenomenal in helping me work through my anxiety issues. The staff is friendly and always ready to assist. If you're looking for effective and compassionate mental health care, Cast of Minds is the place to go."</p>
        <p>Mar 15, 2023</p>
    </article>
</div>
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Michael H.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★
        </ul>
    </div>
<article>
        <p class="review">"I was hesitant to seek therapy, but Cast of Minds made the process easy and accessible. My therapist created a comfortable environment where I could open up about my struggles without fear of judgment. I've made significant progress thanks to their support."</p>
        <p>Jan 05, 2024</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Zara Ali</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Cast of Minds offers an exceptional online therapy service. I was hesitant at first, but my therapist, Dr. Salman Rizvi, made me feel comfortable from the very first session. The virtual sessions are just as effective as in-person ones, and the flexibility allows me to fit therapy into my hectic schedule. The level of care and professionalism is top-notch. Highly recommend!"</p>
        <p>Apr 20, 2023</p>
    </article>
</div>
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Emma L.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★
        </ul>
    </div>
    <article>
        <p class="review">"I've struggled with depression for years, but working with Cast of Minds has given me hope for the future. My therapist helped me uncover underlying issues and develop practical strategies for self-care. I'm grateful for their expertise and guidance."</p>
        <p>Dec 01, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Hassan Raza</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Attending on-site therapy at Cast of Minds has been a wonderful experience. The center is easily accessible and has a very soothing environment. My therapist, Dr. Nadia Kamal, is incredibly skilled and has helped me make significant progress in dealing with my depression. The personalized approach and the genuine care I receive make each visit worthwhile. I strongly recommend their services to anyone in need of mental health support."</p>
        <p>May 05, 2023</p>
    </article>
</div>
<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>David M.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★
        </ul>
    </div>
    <article>
        <p class="review">"The therapists at Cast of Minds are incredibly skilled and empathetic. I felt understood and supported from the moment I walked through the door. Therapy has helped me navigate challenges and find greater clarity in my life."</p>
        <p>Nov 15, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Fatima Javed</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"I can't thank Cast of Minds enough for their excellent online therapy services. Dr. Ahmed Iqbal, my therapist, has been a tremendous help in guiding me through my stress and work-life balance issues. The convenience of online sessions has made it possible for me to maintain regular therapy despite my busy work schedule. Their commitment to client well-being is evident in every session. Highly recommended for anyone seeking online mental health support."</p>
        <p>Jun 10, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
          
            <span>Imran Tariq</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Cast of Minds has been a beacon of hope for me. The on-site therapy sessions with Dr. Saira Ali have been instrumental in my recovery journey. The clinic's ambiance is very comforting, and the staff's professionalism is commendable. The holistic approach to therapy has helped me immensely, and I always look forward to my sessions. For those who prefer face-to-face interaction, Cast of Minds is the best choice for mental health care."</p>
        <p>Jul 15, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Sarah T.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Cast of Minds has been a lifeline for me during a difficult time. My therapist provided a safe and supportive space for me to explore my emotions and learn valuable coping strategies. I am grateful for their expertise and compassionate approach."</p>
        <p>Aug 20, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>James R.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★
        </ul>
    </div>
    <article>
        <p class="review">"I was skeptical about therapy at first, but my experience with Cast of Minds exceeded my expectations. My therapist listened without judgment and helped me gain insights into my thought patterns. I feel more equipped to manage my anxiety now."</p>
        <p>Sep 05, 2023</p>
    </article>
</div>

<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Emily G.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Choosing Cast of Minds for therapy was one of the best decisions I've made for my mental health. The sessions were tailored to my needs, and I felt genuinely cared for throughout the process. I highly recommend their services."</p>
        <p>Oct 10, 2023</p>
    </article>
</div>




<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Olivia S.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"Therapy with Cast of Minds has been transformative for me. My therapist challenged me to explore difficult emotions and confront negative patterns of thinking. I feel more empowered and resilient as a result of our work together."</p>
        <p>Feb 10, 2024</p>
    </article>
</div>



<div class="reviewItem">
    <div class="top">
        <div class="clientImage">
            
            <span>Sophia B.</span>
        </div>
        <ul style={{color  :"rgb(25, 18, 43)"}}>
            ★★★★★
        </ul>
    </div>
    <article>
        <p class="review">"I was struggling with low self-esteem and anxiety when I started therapy with Cast of Minds. My therapist helped me develop a deeper understanding of myself and cultivate a more positive mindset. I feel more confident and capable as a result of our sessions."</p>
        <p>Apr 20, 2024</p>
    </article>
</div>


            {/* <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>
            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, Fugit beatae ipsa
                        voluptatibus</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>
            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>
            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, Fugit beatae ipsa
                        voluptatibus</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>
            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>
            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, laudantium tenetur, neque,
                        facere exercitationem!</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error </p>
                    <p>Jan 01, 2023</p>
                </article>
            </div>

            <div class="reviewItem">
                <div class="top">
                    <div class="clientImage">
                        
                        <span>Raju Sheoran</span>
                    </div>
                    <ul style={{color  :"rgb(25, 18, 43)"}}>
                        ★★★★★
                    </ul>
                </div>
                <article>
                    <p class="review">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit beatae ipsa
                        voluptatibus perferendis quos eaque nemo error tempora harum quas, Fugit beatae ipsa
                        voluptatibus</p>
                    <p>Jan 01, 2023</p>
                </article>
            </div> */}
        </div>
    </div>

















            {/* End All Title Box */}
            {/* Start About Page  */}










            {/* <div className="about-box-main">
              <div className="container">
                <div className="row">
              
                  <div className="col-lg-12">
                      <center>

                    <p style={{fontSize : "35px" , fontWeight : "bold"}}>How cast of mind is being rated by people

</p>
                      </center>
                  </div>
             
                </div>
                <div className="row">
                <div className="col-lg-12">
                  <h1 style={{padding : "10px 10px",  fontSize : "35px"  ,fontWeight : "bold"}}><center>
            Our Reviews

</center></h1>
                  </div>
               
             
                  </div>

<br />
<br />
<br />
<center>
<h1 className="the456" style={{ textAlign : "center" , fontWeight : "600" , fontSize : "30px"}}> 
Therapists
</h1>

                  <img src={ban32} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/>
</center>

<h1 className="the456" style={{ textAlign : "left" , fontWeight : "500" , fontSize : "17px"}}> 
Once you sign up, a personal therapist will be assigned to you. This person will be by your side from the beginning to the end. The therapists at cast of minds have a wide range of credentials (academic degrees, licenses or certifications) and many hours of hands-on experience.
<br />
<br />
From online therapy sessions to activity kits and everything in between, Cast Of Minds makes sure you have every right to your privacy and confidentiality no matter who or where you are. A diverse team of international psychologists are here at one click.

 <br /> 
 <br /> 
 <br /> 
 </h1>


<center>

                  <img src={ban31} alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "scale-down"}}/>
</center>

<h1 className="thers the456" style={{ textAlign : "left" , fontWeight : "500" , fontSize : "17px"}}> 
Asad Vichi <br />
Chief Executive Officer, CEO<br />
Karachi, Pakistan <br /><br />
<i>
  Asad Vichi is the CEO and founder of Castofminds.com<br />

After nearly two years as an successful equity trader and website developer, Asad decided to focus on something completely different in 2018. He Joined Institute of psychology in Karachi and since than the passion for psychology and its domains increases. Studies showed that web based therapy, in many cases, can be as efficient as traditional face-to-face therapy sessions. You can access your online therapy program from anywhere in the world, anonymously, whenever it suits you the best, at a low cost.

In an aim to make web-based therapy accessible to the general public, so that more people in need could get most of their lives, Asad founded Castofminds.com <br />


Asad currently is an undergraduate in IPP Bahira university, Karachi <br />
</i>

<br />
Email: asad(at) castofminds.com<br />
 <br /> 

 </h1>


 <h1 className="thers the456" style={{ textAlign : "center" , fontWeight : "600" , fontSize : "30px"}}> 
Contact Us
</h1>

<h1 className="thers the456" style={{ textAlign : "left" , fontWeight : "500" , fontSize : "17px"}}> 
Contact us by e-mail <br />
Please use this e-mail address, if you have any questions about our services. We normally reply within 24 hours.
<br />
support (at) castofminds.com

 <br /> 
 </h1>




                </div>


                </div>
            <br />  */}
          
          </div>
        )
    }
}

export default About;