import React ,{useEffect , useState} from 'react'
// import {Link} from "react-router-dom"

// import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
// import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban34 from '../assets/images/ban-34.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [Product_Image_Upload, setProduct_Image_Upload] = useState("") 
    const [TherapistType, setTherapistType] = useState("") 
    const [CredentialType, setCredentialType] = useState("") 
    const [UploadYourCertificate, setUploadYourCertificate] = useState("") 
    const [InsuranceCompany, setInsuranceCompany] = useState("") 
    const [InsuranceExpires, setInsuranceExpires] = useState("") 
    const [IDType, setIDType] = useState("") 
    const [IdentityDocumentPhoto, setIdentityDocumentPhoto] = useState("") 
  
    useEffect(() =>{

        // window.scrollTo(0, 0)
        window.scrollTo(0, 0)


if(
 JSON.parse(localStorage.getItem("create_therpist_2")) &&
 JSON.parse(localStorage.getItem("create_therpist_3")) 
){

}else{
    swal("Fill All the Feild")
    props.history.push("/create_account_therapist")
}



        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      



        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

//         fetch("https://kmelectronics-database1.herokuapp.com/AllProduct",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     }
//    })
//    .then(res5=>res5.json())
//    .then(res6=>{
//     //    console.log(res6);
//     const pro = []
//     res6.map((res7,i)=>{
//         //    console.log(res7.Product_Popular );
//         if ( res7.Arrival){
//             //    console.log(res7);
//             //         // return setproduct1(...product1 , res3)
//             pro.push(res7)
//         }
//     })
//     // setproduct(pro.reverse())
//     setproduct(pro.slice(0,5))
//     setdis(true)
//    })
 
 },[])

  const SubmitDataMessage = (e) =>{
    e.preventDefault()
    if(TherapistType){

    }else{
        swal("Enter Therapist Type")
        return
    }
    console.log("wddde");
    
        const data = new FormData()
        data.append("file", UploadYourCertificate)
        data.append("upload_preset", "castofminds")
        data.append("cloud_name", "dskcqccr4")
        fetch("https://api.cloudinary.com/v1_1/dskcqccr4/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res=>res.json())
        .then((res2)=>{
            // this.setState({url : res2.url})
            const data1 = new FormData()
            data1.append("file", IdentityDocumentPhoto)
            data1.append("upload_preset", "castofminds")
            data1.append("cloud_name", "dskcqccr4")
            fetch("https://api.cloudinary.com/v1_1/dskcqccr4/image/upload",{ 
                method : "POST",
                body : data1 , 
            })
            .then(res1=>res1.json())
            .then((res21)=>{
                    localStorage.setItem("create_therpist_4", JSON.stringify({UploadYourCertificate : res2.url ,IdentityDocumentPhoto : res21.url
                    ,TherapistType,CredentialType
                    // ,IDType
                    // ,InsuranceExpires,InsuranceCompany

                    })) 
                    // setT
                    setTimeout(()=>{
                        props.history.push("/create_account_therapist4")
                    },2000)
                           
                        })
                        })
        
                    }   

        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
      
      

      








<h1 style={{padding : "0px 10px 0px 10px",  fontSize : "35px"  }}><center>

Step 3/5: My Credentials


</center></h1>


     <div className="site-section">
                     <div className="container">
                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>The photo you select will be displayed on your profile webpage.


</h2>
                             </center>
                         <div className="row">
                             <div className="col-md-12">
                             <form  onSubmit={(e)=>SubmitDataMessage(e)} className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                             <div className="col-md-6">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Therapist Type:<span className="text-danger">*</span></label>
                                                 <input type="text" value={TherapistType} onChange={(e)=>setTherapistType(e.target.value)}   className="form-control" placeholder="E.g Therapist, Counselor, Psychologist"/>
                                           
                                            </div>
                                             <div className="col-md-6">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_lname" className="text-black">Credential Type: <span className="text-danger">*</span></label>
                                                 <select name="no_lic_credential_type" className="form-control" value={CredentialType} onChange={(e)=>setCredentialType(e.target.value)}  ><option value="0">Select one:</option><option value="License">License</option><option value="Certificate">Certificate</option><option value="Diploma/Degree">Diploma/Degree</option><option value="Membership">Membership</option></select>
                                             </div>
                                         </div>
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Upload your Educational Document<span className="text-danger">*</span></label>
                                                 <input type="file" onChange={(e)=>setUploadYourCertificate(e.target.files[0])}   className="form-control" />
                                           
                                            </div>
                                             
                                         </div>

                                        {/* <div className="form-group row">
                                             <div className="col-md-6">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Insurance Company:<span className="text-danger">*</span></label>
                                                 <input type="text" value={InsuranceCompany} onChange={(e)=>setInsuranceCompany(e.target.value)}   className="form-control" placeholder="E.g Therapist, Counselor, Psychologist"/>
                                           
                                            </div>
                                             <div className="col-md-6">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_lname" className="text-black">Insurance Expires:<span className="text-danger">*</span></label>
                                                 <input type="text" value={InsuranceExpires} onChange={(e)=>setInsuranceExpires(e.target.value)}   className="form-control" placeholder="12/12 Example"/>
                                             </div>
                                         </div> */}


                                        <div className="form-group row">
                                             {/* <div className="col-md-6">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">ID Type:<span className="text-danger">*</span></label>
                                                 <input type="text" value={IDType} onChange={(e)=>setIDType(e.target.value)}   className="form-control" placeholder='Drivers license or passport'/>
                                           
                                            </div> */}
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Identity Document Photo<span className="text-danger">*</span></label>
                                                 <input type="file" onChange={(e)=>setIdentityDocumentPhoto(e.target.files[0])}   className="form-control" />
                                           
                                            </div>
                                             
                                           
                                         </div>



                                         <div className="form-group row">
                                             <div className="col-lg-4" style={{  margin : "20px auto", }}>
                                             <button className="nav-link" style={{ color : "white",padding : "5px  10px" , width : "200px",background :"rgb(25 18 43)",fontWeight : "600", textAlign  : 'center', fontSize : "18px", textTransform: "uppercase"}}  type="submit">Next</button>

                                             </div>
                                         </div>
                                    </div>
                                 </form>
                             </div>
                            
                         </div>
                     </div>
                 </div>











            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;