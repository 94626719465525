import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
// import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import ban23 from '../assets/images/ban-23.jpg';
// import ban24 from '../assets/images/ban-24.jpg';
// import ban25 from '../assets/images/ban-25.jpeg';
// import ban26 from '../assets/images/ban-26.jpeg';
import ban31 from '../assets/images/ban-31.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [theripist , settheripist] = useState([])
    const [theripist1 , settheripist1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    useEffect(() =>{
        window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        if ( JSON.parse(localStorage.getItem("user main 1")) )
        {
        }else{
            
            props.history.push("/login")
        }



        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://asad-backend-two.vercel.app/all-therispist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            const F = res1.filter((res3,j)=>res3._id === JSON.parse(localStorage.getItem("user main 1")).TheripistId )
            settheripist(F)
            settheripist1(res1)
        })

        // fetch("https://kmelectronics-database1.herokuapp.com/BannerPhoto",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res22=>res22.json())
        // .then(res222=>{
        //     console.log(res222[0]);
        //     setBannerPhoto(res222)
        
        // })

        // fetch("https://kmelectronics-database1.herokuapp.com/arrival-photo",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res2=>res2.json())
        // .then(res3=>{
        //     console.log(res3);
        //     setArrivalPhoto(res3)
        
        // })
        // fetch("https://kmelectronics-database1.herokuapp.com/MainCatogories",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res2=>res2.json())
        // .then(res3=>{
        //     console.log(res3);
        //     // setMainCatogories(res3)
            

        //     let dat = []
        //     res3.map((res55,c)=>{
        //         dat.push(res55)
        //     })
        //     // console.log(SubClassCategories,asse)
        //     console.log(dat)
        //     let data = dat.sort((a, b) => {
        //         return a.sort - b.sort;
        //     });
        //     setMainCatogories(data)
            
        // })
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

   
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])


 const setChanger1 = (res)=>{
    localStorage.setItem("create-user-3", JSON.stringify({
        TheripistId : res._id ,
        })) 
        setTimeout(()=>{
            props.history.push("/payment")
        },2000)
  
 }



 const setChanger2 = (res)=>{

 }



        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
            {/* End Top Search */}
            {/* Start Slider */}
     





            <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
            Your Therispist

</center></h1>

{/* <p style={{padding : "20px 20px 30px 20px",  fontSize : "20px"  ,fontWeight : "500"}}>
Below is the available therapist sorted after how well they match your profile. Feel free to pick anyone you prefer. Our therapists are highly qualified with a wide range of credentials that have been manually verified by our staff members.


</p> */}

<hr />



{theripist.map((res,i)=>{
    return (
        <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px" , margin : "auto"}} data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    {/* <span className="icon-truck"></span> */}
                                    <img src={res.ProfilePhoto} alt=""  style={{width : "100px"  ,  height : "100px"  , borderRadius : "100px"}}/>
                        </div>
                                <div className="text">
                                <h1 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "600" , fontSize : "24px"}}>{res.Title} {res.fname} {res.lname}


</h1>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "18px"}}>{res.TherapistType}


</h2>
                                <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "17px"}}>{res.City}, {res.Country}
{/* United States */}


</h2>
                                    <p  className='the456' style={{fontSize: "12px",textAlign: "justify",
    fontWeight: "500"}}>
        {res.Summaryofyoureducation}
        {/* {res.Summaryofyoureducation[0]}
        {res.Summaryofyoureducation[1]}
        {res.Summaryofyoureducation[2]}
        {res.Summaryofyoureducation[3]}
        {res.Summaryofyoureducation[4]}
        {res.Summaryofyoureducation[5]}
        {res.Summaryofyoureducation[6]}
        {res.Summaryofyoureducation[7]}
        {res.Summaryofyoureducation[8]}
        {res.Summaryofyoureducation[9]}
        {res.Summaryofyoureducation[10]}
        {res.Summaryofyoureducation[11]}
        {res.Summaryofyoureducation[12]}
        {res.Summaryofyoureducation[13]}
        {res.Summaryofyoureducation[14]}
        {res.Summaryofyoureducation[15]}
        {res.Summaryofyoureducation[16]}
        {res.Summaryofyoureducation[17]}
        {res.Summaryofyoureducation[18]}
        {res.Summaryofyoureducation[19]}
        {res.Summaryofyoureducation[20]}
        {res.Summaryofyoureducation[21]}
        {res.Summaryofyoureducation[22]}
        {res.Summaryofyoureducation[23]}
        {res.Summaryofyoureducation[24]}
        {res.Summaryofyoureducation[25]}
        {res.Summaryofyoureducation[26]}
        {res.Summaryofyoureducation[27]}
        {res.Summaryofyoureducation[28]}
        {res.Summaryofyoureducation[29]}
        {res.Summaryofyoureducation[30]}
        {res.Summaryofyoureducation[31]}
        {res.Summaryofyoureducation[32]}
        {res.Summaryofyoureducation[33]}
        {res.Summaryofyoureducation[34]}
        {res.Summaryofyoureducation[35]}
        {res.Summaryofyoureducation[36]}
        {res.Summaryofyoureducation[37]}
        {res.Summaryofyoureducation[38]}
        {res.Summaryofyoureducation[39]}
        {res.Summaryofyoureducation[40]}
        {res.Summaryofyoureducation[41]}
        {res.Summaryofyoureducation[42]}
        {res.Summaryofyoureducation[43]}
        {res.Summaryofyoureducation[44]}
        {res.Summaryofyoureducation[45]}
        {res.Summaryofyoureducation[46]}
        {res.Summaryofyoureducation[47]}
        {res.Summaryofyoureducation[48]}
        {res.Summaryofyoureducation[49]}
        {res.Summaryofyoureducation[50]}
        {res.Summaryofyoureducation[51]}
        {res.Summaryofyoureducation[52]}
        {res.Summaryofyoureducation[53]}
        {res.Summaryofyoureducation[54]}
        {res.Summaryofyoureducation[55]}
        {res.Summaryofyoureducation[56]}
        {res.Summaryofyoureducation[57]}
        {res.Summaryofyoureducation[58]}
        {res.Summaryofyoureducation[59]}
        {res.Summaryofyoureducation[60]}
        {res.Summaryofyoureducation[61]}
        {res.Summaryofyoureducation[62]}
        {res.Summaryofyoureducation[63]}
        {res.Summaryofyoureducation[64]}
        {res.Summaryofyoureducation[65]}
        {res.Summaryofyoureducation[66]}
        {res.Summaryofyoureducation[67]}
        {res.Summaryofyoureducation[68]}
        {res.Summaryofyoureducation[69]}
        {res.Summaryofyoureducation[70]}
        {res.Summaryofyoureducation[71]}
        {res.Summaryofyoureducation[72]}
        {res.Summaryofyoureducation[73]}
        {res.Summaryofyoureducation[74]}
        {res.Summaryofyoureducation[75]}
        {res.Summaryofyoureducation[76]}
        {res.Summaryofyoureducation[77]}
        {res.Summaryofyoureducation[78]}
        {res.Summaryofyoureducation[79]}
        {res.Summaryofyoureducation[80]}
        {res.Summaryofyoureducation[81]}
        {res.Summaryofyoureducation[82]}
        {res.Summaryofyoureducation[83]}
        {res.Summaryofyoureducation[84]}
        {res.Summaryofyoureducation[85]}
        {res.Summaryofyoureducation[86]}
        {res.Summaryofyoureducation[87]}
        {res.Summaryofyoureducation[88]}
        {res.Summaryofyoureducation[89]}
        {res.Summaryofyoureducation[90]}
        {res.Summaryofyoureducation[91]}
        {res.Summaryofyoureducation[92]}
        {res.Summaryofyoureducation[93]}
        {res.Summaryofyoureducation[94]}
        {res.Summaryofyoureducation[95]}
        {res.Summaryofyoureducation[96]}
        {res.Summaryofyoureducation[97]}
        {res.Summaryofyoureducation[98]}
        {res.Summaryofyoureducation[99]}
        {res.Summaryofyoureducation[100]}
        {res.Summaryofyoureducation[101]}
        {res.Summaryofyoureducation[102]}
        {res.Summaryofyoureducation[103]}
        {res.Summaryofyoureducation[104]}
        {res.Summaryofyoureducation[105]}
        {res.Summaryofyoureducation[106]}
        {res.Summaryofyoureducation[107]}
        {res.Summaryofyoureducation[108]}
        {res.Summaryofyoureducation[109]}
        {res.Summaryofyoureducation[110]}
        {res.Summaryofyoureducation[111]}
        {res.Summaryofyoureducation[112]}
        {res.Summaryofyoureducation[113]}
        {res.Summaryofyoureducation[114]}
        {res.Summaryofyoureducation[115]}
        {res.Summaryofyoureducation[116]}
        {res.Summaryofyoureducation[117]}
        {res.Summaryofyoureducation[118]}
        {res.Summaryofyoureducation[119]}
        {res.Summaryofyoureducation[120]}
        ...
        
        */}
 
        
        



</p>
                               
                                </div>
                            </div>
                          
                          
                            
                          
                          
                        </div>
                        {/* <center> */}

                                {/* <div style={{display : "flex" , justifyContent : "center"}}> */}
                                {/* <div style={{display : "flex" , justifyContent : "center"}}> */}
                                {/* <a onClick={()=>setChanger2(res)}  className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link style={{color : "white"}}>View Profile</Link></a> */}
                                {/* <a onClick={()=>setChanger1(res)} className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/payment" style={{color : "white"}}>PiCk me</Link></a>
                                <a onClick={()=>setChanger2(res)}  className="nav-link the456" style={{ color : "white", border : "1px solid white",padding : "10px  14px" , width : "200px", margin : "10px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>View Profile</Link></a> */}

                                {/* </div> */}
                        {/* </center> */}

                    </div>
                </div>
    )
     
})
}
           

{/* <hr /> */}

          

{/* <hr /> */}






            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;