import React ,{useEffect , useState} from 'react'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';


export default function App(props) {
    // const roomID = getUrlParams().get('roomID') || randomID(5);
    const [ab,seta] = useState("");



    useEffect(() =>{

      window.scrollTo(0, 0)
      // if (! JSON.parse(localStorage.getItem("user main therapist")) || ! JSON.parse(localStorage.getItem("user main 1")) ){
        if( JSON.parse(localStorage.getItem("user main therapist"))){
          
          let  b= ""
          if( JSON.parse(localStorage.getItem("user main therapist")) ){
             b = JSON.parse(localStorage.getItem("user main therapist")) 
          }
          // else{
          //    b =  JSON.parse(localStorage.getItem("user main 1"))
          // }
          let a = b.email.split("@")[0]
          console.log(a)
          seta(a)
        }else{
          if(JSON.parse(localStorage.getItem("user main 1"))){
            let  b= ""
            if( JSON.parse(localStorage.getItem("user main 1")) ){
               b = JSON.parse(localStorage.getItem("user main 1")) 
            }
            // else{
            //    b =  JSON.parse(localStorage.getItem("user main 1"))
            // }
            let a = b.email.split("@")[0]
            console.log(a)
            seta(a)
          }
          else{

            props.history.push("/")
          }


  }
  //     // seta("umer")
  // }
  // }
  

 
},[])
    const myMeeting = async (element) => {
   // generate Kit Token
    const appID = 1018795770;
    const serverSecret = "1a3558280cd627be0e034cacac876bad";
    const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, "123",Date.now().toString(),ab);


   // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      sharedLinks: [
        {
          name: 'Personal link',
          url:
           window.location.protocol + '//' + 
           window.location.host + window.location.pathname +
            '?roomID=' +
            ab,
        },
      ],
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, 
      },
    
    });


};

return (
  <>
  <br></br><br></br>
  <div
    ref={myMeeting}
    style={{ width: '100vw', height: '100vh' }}
  ></div>
  </>
);
}