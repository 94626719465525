import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import logo3 from '../assets/images/logo3.jpeg';

const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [data, setdata] = useState([]) 
    const [data1, setdata1] = useState([]) 


   useEffect(()=>{
    window.scrollTo(0, 0)

         fetch("https://database-student-1-stop.herokuapp.com/FAQ",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
               setdata(res4)
               data.map((res,i)=>{
                data1[i] = res.text
               })

            console.log(res4)
          })
          return () => {
            document.getElementById("get1").classList.remove("is-active");
            document.getElementById("get2").style.display = "none";
          }
        },[])

      
        
    



        return (
            <div>
                {/* <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Contact</strong></div>
                        </div>
                    </div>
                </div> */}

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                <h2 className="h3 mb-5 mt-5 text-black">HOW WE CAN HELP YOU ?</h2>

                                </center>
                            </div>
                      

                            </div> 

                            <img src={logo3} alt="" />

                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;