import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
import ban27 from '../assets/images/ban-27.jpeg';

import ClearIcon from '@material-ui/icons/Clear';


// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
   
    const [data , setdata] = useState([])

    const [Date ,  setDate ] = useState("")
    const [StartTime ,  setStartTime ] = useState("")
    const [EndTime ,  setEndTime ] = useState("")
    const [Description ,  setDescription ] = useState("")
    const [user ,  setuser ] = useState("")
    // const [Country ,  setCountry ] = useState("")
    // const [phone ,  setphone ] = useState("+92")
    // const [code ,  setcode ] = useState("")
    // const [gy ,  setgy ] = useState(true)
    // const [dispaly ,  setdispaly ] = useState(true)
    const [dispaly1 ,  setdispaly1 ] = useState(true)
    // const [changer1 ,  setchanger1 ] = useState(false)
    // const [changer2 ,  setchanger2 ] = useState("")
    const [Product_Sub_Catagories ,  setProduct_Sub_Catagories ] = useState("Exercise")


    useEffect(() =>{

        window.scrollTo(0, 0)

        if (! JSON.parse(localStorage.getItem("user main therapist")) ){
            props.history.push("/login")
        }
        if (! JSON.parse(localStorage.getItem("For Detail"))){
            props.history.push("/therapist/dashboard")
        }
        fetch("https://asad-backend-two.vercel.app/all-activity",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            console.log(res6)
            let A = []
            res6.map((res1,i)=>{
                if(res1.Userid === JSON.parse(localStorage.getItem("For Detail"))._id &&  res1.theripistId ===JSON.parse(localStorage.getItem("For Detail")).TheripistId && res1.Status){
                    A.push(res1)
                }
            })
            setdata(A)
        })
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
    
     
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

     
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])








 const deleteProduct = (id,i) =>{
    fetch("https://asad-backend-two.vercel.app/delete-activity",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            id
        })
    })
    .then(res11=>res11.json())
    .then((res12)=>{ 
        const newData = data.filter(data=> data._id !== id)
        setdata(newData)
    })
    // fetch("https://asad-backend-two.vercel.app/deleteActivity/"+id ,{
    //   method: "DELETE" , 
    //   headers: {
    //     "Content-Type" : "application/json" ,
    //   }
    // })
    // .then(res=>res.json())
    // .then(res2=>{
        // const newData = data.filter(data=> data._id !== id)
        //   setdata(newData)
    // })
  }


 const SubmitDataMessage = () =>{
  setdispaly1(false)
  // this.setState({displayNone : false})
  
                                fetch("https://asad-backend-two.vercel.app/create-activity",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                                Userid : JSON.parse(localStorage.getItem("user main 1"))._id ,
                                                theripistId : JSON.parse(localStorage.getItem("user main 1")).TheripistId ,
                                                Name : user,
                                                // email,
                                                Type : Product_Sub_Catagories,
                                                Date,
                                                StartTime,
                                                EndTime,
                                                Description,
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(res12)
                                            setdata([...data,res12])
                                            setuser("")
                                            setDate("")
                                            setStartTime("")
                                            setEndTime("")
                                            setProduct_Sub_Catagories("")
                                            setDescription("")


                                            console.log(res12)
                                            if(!res12.Error){
                                                setdispaly1(true)
                                                swal("Success");
                                                this.setState({
                                                        mainline: "",
                                                    })
                                            }
                                            else{
                                                setdispaly1(true)
                                                
                                                swal(res12.Error);
                                            }
                                        })
                                        .catch(err=>{
                                            swal("Success");
                                            setdispaly1(true)
                                    }) 
                                    }
                                   
                                    

                                    


        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         

   <h1 style={{padding : "80px 10px 10px 10px",  fontSize : "38px"  ,fontWeight : "400",textTransform:"uppercase"}}><center>
   Activity plan 

</center></h1>
{/* <div className="container-fuild rgjrgou" style={{width : "30%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px rgb(25,18,43)"}}>
           
         <center>

                    <h4 style={{color : "rgb(61, 61, 61)" ,fontSize : "23px"  ,fontWeight : "500"}}>New Event</h4>
         </center>
              
                <br />

                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Name</label>
                    <input type="text"  value={user} onChange={(e)=>setuser(e.target.value)}  className="form-control" placeholder="Enter Name" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Date </label>
                    <input type="date"  value={Date} onChange={(e)=>setDate(e.target.value)}  className="form-control" placeholder="Enter Date" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Start Time </label>
                    <input type="time"  value={StartTime} onChange={(e)=>setStartTime(e.target.value)}  className="form-control" placeholder="Enter Start Time" />
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>End Time </label>
                    <input type="time"  value={EndTime} onChange={(e)=>setEndTime(e.target.value)}  className="form-control" placeholder="Enter End Time" />
                </div>
                <br />
               

                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label for="inputAddress">Type</label>
                    <select required id="c_country" className="form-control" value={Product_Sub_Catagories} onChange={(e)=>setProduct_Sub_Catagories(e.target.value)} >
                            <option value="Exercise">Exercise</option>
                            <option  value="Mediatation">Mediatation</option>
                            <option  value="Therapist chat">Therapist chat</option>
                            <option  value="Work">Work</option>
                            <option  value="Just for fun">Just for fun</option>
                            <option  value="Yoga">Yoga</option>
                            <option  value="ohters">ohters</option>
                        </select>
                </div>
                <br />
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label>Description</label>
                    <input type="text"  value={Description} onChange={(e)=>setDescription(e.target.value)}  className="form-control" placeholder="Enter Description" />
                </div>
            <br/>
                    <button  onClick={()=>SubmitDataMessage()} className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)"}}>Create</button>
            
               
        </div> */}

     
<center>
<h1  className='the456' style={{fontSize : "45px",  padding  : "20px 20px 30px 20px",textTransform:"uppercase"}}>Client Activities

</h1>

</center>


{data.length > 0 ? data.map((res,i)=>{
    return (
        

            <div className="site-blocks-1 backk" style={{margin : "20px auto"}}>
                    <div className="container jhhjhh">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0" style={{ margin  : '0px auto',   padding: "0px",boxShadow: "0px 2px 6px rgb(25,18,43)"}} data-aos="fade-up" data-aos-delay="">
                                {/* <div className="icon mr-4 align-self-start">
                                    <span style={{fontSize : "25px",marginTop : "20px" ,  width : "20px" , fontWeight : "bold", color : "rgb(170, 31, 130)"}}  >Hello</span>
                        </div> */}
                                <div className="text" style={{width : "100%"}}>
                                <center>
                                    <p style={{background : "rgb(25,18,43)" ,color : "white",fontSize : "45px",}}>{res.Name} <span>                <ClearIcon id="myDiv3456767"  style={{color : "white",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} onClick={()=>deleteProduct(res._id,i)}/></span> </p>
                                </center>
                                <div style={{display : "flex" , justifyContent : "space-between" , padding : "10px" , color : "white"  ,fontWeight :"500"}}>
                                    <p style={{color : "black"}}>Start : {res.StartTime}</p>
                                    <p style={{color : "black"}}>End : {res.EndTime}</p>
                                    <p style={{color : "black"}}>Date {res.Date}</p>
                                    {/* <p></p> */}
                                </div>
                                    <p  className='the456' style={{fontSize: "17px",
    fontWeight: "500", padding : "10px 12px"}}>{res.Description}



</p>
                                
                            </div>
                      
                            </div>
                         
                        </div>
                    </div>
                </div>

    )
})
: <center>
    <h1>
        <b>
       " No Activity "
        </b>
    </h1>
    <br />
    <br />
    <br />
</center>
}











            {/* <div className="site-blocks-1 backk" style={{margin : "20px auto"}}>
                    <div className="container jhhjhh">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0" style={{ margin  : '0px auto',   padding: "0px",boxShadow: "0px 2px 6px rgb(25,18,43)"}} data-aos="fade-up" data-aos-delay="">
                                
                                <div className="text" style={{width : "100%"}}>
                                <center>
                                    <p style={{background : "rgb(25,18,43)" ,color : "white",fontSize : "45px",}}>Umer <span>                <ClearIcon id="myDiv3456767"  style={{color : "white",fontSize : "30px",position : "absolute" , right: "10%",top : "10%" , zIndex: "2"}} /></span> </p>
                                </center>
                                <div style={{display : "flex" , justifyContent : "space-between" , padding : "10px" , color : "black"  ,fontWeight :"500"}}>
                                    <p>Start : 12:00</p>
                                    <p>End : 12:00</p>
                                    <p>Date 20/4/22</p>
                                </div>
                                    <p  className='the456' style={{fontSize: "17px",minWidth: "100%",
    fontWeight: "500", padding : "10px 12px"}}>Choose your areas



</p>
                                
                            </div>
                      
                            </div>
                         
                        </div>
                    </div>
                </div> */}











            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;