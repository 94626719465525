import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";
import swal from 'sweetalert';

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

import ban11 from '../assets/images/ban-1.JPG';
// import ban13 from '../assets/images/ban-13.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import ban23 from '../assets/images/ban-23.jpg';
// import ban24 from '../assets/images/ban-24.jpg';
// import ban25 from '../assets/images/ban-25.jpeg';
// import ban26 from '../assets/images/ban-26.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
   
    const [data , setdata] = useState([])
    const [data1 , setdata1] = useState("")
    useEffect(() =>{

        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main therapist")) ){
            props.history.push("/login")
        }else{
            
            // setdata(JSON.parse(localStorage.getItem("user main 1")) )
            
            
            
            if (!JSON.parse(localStorage.getItem("User Select Id")) ){
                props.history.push("/login")

    }else{
         

    setdata(JSON.parse(localStorage.getItem("User Select Id")))
    setdata1(JSON.parse(localStorage.getItem("User Select Id")).meet)
    }
       
    }
       

        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      

 },[])



 const setChange = ()=>{
    fetch("https://asad-backend-two.vercel.app/new-live-session",{
        method: 'POST' , 
        headers :  {
          "Content-Type" : "application/json" , 
        } , 
        body : JSON.stringify({
            item : data1 ,
            id : JSON.parse(localStorage.getItem("User Select Id"))._id
        })
      })
      .then((res)=>res.json())
      .then((res2)  =>{
          console.log(res2)

          if (res2){
              swal("SucessFully Updated")
              props.history.push("/therapist/dashboard")
              localStorage.removeItem("User Select Id")
          }
          else{
            swal("Plz Try Again !"  )
          }
      })
      .catch((err)=>console.log(err))

   
}
        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         

   <h1 style={{padding : "80px 10px 10px 10px",  fontSize : "38px"  ,fontWeight : "400"}}><center>
   Live Sessions

</center></h1>
<div style={{width : "52%" ,  height : "auto"  ,borderRadius : "10px" , boxShadow: "0px 2px 6px rgb(25,18,43)" , margin :"70px auto"}} className="oiuoiip">
   <h1 style={{padding : "20px 10px 10px 10px",  fontSize : "23px" , color  :"rgb(25,18,43)" ,fontWeight : "400"}}><center>
   Worksheet reply needed before scheduling a live session!
</center></h1>

   <p style={{padding : "20px 10px 0px 10px",  fontSize : "15px"  ,fontWeight : "400" , color : "black"}}><center>
   One live session à 45 minutes/week with me is included in your subscription. To be able to schedule a live session, you first need to reply to the worksheet: Getting to know you »
</center></p>


    <Carousel style={{height  :"367px"}}>
              
              <Carousel.Item interval={2298000}>
                      <img src={ban11} alt="" style={{height : "267px"}}/>

                        <Carousel.Caption>
                        {/* <div className="container thers"  style={{top : "-420px", position : "absolute"}}>
                                  <div className="row">
                                  <div className="col-md-12"> */}
                                    {/* <img src={data[0] && data[0].ProfilePhoto } alt=""style={{marginBottom :  "30px",width : "200px" , height : "200px"  , borderRadius : "50%" , objectFit : "cover"}}/> */}
              {/* <h1 className="ther1s m-b-20" style={{ paddingBottom : "30px"}}> "I love the fact that I get feedback on the worksheets every day, sometimes several times a day. I prefer the online therapy to a traditional therapy, because you don't have to wait for feedback."
                  </h1> */}
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}><strong> <span style={{textTransform:"capitalize"}}>{data && data.Name}</span> <br />   </strong>    {data && data.City} {data && data.Country}</p>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}> {data && data.email}</p>
              <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>Change meet link : </p>
              <input type="text"  value={data1} onChange={(e)=>setdata1(e.target.value)}  className="form-control" placeholder="Enter Meet Link Here" />
              <br />
              <p ><a className="btn hvr-hover"  style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"500" , border : "1px solid white",  padding : "8px 16px"}}  onClick={()=>setChange()}>Save</a></p>
              {/* <p className="m-b-40" style={{fontWeight : "bold" ,fontSize : "13px" , color : "grey"}}>Change therapist »</p> */}
            {/* </div> */}
                                  {/* </div>
                                </div> */}
                        </Carousel.Caption>
                      </Carousel.Item>
            
</Carousel>

</div>
        




            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;