import React,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'


const Signup = (props) => {
    const [pass ,  setpass ] = useState("")
    const [phone ,  setphone ] = useState("")





    useEffect(() => {
      window.scrollTo(0, 0)

      if ( JSON.parse(localStorage.getItem("user main therapist")) )
        props.history.push("/therapist/dashboard")
      
           }, [])
  

const postData = (e) =>{
    e.preventDefault()
   
    if(pass.length === 0){
        swal("Enter The Password !"  )
           return
         }
    if(phone.length === 0){
        swal("Enter The Email !"  )
           return
         }
         
      fetch("https://asad-backend-two.vercel.app/main-login-user-therapist",{
        method: 'POST' , 
        headers :  {
          "Content-Type" : "application/json" , 
        } , 
        body : JSON.stringify({
          pass  ,
          email :phone ,
        })
      })
      .then((res)=>res.json())
      .then((res2)  =>{
          console.log(res2)
          if (res2 !== null && !res2.Error  ){
            console.log(res2)
            // swal("SucessFully Login"  )
            localStorage.setItem("user main therapist" , JSON.stringify(res2) )
                props.history.push("/therapist/dashboard")
          }
          else if (res2.Error){
            swal(res2.Error )
          }
          else{
            swal("Email & Password are Incorrect Plz Try Again !"  )
          }
        // console.log(res2)
      })
      .catch((err)=>console.log(err))
    }












  return (
    <div className='jkjkjkkjjk1'>

       <div className="container-fuild rgjrgou1" style={{width : "30%" , margin : "50px auto",padding: "40px 41px",boxShadow: "0px 2px 6px rgb(25,18,43)",background:"rgb(25, 18, 43)"}}>
            <form  onSubmit={(e)=>postData(e)}>
                {/* <center> */}

                <div style={{width: "90%",margin: "auto"}}>
                    <h4 style={{color : "white",fontWeight:"bold"}}>Welcome Back</h4>
                    <h6 style={{color : "white",fontWeight:"bold"}}>Don’t have an Account?  <span style={{textDecoration: "underline",color : "white",textTransform:"uppercase"}}> <Link to="/create_account_therapist" style={{color : "#F7CF47"}}> Signup Now</Link></span></h6>
                </div>
                <br />
                {/* </center> */}

                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{color:"white",fontWeight:"bold"}}>Email</label>
                    <input type="email"  value={phone} onChange={(e)=>setphone(e.target.value)}  className="form-control" placeholder="Enter Email" />
                </div>
               
                
                <div className="form-group" style={{width: "90%",margin: "auto"}}>
                    <label style={{color:"white",fontWeight:"bold"}}>Password</label>
                    <input type="password"   value={pass} onChange={(e)=>setpass(e.target.value)}  className="form-control" placeholder="Enter password" />
                </div>
            <br/>

                <button type="submit" className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "#F7CF47" , border : "1px solid #F7CF47",color:"rgb(25,18,43)",fontWeight:"bold"}}>Login</button>
                {/* <button type="submit" className="btn btn-primary btn-block" style={{width: "90%",margin: "auto",background : "rgb(25,18,43)" , border : "1px solid rgb(227, 77, 92)"}}>Sign Up</button> */}
                {/* <p className="forgot-password text-right">
                    Forgot <Link to="/forget-pass-admin">password?</Link>
                </p> */}
                {/* <p className=" text-center">
                    <Link to="/login">Already Have an Account </Link>

                </p> */}
            </form>
            <br/>
            <center>
                    <span style={{textDecoration: "underline",color:"white",fontSize:"13px",fontWeight:"500",textTransform:"uppercase"}}> <Link to="/password_forgotten-therapist"  style={{textDecoration: "underline",color:"white"}}>Forget Password </Link> </span>
            </center>
        </div>
        </div>
  )
}
export default Signup