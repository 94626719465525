import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

// import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';


// import ban333 from '../assets/images/ban-33.jpeg';


// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
// import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [Changer , setChanger] = useState("")
    const [Changer1 , setChanger1] = useState("")

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        



        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

       
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])


 const setChange45 = ()=>{
    // document.getElementById(Changer).style.background = "white"
    // document.getElementById(Changer).style.color = "black"
    // document.getElementById(Changer1).style.background = "white"
    // document.getElementById(Changer1).style.color = "black"
    localStorage.setItem("create-user-2", JSON.stringify({
        Gender : Changer ,
        Session :  Changer1
        
        })) 
        setTimeout(()=>{
            props.history.push("/therapist/indivdiual")
        },2000)
  
}
 const setCha = (e)=>{
    document.getElementById(e).style.background = "#18122b"
        document.getElementById(e).style.color = "white"
    // document.getElementById(Changer1).style.background = "white"
    // document.getElementById(Changer1).style.color = "black"
  
  
}
 const setCha1 = (e)=>{
    document.getElementById(e).style.background = "#18122b"
    document.getElementById(e).style.color = "white"
  
}

        return (
            
            <div>
           


                <center>
   <div className="site-section site-section-sm site-blocks-1 backk" style={{background : `white`,  height : "auto" }}>


           <h3 style={{padding : "20px 10px 40px 10px",  fontSize : "40px"  ,fontWeight : "500" , color : "black"}}><center>
           Additional Information

</center></h3>
           <h1 style={{padding : "20px 10px 40px 10px",  fontSize : "20px"  ,fontWeight : "500" , color : "black"}}><center>
           Would you prefer a female or male therapist?



</center></h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Female" onClick={()=>setChanger("Female")} onMouseUp={()=>setCha("Female")}>Female</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Male" onClick={()=>setChanger("Male")} onMouseUp={()=>setCha("Male")}>Male </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Doesn't matter" onClick={()=>setChanger("Doesn't matter")} onMouseUp={()=>setCha("Doesn't matter")}>Doesn't matter </h2>
                                </div>
                            </div>
                            
                           
                        </div>
                    </div>
      
      <br />

           <h1 style={{padding : "20px 10px 40px 10px",  fontSize : "20px"  ,fontWeight : "500" , color : "black"}}><center>
For therapist live sessions, what would you prefer?




</center></h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Video" onClick={()=>setChanger1("Video")} onMouseUp={()=>setCha1("Video")}>Video</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Voice only" onClick={()=>setChanger1("Voice only")} onMouseUp={()=>setCha1("Voice only")}>Voice only </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 33px"  , borderRadius :"10px" , width : "220px"}} id="Text" onClick={()=>setChanger1("Text")} onMouseUp={()=>setCha1("Text")}>Text</h2>
                                </div>
                            </div>
                          
                           
                        </div>
                    </div>
                    <br />
                    <br />
   <center>
<a className="nav-link the456" style={{ color : "white", border : "1px solid #F7CF47",padding : "5px  14px" , width : "200px", margin : "20px 0px",borderRadius:"10px", background :"#F7CF47",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"}} onClick={()=>setChange45()}><Link style={{color : "white"}}>Contiune</Link></a>
       </center>        
                </div>



</center>





            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;