import React,{useState , useEffect } from 'react';
// import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
// import Carousel from 'react-bootstrap/Carousel';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';




const Signup = (props) => {
   


    const [Data ,  setData ] = useState([])
    // const [phone ,  setphone ] = useState("")


    useEffect(() => {
        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main therapist")) ){
            props.history.push("/login")
        }
        else{
            fetch("https://asad-backend-two.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res5=>res5.json())
            .then(res6=>{
                // console.log(res6)
                // setUserM(res6)
                localStorage.setItem("user main therapist" , JSON.stringify(res6))
 
                
                fetch("https://asad-backend-two.vercel.app/allUser",{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                })
                .then(res15=>res15.json())
                .then(res16=>{
                    const A = []
                         res16.map((res9,j)=>{
                           if(res6._id === res9.TheripistId){
                            A.push(res9)
                          }
                      })
                      console.log(A)
                      setData(A)
                })






            })










        }
    }, [])







        return (
            <div>

                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "44px"  ,fontWeight : "500"}}><center>
                  Select Client

</center></h1>

                  <h4 style={{padding : "20px 10px 0px 10px",  fontSize : "34px"  ,fontWeight : "400"}}><center>
                  YOUR THERAPY TOOLBOX:



</center></h4>
        


{/* <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "40px"  ,fontWeight : "500"}}><center>
Recruitment (job applicants at the Company)

</center></h1> */}

<center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                            {
                                Data.map((res,i)=>{
                                    return(
                                        <>
                                            <div className="col-md-12 col-lg-12 mb-12 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                                <div className="icon mr-4" style={{display : "flex" , flexDirection : "column",height : "140px",border : "1px solid black", padding : "30px 0px" , borderRadius : "5px",  boxShadow: "rgb(152 152 152) 0px 0px 27px 0px"}} >
                                                    {/* <span><img src={ban16} alt="" style={{width : "80px" , height:  "77px"}} /></span> */}
                                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Name : {res.Name} </span>
                                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"bold", fontSize : "11px"}}>Email : {res.email} </span>
                                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"bold", fontSize : "11px"}}>Country : {res.Country} </span>
                                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"bold", fontSize : "11px"}}>Gender : {res.Gender} </span>
                                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"bold", fontSize : "11px"}}>{res.meet && "Meet : " } {res.meet && res.meet} </span>
                                                    {/* <p ><a className="btn hvr-hover"  style={{background : "rgb(25 18 43)" ,  color : "white" , fontWeight :"500" , border : "1px solid white",  padding : "8px 16px"}}  onClick={()=>setChange(res)}>Change Meet link</a></p> */}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                     
                            
                            </div>
                    </div>
                </div>



</center>







            
          </div>
        )
    
}

export default Signup;