import React ,{useEffect , useState,useRef} from 'react'
// import {Link} from "react-router-dom"




// import video1 from '../assets/images/gif.gif';
// import video1 from '../assets/images/COM_1.png';
// import video2 from '../assets/images/COM_1_.png';

import key13 from '../assets/images/ban-31.jpg';

import therapist5 from '../assets/images/therapist (5).jpeg';
import therapist4 from '../assets/images/therapist (4).jpeg';
import therapist1 from '../assets/images/therapist (1).jpeg';
import therapist3 from '../assets/images/therapist (3).jpeg';
import therapist from '../assets/images/therapist.jpeg';






const Home = ( props ) => {
  // const videoEl = useRef(null);


    const [count , setcount] = useState(0)
    const [display , setdisplay] = useState(false)
    useEffect(() =>{
      window.scrollTo(0, 0)


    

    //   let a = 0
    //   setInterval(() => {

    //     a = a + 1000
    //     sett1(a)        
        
    // }, 100);

      // document.querySelector('video').play();
      // videoEl &&
      // videoEl.current &&
      // videoEl.current.play().catch(error => {
      //   console.error("Error attempting to play", error);
      // });
        // window.scrollTo(0, 0)
        localStorage.removeItem("check Head")
        localStorage.removeItem("check Head 1")
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       
 },[])




const setChange = (i) =>{
    if(!display){
setcount(i)
    setdisplay(true)
}
else{
        setcount(i)
        setdisplay(false)
    }
    
}



        return (
            
            <div>
         
<br />
<br />








<div class="container">
  <div class="title"  style={{width :  "100%"}}>
    <div><h1 className='h11'>The Cast Of Minds Team </h1>
    </div>
  </div>
  <div class="details"  style={{width :  "100%",color :"black",fontWeight:"400",fontSize:"18px"}}>
    <p>We take immense pride in our dedicated team at Cast of Minds, whose relentless efforts drive positive change. Together, we are committed to fostering an inclusive environment where open dialogue thrives, paving the way for meaningful impacts.</p>
  </div>
</div>

<br />








<div class="container" >
  <div class="title"  style={{width :  "100%"}}>
    <div><h1 className='h11' style={{textAlign:"center"}}>Executive Leadership Team
    </h1>
    </div>
  </div>
 
</div>

<br />



<div class="container" >

 <div class="executive-leadership-team change-mobile-veiw1">
		<div>
			<img src={key13} alt="Matthew McEvoy" onClick={()=>setChange(0)}/>
			<div class="text1">
				<h2>Asad Vichi</h2>
				<p>Chief Executive Officer</p>
			</div>
		</div>
		<div>
			<img src={therapist3} alt="Tim Davison" onClick={()=>setChange(1)}/>
			<div class="text1">
				<h2>Maliha Usman</h2>
				<p>Cheif Operating Officer</p>
			</div>
		</div>
		<div>
			<img src={therapist1} alt="Maggie Wright" onClick={()=>setChange(2)}/>
			<div class="text1">
				<h2>Syeda Quratulain</h2>
				<p>Managing director </p>
			</div>
		</div>
		<div>
			<img src={therapist4} alt="Stephanie Evans" onClick={()=>setChange(3)}/>
			<div class="text1">
				<h2>Mishaa</h2>
				<p>Creative Head</p>
			</div>
		</div>
		<div>
    <img src={therapist} alt="Stephanie Evans" onClick={()=>setChange(4)}/>
			<div class="text1">
				<h2>Alexander Pauwels</h2>
				<p>Business Development Officer</p>
			</div>
		</div>
	</div>
	</div>


  {count === 0 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Asad Vichi</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Chief Executive Officer </div>
    
  </div>
  <div class="details">
    <p>After nearly three years as an successful equity trader and website developer, Asad decided to focus on something completely different in 2018. He joined Institute of psychology in Karachi and since than the passion for psychology and its domains increases. Studies showed that web based therapy, in many cases, can be as efficient as traditional face-to-face therapy sessions. You can access your online therapy program from anywhere in the world, anonymously, whenever it suits you the best, at a low cost. In an aim to make web-based therapy accessible to the general public, so that more people in need could get most of their lives, Asad founded Castofminds.com
Asad currently graduated in IPP Bahira university, Karachi</p>
  </div>
</div>
    </div>
: ""
}
{count === 1 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
<div class="name">Maliha Usman</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Cheif Operating Officer </div>
    
  </div>
  <div class="details">
    <p>Hello, I am Maliha Usman, an Organizational Psychologist deeply committed to fostering healthy and productive workplace environments. I firmly believe that everyone deserves the opportunity to thrive in their professional lives. Many individuals face challenges at work that they hesitate to address due to fear of stigma or repercussions. Promoting mental health awareness within organizations is crucial to letting people know they are not alone and that their well-being is a priority. No matter the obstacles, with the right support, everyone can achieve their full potential.</p>
  </div>
</div>
    </div>
: ""
}
{count === 2 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
<div class="name">Syeda Quratulain</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Managing director </div>
    
  </div>
  <div class="details">
    <p>Associate psychologist currently serving as an Organizational Development and Engagement Specialist, I bring a unique psychological perspective to enhancing workplace dynamics. My role involves designing and implementing strategies to improve employee engagement, foster positive organizational culture, and drive overall performance. With a deep interest in clinical psychology, I continuously explore the complexities of human behavior and thought, applying these insights to create supportive and thriving work environments. My passion for understanding the human mind fuels my commitment to promoting mental well-being and engagement within the workplace.</p>
  </div>
</div>
    </div>
: ""
}
{count === 3 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Mishaa</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Creative Head</div>
    
  </div>
  <div class="details">
    <p>Hello, I am Mishaa, an Associate Clinical Psychologist currently pursuing my specialization. I am dedicated to the belief that everyone deserves the opportunity to heal.Everyone has a chapter they don't want to read out loud; they don't seek treatments for their issues because they are scared of the negative associations attached with it. Mental health awareness is an important initiative to let people know that they are not alone, and no matter what happens, they can and will get through it.</p>
  </div>
</div>
    </div>
: ""




}
   


{count === 4 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Alexander Pauwels</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Business Development Officer</div>
    
  </div>
  <div class="details">
    <p>Alexander has been on the spiritual path since 2007. He originally comes from a background that involved creating and coaching from a mix of motivation, passion and mindset in the fitness/health industry. Now his work includes much deeper integration and alignment between mind, body and heart, and facilitating other people’s self-healing. His focus is on relationships and on connecting people to their power within. He is a writer, video creator and is the founder of a project called "The Source" which can be found back on Facebook.</p>
  </div>
</div>
    </div>
: ""




}
   










{/* {count === 0 && display ?
    <>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Asad Vichi</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Chief Executive Officer </div>
    
  </div>
  <div class="details">
    <p>After nearly three years as an successful equity trader and website developer, Asad decided to focus on something completely different in 2018. He joined Institute of psychology in Karachi and since than the passion for psychology and its domains increases. Studies showed that web based therapy, in many cases, can be as efficient as traditional face-to-face therapy sessions. You can access your online therapy program from anywhere in the world, anonymously, whenever it suits you the best, at a low cost. In an aim to make web-based therapy accessible to the general public, so that more people in need could get most of their lives, Asad founded Castofminds.com
Asad currently graduated in IPP Bahira university, Karachi</p>
  </div>
</div>
    </>
: ""
}
{count === 1 && display ?
    <>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Syeda Quratulain</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Operating </div>
    
  </div>
  <div class="details">
    <p>Hello, I am Maliha Usman, an Organizational Psychologist deeply committed to fostering healthy and productive workplace environments. I firmly believe that everyone deserves the opportunity to thrive in their professional lives. Many individuals face challenges at work that they hesitate to address due to fear of stigma or repercussions. Promoting mental health awareness within organizations is crucial to letting people know they are not alone and that their well-being is a priority. No matter the obstacles, with the right support, everyone can achieve their full potential.</p>
  </div>
</div>
    </>
: ""
}
{count === 2 && display ?
    <>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Maliha Usman</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Managing director </div>
    
  </div>
  <div class="details">
    <p>Associate psychologist currently serving as an Organizational Development and Engagement Specialist, I bring a unique psychological perspective to enhancing workplace dynamics. My role involves designing and implementing strategies to improve employee engagement, foster positive organizational culture, and drive overall performance. With a deep interest in clinical psychology, I continuously explore the complexities of human behavior and thought, applying these insights to create supportive and thriving work environments. My passion for understanding the human mind fuels my commitment to promoting mental well-being and engagement within the workplace.</p>
  </div>
</div>
    </>
: ""
}
{count === 3 && display ?
    <>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Mishaa</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Creative Head</div>
    
  </div>
  <div class="details">
    <p>Hello, I am Mishaa, an Associate Clinical Psychologist currently pursuing my specialization. I am dedicated to the belief that everyone deserves the opportunity to heal.Everyone has a chapter they don't want to read out loud; they don't seek treatments for their issues because they are scared of the negative associations attached with it. Mental health awareness is an important initiative to let people know that they are not alone, and no matter what happens, they can and will get through it.</p>
  </div>
</div>
    </>
: ""}
 */}




















 <div class="executive-leadership-team change-mobile-veiw">
		<div style={{margin: "40px auto"}}>
			<img src={key13} alt="Matthew McEvoy" onClick={()=>setChange(0)}/>
			<div class="text1">
				<h2>Asad Vichi</h2>
				<p>Chief Executive Officer</p>
			</div>
		</div>
    
{count === 0 && display ?
    <div className='change-view' style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Asad Vichi</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container">
  <div class="title" style={{width :  "100%"}}>
    <div>Chief Executive Officer </div>
    
  </div>
  <div class="details" style={{width :  "100%"}}>
    <p>After nearly three years as an successful equity trader and website developer, Asad decided to focus on something completely different in 2018. He joined Institute of psychology in Karachi and since than the passion for psychology and its domains increases. Studies showed that web based therapy, in many cases, can be as efficient as traditional face-to-face therapy sessions. You can access your online therapy program from anywhere in the world, anonymously, whenever it suits you the best, at a low cost. In an aim to make web-based therapy accessible to the general public, so that more people in need could get most of their lives, Asad founded Castofminds.com
Asad currently graduated in IPP Bahira university, Karachi</p>
  </div>
</div>
    </div>
: ""
}
		<div style={{margin: "40px auto"}}>
			<img src={therapist3} alt="Tim Davison" onClick={()=>setChange(1)}/>
			<div class="text1">
				<h2>Maliha Usman</h2>
				<p>Cheif Operating Officer</p>
			</div>
		</div>

    {count === 1 && display ?
    <div className='change-view'  style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Maliha Usman</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container">
  <div class="title"  style={{width :  "100%"}}>
    <div>Operating </div>
    
  </div>
  <div class="details"  style={{width :  "100%"}}>
    <p>Hello, I am Maliha Usman, an Organizational Psychologist deeply committed to fostering healthy and productive workplace environments. I firmly believe that everyone deserves the opportunity to thrive in their professional lives. Many individuals face challenges at work that they hesitate to address due to fear of stigma or repercussions. Promoting mental health awareness within organizations is crucial to letting people know they are not alone and that their well-being is a priority. No matter the obstacles, with the right support, everyone can achieve their full potential.</p>
  </div>
</div>
    </div>
: ""
}
		<div style={{margin: "40px auto"}}>
			<img src={therapist1} alt="Maggie Wright" onClick={()=>setChange(2)}/>
			<div class="text1">
				<h2>Syeda Quratulain</h2>
				<p>Managing director </p>
			</div>
		</div>

    {count === 2 && display ?
    <div className='change-view'  style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Syeda Quratulain</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container">
  <div class="title"  style={{width :  "100%"}}>
    <div>Managing director </div>
    
  </div>
  <div class="details"  style={{width :  "100%"}}>
    <p>Associate psychologist currently serving as an Organizational Development and Engagement Specialist, I bring a unique psychological perspective to enhancing workplace dynamics. My role involves designing and implementing strategies to improve employee engagement, foster positive organizational culture, and drive overall performance. With a deep interest in clinical psychology, I continuously explore the complexities of human behavior and thought, applying these insights to create supportive and thriving work environments. My passion for understanding the human mind fuels my commitment to promoting mental well-being and engagement within the workplace.</p>
  </div>
</div>
    </div>
: ""
}
		<div style={{margin: "40px auto"}}>
			<img src={therapist4} alt="Stephanie Evans" onClick={()=>setChange(3)}/>
			<div class="text1">
				<h2>Mishaa</h2>
				<p>Creative Head</p>
			</div>
		</div>
	

{count === 3 && display ?
    <div className='change-view'  style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Mishaa</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" >
  <div class="title"  style={{width :  "100%"}}>
    <div>Creative Head</div>
    
  </div>
  <div class="details"  style={{width :  "100%"}}>
    <p>Hello, I am Mishaa, an Associate Clinical Psychologist currently pursuing my specialization. I am dedicated to the belief that everyone deserves the opportunity to heal.Everyone has a chapter they don't want to read out loud; they don't seek treatments for their issues because they are scared of the negative associations attached with it. Mental health awareness is an important initiative to let people know that they are not alone, and no matter what happens, they can and will get through it.</p>
  </div>
</div>
    </div>
: ""




}
   
		<div style={{margin: "40px auto"}}>
			<img src={therapist} alt="Stephanie Evans" onClick={()=>setChange(4)}/>
			<div class="text1">
				<h2>Alexander Pauwels</h2>
				<p>Business Development Officer</p>
			</div>
		</div>
	</div>

{count === 4 && display ?
    <div className='change-view'  style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Alexander Pauwels</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" >
  <div class="title"  style={{width :  "100%"}}>
    <div>Business Development Officer</div>
    
  </div>
  <div class="details"  style={{width :  "100%"}}>
    <p>Alexander has been on the spiritual path since 2007. He originally comes from a background that involved creating and coaching from a mix of motivation, passion and mindset in the fitness/health industry. Now his work includes much deeper integration and alignment between mind, body and heart, and facilitating other people’s self-healing. His focus is on relationships and on connecting people to their power within. He is a writer, video creator and is the founder of a project called "The Source" which can be found back on Facebook.</p>
  </div>
</div>
    </div>
: ""




}
   






<div class="container" >
  <div class="title"  style={{width :  "100%"}}>
    <div><h1 className='h11'>The clinical team</h1>
    </div>
  </div>
  
</div>

<br />


<div class="container" >


<div class="executive-leadership-team change-mobile-veiw1">
		<div>
			<img src={therapist5} alt="Matthew McEvoy" onClick={()=>setChange(5)}/>
			<div class="text1">
				<h2>Sana Sadia</h2>
				<p>Clinical Supervisor</p>
			</div>
		</div>
	</div>
	</div>


  {count === 5 && display ?
    <div className='change-view1'>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Sana Sadia</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container" style={{display:"flex"}}>
  <div class="title">
    <div>Clinical Supervisor </div>
    
  </div>
  <div class="details">
    <p>Dr. Sana Sadia is a renowned clinical psychologist with around 10 years of experience in behavioural and cognitive analysis</p>
  </div>
</div>
    </div>
: ""
}













<div class="executive-leadership-team change-mobile-veiw">
		<div style={{margin: "40px auto"}}>
			<img src={therapist5} alt="Matthew McEvoy" onClick={()=>setChange(5)}/>
			<div class="text1">
				<h2>Sana Sadia</h2>
				<p>Clinical Supervisor</p>
			</div>
		</div>
    
{count === 5 && display ?
    <div className='change-view' style={{width :  "100%",height:"auto",margin:"10px 0px 40px 0px"}}>
    <div class="container" style={{display:"flex"}}>
  <div class="name">Sana Sadia</div>
  <div class="close" onClick={()=>setdisplay(false)}>CLOSE X</div>
</div>

  <hr  style={{color :  "black"}}/>
<div class="container">
  <div class="title" style={{width :  "100%"}}>
    <div>Clinical Supervisor </div>
    
  </div>
  <div class="details" style={{width :  "100%"}}>
    <p>Dr. Sana Sadia is a renowned clinical psychologist with around 10 years of experience in behavioural and cognitive analysis</p>
  </div>
</div>
    </div>
: ""
}
		
</div>







<div class="container" >
  
  <div class="details"  style={{width :  "100%",color :"black",fontWeight:"400",fontSize:"18px"}}>
    <p>Want to join the CASTOFMINDS team?  See our latest vacancies and experience the world of work in digital mental health.

</p>
<a className="btn" onClick={()=>props.history.push("/contact-us")} style={{background : "transparent" ,  color : "rgb(25 18 43)" , fontWeight :"500" , border : "3px solid #F7CF47", margin :"20px 20px",width: "fit-content",borderRadius : "4px",fontSize: "16px",
      }}>CAREERS WITH CASTOFMINDS</a>

  </div>
</div>

<br />



















            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;