import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

// import logo from '../assets/images/logo.png';
// import ban1 from '../assets/images/banner-01.jpg';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import ban66 from '../assets/images/ban-6.jpg';
// import ban666 from '../assets/images/ban-6.jpeg';
// import ban1111 from '../assets/images/ban-11.jpeg';
// import ban77 from '../assets/images/ban-7.JPG';
// import ban88 from '../assets/images/ban-8.JPG';
// import ban99 from '../assets/images/ban-9.JPG';
// import ban10 from '../assets/images/ban-10.JPG';
// import ban11 from '../assets/images/ban-1.JPG';
import ban13 from '../assets/images/ban-13.jpeg';
import ban34 from '../assets/images/ban-34.jpeg';
// import ban14 from '../assets/images/ban-14.jpg';
// import ban15 from '../assets/images/ban-15.JPG';
// import ban16 from '../assets/images/ban-16.JPG';
// import ban17 from '../assets/images/ban-17.jpeg';
// import gif1 from '../assets/images/gif1.gif';
// import ban2 from '../assets/images/banner-02.jpg';
// import ban3 from '../assets/images/banner-03.jpg';
// import cat1 from '../assets/images/categories_img_01.jpg';
// import cat2 from '../assets/images/categories_img_02.jpg';
// import cat3 from '../assets/images/categories_img_03.jpg';
// import add1 from '../assets/images/add-img-01.jpg';
// import add2 from '../assets/images/add-img-02.jpg';
// import add3 from '../images/partner.jpeg';
// import add4 from '../images/partner1.jpeg';
// import add5 from '../images/web.jpeg';
// import add6 from '../images/mob.jpeg';
// import par1 from '../assets/images/img-pro-01.jpg';
// import par2 from '../assets/images/img-pro-02.jpg';
// import par3 from '../assets/images/img-pro-03.jpg';
// import par4 from '../assets/images/img-pro-04.jpg';

// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

// import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
// import ReplayIcon from '@material-ui/icons/Replay';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';

// import SecurityIcon from '@material-ui/icons/Security';
// import SecurityIcon from '@mui/icons-material/Security';

// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
import swal from 'sweetalert';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [Product_Image_Upload, setProduct_Image_Upload] = useState("") 
    const [TherapistType, setTherapistType] = useState("") 
    const [CredentialType, setCredentialType] = useState("") 
    const [UploadYourCertificate, setUploadYourCertificate] = useState("") 
    const [InsuranceCompany, setInsuranceCompany] = useState("") 
    const [InsuranceExpires, setInsuranceExpires] = useState("") 
    const [IDType, setIDType] = useState("") 
    const [IdentityDocumentPhoto, setIdentityDocumentPhoto] = useState("") 
    const [currentworksituation, setcurrentworksituation] = useState("") 
    const [hoursperweek, sethoursperweek] = useState("") 
    const [MonFriwouldyouprefer, setMonFriwouldyouprefer] = useState("") 
    const [startworkingforus, setstartworkingforus] = useState("") 
    const [licensingstates, setlicensingstates] = useState("") 
    const [professionalmalpracticeliabilityinsurance, setprofessionalmalpracticeliabilityinsurance] = useState("") 
    const [disciplinaryactions, setdisciplinaryactions] = useState("") 
    const [Language, setLanguage] = useState("") 
    const [selected_specialities1, setselected_specialities1] = useState([]) 
    const [selected_treatment_orientation1, setselected_treatment_orientation1] = useState([]) 
    const [selected_therapy_types1, setselected_therapy_types1] = useState([]) 
  
  
    useEffect(() =>{
      window.scrollTo(0, 0)

        // window.scrollTo(0, 0)


        
if(JSON.parse(localStorage.getItem("create_therpist_2")) 
){

}else{
   swal("Fill All the Feild")
   props.history.push("/create_account_therapist")
}
  

if(
JSON.parse(localStorage.getItem("create_therpist_3"))
){

}else{
   swal("Fill All the Feild")
   props.history.push("/create_account_therapist2")
}


if(
JSON.parse(localStorage.getItem("create_therpist_4"))
){

}else{
   swal("Fill All the Feild")
   props.history.push("/create_account_therapist3")
}



        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
      



        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

//         fetch("https://kmelectronics-database1.herokuapp.com/AllProduct",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     }
//    })
//    .then(res5=>res5.json())
//    .then(res6=>{
//     //    console.log(res6);
//     const pro = []
//     res6.map((res7,i)=>{
//         //    console.log(res7.Product_Popular );
//         if ( res7.Arrival){
//             //    console.log(res7);
//             //         // return setproduct1(...product1 , res3)
//             pro.push(res7)
//         }
//     })
//     // setproduct(pro.reverse())
//     setproduct(pro.slice(0,5))
//     setdis(true)
//    })
 
 },[])

 const SubmitDataMessage = () =>{
  if(currentworksituation){
  }else{
    
    swal("Enter Your current work situation")
    return
  }

  if(selected_specialities1.length === 0){
    swal("Select Your Specialities")
  }else{
        localStorage.setItem("create_therpist_5", JSON.stringify({
                    selected_specialities : selected_specialities1,
                    selected_treatment_orientation  :selected_treatment_orientation1,
                    selected_therapy_types : selected_therapy_types1,
                    Language,
                    currentworksituation,
                    hoursperweek,
                    MonFriwouldyouprefer,
                    licensingstates,
                    disciplinaryactions,


                  // ,IDType
                  // ,InsuranceExpires,InsuranceCompany

                  })) 
                  // props.history.push("/create_account_therapist5")
                  setTimeout(()=>{
                    props.history.push("/create_account_therapist5")
                },2000)
                
  }
              
      
                  }   

                  
 const setselected_specialities = (e) =>{
  let a= []
  selected_specialities1.length > 0 && selected_specialities1.map((res,i)=>{
      if(res !== e){
        a.push(res)
      }
  })
 
  if( selected_specialities1.length === 0 ){
    setselected_specialities1([e])
  }
  else{
    a.push(e)
  setselected_specialities1(a)
}
  console.log(a,e,selected_specialities1)
                  }   
                  
 const setselected_treatment_orientation = (e) =>{
  let a= []
  selected_treatment_orientation1.length > 0 && selected_treatment_orientation1.map((res,i)=>{
    if(res !== e){
      a.push(res)
    }
  })

  if( selected_treatment_orientation1.length === 0 ){
    setselected_treatment_orientation1([e])
    
  }
  else{
    a.push(e)
    setselected_treatment_orientation1(a)
}
      
                  }   
                  
 const setselected_therapy_types = (e) =>{
  let a= []
  selected_therapy_types1.length > 0 && selected_therapy_types1.map((res,i)=>{
    if(res !== e){
      a.push(res)
    }
  })
 
  if( selected_therapy_types1.length === 0 ){
    setselected_therapy_types1([e])
  }else{
    a.push(e)

  setselected_therapy_types1(a)

}
   

      
                  }   



        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
      
      

      






<br />
<br />


<h1 style={{padding : "0px 10px 0px 10px",  fontSize : "35px"  }}><center>

Step 4/5: Therapy Approach

<br />
</center></h1>

<br />
                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>My Specialties:

                         </h2>





<div style={{display : "flex" , justifyContent : "center" ,margin : "30px 0px"}}>  
          <ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Addictions"/> Addictions</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="ADHD & Attention Issues"/> ADHD &amp; Attention Issues</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Adoption"/> Adoption</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Agoraphobia"/> Agoraphobia</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Alzheimer's"/> Alzheimer's</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Anger Management"/> Anger Management</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Anxiety"/> Anxiety</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Asperger's Syndrome"/> Asperger's Syndrome</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Autism"/> Autism</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Bipolar Disorder"/> Bipolar Disorder</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Borderline Personality"/> Borderline Personality</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Career Difficulties"/> Career Difficulties</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Children & Teens"/> Children &amp; Teens</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Chronic Pain"/> Chronic Pain</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Compassion Fatigue"/> Compassion Fatigue</label>
        </li>
              </ul>





              <ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Depression"/> Depression</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Divorce"/> Divorce</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Domestic Violence"/> Domestic Violence</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Eating & Food Disorders"/> Eating &amp; Food Disorders</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Family Issues"/> Family Issues</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="GAD"/> GAD</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Grief"/> Grief</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Health Anxiety"/> Health Anxiety</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Infertility"/> Infertility</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Intimacy"/> Intimacy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="LGBTQ"/> LGBTQ</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Life Changes"/> Life Changes</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Marriage Issues"/> Marriage Issues</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="OCD"/> OCD</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Panic Attack"/> Panic Attack</label>
        </li>
              </ul>


              <ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Parenting"/> Parenting</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Phobias"/> Phobias</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Postnatal Depression"/> Postnatal Depression</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Procrastination"/> Procrastination</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="PTSD"/> PTSD</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Relationship Issues"/> Relationship Issues</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Self Esteem"/> Self Esteem</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Sex & Sexuality"/> Sex &amp; Sexuality</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Sleep or Insomnia"/> Sleep or Insomnia</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Social Anxiety"/> Social Anxiety</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Speech Anxiety"/> Speech Anxiety</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Stress"/> Stress</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Trauma and/or Abuse"/> Trauma and/or Abuse</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_specialities[]" onChange={(e)=>setselected_specialities(e.target.value)} value="Weight Loss"/> Weight Loss</label>
        </li>
              </ul>

</div>
                   
                             </center>



























                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>My Treatment Orientation:



                         </h2>





<div style={{display : "flex" , justifyContent : "center" ,margin : "30px 0px"}}>  
<ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="ACT"/>  ACT</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Art Therapy"/>  Art Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Behavioral Therapy"/>  Behavioral Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Career Counseling"/>  Career Counseling</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="CAT"/>  CAT</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="CBT"/>  CBT</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Christian Counseling"/>  Christian Counseling</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Coaching"/>  Coaching</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Cognitive Therapy"/>  Cognitive Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Couples Counseling"/>  Couples Counseling</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Couples Therapy"/>  Couples Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="DBT"/>  DBT</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Drama Therapy"/>  Drama Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="EMDR"/>  EMDR</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Emotionally"/>  Emotionally</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Emotionally Focused"/>  Emotionally Focused</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Existential Therapy"/>  Existential Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Family Therapy"/>  Family Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Gestalt Therapy"/>  Gestalt Therapy</label>
        </li>
              </ul>





              <ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Group Therapy"/>  Group Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Human Givens Psychotherapy"/>  Human Givens Psychotherapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Humanistic Therapy"/>  Humanistic Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Hypnotherapy"/>  Hypnotherapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Individual Therapy"/>  Individual Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Intervention"/>  Intervention</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Jungian Therapy"/>  Jungian Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Life Coaching"/>  Life Coaching</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Mindfulness"/>  Mindfulness</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Music Therapy"/>  Music Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Play Therapy"/>  Play Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Psychoanalytic"/>  Psychoanalytic</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Psychoanalytic Therapy"/>  Psychoanalytic Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Psychodynamic Therapy"/>  Psychodynamic Therapy</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Religious Counseling"/>  Religious Counseling</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Transactional Analysis"/>  Transactional Analysis</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Transpersonal Psychology"/>  Transpersonal Psychology</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_treatment_orientation[]" onChange={(e)=>setselected_treatment_orientation(e.target.value)} value="Trauma Focused"/>  Trauma Focused</label>
        </li>
              </ul>

</div>
                   
                             </center>







                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>Therapy types that I offer:





                         </h2>





<div style={{display : "flex" , justifyContent : "center" ,margin : "30px 0px"}}>  
<ul class="list_ul" style={{padding : "10px 40px"}}>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Couples"/> Couples</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Email"/> Email</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Individual"/> Individual</label>
        </li>
              </ul>

              <ul class="list_ul" style={{padding : "10px 40px"}}> 
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Online"/> Online</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Phone"/> Phone</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Physical Facility"/> Physical Facility</label>
        </li>
              </ul>
              <ul class="list_ul" style={{padding : "10px 40px"}}> 
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Text Chat"/> Text Chat</label>
        </li>
                <li>
          <label  style={{color : "black"  ,fontWeight : "600"}} ><input type="checkbox"  name="selected_therapy_types[]" onChange={(e)=>setselected_therapy_types(e.target.value)} value="Video"/> Video</label>
        </li>
              </ul>

</div>
                   
                             </center>





<br /> 

                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>My Languages:







                         </h2>


<br />


                         <div className="container">
                         <div className="row">
                             
                             <div className="col-md-12"><div className="form-group row">
                                             <div className="col-md-6" style={{margin : "auto"}}>
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">My Language<span className="text-danger">*</span></label>
                                                 <input type="text" value={Language} onChange={(e)=>setLanguage(e.target.value)}   className="form-control" />
                                             </div>
                                            
                                         </div>
                                         </div>
                                         </div>

</div>
                   
                             </center>




                             <br />
                             <br />
                             <center>

                         <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>My countries/regions:









                         </h2>
                         <br />
<p>Licensing limitations may prevent you from offering online therapy to all states and countries. Select you countries/regions from below.

</p>

                   
                             </center>

<br />
<br />
                             {/* <center> <h2 className="text-uppercase" style={{color :  "black" , fontWeight : "400"}}>Avaialble position as in-house therapist










                         </h2>
<p>Working as an in-house therapist means that some of the clients that sign up for our online therapy services will be automatically assigned to you and you will get paid by us on commission.
<br />
Your job will be to write daily messages to your clients (Mon-Fri) + 30-45 minute weekly live sessions (video, audio or text chats).
</p>

                   
                             </center> */}





{/* <br /> */}
<br />





                     <div className="container" style={{border : "none"}}>

                         <div className="row">
                             <div className="col-md-12">
                             <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="p-3 p-lg-5 border">
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">What's your current work situation?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={currentworksituation} onChange={(e)=>setcurrentworksituation(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">How many hours per week would you estimate you would be able to work with clients from us?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={hoursperweek} onChange={(e)=>sethoursperweek(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">What hours of the day (Mon-Fri) would you prefer to be available for live sessions?<span className="text-danger">*</span></label>
                                                <br /> <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">For example:
<br />
Mon: 8am-11am
<br />
Tue-Fri: 3pm-6pm.<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={MonFriwouldyouprefer} onChange={(e)=>setMonFriwouldyouprefer(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        {/* <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">When would you be able to start working for us?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={startworkingforus} onChange={(e)=>setstartworkingforus(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div> */}
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Are you limited to only accpeting clients from your licensing states or do you also accept national as well as international (English speaking) clients?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={licensingstates} onChange={(e)=>setlicensingstates(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        {/* <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Do you have a professional malpractice liability insurance policy, minimum coverage limits of $1,000,000/$3,000,000 or similar for your country?<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={professionalmalpracticeliabilityinsurance} onChange={(e)=>setprofessionalmalpracticeliabilityinsurance(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div> */}
                                        <div className="form-group row">
                                             <div className="col-md-12">
                                                 <label  style={{color : "black"  ,fontWeight : "600"}}  htmlFor="c_fname" className="text-black">Do you have any disciplinary actions in the past 10 years<span className="text-danger">*</span></label>
                                                 <textarea rows="5" col="10" value={disciplinaryactions} onChange={(e)=>setdisciplinaryactions(e.target.value)}   className="form-control" >
                                                 </textarea>
                                            </div>
                                         </div>
                                        

                                         <div className="form-group row">
                                             <div className="col-lg-4" style={{  margin : "20px auto", }}>
                                             <button onClick={()=>SubmitDataMessage()} className="nav-link" style={{ color : "white",padding : "5px  10px" , width : "200px",background :"rgb(25 18 43)",fontWeight : "600", textAlign  : 'center', fontSize : "18px", textTransform: "uppercase"}}  type="submit">Next</button>

                                             </div>
                                         </div>
                                         {/* <div className="form-group row">
                                             <div className="col-lg-6"  style={{margin : "auto"}}>
                                                 <input className="btn btn-primary btn-lg btn-block" value="Contiune" />
                                             </div>
                                         </div> */}
                                    </div>
                                 </div>
                             </div>
                            
                         </div>
                     </div>











            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;