import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import Loader from "react-loader-spinner";

// import blockCover from '../assets/images/hero_1.jpg';
// import categ from '../assets/images/cate.jpeg';
// import women from '../assets/images/women.jpg';
// import children from '../assets/images/children.jpg';
// import men from '../assets/images/men.jpg';

// import cloth_1 from '../assets/images/cloth_1.jpg';
// import shoe_1 from '../assets/images/shoe_1.jpg';
// import cloth_2 from '../assets/images/cloth_2.jpg';
// import cloth_3 from '../assets/images/cloth_3.jpg';
// import blog_1 from '../assets/images/blog_1.jpg';
// import part_1 from '../assets/images/part1.jpg';
// import part_2 from '../assets/images/part2.jpg';

import logo from '../assets/images/logo.png';
import ban1 from '../assets/images/banner-01.jpg';
import ban00 from '../assets/images/ban-00.JPG';
import ban22 from '../assets/images/ban-2.JPG';
import ban33 from '../assets/images/ban-3.JPG';
import ban44 from '../assets/images/ban-4.JPG';
import ban55 from '../assets/images/ban-5.JPG';

import ban16 from '../assets/images/ban-16.JPG';

import ban333 from '../assets/images/ban-33.jpeg';


// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-bootstrap/Carousel';
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
   
    useEffect(() =>{

        window.scrollTo(0, 0)
        localStorage.setItem("create-user-2", JSON.stringify({
            Gender : "Male" ,
            Session :  "Voice only"
            
            })) 
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
       
        




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kmelectronics-database1.herokuapp.com/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }

   
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])



    const setChange = (e,r)=>{

        document.getElementById(e).style.background = "#18122b"
        document.getElementById(e).style.color = "white"
    
        if(JSON.parse(localStorage.getItem("create-user-1"))){
           let a = []
           let b = false
            for (let i = 0; i < JSON.parse(localStorage.getItem("create-user-1")).Category.length; i++) {
                if(JSON.parse(localStorage.getItem("create-user-1")).Category[i] === e){
                    b = true

                }   else{
                        a.push(JSON.parse(localStorage.getItem("create-user-1")).Category[i])
                }     
            }
            if( b === true){

                localStorage.setItem("create-user-1", JSON.stringify({
        
                    Category : a
                    })) 
                    document.getElementById(e).style.background = "white"
        document.getElementById(e).style.color = "#18122b"
    
                }
                else{
                    localStorage.setItem("create-user-1", JSON.stringify({
            
                        Category : [e,...JSON.parse(localStorage.getItem("create-user-1")).Category]
                        })) 
                    
            }
            }
            else{
            localStorage.setItem("create-user-1", JSON.stringify({
    
                Category : [e]
                })) 

        }
            // setTimeout(()=>{
            //     props.history.push("/signup-2")
            // },2000)
      
    }
        return (
            
            <div>
           


                <center>
   <div className="site-section site-section-sm site-blocks-1 backk" style={{background : `white`,  height : "auto" }}>
     {/* <h1 style={{fontSize : "45px",  padding  : "40px 20px 60px 20px", fontWeight :"600"}}>Experience the world class and complete therapy toolbox : */}
{/* </h1> */}

           <h3 style={{padding : "20px 10px 40px 10px",  fontSize : "40px"  ,fontWeight : "500" , color : "black"}}><center>
           What can we help you with?

</center></h3>
           <h1 style={{padding : "20px 10px 40px 10px",  fontSize : "20px"  ,fontWeight : "500" , color : "black"}}><center>
           Choose one or more areas that you are experiencing difficulties with.


</center></h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Anger Management" onClick={()=>setChange("Anger Management")}>Anger Management </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Anxiety" onClick={()=>setChange("Anxiety")}>Anxiety </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Career Counselling" onClick={()=>setChange("Career Counselling")}>Career Counselling </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Depression" onClick={()=>setChange("Depression")}>Depression </h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Family Issues" onClick={()=>setChange("Family Issues")}>Family Issues</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Social Anxiety" onClick={()=>setChange("Social Anxiety")}>Social Anxiety</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Stress" onClick={()=>setChange("Stress")}>Stress</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Panic Attacks" onClick={()=>setChange("Panic Attacks")}>Panic Attacks</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Procrastination" onClick={()=>setChange("Procrastination")}>Procrastination</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 20px"  , borderRadius :"10px",width: "223px"}} id="Sleep Issues or Insomnia" onClick={()=>setChange("Sleep Issues or Insomnia")}>Sleep Issues</h2>
                                    {/* <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 20px"  , borderRadius :"10px",width: "223px"}} id="Sleep Issues or Insomnia" onClick={()=>setChange("Sleep Issues or Insomnia")}>Sleep Issues <br /> or Insomnia</h2> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Self Esteem" onClick={()=>setChange("Self Esteem")}>Self Esteem</h2>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="" onClick={()=>setChange("")}>OCD</h2>
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Relationship Issues" onClick={()=>setChange("Relationship Issues")}>Relationship Issues</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "17px 13px"  , borderRadius :"10px" , width : "220px"}} id="Trauma and/or Abuse" onClick={()=>setChange("Trauma and/or Abuse")}>Trauma and/or Abuse</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Parenting" onClick={()=>setChange("Parenting")}>Parenting</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Weight Loss" onClick={()=>setChange("Weight Loss")}>Weight Loss</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Addictions" onClick={()=>setChange("Addictions")}>Addictions</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="ADHD & Attention Issues" onClick={()=>setChange("ADHD & Attention Issues")}>ADHD & Attention Issues</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Alzheimer's" onClick={()=>setChange("Alzheimer's")}>Alzheimer's</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Autism" onClick={()=>setChange("Autism")}>Autism</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Children & Teens" onClick={()=>setChange("Children & Teens")}>Children & Teens</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Divorce" onClick={()=>setChange("Divorce")}>Divorce</h2>
                                </div>
                            </div>
                           
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Domestic Violence" onClick={()=>setChange("Domestic Violence")}>Domestic Violence</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Eating & Food Disorders" onClick={()=>setChange("Eating & Food Disorders")}>Eating & Food Disorders</h2>
                                </div>
                            </div>
                            <div id="back-to-top1"   className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Grief" onClick={()=>setChange("Grief")}>Grief</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Marriage Issues" onClick={()=>setChange("Marriage Issues")}>Marriage Issues</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Phobias" onClick={()=>setChange("Phobias")}>Phobias</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"400",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Speech Anxiety" onClick={()=>setChange("Speech Anxiety")}>Speech Anxiety</h2>
                                </div>
                            </div>

                          
                            <div className="col-md-6 col-lg-3 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{margin : "20px 0px"}}>
                                <div className="icon align-self-start" style={{margin :"auto"}}>
                                    <h2 className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",fontSize : "14px" , color : "black"  , border : "1px solid black" , padding : "13px 33px"  , borderRadius :"10px" , width : "220px"}} id="Others" onClick={()=>setChange("Others")}>Others</h2>
                                </div>
                            </div>
                            <hr />
                        
                        </div>
                    </div>
                    {/* <br /> */}
                    <br />
                    <center>
<a className="nav-link the456 change-thres" style={{ color : "white",borderRadius:"10px", border : "1px solid #F7CF47",padding : "5px  14px" , width : "200px", margin : "20px 0px", background :"#F7CF47",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"

    // }}><Link to="/signup-2" style={{color : "white"}} > Contiune</Link></a>
     }}><Link to="/therapist/indivdiual" style={{color : "white"}} > Contiune</Link></a> 
       </center>  
                </div>


                {/* <a className="nav-link" style={{ color : "white", border : "1px solid white",padding : "15px  14px" , width : "280px", margin : "50px 0px", background :"rgb(25 18 43)",fontWeight : "600", fontSize : "18px", textTransform: "uppercase"}}><Link to="/about" style={{color : "white"}}>Let's the cast of minds begin</Link></a> */}

</center>
           





            <a href="#back-to-top1" className='back-to-top12'>↑</a>
          </div>
        )
    
}

export default Home;