import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
// import Carousel from 'react-bootstrap/Carousel';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import tb1 from '../assets/images/tb1.png';
// import tb2 from '../assets/images/tb2.png';
// import tb3 from '../assets/images/tb3.png';
// import tb4 from '../assets/images/tb4.png';
// import tb5 from '../assets/images/tb5.png';
// import tb6 from '../assets/images/tb6.png';
// import tb7 from '../assets/images/tb7.png';
// import tb8 from '../assets/images/tb8.png';
// import tb9 from '../assets/images/tb9.png';
// import tb10 from '../assets/images/tb10.png';
// import tb11 from '../assets/images/tb11.png';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';






import { ToastContainer, toast } from 'react-toastify';





import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
// import VideoChatIcon from '@material-ui/icons/VideoChat';
import VideocamIcon from '@material-ui/icons/Videocam';





class About extends Component {
    state = {
        settheripist1 : [],
        settheripist : [],
        schedulemeet : [],
        user : "",

    }
    componentDidMount(){
        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main 1")) ){
            this.props.history.push("/login")
        }
        else{
            fetch("https://asad-backend-two-lemon.vercel.app/allUser/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res5=>res5.json())
            .then(res6=>{
                console.log(res6)
                // setUserM(res6)
                localStorage.setItem("user main 1" , JSON.stringify(res6))
                localStorage.setItem("check Head 1" , JSON.stringify("Yes"))
                localStorage.removeItem("check Head")
                fetch("https://asad-backend-two-lemon.vercel.app/all-therispist",{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                })
                .then(res=>res.json())
                .then(res1=>{
                    const F = res1.filter((res3,j)=>res3._id === res6.TheripistId )
                    // settheripist(F)
                    // settheripist1(res1)
                    console.log
                    (res1)
                    this.setState({settheripist:res1})
                    this.setState({settheripist1:F})
                    let a = F[0].email.split("@")[0]
                    console.log(a)
                    this.setState({user:a})
                    fetch("https://asad-backend-two-lemon.vercel.app/all-schedule",{
                        method: "GET",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                    })
                    .then(res6=>res6.json())
                    .then(res7=>{
                        console.log(res7)
                        const Fa = res7.filter((res3,j)=>res3.Tid === F[0]._id && res3.Cid === res6._id)
                        this.setState({schedulemeet:Fa})

                })
                })
                
            })
            .catch((err)=>{
                localStorage.removeItem("user main 1")
                this.props.history.push("/login")

            })

            const inter1 = setInterval(()=>{
                if(JSON.parse(localStorage.getItem("user main therapist"))){
                  fetch("https://asad-backend-two-lemon.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                })
                .then(res5=>res5.json())
                .then(res6=>{
                  console.log(res6)
                  if(res6.Notification){
                    toast("New Message");
                    fetch("https://asad-backend-two-lemon.vercel.app/changeNoti/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                })
                .then(res5=>res5.json())
                .then(res6=>{
        
                })
                  }
          
                })
                 }
                 if(JSON.parse(localStorage.getItem("user main 1"))){
                  fetch("https://asad-backend-two-lemon.vercel.app/allUser/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } 
                })
                .then(res5=>res5.json())
                .then(res6=>{
                  if(res6.Notification){
                    toast("New Message");

                    fetch("https://asad-backend-two-lemon.vercel.app/changeNoti1/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
                      method: "POST",
                       headers :  {
                       "Content-Type" : "application/json" , 
                   } ,
                   body : JSON.stringify({
                   
                   })
                  })
                  .then(res5=>res5.json())
                  .then(res6=>{
          
                  })
                  }
          
                })
                 }
                
              },8000)
      
              return () => {
                clearInterval(inter1);
              
              } 
        }
    }

    
    setLogout = () =>{
        localStorage.removeItem("user main 1")
        this.props.history.push("/login")


    }

    render() {
        return (
            <div>

{/* <a className="nav-link the456" style={{  
       color: "black",
    // padding: "5px 14px",
    // width: "85px",
    margin: "20px 0px",
    // background: "black",
    fontWeight: "500",
    fontSize: "24px",
    textTransform: "uppercase",
    position: "absolute",
    right: "0px",
    top:"53px",
    zIndex:99999

    }}> <ExitToAppIcon  onClick={()=>this.setLogout()} style={{color :"black",fontSize: "36px",}}/> </a> */}
       {/* </center>   */}
       {/* </center>   */}















       <h3 style={{marginTop:"20px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",textTransform: "uppercase",textDecoration: "underline"}}>
                   Therapist 

</h3>

<div>
    <div style={{display:"flex",justifyContent:"center"}}>

{
    this.state.settheripist1.map((res,i)=>{
        return (
            <div className="site-blocks-1 backk" >
                        <div className="container-fuild" style={{width : "90%" , margin  : "auto" }}>
                            <div className="row">
                                <div className="col-md-6 col-lg-6 d-lg-flex mb-lg-0 pl-4" style={{    padding: "22px 10px" }} data-aos="fade-up" data-aos-delay="">
                                    <div className="icon mr-4 align-self-start">
                                        <img src={res.ProfilePhoto} alt=""  style={{width : "100px"  ,  height : "100px"  , borderRadius : "100px"}}/>
                            </div>
                                    <div className="text">
                                    <h1 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "600" , fontSize : "22px"}}>{res.Title} {res.fname} {res.lname}
    
    
    </h1>
                                    <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "14px"}}>{res.TherapistType}
    
    
    </h2>
                                    <h2 className="text-uppercase" style={{color :  "rgb(25,18,43)" , fontWeight : "400" , fontSize : "14px"}}>{res.City}, {res.Country}
    
    
    </h2>
                                                         
                                    </div>
                                </div>
                              
                              
                                
                              
                              
                            </div>
                            
    
                        </div>
                              
                       
                    </div>
        )
         
    })
    }
        
    </div>
</div>
<h3 style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",textTransform: "uppercase",textDecoration: "underline"}}>
                  Schedule 

</h3>
<p  style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "14px",textAlign:"center",color : "black" ,fontWeight:"400" }}>One live session a 45 minutes/week with me is included in your subscription. <br /> To be able to schedule a live session, you first need to reply to the worksheet: <Link to="/worksheet1/1">Getting to know you »</Link> </p>

{this.state.schedulemeet.length ?
this.state.schedulemeet.map((res,i)=>{
    return(
        <>
            <div className="container">

            <div className='row'>

            <div className="col-md-6 p-2">
                                <label className="form-label">Date </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={res.date}
                                    />
                                    </div>
                            <div className="col-md-6 p-2">
                                <label className="form-label">Timing</label>
                                <input
                                className="form-control"
                                type="text"
                                value={res.time} 
                               
                                />
                            </div>
                            {/* <div className="col-md-6 p-2">
                            <a className="nav-link the456 change-thres" style={{ color : "rgb(25, 18, 43)", border : "1px solid rgb(25, 18, 43)",padding : "5px  14px" , width : "100px", margin : "20px 0px", background :"transparent",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"

            }}><Link to="/signup-2" style={{color : "rgb(25, 18, 43)"}} > Update</Link></a>
                            </div> */}
            </div>
            </div>
<center>

<p style={{fontWeight:"600"}}>
If you don't see a time that works for you, please message your therapist to schedule a different time.
    </p>
</center>
</>
    )
})
:""

}

<h3 style={{marginTop:"80px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",textTransform: "uppercase",textDecoration: "underline"}}>
                  Room 

</h3>

<p  style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "14px",textAlign:"center",color : "black" ,fontWeight:"400" }}>Here you have finally taken a step towards a brighter tomorrow discover inner peace and emotional well-being through personalized therapy by scheduling a session. Call, chat, or video call with our compassionate therapists from the comfort of your home.</p>

<center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                      
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex" , flexDirection : "column",border : "2px solid rgb(247, 207, 71)",  width : "100px",height: "102px"  , padding : "15px 0px" , borderRadius : "15px"}}  onClick={()=>this.props.history.push("/chatting")}> 
                                    <center>
                                    <MessageIcon alt="" style={{fontSize:"45px",color : "rgb(247, 207, 71)"}} />
                                        </center> 
                                    <span className="text-uppercase the456" style={{color :  "rgb(247, 207, 71)" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Chat</span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex" , flexDirection : "column",border : "2px solid black",  width : "100px",height: "102px"  , padding : "15px 0px" , borderRadius : "15px"}}  onClick={()=>this.props.history.push("/"+this.state.user+"/audio-call")}> 
                                   <center>

                                        <CallIcon alt="" style={{fontSize:"45px",color : "rgb(25, 18, 43)"}} />
                                   </center>
                                   
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Phone</span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex" , flexDirection : "column",border : "2px solid black",  width : "100px",height: "102px"  , padding : "15px 0px" , borderRadius : "15px"}}   onClick={()=>this.props.history.push("/"+this.state.user+"/video-call")}> 
                                  <center>

                                    <VideocamIcon alt="" style={{fontSize:"45px",color : "rgb(25, 18, 43)"}} />
                                  </center>
                                   
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Video</span>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>



</center>
























{/* 
                  <h1 style={{padding : "80px 10px 30px 10px",  fontSize : "44px"  ,fontWeight : "500"}}><center>
                  Dashboard

</center></h1>

                  <h4 style={{padding : "20px 10px 0px 10px",  fontSize : "34px"  ,fontWeight : "400"}}><center>
                  YOUR THERAPY TOOLBOX:



</center></h4>
        



<center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}}  onClick={()=>this.props.history.push("/CheckTherispistProfile1")} >
                                    <span><img src={tb7} alt="" className='change-icons' style={{width : "40px" , height:  "40px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Therapist </span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"400", fontSize : "11px"}}>Your Progess </span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}} onClick={()=>this.props.history.push("/live_chat")}>
                                    <span><img src={tb10} alt="" className='change-icons' style={{width : "40px" , height:  "40px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Live sessions </span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"400", fontSize : "11px"}}>schedule a video, voice or text chat with your therapist! </span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}}  onClick={()=>this.props.history.push("/worksheet")}>
                                    <span><img src={tb9} alt="" className='change-icons' style={{width : "50px" , height:  "56px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>worksheets </span>
                                    <span className="text-uppercase the456" style={{color :  "black"  , fontWeight :"400", fontSize : "11px"}}>get your hands on worksheets for daily directions </span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}}  onClick={()=>this.props.history.push("/chatting")}> 
                                    <span><img src={tb4} alt="" className='change-icons' style={{width : "50px" , height:  "47px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Messages</span>
                                    <span className="text-uppercase the456" style={{color :  "black"  , fontWeight :"400", fontSize : "11px"}}>Check new messages from your therapist</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}} onClick={()=>this.props.history.push("/activity_plan")}>
                                    <span><img src={tb2} alt="" className='change-icons' style={{width : "50px" , height:  "55px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Activity plan</span>
                                    <span className="text-uppercase the456" style={{color :  "black"  , fontWeight :"400", fontSize : "11px"}}>do the things that move you forward</span>
                                </div>
                            </div>  <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="" style={{padding : "30px",}}>
                                <div className="icon mr-4 change-icons1" style={{display : "flex" , flexDirection : "column",height : "200px",border : "1px solid black",  width : "170px" , padding : "20px 0px" , borderRadius : "5px"}}>
                                    <span><img src={tb11} alt="" className='change-icons' style={{width : "40px" , height:  "40px"}} /></span>
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600", fontSize : "16px"}}>Mindfullness</span>
                                    <span className="text-uppercase the456" style={{color :  "black"  , fontWeight :"400", fontSize : "11px"}}>start working your way through mindfulness</span>
                                </div>
                            </div>
                            </div>
                          
                    </div>
                </div>



</center> */}







            
          </div>
        )
    }
}

export default About;
