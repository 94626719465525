import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';
import  "../../src/style23.css"

import "react-multi-carousel/lib/styles.css";
import SendIcon from '@material-ui/icons/Send';

// import logo from './images/img1.jpg'
import x1 from '../assets/images/x1.jpg'

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ClearIcon from '@material-ui/icons/Clear';

import { ToastContainer, toast } from 'react-toastify';





export function SubCategoriesProductDetails(props) {
    const [UserM , setUserM] = useState({})
    const [message , setmessage] = useState("")
    const [allmessage , setallmessage] = useState([])
    const [allshowmessage , setallshowmessage] = useState([])
    const [allvendor , setallvendor] = useState([])
    const [onevendor , setonevendor] = useState([])
    const [dis123 , setdis123] = useState(false)
    const [dis1 , setdis1] = useState(true)
    // const [dis1 , setdis1] = useState(true)
    const [photo, setphoto] = useState("") 


    useEffect(() =>{
      window.scrollTo(0, 0)

if(JSON.parse( localStorage.getItem("user main 1"))){
    fetch("https://asad-backend-two-lemon.vercel.app/allUser/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res5=>res5.json())
.then(res6=>{
    console.log(res6)
    setUserM(res6)
    localStorage.setItem("user main 1" , JSON.stringify(res6))
 
    fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{
  console.log(res6,res16)
    const A = []
    const A1 = []
        // res6.Chat.map((res8,i)=>{
            res16.map((res9,j)=>{
              // console.log(res8,res9)
                if(res6.TheripistId === res9._id){
                 A.push(res9)
                }
            })
          // })
          
          console.log(A)
          setallvendor(A)
          if(A.length > 0)
          setonevendor([A[0]])
        console.log(A[0])
          if(A.length === 0){
          //   if(res8.TheripistId === res9._id){
          //     A.push(res9)
          //    }
            // if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater ){
            //   console.log(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater )
            //   res16.map((res9,j)=>{
            //     if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater === res9._id){
            //       A.push(res9)
            //     }
            //   })
            //   setallvendor(A)
            //   setonevendor([A[0]])
            //   console.log(A)
            // }
        }
})
// fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
//   method: "GET",
//    headers :  {
//    "Content-Type" : "application/json" , 
// } ,
// })
// .then(res151=>res151.json())
// .then(res161=>{
//   res161.map((res9,j)=>{
// if(JSON.parse( localStorage.getItem("Details Product"))[0].ProductCreater === res9._id)
// setonevendor([res9])
// })
// })
// const inter = setInterval(()=>{

      fetch("https://asad-backend-two-lemon.vercel.app/AllUserShowMessage",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res=>res.json())
        .then(res1=>{
            setallmessage(res1)
            console.log(res1)
         const Chat1 = res1.filter((user,j)=>{
            //  if(JSON.parse( localStorage.getItem("Details Product"))[0]._id){
                 if(user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id && user.TheripistId === onevendor._id){
                    return user
                 }
            //  }
            //  else{
            //      if( user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id)  {
                    
            //         return user
            //     }
            //  }
                
                })
                console.log(Chat1)
            setallshowmessage(Chat1)
        })
})

// },4000)
        
// return () => clearInterval(inter);
        
      }
      else{
        props.history.push("/login")
      }
      
      
      

//         document.getElementById("fgjkln").style.display = "block"
//         document.getElementById("eefjksefjksf").style.display = "block"
const inter1 = setInterval(()=>{
 
   
   if(JSON.parse(localStorage.getItem("user main 1"))){
    fetch("https://asad-backend-two-lemon.vercel.app/allUser/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   } 
  })
  .then(res5=>res5.json())
  .then(res6=>{
    console.log(res6)
    if(res6.Notification){
      toast("New Message");
console.log("fdssssssssssssssss")
      fetch("https://asad-backend-two-lemon.vercel.app/changeNoti1/"+JSON.parse(localStorage.getItem("user main 1"))._id,{
        method: "POST",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
     body : JSON.stringify({
                   
     })
    })
    .then(res15=>res15.json())
    .then(res16=>{

    })
    }

  })
   }
  
},7000)

const inter = setInterval(()=>{

  fetch("https://asad-backend-two-lemon.vercel.app/AllUserShowMessage",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res=>res.json())
    .then(res1=>{
        setallmessage(res1)
        console.log(res1)
     const Chat1 = res1.filter((user,j)=>{
        //  if(JSON.parse( localStorage.getItem("Details Product"))[0]._id){
             if(user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id && user.TheripistId === onevendor._id){
                return user
             }
        //  }
        //  else{
        //      if( user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id)  {
                
        //         return user
        //     }
        //  }
            
            })
            console.log(Chat1)
        setallshowmessage(Chat1)
    })
  },5000)
        
  return () => {
    clearInterval(inter);
    clearInterval(inter1);
  
  }          

    },[]) 

    const setMess1 = ( e) =>{
      e.preventDefault()
      if(dis1){

        setdis1(false)
        console.log(message === "")
        if(message === ""){
          if(photo === ""){
            setdis1(true)

            swal("Type a Message");
            return
          }
          else{

            const data = new FormData()
            data.append("file", photo)
            data.append("upload_preset", "castofminds")
            data.append("cloud_name", "dskcqccr4")
            fetch("https://api.cloudinary.com/v1_1/dskcqccr4/image/upload",{ 
                method : "POST",
                body : data , 
            })
            .then(res=>res.json())
            .then((res2)=>{

        let date = new Date()
        let hour= date.getHours()
        let minnte= date.getMinutes()


        if(date.getHours() < 10) hour = "0"+ date.getHours()
        if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())

        let c =hour+ ":"+minnte
        console.log(hour+ ":"+minnte,c, )

        const msgDetails = {
            message : message , 
            user_id :JSON.parse( localStorage.getItem("user main 1"))._id ,
            vendor_id :JSON.parse( localStorage.getItem("user main 1")).TheripistId ,
            // product_id :JSON.parse( localStorage.getItem("Details Product"))[0]._id ,
            user : true,
            photo  :res2.url ? res2.url : "", 

            vendor : false,
            time : c

        }
        console.log(msgDetails)
        let v = 0

        UserM.Chat.map((res,i)=>{
            if(onevendor[0]._id ===  msgDetails.vendor_id ){
                v = v + 0
              }
              else{
                v = v + 1
              }
            })
            console.log(UserM , v)
        let m = 0
            console.log(onevendor)
            onevendor[0]&& onevendor[0].ChatName && onevendor[0].ChatName.map((res,i)=>{
          console.log(res.product_id , msgDetails.product_id  , res.user_id ,  msgDetails.user_id)
            if(res.user_id ===  msgDetails.user_id){
               console.log("0")
               m = m + 0
              }
              else{
                console.log("1")
                m = m + 1
              }
            })
            console.log(m , onevendor[0].ChatName.length)
        if(onevendor[0].ChatName.length){
            if(m === onevendor[0].ChatName.length){
              console.log("fdfdsfsf1")

                fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        if(res8.Error){
            console.log("Message")
        }
    })
  }
  else{
    console.log("fdfdsfsf2")

          
                          fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
                  method: "POST",
                  headers :  {
                      "Content-Type" : "application/json" , 
                  } ,
                  body : JSON.stringify({
                     ...msgDetails
                  }),
              })
              .then(res5=>res5.json())
              .then((res8)=>{ 
                  if(res8.Error){
                      console.log("Message")
                  }
              })

        }
        
}   
        if(onevendor[0].ChatName){
        if(onevendor[0].ChatName.length === 0){
          console.log("fdfdsfsf3")
            fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        if(res8.Error){
            console.log("Message")

        }
    })
        }
        }



        if(v === UserM.Chat.length  && UserM.Chat.length !== 0){

                fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
      console.log(res8)
        if(res8.Error){
            console.log("Message")
        }
        else{
          const fg = res8.message.map((res78,y)=>{
            if(y = 0 ) return {
              ...res78 
            }
            else return res78
          })
            setUserM({
              ...UserM ,
              Chat : fg
            })
            localStorage.setItem("user main 1" , JSON.stringify({
              ...UserM ,
              Chat : fg
            }))

            fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{
    const A = []
            res16.map((res9,j)=>{
                if(msgDetails.vendor_id === res9._id){
                  A.push(res9)
                }
            })
     setallvendor([...A,...allvendor])
     if(!dis123)  setdis123(true)
     else setdis123(false)
})
        }
    })
} 



































else{
        if(UserM.Chat.length > 1)  {
      fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
        method: "POST",
        headers :  {
        "Content-Type" : "application/json" , 
      } ,
      body : JSON.stringify({
        ...msgDetails
        }),
      })
      .then(res5=>res5.json())
      .then((res8)=>{ 
        if(res8.message){
          const fg = res8.message.map((res78,y)=>{
            if(y = 0 ) return {
              ...res78 , new : false
            }
            else return res78
          })
let D = []
let S = []
          UserM.Chat.map((res9,c)=>{
  if(res9.vendor_id !== msgDetails.vendor_id ||  JSON.parse( localStorage.getItem("user main 1"))._id !== msgDetails.user_id ){
    D.push(res9)
  }
  else{
    S.push({...res9 , new : false})
  }
})
console.log(D)
const f = [...S , ...D]
fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
  method: "GET",
   headers :  {
   "Content-Type" : "application/json" , 
} ,
})
.then(res15=>res15.json())
.then(res16=>{
  const A = []
  f.map((res56, h)=>{
    res16.map((res9,j)=>{
      if(res56.vendor_id === res9._id){
       A.push(res9)
      }
  })
  })
  console.log(allvendor,A ,onevendor ,A[0],f)
  // setallvendor(A)
  // setonevendor([A[0]])
  
  

  
  setUserM({
    ...UserM ,
    Chat : f
  // }

})
  localStorage.setItem("user main 1" , JSON.stringify({
    ...UserM ,
    Chat : f
  // }

}))
  
  
  console.log(f,S,A)
  const fas = [...allmessage , msgDetails]
  const Chat1 = fas.filter((user,j)=>{
    if( user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id )  {
        return user
    }
    })
    console.log(Chat1)
    fetch("https://asad-backend-two-lemon.vercel.app/AllProduct1/"+ S[0].product_id,{
        method: "GET",
        headers :  {
            "Content-Type" : "application/json" , 
        }
    })
    .then(res=>res.json())
    .then((res2)=>{ 
        localStorage.setItem("Details Product" , JSON.stringify(res2))
    })
// setallshowmessage(Chat1)

setallshowmessage(Chat1)












})

          fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res15=>res15.json())
.then(res16=>{


})
        }
      })

    }
    }




































        if(UserM.Chat.length === 0){
            fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchanger",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
        // localStorage.setItem("user main 1" , JSON.stringify(
        //   {
        //     ...JSON.parse( localStorage.getItem("user main 1")) ,
            setUserM({
              ...UserM ,
              Chat : [{  
                vendor_id : JSON.parse( localStorage.getItem("user main 1")).TheripistId,        
                }]
            // }

          })
        // setallshowmessage([...allshowmessage , msgDetails])

        // ))
    //     fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
    //       method: "GET",
    //        headers :  {
    //        "Content-Type" : "application/json" , 
    //    } ,
    //   })
    //   .then(res15=>res15.json())
    //   .then(res16=>{
    //     setonevendor(res16)
    //   })
    //     if(res8.Error){
    //         console.log("Message")
    //     }
    })
        }
       
        fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           ...msgDetails
        }),
    })
    .then(res=>res.json())
    .then((res2)=>{ 
        console.log(res2)       
        if(res2){
            setallshowmessage([...allshowmessage , msgDetails])
            setallmessage([...allmessage , msgDetails])
            setmessage("")
            setphoto("")
            setdis1(true)

            // swal("Successfully Submit your Review!");
        }
        else{
            swal(res2.Error);
        }
        console.log(res2)       
    })
    .catch(err=>{  setdis1(true)

    console.log(err)}
    )

    })
}

  
  } 
  else{

    
    let date = new Date()
    let hour= date.getHours()
    let minnte= date.getMinutes()


    if(date.getHours() < 10) hour = "0"+ date.getHours()
    if(date.getMinutes() < 10) minnte = "0"+ (date.getMinutes())

    let c =hour+ ":"+minnte
    console.log(hour+ ":"+minnte,c, )

    const msgDetails = {
        message : message , 
        user_id :JSON.parse( localStorage.getItem("user main 1"))._id ,
        vendor_id :JSON.parse( localStorage.getItem("user main 1")).TheripistId ,
        // product_id :JSON.parse( localStorage.getItem("Details Product"))[0]._id ,
        user : true,
        photo  :"", 

        vendor : false,
        time : c

    }
    console.log(msgDetails)
    let v = 0

    UserM.Chat.map((res,i)=>{
        if(onevendor[0]._id ===  msgDetails.vendor_id ){
            v = v + 0
          }
          else{
            v = v + 1
          }
        })
        console.log(UserM , v)
    let m = 0
        console.log(onevendor)
        onevendor[0]&& onevendor[0].ChatName && onevendor[0].ChatName.map((res,i)=>{
      console.log(res.product_id , msgDetails.product_id  , res.user_id ,  msgDetails.user_id)
        if(res.user_id ===  msgDetails.user_id){
           console.log("0")
           m = m + 0
          }
          else{
            console.log("1")
            m = m + 1
          }
        })
        console.log(m , onevendor[0].ChatName.length)
    if(onevendor[0].ChatName.length){
        if(m === onevendor[0].ChatName.length){
          console.log("fdfdsfsf1")

            fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
       ...msgDetails
    }),
})
.then(res5=>res5.json())
.then((res8)=>{ 
    if(res8.Error){
        console.log("Message")
    }
})
}
else{
console.log("fdfdsfsf2")

      
                      fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger1",{
              method: "POST",
              headers :  {
                  "Content-Type" : "application/json" , 
              } ,
              body : JSON.stringify({
                 ...msgDetails
              }),
          })
          .then(res5=>res5.json())
          .then((res8)=>{ 
              if(res8.Error){
                  console.log("Message")
              }
          })

    }
    
}   
    if(onevendor[0].ChatName){
    if(onevendor[0].ChatName.length === 0){
      console.log("fdfdsfsf3")
        fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
       ...msgDetails
    }),
})
.then(res5=>res5.json())
.then((res8)=>{ 
    if(res8.Error){
        console.log("Message")

    }
})
    }
    }



    if(v === UserM.Chat.length  && UserM.Chat.length !== 0){

            fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchanger",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
       ...msgDetails
    }),
})
.then(res5=>res5.json())
.then((res8)=>{ 
  console.log(res8)
    if(res8.Error){
        console.log("Message")
    }
    else{
      const fg = res8.message.map((res78,y)=>{
        if(y = 0 ) return {
          ...res78 
        }
        else return res78
      })
        setUserM({
          ...UserM ,
          Chat : fg
        })
        localStorage.setItem("user main 1" , JSON.stringify({
          ...UserM ,
          Chat : fg
        }))

        fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
method: "GET",
 headers :  {
 "Content-Type" : "application/json" , 
} ,
})
.then(res15=>res15.json())
.then(res16=>{
const A = []
        res16.map((res9,j)=>{
            if(msgDetails.vendor_id === res9._id){
              A.push(res9)
            }
        })
 setallvendor([...A,...allvendor])
 if(!dis123)  setdis123(true)
 else setdis123(false)
})
    }
})
} 



































else{
    if(UserM.Chat.length > 1)  {
  fetch("https://asad-backend-two-lemon.vercel.app/newvendorchanger2",{
    method: "POST",
    headers :  {
    "Content-Type" : "application/json" , 
  } ,
  body : JSON.stringify({
    ...msgDetails
    }),
  })
  .then(res5=>res5.json())
  .then((res8)=>{ 
    if(res8.message){
      const fg = res8.message.map((res78,y)=>{
        if(y = 0 ) return {
          ...res78 , new : false
        }
        else return res78
      })
let D = []
let S = []
      UserM.Chat.map((res9,c)=>{
if(res9.vendor_id !== msgDetails.vendor_id ||  JSON.parse( localStorage.getItem("user main 1"))._id !== msgDetails.user_id ){
D.push(res9)
}
else{
S.push({...res9 , new : false})
}
})
console.log(D)
const f = [...S , ...D]
fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
method: "GET",
headers :  {
"Content-Type" : "application/json" , 
} ,
})
.then(res15=>res15.json())
.then(res16=>{
const A = []
f.map((res56, h)=>{
res16.map((res9,j)=>{
  if(res56.vendor_id === res9._id){
   A.push(res9)
  }
})
})
console.log(allvendor,A ,onevendor ,A[0],f)
// setallvendor(A)
// setonevendor([A[0]])




setUserM({
...UserM ,
Chat : f
// }

})
localStorage.setItem("user main 1" , JSON.stringify({
...UserM ,
Chat : f
// }

}))


console.log(f,S,A)
const fas = [...allmessage , msgDetails]
const Chat1 = fas.filter((user,j)=>{
if( user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id )  {
    return user
}
})
console.log(Chat1)
fetch("https://asad-backend-two-lemon.vercel.app/AllProduct1/"+ S[0].product_id,{
    method: "GET",
    headers :  {
        "Content-Type" : "application/json" , 
    }
})
.then(res=>res.json())
.then((res2)=>{ 
    localStorage.setItem("Details Product" , JSON.stringify(res2))
})
// setallshowmessage(Chat1)

setallshowmessage(Chat1)












})

      fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
method: "GET",
 headers :  {
 "Content-Type" : "application/json" , 
} ,
})
.then(res15=>res15.json())
.then(res16=>{


})
    }
  })

}
}




































    if(UserM.Chat.length === 0){
        fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchanger",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
       ...msgDetails
    }),
})
.then(res5=>res5.json())
.then((res8)=>{ 
    // localStorage.setItem("user main 1" , JSON.stringify(
    //   {
    //     ...JSON.parse( localStorage.getItem("user main 1")) ,
        setUserM({
          ...UserM ,
          Chat : [{  
            vendor_id : JSON.parse( localStorage.getItem("user main 1")).TheripistId,        
            }]
        // }

      })
    // setallshowmessage([...allshowmessage , msgDetails])

    // ))
//     fetch("https://asad-backend-two-lemon.vercel.app/allUserVender34534",{
//       method: "GET",
//        headers :  {
//        "Content-Type" : "application/json" , 
//    } ,
//   })
//   .then(res15=>res15.json())
//   .then(res16=>{
//     setonevendor(res16)
//   })
//     if(res8.Error){
//         console.log("Message")
//     }
})
    }
   
    fetch("https://asad-backend-two-lemon.vercel.app/chatcreateuser",{
    method: "POST",
    headers :  {
        "Content-Type" : "application/json" , 
    } ,
    body : JSON.stringify({
       ...msgDetails
    }),
})
.then(res=>res.json())
.then((res2)=>{ 
    console.log(res2)       
    if(res2){
        setallshowmessage([...allshowmessage , msgDetails])
        setallmessage([...allmessage , msgDetails])
        setmessage("")
        setphoto("")
        setdis1(true)

        // swal("Successfully Submit your Review!");
    }
    else{
        swal(res2.Error);
    }
    console.log(res2)       
})
.catch(err=>{  setdis1(true)

console.log(err)}
)
  }
  }
  
  }
const setallchanger = (onevenderdetail,i)=>{
    console.log(allmessage,UserM , i, onevenderdetail)
    setonevendor([onevenderdetail])
    const Chat1 = allmessage.filter((user,j)=>{
        if( user.user_id === JSON.parse( localStorage.getItem("user main 1"))._id )  {
            return user
        }
        })

        console.log(Chat1)
        // fetch("https://asad-backend-two-lemon.vercel.app/AllProduct1/"+ UserM.Chat[i].product_id,{
        //     method: "GET",
        //     headers :  {
        //         "Content-Type" : "application/json" , 
        //     }
        // })
        // .then(res=>res.json())
        // .then((res2)=>{ 
        //     localStorage.setItem("Details Product" , JSON.stringify(res2))
        // })
    setallshowmessage(Chat1)




    const fg = UserM.Chat.map((res78,y)=>{
      if(y === i ){
         return {
        ...res78 , new : false
      }
      }
      else return res78
    })
    setUserM({
      ...UserM ,
      Chat : fg
    })
    localStorage.setItem("user main 1" , JSON.stringify({
      ...UserM ,
      Chat : fg
    })) 

    fetch("https://asad-backend-two-lemon.vercel.app/newuservendorchangerinClick",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
           k :  fg , user_id :  JSON.parse( localStorage.getItem("user main 1"))._id
        }),
    })
    .then(res5=>res5.json())
    .then((res8)=>{ 
      console.log(res8)
          })









}
const setphoto1 = (e)=>{
  console.log(e)
 setphoto(e.target.files[0]) 
//  setphoto2(e.target.files[0]) 
  
}
    return (
        <>  
        <div className="fgjgfgjkjl">
             <div className="container container4994" style={{padding :  "0px"}}>
        {/* <div className="leftSide rtrtrtt">
            <div className="header">
                <div>
                <h5>All Vendor</h5>
                </div>
              
              </div>
             
              <div className="chatlist">
               
              {allvendor.map((res,i)=>{
                    return(
                     <div className="block" onClick={()=>setallchanger(res,i)}>
                        <div className="imgbx">
                          <img src={x1}  alt="imagem usuário" className="cover" />
                        </div>
                        <div className="details">

                     
                     
                     
                     
                     
                     
                     
                     
                     
                      {UserM.Chat[i].new && UserM.Chat[i].new ? 
                          <div className="listHead" style={{flexDirection : "column"}}>
                            <h4>{res.Name}</h4>

                        <p  style={{color : "black" , fontWeight : "bold" , fontSize : "10px",}}>New message .</p>
                          </div>
                        
                        :
                        <div className="listHead">
                            <h4>{res.Name}</h4>

                          </div>
                        
                      } 











                        </div>
                          
                          
                          
                          
                           {UserM.Chat[i].new && UserM.Chat[i].new ? 
                            <p className="time" style={{color : "green" , fontWeight : "bold" , fontSize : "20px"}}>◍</p>
                            :
                            ""
                            
                          }
                        










                      </div>
                        
                    )
                })
                    
                }
              
              </div>
        </div> */}

   




        <div className="rightSide">
          <div className="header">

            {
            onevendor.length > 0 ? onevendor.map((res,i)=>{
                    return(
                
                
                <div className="imgText">
              <div className="userimg">
                <img src={res.ProfilePhoto} alt="Foto Usuário" className="cover" />
              </div>
              <h6 style={{paddingLeft : "20px", color  :"white",marginTop: "13px"}}> <b>{res.fname} {res.lname}</b>  <br /> 
              </h6>

            </div>
              )
            })
               : "" 
            }
    
            {/* <ul className="nav_icons">
              <li>
                <ion-icon name="search-outline" />
              </li>
              <li>
                <ion-icon name="ellipsis-vertical" />
              </li>
            </ul> */}
          </div>
          {/* chatbox */}
          <div className="chatBox">
              {allshowmessage.map((res,i)=>{
                //   if(res.product_id === JSON.parse( localStorage.getItem("Details Product"))[0]._id){
                      if(res.user){
                        if(res.message) {

                      return(
                        <div className="message my_message">
                                <p>
                                <img src={UserM && UserM.ProfilePhoto ? UserM.ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginRight:"15px"}}/>
                                  {res.message}   {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}
                                {/* <span>12:15</span> */}
                                </p>
                        </div>

                  ) 
                      }
                      else{

                        return(
                          <div className="message my_message">
                                  <p>
                                  <img src={UserM && UserM.ProfilePhoto ? UserM.ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginRight:"15px"}}/>
                                  <img src={res.photo} alt="" className="change-mob-pho"  style={{maxWidth: "350px",
    maxHeight: "450px",
    objectFit: "cover",
}}/>   {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}
                                  {/* <span>12:15</span> */}
                                  </p>
                          </div>
  
                    ) 
                      }



                  }
                  else{
                    if(res.message) {

                    return(
                        <div className="message frnd_message">
                            <p>

                              {res.message}
                            <img src={onevendor[0] && onevendor[0].ProfilePhoto ? onevendor[0].ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginLeft:"15px"}}/>
                            {/* <span>16:15</span> */}
                            {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}

                            </p>
                        </div>
                    )
                    }
                    else{
                      return(
                        <div className="message frnd_message">
                            <p>

                            <img src={res.photo} alt="" className="change-mob-pho"  style={{maxWidth: "350px",
    maxHeight: "450px",
    objectFit: "cover",
}}/>
                            <img src={onevendor[0] && onevendor[0].ProfilePhoto ? onevendor[0].ProfilePhoto : x1} alt="" style={{width:"40px", heighT:"40px", borderRadius:"40px",marginLeft:"15px"}}/>
                            {/* <span>16:15</span> */}
                            {res.time  ? <span style={{color : "white",fontWeight:"800",fontSize:"12px",margin:"0"}}>{res.time}</span>:""}

                            </p>
                        </div>
                    )
                    }
                  }
                //   }
                  
                 
              })

              }
            {/* <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message my_message">
              <p>Hi<br /><span>12:15</span></p>
            </div>
            <div className="message frnd_message">
              <p>Helloooo, How are you ?<br /><span>16:15</span></p>
            </div> */}
          </div>
          {/* chat input */}
          
          <form onSubmit={(e)=>{setMess1(e)}}>
          <div className="chatbox_input">
          <div class="fab_field" style={{minWidth:"90%"}}>
  
<div style={{display : "flex",minWidth:"100%"}}>
    {/* <a id="fab_send" class="fab"  onClick={()=>setMess1()}><SendIcon/></a> */}
    <a id="fab_camera" class="fab"><PhotoCameraIcon for="files" style={{marginTop:"11px" , marginRight:"5px"}}/>  <input type="file" id="files" style={{opacity : 0 ,width: "29px",position: "absolute",zIndex: 1,left: '4px'}}  onChange={(e)=>setphoto1(e)} /></a>
    {/* <textarea id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message" value={photo.name}  style={{width : "90%"}}></textarea> */}
    {photo ? 
 <>
  <input type="text"  id="chatSend" value={photo.name}   />

  <ClearIcon style={{marginTop : "10px"}}  onClick={()=>{
setphoto("")

}}/>

</>
:
<input type="text"  id="chatSend"  placeholder="Type a message ..." value={message} onChange={(e)=>setmessage(e.target.value)} />

}
</div>
{/* <textarea id="chatSend" name="chat_message" placeholder="Send a message" class="chat_field chat_message" value={message} onChange={(e)=>setmessage(e.target.value)}  ></textarea> */}
  </div>
            {/* <input type="text" placeholder="Type a message ..." value={message} onChange={(e)=>setmessage(e.target.value)}  /> */}
            <button type="submit" style={{border:"none",background:"transparent"}}><SendIcon name="paper-plane-outline"/></button>
          </div>
        </form>
        </div>
      </div> 
        </div>
            
        </>
    )
}

export default SubCategoriesProductDetails;
