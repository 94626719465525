import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import swal from 'sweetalert';

// import Loader from "react-loader-spinner";

import "react-multi-carousel/lib/styles.css";




const Home = ( props ) => {

    const [data , setdata] = useState([])
   


    const [val, setval] = useState("") 
    const [val1, setval1] = useState("") 
    const [val2, setval2] = useState("") 
    const [val3, setval3] = useState("") 
    const [val4, setval4] = useState("") 
    const [val5, setval5] = useState("") 
    const [val6, setval6] = useState("") 
    const [val7, setval7] = useState("") 
    const [val8, setval8] = useState("") 
    const [val9, setval9] = useState("") 
    const [val10, setval10] = useState("") 
    const [val11, setval11] = useState("") 
    const [val12, setval12] = useState("") 
    const [val13, setval13] = useState("") 
    const [val14, setval14] = useState("") 
    const [val15, setval15] = useState("") 
    const [val16, setval16] = useState("") 
    const [val17, setval17] = useState("") 
    const [val18, setval18] = useState("") 
    const [val19, setval19] = useState("") 
    const [val20, setval20] = useState("") 
    const [val21, setval21] = useState("") 
    const [val22, setval22] = useState("") 
    const [val23, setval23] = useState("") 
    const [val24, setval24] = useState("") 
    const [val25, setval25] = useState("") 
    const [val26, setval26] = useState("") 
    const [val27, setval27] = useState("") 
    const [val28, setval28] = useState("") 
    const [val29, setval29] = useState("") 
    
    useEffect(() =>{

        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main therapist")) ){
        props.history.push("/login")
        if (! JSON.parse(localStorage.getItem("For Detail"))){
            props.history.push("/therapist/dashboard")
        }
    }else{
        // setdata(JSON.parse(localStorage.getItem("user main therapist")) )
        fetch("https://asad-backend-two.vercel.app/all-journals",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            console.log(res6)
            let A = []
            res6.map((res1,i)=>{
                if(res1.id ===JSON.parse(localStorage.getItem("For Detail"))._id && res1.Tid ===JSON.parse(localStorage.getItem("user main therapist"))._id){
                    A.push(res1)
                }
            })
            // setdata(A)
            if(A[0]){

                setval(A[0].val) 
                setval1(A[0].val1) 
                setval2(A[0].val2) 
                setval3(A[0].val3) 
                setval4(A[0].val4) 
                setval5(A[0].val5) 
                setval6(A[0].val6) 
                setval7(A[0].val7) 
                setval8(A[0].val8) 
                setval9(A[0].val9) 
                setval10(A[0].val10) 
                setval11(A[0].val11) 
                setval12(A[0].val12) 
                setval13(A[0].val13) 
                setval14(A[0].val14) 
                setval15(A[0].val15) 
                setval16(A[0].val16) 
                setval17(A[0].val17) 
                setval18(A[0].val18) 
                setval19(A[0].val19) 
                setval20(A[0].val20) 
                setval21(A[0].val21) 
                setval22(A[0].val22) 
                setval23(A[0].val23) 
                setval24(A[0].val24) 
                setval25(A[0].val25) 
                setval26(A[0].val26) 
                setval27(A[0].val27) 
                setval28(A[0].val28) 
                setval29(A[0].val29) 
            // setselected_specialities1(A[0].selected_specialities1)
            // setval(A[0].val)
            // settrytosolvetheproblemandwithwhatsuccesses(A[0].trytosolvetheproblemandwithwhatsuccesses)
            // setissuesbeenexperienced(A[0].issuesbeenexperienced)
            // setPriortreatmenthistory(A[0].Priortreatmenthistory)
            // setWorkhistory(A[0].Workhistory)
            // setEducationalhistory(A[0].Educationalhistory)
            // setDevelopmentalhistory(A[0].Developmentalhistory)
            // setFamilyhistory(A[0].Familyhistory)
            // setMedicalhistory(A[0].Medicalhistory)

            }
        })

        
        fetch("https://asad-backend-two.vercel.app/all-therispist",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         }
        })
        .then(res5=>res5.json())
        .then(res6=>{
            console.log(res6)
            let A = []
            res6.map((res1,i)=>{
                if(res1._id ===JSON.parse(localStorage.getItem("user main therapist")).TheripistId){
                    A.push(res1)
                }
            })
            setdata(A)
        })
    }
       

        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
 
 },[])

 const Submitdata = (e)=>{
    e.preventDefault()

    fetch("https://asad-backend-two.vercel.app/create-journals1",{
        method: 'POST' , 
        headers :  {
          "Content-Type" : "application/json" , 
        } , 
        body : JSON.stringify({
            id : JSON.parse(localStorage.getItem("user main 1"))._id,
            Tid : JSON.parse(localStorage.getItem("user main 1")).TheripistId,
            val,
            val1,
            val2,
            val3,
            val4,
            val5,
            val6,
            val7,
            val8,
            val9,
            val10,
            val11,
            val12,
            val13,
            val14,
            val15,
            val16,
            val17,
            val18,
            val19,
            val20,
            val21,
            val22,
            val23,
            val24,
            val25,
            val26,
            val27,
            val28,
            val29,
        })
      })
      .then((res)=>res.json())
      .then((res2)  =>{
          console.log(res2)
          if (res2){
            swal(" Save "  )
            
          }
          else if (res2.Error){
            swal(res2.Error )
          }
          else{
            swal("Plz Try Again !"  )
          }
        // console.log(res2)
      })
    
}

        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
         

        <h1 style={{padding : "80px 10px 10px 10px",  fontSize : "38px"  ,fontWeight : "500",textTransform:"uppercase"}}><center>
   My Journal

</center></h1>
{/* <br/> */}
    <p style={{padding : "20px 10px 0px 10px",  fontSize : "14px"  ,fontWeight : "400" , color : "black"}}><center>
    At Cast of Minds, we believe in the transformative power of journaling. It serves as a sanctuary for self-expression, offering a therapeutic outlet to process emotions and untangle complex thoughts. Through journaling, individuals can gain deeper insights into their inner world, foster personal growth, and embark on a journey towards holistic well-being</center></p>

<br/>



 <div className="container ejkfwefjk" style={{width : "90%"  ,margin : "auto"}}>
                        <div className="row">
                            {/* <div className="col-md-12">
                                <h2 className="h3 mb-3 text-black">Join the team</h2>
                            </div> */}
                            <div className="col-md-12">

                                <form onSubmit={(e)=>Submitdata(e)}>

                                    <div className="p-3 p-lg-5 border">
                                       
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">1. Describe a moment today when you felt proud of yourself. How can you bring more of such
moments into your life? </label>
                                                <textarea value={val} onChange={(e)=>setval(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">2. Reflect on a small victory this week and how it made you feel. Why do you think it impacted
you the way it did?</label>
                                                <textarea value={val1} onChange={(e)=>setval1(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">3. Write about a time you overcame a challenge by changing your perspective. How can you
apply this lesson to current challenges?</label>
                                                <textarea value={val2} onChange={(e)=>setval2(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">4. List three things you're grateful for today and explain why each one matters to you. ? </label>
                                                <textarea value={val3} onChange={(e)=>setval3(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">5. Think of a place that makes you feel peaceful. Describe it in detail. How can you bring
elements of this peace into your daily environment? </label>
                                                <textarea value={val4} onChange={(e)=>setval4(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">6. Recall a compliment you received that made you feel good. Why do you think it stayed with
 </label>
                                                <textarea value={val5} onChange={(e)=>setval5(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">7. Imagine your ideal day. What does it look like, and what steps can you take to make it a
reality?</label>
                                                <textarea value={val6} onChange={(e)=>setval6(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">8. Describe a hobby or activity that brings you joy. How does it make you feel, and why is it
important to you?</label>
                                                <textarea value={val7} onChange={(e)=>setval7(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">9. Write about a goal you achieved that once seemed impossible. What did you learn about
yourself through the process?</label>
                                                <textarea value={val8} onChange={(e)=>setval8(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">10. Reflect on a book or movie that changed your outlook on life. What lessons did you take
away from it?</label>
                                                <textarea value={val9} onChange={(e)=>setval9(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">11. List five qualities you like about yourself and how they have helped you in relationships with
others.?</label>
                                                <textarea value={val10} onChange={(e)=>setval10(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">12. Think of someone who inspires you. What qualities do they possess that you admire?
</label>
                                                <textarea value={val11} onChange={(e)=>setval11(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">13. Describe a moment when you felt in tune with your surroundings. How can you create more
moments like that?</label>
                                                <textarea value={val12} onChange={(e)=>setval12(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">14. Write about an act of kindness you witnessed or participated in. How did it impact you and
those involved?</label>
                                                <textarea value={val13} onChange={(e)=>setval13(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">15. Imagine your life five years from now in the best possible scenario. What steps can you take
today to make that vision a reality?</label>
                                                <textarea value={val14} onChange={(e)=>setval14(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">16. Reflect on a moment of unexpected joy. What triggered it, and how can you invite more joy
into your life?</label>
                                                <textarea value={val15} onChange={(e)=>setval15(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">17. Describe a situation where you showed resilience. What did you learn about your strengths
and weaknesses?</label>
                                                <textarea value={val16} onChange={(e)=>setval16(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">18. Think of a time you forgave someone. How did it affect your mental and emotional
well-being?</label>
                                                <textarea value={val17} onChange={(e)=>setval17(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">19. Write about a tradition that makes you feel connected to others. Why is it meaningful to you?
</label>
                                                <textarea value={val18} onChange={(e)=>setval18(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">20. Recall a moment when you felt genuinely content. What contributed to that feeling, and how
can you cultivate it more often?</label>
                                                <textarea value={val19} onChange={(e)=>setval19(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">21. List three things you did for yourself this week. How did they improve your mood or
well-being?</label>
                                                <textarea value={val20} onChange={(e)=>setval20(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">22. Describe a personal boundary you set and how it benefited your mental health.
</label>
                                                <textarea value={val21} onChange={(e)=>setval21(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">23. Think of a moment when you felt connected to nature. What was it about that experience
that was soothing?</label>
                                                <textarea value={val22} onChange={(e)=>setval22(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">24. Write about an experience where you took a risk and it paid off. How did it affect your
confidence?</label>
                                                <textarea value={val23} onChange={(e)=>setval23(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">25. Reflect on a piece of advice you received that was life-changing. How did it impact your
decisions or outlook?</label>
                                                <textarea value={val24} onChange={(e)=>setval24(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">26. Describe a challenge you're facing and brainstorm potential positive outcomes.
</label>
                                                <textarea value={val25} onChange={(e)=>setval25(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">27. Think of a habit you've developed that has a positive impact on your mental health. How did
you establish this habit?</label>
                                                <textarea value={val26} onChange={(e)=>setval26(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">28. Write about a moment of clarity or realization that helped you grow. What was the situation,
and what did you learn?</label>
                                                <textarea value={val27} onChange={(e)=>setval27(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">29. Recall an interaction that left you feeling energized and happy. What was it about the
interaction that was uplifting?</label>
                                                <textarea value={val28} onChange={(e)=>setval28(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-12">
                                                <label htmlFor="c_message" className="text-black">30. Imagine a project or activity that you're passionate about. How does working on it affect your
mental state and overall happiness?</label>
                                                <textarea value={val29} onChange={(e)=>setval29(e.target.value)}   cols="30" rows="7" className="form-control"></textarea>
                                            </div>
                                        </div>
                                        







                                        <div className="form-group row">
                                            <div className="col-lg-4" style={{margin : "auto"}}>
                                            <input type="submit" className="btn btn-primary btn-lg btn-block" value="Save" style={{background : 'rgb(25, 18, 43)' , color : "rgb(247, 207, 71)",fontWeight:"bold"}}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>


            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;