import React ,{useEffect , useState} from 'react'

import StripeCheckout from "react-stripe-checkout"
import axios from "axios"
import "react-multi-carousel/lib/styles.css";



const Home = ( props ) => {
    // const [Categories , setCategories] = useState([])
    const [Price , setPrice] = useState(20)

 
    useEffect(() =>{
      window.scrollTo(0, 0)

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
          if( document.getElementsByClassName("carousel-control-next")[0])
          document.getElementsByClassName("carousel-control-next")[0].remove()
          if( document.getElementsByClassName("carousel-control-prev")[0])
          document.getElementsByClassName("carousel-control-prev")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        
 },[])

 const setChanger1 = (res)=>{
    localStorage.setItem("create-user-4", JSON.stringify({
        payment : res ,
        })) 
        // setTimeout(()=>{
        //     props.history.push("/create_account")
        // },2000)
  
 }

 const handleToken = async (token) => {
  console.log(token) 
    
  const product = {name: 'All Products', price: Price}
    const response = await axios.post('https://asad-backend-two.vercel.app/checkout', {
        token,
        product
    });
    const {status} = response.data;
    if(status === 'success'){
       
        props.history.push(`/`)
        console.log("efwefwf")
      //   toast.success("You have paid successfully now you can continue your shopping!", {
      //     position: toast.POSITION.TOP_RIGHT
      //   });

    } else {
     console.log("efwefwf")

    }

   
          
  
}
        return (
            
            <div>
            <center>
            <div className="pop-up-1 dis-off" id="myDi">
                <h1>Product Updated in Cart</h1>
            </div> 
        </center>
            {/* End Top Search */}
            {/* Start Slider */}
     




                                           
            {/* <div className="site-section site-section-sm site-blocks-1 backk" >
  
  <div className="container">
      <div className="row">
          <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
              <div className="icon mr-4 align-self-start eedfjjn">
                  <span onClick={()=>setChanger1(1)}><img src={ban33} alt="" style={{width : "100%" , height:  "800px"}} /></span>
              </div>
          </div>
          <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
              <div className="icon mr-4 align-self-start eedfjjn">
                  <span onClick={()=>setChanger1(2)}><img src={ban34} alt="" style={{width : "100%" , height:  "800px"}} /></span>
              </div>
          </div>
          <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
              <div className="icon mr-4 align-self-start eedfjjn">
                  <span onClick={()=>setChanger1(3)}><img src={ban35} alt="" style={{width : "100%" , height:  "800px"}} /></span>
              </div>
          </div>
      </div>
  </div>
</div> */}

<header className='header1'>
      <h1>Subscribe</h1>
      {/* <div class="toggle">
        <label>Annually </label>
        <div class="toggle-btn">
          <input type="checkbox" class="checkbox1" id="checkbox" />
          <label class="sub" id="sub" for="checkbox">
            <div class="circle"></div>
          </label>
        </div>
        <label> Monthly</label>
      </div> */}
    </header>
    <div class="cards1">
    
      <div class="card1 active">
        <ul class="ul1">
          <li class="pack">Therapy Premium</li>
          <li id="professional" class="price bottom-bar" style={{fontWeight:"bold"}}>$ 50<span style={{fontSize:"19px",paddingTop:"40px"}}>/week</span></li>
          <li class="bottom-bar">1 Live Session : 45min/week

</li>
          
<li class="bottom-bar">Chat Integration with express replies

</li>
         
          <li class="bottom-bar">Journal & Activity Plans

</li>
         
          <li class="bottom-bar">Therapy on a secured & confidential platform

</li>
<li>
          <button class="btn1" onClick={()=>setChanger1(2)}> <a href="https://buy.stripe.com/6oE5my9zE09R7lu4gp">Buy Now</a> </button>
            </li>        </ul>
      </div>
     
    </div>

<br />

<br />


            <a href="#" id="back-to-top" title="Back to top" style={{display: 'none'}}>↑</a>
          </div>
        )
    
}

export default Home;
