import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import x1 from '../assets/images/x1.jpg';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';
// import Carousel from 'react-bootstrap/Carousel';

// import ban16 from '../assets/images/ban-16.JPG';
// import ban00 from '../assets/images/ban-00.JPG';
// import ban22 from '../assets/images/ban-2.JPG';
// import ban33 from '../assets/images/ban-3.JPG';
// import ban44 from '../assets/images/ban-4.JPG';
// import ban55 from '../assets/images/ban-5.JPG';
// import tb1 from '../assets/images/tb1.png';
// import tb2 from '../assets/images/tb2.png';
// import tb3 from '../assets/images/tb3.png';
// import tb4 from '../assets/images/tb4.png';
// import tb5 from '../assets/images/tb5.png';
// import tb6 from '../assets/images/tb6.png';
// import tb7 from '../assets/images/tb7.png';
// import tb8 from '../assets/images/tb8.png';
// import tb9 from '../assets/images/tb9.png';
// import tb10 from '../assets/images/tb10.png';
// import tb11 from '../assets/images/tb11.png';
// import LogoutIcon from '@material-ui/icons/Logout';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
// import LocationOnIcon from '@material-ui/icons/LocationOn';


import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
// import VideoChatIcon from '@material-ui/icons/VideoChat';
import VideocamIcon from '@material-ui/icons/Videocam';
import swal from 'sweetalert';


class About extends Component {
    state = {
        user : "",
        time : "",
        date : "",
        data : [],
        select : [],
        Detail : {},
        schedulemeet : {},

    }
    componentDidMount(){
        window.scrollTo(0, 0)
        if (! JSON.parse(localStorage.getItem("user main therapist")) ){
            this.props.history.push("/login")
        }
        else{
            localStorage.removeItem("check Head 1")

            localStorage.setItem("check Head" , JSON.stringify("Yes"))
            fetch("https://asad-backend-two-lemon.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res5=>res5.json())
            .then(res6=>{
                // console.log(res6)
                // setUserM(res6)
                localStorage.setItem("user main therapist" , JSON.stringify(res6))
                let a = res6.email.split("@")[0]
                console.log(a)
                this.setState({user:a})

                fetch("https://asad-backend-two-lemon.vercel.app/allUser",{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                })
                .then(res15=>res15.json())
                .then(res16=>{
                    const A = []
                         res16.map((res9,j)=>{
                           if(res6._id === res9.TheripistId){
                            A.push(res9)
                          }
                      })
                      console.log(A)
                      this.setState({data:A})
                      if(A.length > 0){
                          this.setState({select:[A[0]]})
                          this.setState({Detail:A[0]})
                          localStorage.setItem("For Detail",JSON.stringify(A[0]))

                      }

                      fetch("https://asad-backend-two-lemon.vercel.app/all-schedule",{
                        method: "GET",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                    })
                    .then(res16=>res16.json())
                    .then(res17=>{
                        console.log(res17)
                        const Fa = res17.filter((res3,j)=>res3.Tid === JSON.parse(localStorage.getItem("user main therapist"))._id && res3.Cid === A[0]._id)
                        this.setState({schedulemeet:Fa})
                        console.log(Fa)
                        if(Fa.length){
                            this.setState({
                                time  :Fa[0].time, date:Fa[0].date
                            })
                        }

                })
                    //   setData(A)
                })
            })



            const inter1 = setInterval(()=>{
                if(JSON.parse(localStorage.getItem("user main therapist"))){
                  fetch("https://asad-backend-two-lemon.vercel.app/allUser567609/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                    method: "GET",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } 
                })
                .then(res5=>res5.json())
                .then(res6=>{
                  console.log(res6)
                  if(res6.Notification){
                    toast("New Message");
                    fetch("https://asad-backend-two-lemon.vercel.app/changeNoti/"+JSON.parse(localStorage.getItem("user main therapist"))._id,{
                    method: "POST",
                     headers :  {
                     "Content-Type" : "application/json" , 
                 } ,
                 body : JSON.stringify({
                   
                  })
                })

                .then(res5=>res5.json())
                .then(res6=>{
        
                })
                  }
          
                })
                 }
               
                
              },8000)
              
              return () => {
                clearInterval(inter1);
              
              }   



        }
    }

    setLogout = () =>{
        console.log("dsf")
        localStorage.removeItem("user main therapist")
        this.props.history.push("/therapist/login")


    }
    setChange1 = () =>{console.log(this.state.select)
        const {time , date}  =this.state
        if(this.state.select.length>0){
            console.log(JSON.parse(localStorage.getItem("user main therapist")), this.state.select[0])
            fetch("https://asad-backend-two-lemon.vercel.app/create-schedule",{
                method: 'POST' , 
                headers :  {
                  "Content-Type" : "application/json" , 
                } , 
                body : JSON.stringify({
                  Tid : JSON.parse(localStorage.getItem("user main therapist"))._id,
                  Cid : this.state.select[0]._id,
                  time,
                  date
                  
                })
              })
              .then((res)=>res.json())
              .then((res2)  =>{
                  console.log(res2)
                  if (res2){
                    swal("SucessFully Updated "  )
                    // this.setState({time:""})
                    // this.setState({date:""})
                    
                }
                  else if (res2.Error){
                    swal(res2.Error )
                  }
                  else{
                      swal("Plz Try Again !"  )
                  }
              })
        }
        else{
            swal("There is no any Client")
        }


    }
    setProfileChange = (data) =>{

        this.setState({select:[data]})
        localStorage.setItem("For Detail",JSON.stringify(data))















        // console.log(this.state.select)
        // const {time , date}  =this.state
        // if(this.state.select.length>0){
        //     console.log(JSON.parse(localStorage.getItem("user main therapist")), this.state.select[0])
        //     fetch("https://asad-backend-two-lemon.vercel.app/create-schedule",{
        //         method: 'POST' , 
        //         headers :  {
        //           "Content-Type" : "application/json" , 
        //         } , 
        //         body : JSON.stringify({
        //           Tid : JSON.parse(localStorage.getItem("user main therapist"))._id,
        //           Cid : this.state.select[0]._id,
        //           time,
        //           date
                  
        //         })
        //       })
        //       .then((res)=>res.json())
        //       .then((res2)  =>{
        //           console.log(res2)
        //           if (res2){
        //             swal("SucessFully Send "  )
                   
        //           }
        //           else if (res2.Error){
        //             swal(res2.Error )
        //           }
        //           else{
        //             swal("Plz Try Again !"  )
        //           }
        //       })
        // }
        // else{
        //     swal("There is no any Client")
        // }


    }

    render() {
        return (
            <div>



{/* <center> */}


{/* 
<a className="nav-link the456" style={{  
       color: "black",
    // padding: "5px 14px",
    // width: "85px",
    margin: "20px 0px",
    // background: "black",
    fontWeight: "500",
    fontSize: "24px",
    textTransform: "uppercase",
    position: "absolute",
    right: "0px",
    top:"53px",
    zIndex:99999

    }}> <ExitToAppIcon  onClick={()=>this.setLogout()} style={{color :"black",fontSize: "36px",}}/> </a> */}
       {/* </center>   */}

       <h3 style={{marginTop:"20px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",texttransform: "uppercase",textDecoration: "underline"}}>
                   Client 

</h3>

<div>
    <div style={{display:"flex",justifyContent:"center"}}>

{
    this.state.data.map((res,i)=>{
        if(this.state.Detail){
            if(this.state.Detail._id === res._id){
              return(
                <div style={{padding :"10px 20px"}}  onClick={()=>this.setProfileChange(res)}>
                    <img src={x1} alt="" style={{height:"50px",width:"50px",borderRadius:"50px",border:"1px solid red"}}/>
                   <center>
                    <p style={{fontWeight:"600"}}>{res.Name}</p>
                    </center> 
                </div>
            )  
        }else{
        return(
          <div style={{padding :"10px 20px"}}  onClick={()=>this.setProfileChange(res)}>
              <img src={x1} alt="" style={{height:"50px",width:"50px",borderRadius:"50px"}}/>
             <center>
              <p style={{fontWeight:"600"}}>{res.Name}</p>
              </center> 
          </div>
      )  
        
        }
    }
        
    })
}
    </div>
</div>
<h3 style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",texttransform: "uppercase",textDecoration: "underline"}}>
                  Schedule 

</h3>
<p  style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "14px",textAlign:"center",color : "black" ,fontWeight:"400"}}>Your Client has a 45minutes/week live session is included in his subscription. 
 <br /> You can schedule a live session from the Calendar given below once the client reply to the Worksheet: <Link to="/therapist/worksheet1/1"> GETTING TO KNOW YOU >></Link></p>

 {this.state.schedulemeet.length ?
this.state.schedulemeet.map((res,i)=>{
    return(
        <>
<div className="container">

<div className='row'>

<div className="col-md-6 p-2">
                      <label className="form-label">Date </label>
                      <input
                        className="form-control"
                        type="date"
                        value={this.state.date} onChange={(e)=>{this.setState({date:e.target.value})}  }
                        />
                        </div>
                  <div className="col-md-6 p-2">
                    <label className="form-label">Timing</label>
                    <input
                      className="form-control"
                      type="time"
                      value={this.state.time} 
                      onChange={(e)=>{this.setState({time:e.target.value})} } 
                    />
                  </div>
                  <div className="col-md-12 p-2">
                  <a onClick={()=>this.setChange1()} className="nav-link the456 change-thres" style={{ color : "rgb(25, 18, 43)", border : "1px solid rgb(25, 18, 43)",padding : "2px  14px" , width : "100px", margin : "20px auto", background :"transparent",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"

}}> Update</a>
                  </div>
</div>
</div>
<center>
<p style={{fontWeight:"600"}}>
If you don't see a time that works for you, please message your therapist to schedule a different time.
    </p>
</center>
</>
    )
})
:
<>
<div className="container">
<div className='row'>
<div className="col-md-6 p-2">
                      <label className="form-label">Date </label>
                      <input
                        className="form-control"
                        type="date"
                        value={this.state.date} onChange={(e)=>{this.setState({date:e.target.value})}  }
                        />
                        </div>
                  <div className="col-md-6 p-2">
                    <label className="form-label">Timing</label>
                    <input
                      className="form-control"
                      type="time"
                      value={this.state.time} 
                      onChange={(e)=>{this.setState({time:e.target.value})} } 
                    />
                  </div>
                  <div className="col-md-12 p-2">
                  <a onClick={()=>this.setChange1()} className="nav-link the456 change-thres" style={{ color : "rgb(25, 18, 43)", border : "1px solid rgb(25, 18, 43)",padding : "2px  14px" , width : "100px", margin : "20px auto", background :"transparent",fontWeight : "500", fontSize : "18px", textTransform: "uppercase"

}}> Update</a>
                  </div>
</div>
</div>
<center>
<p style={{fontWeight:"600"}}>
If you don't see a time that works for you, please message your therapist to schedule a different time.
    </p>
</center>
</>


}

<h3 style={{marginTop:"80px",padding : "10px 10px 10px 10px",  fontSize : "20px"  ,fontWeight : "500" ,background:"rgb(25, 18, 43)",color : "white",texttransform: "uppercase",textDecoration: "underline"}}>
                  Room 

</h3>
<p  style={{marginTop:"30px",padding : "10px 10px 10px 10px",  fontSize : "14px",textAlign:"center",color : "black" ,fontWeight:"400" }}>Here you have finally taken a step towards a brighter tomorrow discover inner peace and emotional <br /> well-being through personalized therapy by scheduling a session. Call, chat, or video call with our compassionate therapists from the comfort of your home.</p>

<center>
   <div className="site-section site-section-sm site-blocks-1 backk" >
                    <div className="container">
                        <div className="row">
                      
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex",height: "102px" , flexDirection : "column",border : "2px solid rgb(247, 207, 71)",  width : "100px" , padding : "15px 0px" , borderRadius : "15px"}}  onClick={()=>this.props.history.push("/therapist/chatting")}> 
                                    <center>
                                    <MessageIcon alt="" style={{fontSize:"45px",color : "rgb(247, 207, 71)"}} />
                                        </center> 
                                    <span className="text-uppercase the456" style={{color :  "rgb(247, 207, 71)" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Chat</span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex",height: "102px" , flexDirection : "column",border : "2px solid black",  width : "100px" , padding : "15px 0px" , borderRadius : "15px"}}  onClick={()=>this.props.history.push("/"+this.state.user+"/audio-call")}> 
                                   <center>

                                        <CallIcon alt="" style={{fontSize:"45px",color : "rgb(25, 18, 43)"}} />
                                   </center>
                                   
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Phone</span>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 mb-6 mb-lg-0 dfjnjn" data-aos="fade-up" data-aos-delay="" style={{padding : "10px 0px",}}>
                                <div className="icon" style={{background:"white",display : "flex",height: "102px" , flexDirection : "column",border : "2px solid black",  width : "100px" , padding : "15px 0px" , borderRadius : "15px"}}  onClick={()=>this.props.history.push("/"+this.state.user+"/video-call")}> 
                                  <center>

                                    <VideocamIcon alt="" style={{fontSize:"45px",color : "rgb(25, 18, 43)"}} />
                                  </center>
                                   
                                    <span className="text-uppercase the456" style={{color :  "black" , fontWeight :"600",marginTop:"10px", fontSize : "16px"}}>Video</span>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>



</center>







            
          </div>
        )
    }
}

export default About;