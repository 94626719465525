import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
// import whatapps from '../assets/images/what.png';
import ban33 from '../assets/images/ban-33.JPG';
import ban34 from '../assets/images/ban-34.JPG';
import ban35 from '../assets/images/ban-35.JPG';



const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [data, setdata] = useState([]) 
    const [data1, setdata1] = useState([]) 


   useEffect(()=>{
    window.scrollTo(0, 0)

//          fetch("https://database-back-tab1.herokuapp.com/FAQ",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res3=>res3.json())
//         .then(res4=>{
//                setdata(res4)
//                data.map((res,i)=>{
//                 data1[i] = res.text
//                })

//             console.log(res4)
          })
          

      
        
    



        return (
            <div>
               

                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <center>
                                <h2 className="h3 mb-5 mt-5 text-black">HOW WE CAN HELP YOU ?</h2>

                                </center>
                            </div>
                     

                           
                                        {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank" role="button" aria-expanded="false" aria-controls="collapsebank">► What is the cost and how can I pay?</a></h3>

                                        <div className="collapse" id="collapsebank">
                                            <div className="py-2">
                                                <p className="mb-0 the456">
                                                Our subscription plans start at just $20/week (including our 20% discount first month), giving you access to our Cast of minds program including daily contact with your therapist that will help you through your day-to-day challenges (Monday-Friday). That is less money than you would normally pay for one face-to-face therapy session. <br/>
                                                To make it convenient for you, we offer four subscription alternatives that give you varied access to our Cast of minds services, depending on your specific needs. You can upgrade or cancel your subscription any time with the click of a button. All subscriptions are charged on a monthly basis and we accept standard credit/debit cards as well as Hard Cash at the accounts office of Castofmind.
                                                          <br />
                                                          <center>
                                                              <b>

                                                          Choose the subscription plan that suits your needs: 20% discount first month!
                                                              </b>


                                                          </center>





                                           
   <div className="site-section site-section-sm site-blocks-1 backk" >
  
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban33} alt="" style={{width : "100%" , height:  "800px"}} /></span>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban34} alt="" style={{width : "100%" , height:  "800px"}} /></span>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4 d-lg-flex mb-4 mb-lg-0 pl-4" data-aos="fade-up" data-aos-delay="">
                                <div className="icon mr-4 align-self-start">
                                    <span><img src={ban35} alt="" style={{width : "100%" , height:  "800px"}} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                                   
                                   
                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank1" role="button" aria-expanded="false" aria-controls="collapsebank1">► For how long should I use your services?</a></h3>

                                        <div className="collapse" id="collapsebank1">
                                            <div className="py-2">
                                                <p className="mb-0 the456">
                                                It really depends on your situation. However, we recommend that you use our services for at least one month in order to have time to process everything. Some clients just need guidance from the program and therapist for a few weeks to get back on the right track. However, others need more ongoing help and stay with us for about a year.
                                                          <br />
                                                         


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}


                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank2" role="button" aria-expanded="false" aria-controls="collapsebank2">► Who will my therapist be?</a></h3>

                                        <div className="collapse" id="collapsebank2">
                                            <div className="py-2">
                                                <p className="mb-0 the456">
                                                Once you sign up, a personal therapist will be assigned to you based on your profile. This person will be by your side from the beginning to the end. If you wish to change to a new therapist, you can do that from your dashboard with a click of a button.                                                          <br />
                                                         
<br />
The therapists at castofminds.com have a wide range of credentials (academic degrees, licenses or certifications) and many hours of hands-on experience. Once you have signed up, you will be able to read your therapist's full profile from your dashboard by clicking on "Your Therapist".




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>


                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank3" role="button" aria-expanded="false" aria-controls="collapsebank3">► How much time do I need to spend on the program?</a></h3>

                                        <div className="collapse" id="collapsebank3">
                                            <div className="py-2">
                                                <p className="mb-0 the456">
                                                This is a very individual choice that depends on your needs and preferences. Some clients spend several hours per day on their program, while others feel that a few hours per week is sufficient. It is important to remember that you have had your whole life to learn and unconsciously process negative behavior. It will take time and considerable practice to reprogram your frame of mind.

                                                         




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>







                            
                                        <center>
                                            <h3>

                                            General Questions
                                            </h3>


                                           </center>
                            
                                        {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank4" role="button" aria-expanded="false" aria-controls="collapsebank4">► What is castofminds.com?</a></h3>

                                        <div className="collapse" id="collapsebank4">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                We are a dedicated online-based team of consultant therapists, cognitive behavioral therapists, practitioners and support staff that collaboratively works to help people in need of emotional support.
<br />
Our whole operation is based on cognitive behavioral therapy (CBT), which is one of the most commonly used psychotherapeutic approaches for treating mental health problems. CBT helps you to identify, challenge and overcome your dysfunctional thoughts, behavior and emotions. Our "raison d’être" is to help people get happier, wherever you are in the world.
<br />
By signing up for our services, you will have full access to our Cast of minds program, that is divided into 8 sections. Each section includes all the information and hands-on tools that you need to identify, challenge and overcome your problems. The sections are available as video (guided by Dr. Elizabeth Lombardo, PhD), audio and/or text. Your subscription also includes daily contact with your therapist (Monday-Friday), your daily journal and activity plan, yoga & meditation videos and tests to see your progress.
<br />
You will have unlimited communication with your therapist through messages and the worksheets in each section. If you have a standard or premium subscription, your membership also includes one 45-minute live session per week (two sessions per week if you have the premium subscription). The live sessions are available as video, voice only or text chat. For the full details click here »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            





                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank5" role="button" aria-expanded="false" aria-controls="collapsebank5">► Is Cast of minds right for me?</a></h3>

                                        <div className="collapse" id="collapsebank5">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Evidence-based – based on proven, well-researched treatments within the cognitive behavioral therapy (CBT) approach. Read more about CBT online »
<br />
Qualified & experienced therapists - our therapists have a wide range of credentials (academic degrees, licenses or certifications). Katrina Bowen (LPC), the head of our therapists, has, together with the team, helped thousands of people to overcome their difficulties. Read all reviews »
<br />

Fully supported - it is much more than just chat (which many other companies offer). Our services include daily contact with your therapist, easy-to-follow structured information and tools such as the activity plan and your daily journal, follow-ups to see your progress, yoga & meditation videos and weekly tips on our blog.
<br />

Affordable - with our services, you get full support from your therapist. For the same price, you would normally not be able to meet with a face-to-face therapist.
<br />

Accessible & time saving - there is no need to travel to your therapist’s office. You can get help from wherever you are in the world.
<br />

Private & anonymous - you do not need to expose yourself face-to-face in the waiting room or in a therapist’s office, which some people find very unpleasant.
<br />

Secure & confidential - your work is transferred end-to-end encrypted to our secure server, where only you and your therapist can access it.


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            




                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank6" role="button" aria-expanded="false" aria-controls="collapsebank6">► Is Cast of minds really effective?</a></h3>

                                        <div className="collapse" id="collapsebank6">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! Studies show that online cognitive behavioral therapy (CBT) is just as effective as traditional face-to-face therapy. Our work process is based on CBT and provides you with the information, tools and guidance that you need to overcome your problems, with the help of your therapist. Learn more about CBT online »
<br />
We have helped thousands of satisfied people to overcome their difficulties and our program has an average rating of 4.7 out of 5 stars. Read all reviews »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>





                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank7" role="button" aria-expanded="false" aria-controls="collapsebank7">► Is this secure and confidential? Are you HIPAA compliant?</a></h3>

                                        <div className="collapse" id="collapsebank7">
                                            <div className="py-2">
                                                <p className="mb-0">
                                         <ul>
                                             <li>                                         At castofminds.com the privacy and security of your data is our #1 priority, and we have designed our online services to meet and exceed HIPAA guidelines
</li>
                                             <li>All communication between clients and therapists is encrypted with end-to-end best-in-class 256-bit encryption
</li>
                                             <li>All personal health information is hosted behind a 3-tiered web application, with multiple checks and controls to keep your data safe
</li>
                                             <li>All databases are encrypted at rest with industry-leading encryption
</li>
<li>All audio, video and chats between clients and therapists are end-to-end encrypted
</li>
                                         </ul>
<br />
Your account and all of your submitted information is automatically deleted after two years. You can change this to a shorter time period or delete your account right away from Privacy Settings »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            

                             */}

                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank8" role="button" aria-expanded="false" aria-controls="collapsebank8">► Can I be anonymous?</a></h3>

                                        <div className="collapse" id="collapsebank8">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Absolutely! At castofminds.com, you avoid being exposed in the waiting room or in a therapist’s office, which can be very intimidating. In this online environment, you will choose a nickname that your therapist will know you by. However, you will be asked to provide us with your emergency contact information. This information is kept safe in our system and is only used if your counselor believes that you or someone else might be in danger.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            








                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank9" role="button" aria-expanded="false" aria-controls="collapsebank9">► I am a qualified therapist – how can I join your services?</a></h3>

                                        <div className="collapse" id="collapsebank9">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Right now we are looking for new therapy practitioners to join our Cast of minds network! Work from home, during hours that suits you. Go to the Therapist section to learn more.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                        <center>
                                            <h3>

                                        The Cast of minds Program
                                            </h3>


                                           </center>


                                        {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank10" role="button" aria-expanded="false" aria-controls="collapsebank10">► How does it work?</a></h3>

                                        <div className="collapse" id="collapsebank10">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Our whole operation is based on cognitive behavioral therapy (CBT), which is one of the most commonly used psychotherapeutic approaches for treating mental health problems. CBT helps you to identify, challenge and overcome your dysfunctional thoughts, behavior and emotions.
<br />
By signing up for our services, you will have full access to our Cast of minds program, that is divided into 8 sections. Each section includes all the information and hands-on tools that you need to identify, challenge and overcome your problems. The sections are available as video (guided by Dr. Elizabeth Lombardo, PhD), audio and/or text. Your subscription also includes daily contact with your therapist (Monday-Friday), your daily journal and activity plan, yoga & meditation videos and tests to see your progress.
<br />

You will have unlimited communication with your therapist through messages and the worksheets in each section. If you have a standard or premium subscription, your membership also includes one 45-minute live session per week (two sessions per week if you have the premium subscription). The live sessions are available as video, voice only or text.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            

                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank11" role="button" aria-expanded="false" aria-controls="collapsebank11">► How much time do I need to spend on the program?</a></h3>

                                        <div className="collapse" id="collapsebank11">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                This is a very individual choice that depends on your needs and preferences. Some clients spend several hours per day on their program, while others feel that a few hours per week is sufficient. It is important to remember that you have had your whole life to learn and unconsciously process negative behavior. It will take time and considerable practice to reprogram your frame of mind.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank12" role="button" aria-expanded="false" aria-controls="collapsebank12">► For how long should I use your services?</a></h3>

                                        <div className="collapse" id="collapsebank12">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                It really depends on your situation. However, we recommend that you use our services for at least one month in order to have time to process everything. Some clients just need guidance from the program and therapist for a few weeks to get back on the right track. However, others need more ongoing help and stay with us for about a year.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank13" role="button" aria-expanded="false" aria-controls="collapsebank13">► My computer skills are very limited - will I be able to do this?</a></h3>

                                        <div className="collapse" id="collapsebank13">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Definitely! The program is designed so that anyone can use our services, no matter how limited your computer skills are. If you need any help, just click on the "live chat" button and you will get immediate help and guidance.







                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                            



                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank14" role="button" aria-expanded="false" aria-controls="collapsebank14">► Once I have signed up, when can I get started?</a></h3>

                                        <div className="collapse" id="collapsebank14">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                As soon as you complete your payment, you will get full access to the program and your therapist. You can immediately start communicating with your therapist by writing comments in the worksheets, sending a message or booking a live session (available as video, voice only or text chat).








                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                            
                            



                                        {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank15" role="button" aria-expanded="false" aria-controls="collapsebank15">► I am not able to complete the program right now - can I put my subscription on hold?</a></h3>

                                        <div className="collapse" id="collapsebank15">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Whatever the reason, you may put your subscription on hold for as long as needed. Simply contact us »









                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                            




                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank16" role="button" aria-expanded="false" aria-controls="collapsebank16">► How do I cancel my subscription?</a></h3>

                                        <div className="collapse" id="collapsebank16">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                There’s no hassle and no questions asked. You can view the details of your subscription and cancel with a simple click of a button from here »










                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                             */}




                                        <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank17" role="button" aria-expanded="false" aria-controls="collapsebank17">► What is your refund policy?</a></h3>

                                        <div className="collapse" id="collapsebank17">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                castofminds.com provide professional services, which are paid to the counselor through a Counselor Agreement between castofminds.com and the counselor. Refunds for unused time are normally not granted since the counselors charge for time which is scheduled to be used – as other medical professionals. castofminds.com will together with the counselor review refund requests on a case-by-case basis. In case the refund request is based on any special or legal circumstances, such as but not limited to medical, death, disability or court orders, castofminds.com will make accommodations.
<br />
In case refund is not granted or possible, we will be able to provide other accommodations, such but not limited to helping you find a new counselor, extending your subscription at no or a reduced cost and providing credit for future counselor services.
<br />

Want to stop future charges? Cancel your subscription from here »
<br />

Need more information? Read our terms and conditions »
<br />











                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                            

                                        <center>
                                            <h3>

                                            The Therapists
                                            </h3>


                                           </center>




                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank18" role="button" aria-expanded="false" aria-controls="collapsebank18">► Who will my therapist be?</a></h3>

                                        <div className="collapse" id="collapsebank18">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Once you sign up, a personal therapist will be assigned to you based on your profile. This person will be by your side from the beginning to the end. If you wish to change to a new therapist, you can do that from your dashboard with a click of a button.
<br />
The therapists at castofminds.com have a wide range of credentials (academic degrees, licenses or certifications) and many hours of hands-on experience. Once you have signed up, you will be able to read your therapist's full profile from your dashboard by clicking on "Your Therapist".


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank19" role="button" aria-expanded="false" aria-controls="collapsebank19">► Will I have the same therapist throughout the entire subscription?</a></h3>

                                        <div className="collapse" id="collapsebank19">
                                            <div className="py-2">
                                                <p className="mb-0">
                                  Yes. Once you sign up, a personal therapist will be assigned to you. This person will be by your side from the beginning to the end.
<br />
If you wish to change to a new therapist, simply click on "Your Therapist" from your dashboard.



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>



                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank20" role="button" aria-expanded="false" aria-controls="collapsebank20">► What are the online hours for my therapist?</a></h3>

                                        <div className="collapse" id="collapsebank20">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Your therapist is normally online eight hours per day (Monday-Friday). We always try to match you with a therapist with working hours that fit your time zone.
<br />
The staff, including customer service, is online and ready to assist you virtually 24 hours per day (Monday-Friday).




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank21" role="button" aria-expanded="false" aria-controls="collapsebank21">► How will I communicate with my therapist?</a></h3>

                                        <div className="collapse" id="collapsebank21">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You will communicate with your therapist using the worksheets, live sessions (available as video, voice only or text) and unlimited messaging.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank22" role="button" aria-expanded="false" aria-controls="collapsebank22">► How many sessions are included?</a></h3>

                                        <div className="collapse" id="collapsebank22">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                If you have a standard or premium subscription, your membership includes one 45-minute live session per week (two sessions per week if you have the premium subscription) and the unlimited use of messages. The live sessions are available as video, voice only or text chat.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank23" role="button" aria-expanded="false" aria-controls="collapsebank23">► How long will it take for my therapist to respond to the worksheets?</a></h3>

                                        <div className="collapse" id="collapsebank23">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You will get feedback from your therapist within a maximum of 24 hours, Monday-Friday. However, your therapist will usually reply to you within a few hours (or even minutes). Since the communication through the worksheets is not in real-time, you do not have to schedule a session with your therapist. You only write down and read the comments, when it is convenient for you. You can schedule one real-time chat session per week with your therapist (available as video, voice only or text), if you have a standard subscription and two per week if you have a premium subscription. If you need more live sessions, it can be arranged for a minor fee.







                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank24" role="button" aria-expanded="false" aria-controls="collapsebank24">► Can I read the previous comments made by my therapist in the worksheets?</a></h3>

                                        <div className="collapse" id="collapsebank24">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You most certainly can. This is also one of the advantages with Cast of minds, compared to traditional face-to-face therapy. At a physical appointment, it might be difficult to take in and remember all of the advice and feedback that the therapist gave you. With our services, you can easily read and process any previous feedback from your therapist as well as your comments. This is also a great way for you to observe the improvements that you are making during the therapy process.








                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            





                                        <center>
                                            <h3>

                                        Other Questions
                                            </h3>


                                           </center>

                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank25" role="button" aria-expanded="false" aria-controls="collapsebank25">► Can I be transferred to a new therapist?</a></h3>

                                        <div className="collapse" id="collapsebank25">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes you can! Simply click on the  symbol next to "Your Therapist" from your dashboard »









                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank26" role="button" aria-expanded="false" aria-controls="collapsebank26">► Do you have an affiliate program?</a></h3>

                                        <div className="collapse" id="collapsebank26">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes we do! Learn more about our affiliate program »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank27" role="button" aria-expanded="false" aria-controls="collapsebank27">► Is your online anger management court approved?</a></h3>

                                        <div className="collapse" id="collapsebank27">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes. Normally our online anger management is approved by courts, but we advise you to first check the requirements with your court.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                             */}
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank28" role="button" aria-expanded="false" aria-controls="collapsebank28">► Can I receive a certificate of completion once I finish the program?</a></h3>

                                        <div className="collapse" id="collapsebank28">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                As part of or Premium subscription plan, we can we can send you a certificate of completion upon request.
<br />
Requirements:
<br />
Premium subscription plan.
<br />
Minimum 4 live sessions (phone/audio or video) during 4 consecutive weeks.
<br />
Actively involved in the program for at least four consecutive weeks by replying to the worksheets/messages on a daily basis (Mon-Fri).
<br />
Once the above requirements have been completed, it is solely up to your assigned counselor to decide if we can send you a certificate or not.



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank29" role="button" aria-expanded="false" aria-controls="collapsebank29">►How do I start a live session with my therapist?</a></h3>

                                        <div className="collapse" id="collapsebank29">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                The live sessions are available as video, voice only or text chat and you need to schedule them in advance.

<br />
You schedule as well as start the live session by clicking on “Live Sessions” from your dashboard »
<br />
The live sessions take place in your web browser and your therapist will not “call” your phone number. You may use your smartphone as well as your desktop/laptop computer.



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank30" role="button" aria-expanded="false" aria-controls="collapsebank30">► Do you offer video, voice or text chats ?</a></h3>

                                        <div className="collapse" id="collapsebank30">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                All of them! Our live therapist live sessions are available as video, voice only or text.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank31" role="button" aria-expanded="false" aria-controls="collapsebank31">► Can my child join? Can I join if I'm not 18 years old?</a></h3>

                                        <div className="collapse" id="collapsebank31">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately you must be at least 18 years old to use our services.
<br />
Our services are not the best therapeutic format for a child. Having a therapist present with them to walk them through therapeutic techniques and practice new skills is really the best avenue for children.
<br />

If you still wish to give this a try, teenagers may join our services if both parents first email us their consent.
<br />

Here are some of the many available external resources for children and teens:

<br />
Love Our Children USA (List of National and Local Resources)
<br />
Child Help USA (National Child Abuse Hotline)
<br />
Kids Help Canada (Call, Chat or Email)
<br />
Kids Help UK (Call, Chat or Email)
<br />
Kids Help Australia (Call, Chat or Email)



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank32" role="button" aria-expanded="false" aria-controls="collapsebank32">► How do I make changes to my subscription?</a></h3>

                                        <div className="collapse" id="collapsebank32">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You can upgrade, downgrade or cancel your subscription from here »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank33" role="button" aria-expanded="false" aria-controls="collapsebank33">► Do your therapists prescribe medication?</a></h3>

                                        <div className="collapse" id="collapsebank33">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately our therapists do not prescribe medication.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            





                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank34" role="button" aria-expanded="false" aria-controls="collapsebank34">► Do you offer couples therapy?</a></h3>

                                        <div className="collapse" id="collapsebank34">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes we do! Couples therapy includes a weekly live session together with your therapist plus unlimited messaging so you and your partner can message your therapist with whatever is on your mind (get a reply within 24 hours Mon-Fri).
<br />
Couples therapy also includes individual therapy where you have access to our 8-section Cast of minds program including worksheets (with daily therapist replies Mon-Fri), journal, activity plan and yoga. Doing couples therapy online is a truly convenient way to rebuild your relationship and rediscover the love. No need to travel to an office or hire a babysitter!
<br />

Start your new journey together today and sign up for couples therapy »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            




                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank35" role="button" aria-expanded="false" aria-controls="collapsebank35">► Can I delete my account?</a></h3>

                                        <div className="collapse" id="collapsebank35">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! Your account and all of your submitted information is automatically deleted after two years. You can change this to a shorter time period or delete your account right away from Privacy Settings »




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            





                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank36" role="button" aria-expanded="false" aria-controls="collapsebank36">► Can I download all of my information?</a></h3>

                                        <div className="collapse" id="collapsebank36">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! You can download all of your information from Privacy Settings »





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank37" role="button" aria-expanded="false" aria-controls="collapsebank37">► Do you offer financial aid or discounts?</a></h3>

                                        <div className="collapse" id="collapsebank37">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately we do not offer financial aid. But we a 20% discount first month!
<br />
Starting at just $20/week ($40/week first month), you will get access to our Cast of minds program including daily contact with your therapist that will help you through your day-to-day challenges (Monday-Friday). That is less money than you would normally pay for one face-to-face therapy session.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            




                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank38" role="button" aria-expanded="false" aria-controls="collapsebank38">► Can I sign up for only one or two weeks?</a></h3>

                                        <div className="collapse" id="collapsebank38">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately not, since all of our subscriptions are charged on a monthly basis. But if you sign up for one month, we can put your account on hold after one or two weeks, so that you can use your remaining time later on.
<br />
We recommend that you use our services for at least one month in order to have time to process everything. Some clients just need guidance from the program and therapist for a few weeks to get back on the right track. However, others need more ongoing help and stay with us for about a year.


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            






                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank38" role="button" aria-expanded="false" aria-controls="collapsebank38">► Do your therapists do diagnosis?</a></h3>

                                        <div className="collapse" id="collapsebank38">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately not. It is crucial for the therapist to be able to interact in person with a client and have a thorough face-to-face discussion in order to ensure they assign the proper diagnosis.



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank39" role="button" aria-expanded="false" aria-controls="collapsebank39">► Can I receive a refund if I forget to cancel my subscription?</a></h3>

                                        <div className="collapse" id="collapsebank39">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                castofminds.com provide professional services, which are paid to the counselor through a Counselor Agreement between castofminds.com and the counselor. Refunds for unused time are normally not granted since the counselors charge for time which is scheduled to be used – as other medical professionals. castofminds.com will together with the counselor review refund requests on a case-by-case basis. In case the refund request is based on any special or legal circumstances, such as but not limited to medical, death, disability or court orders, castofminds.com will make accommodations.
<br />
In case refund is not granted or possible, we will be able to provide other accommodations, such but not limited to helping you find a new counselor, extending your subscription at no or a reduced cost and providing credit for future counselor services.
<br />

Want to stop future charges? Cancel your subscription from here »
<br />

Need more information? Read our terms and conditions »


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank40" role="button" aria-expanded="false" aria-controls="collapsebank40">► From where can I access my affiliate dashboard?</a></h3>

                                        <div className="collapse" id="collapsebank40">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You access your affiliate dashboard from here »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            





                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank41" role="button" aria-expanded="false" aria-controls="collapsebank41">► Can I use your program offline, with no Internet access?</a></h3>

                                        <div className="collapse" id="collapsebank41">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You can download all video and audio files. We don’t have an option to download the text material to read offline. But what you can do is to manually copy the text from the sections and the worksheets and paste it into for example Words. This way you can work on our program while being offline and you can submit your worksheet replies once you have access to the Internet again.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            



                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank42" role="button" aria-expanded="false" aria-controls="collapsebank42">► Can I download and save worksheets and other pages as pdf?</a></h3>

                                        <div className="collapse" id="collapsebank42">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes. You can view and download all of your worksheets from here ».
<br />
For most browsers, the “Print” function is the fastest and most effective method of saving a webpage to PDF. In the printing window, select "Save as PDF".
<br />

For more details how to save a webpage in pdf, please click here ».



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank43" role="button" aria-expanded="false" aria-controls="collapsebank43">► Emergency: suicidal tendencies and crisis</a></h3>

                                        <div className="collapse" id="collapsebank43">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                If you’re having suicidal tendencies, or experiencing any symptoms that significantly impact your thinking, safety or the safety of those around you, you will be asked to seek immediate help offline and not use this site. These resources can provide you with immediate help worldwide.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            




                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank44" role="button" aria-expanded="false" aria-controls="collapsebank44">► How do I change currency?</a></h3>

                                        <div className="collapse" id="collapsebank44">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Simply click on the desired currency from the list below. Please notice that if you have already signed up, you need to contact us in order to change currency.
<br />

Australian Dollar (AUD) »
<br />
Canadian Dollars (CAD) »
<br />
Euro (EUR) »
<br />
Pound Sterling (GBP) »
<br />
US Dollar (USD) »



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>

                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank45" role="button" aria-expanded="false" aria-controls="collapsebank45">► How does the billing show up on credit card statements</a></h3>

                                        <div className="collapse" id="collapsebank45">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                When paying with your credit/debit card, normally the statement descriptor is “WWW.castofminds.com” and with PayPal it will normally say: “PAYPAL”.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank46" role="button" aria-expanded="false" aria-controls="collapsebank46">► Which currency will I be actually billed in?</a></h3>

                                        <div className="collapse" id="collapsebank46">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You will be billed in the currency you see in our checkout. There will be no currency transfers as long as that is the same currency as for your credit/debit card.
<br />
Some banks add a small fee for international purchases.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank47" role="button" aria-expanded="false" aria-controls="collapsebank47">► Do you have a free trial period?</a></h3>

                                        <div className="collapse" id="collapsebank47">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                We don’t have a free trial.








                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank48" role="button" aria-expanded="false" aria-controls="collapsebank48">► Can I choose therapist myself?</a></h3>

                                        <div className="collapse" id="collapsebank48">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Once you sign up, a personal therapist will be assigned to you automatically based on your profile. If you wish to change to a new therapist, simply click on "Your Therapist" from your dashboard.









                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            

                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank49" role="button" aria-expanded="false" aria-controls="collapsebank49">► Do you accept Health Savings Account (HSA) and/or Flexible Spending Account (FSA) cards?</a></h3>

                                        <div className="collapse" id="collapsebank49">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! Please notice that card issuers are still at liberty to authorize or decline any payment as they would with any other type of credit card.










                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            

                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank50" role="button" aria-expanded="false" aria-controls="collapsebank50">► Do I have access during weekends?</a></h3>

                                        <div className="collapse" id="collapsebank50">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You have full access to your Cast of minds program 24/7, every day of the week.
<br />
However, your therapist works Monday-Friday and, therefore, worksheet feedback and live sessions are only available during these days.


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank51" role="button" aria-expanded="false" aria-controls="collapsebank51">► Is this available internationally?</a></h3>

                                        <div className="collapse" id="collapsebank51">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! You can use our services and get help from wherever you are in the world.


                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank52" role="button" aria-expanded="false" aria-controls="collapsebank52">► How soon can I cancel my subscription?</a></h3>

                                        <div className="collapse" id="collapsebank52">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                You can cancel your subscription right away and still keep all of your pre-paid days. You can view the details of your subscription and cancel with a simple click of a button from here »




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank53" role="button" aria-expanded="false" aria-controls="collapsebank53">► How is my subscription billed?</a></h3>

                                        <div className="collapse" id="collapsebank53">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Your subscription is paid on an ongoing basis. If you sign up on the 11th, the second subscription charge is on the 11th the next month - if you don't decide to cancel before that date.



                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank54" role="button" aria-expanded="false" aria-controls="collapsebank54">► Can I pay on a weekly basis?</a></h3>

                                        <div className="collapse" id="collapsebank54">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately not. All of our subscriptions are charged on a monthly basis.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank55" role="button" aria-expanded="false" aria-controls="collapsebank55">► Do you issue ESA letters (Emotional Support Animal Letter)?</a></h3>

                                        <div className="collapse" id="collapsebank55">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately, we can't help you with that.




                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank56" role="button" aria-expanded="false" aria-controls="collapsebank56">► Do you have an app?</a></h3>

                                        <div className="collapse" id="collapsebank56">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                No app is needed! Our services have been optimized for smartphones and tablets. Simply log on to your account from any device, wherever you are in the world!





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank57" role="button" aria-expanded="false" aria-controls="collapsebank57">► Do you provide itemized receipts?</a></h3>

                                        <div className="collapse" id="collapsebank57">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! You can download and print your receipts from here »





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                             */}


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank58" role="button" aria-expanded="false" aria-controls="collapsebank58">► How do I schedule live sessions with my therapist?</a></h3>

                                        <div className="collapse" id="collapsebank58">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Simply log on to your dashboard and click on "Live Sessions". The live sessions are available as video, voice only or text chat.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            

                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank59" role="button" aria-expanded="false" aria-controls="collapsebank59">► Can I request a female or male therapist?</a></h3>

                                        <div className="collapse" id="collapsebank59">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! When creating your account - select the gender you prefer. You may also change therapist at a later time from your dashboard.





                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank60" role="button" aria-expanded="false" aria-controls="collapsebank60">► Do all the therapists have CBT training?</a></h3>

                                        <div className="collapse" id="collapsebank60">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes!






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank61" role="button" aria-expanded="false" aria-controls="collapsebank61">► Can I find out who my therapist is before giving my payment?</a></h3>

                                        <div className="collapse" id="collapsebank61">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! Once your account is created you can find out who your therapist is from your dashboard. If you wish to change to a new therapist, you can do that from your dashboard with a click of a button, before paying.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank62" role="button" aria-expanded="false" aria-controls="collapsebank62">► How long would the weekly video, phone or text chat sessions be?</a></h3>

                                        <div className="collapse" id="collapsebank62">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                The weekly live sessions are 45 minutes. Our premium plan includes two live sessions per week.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank63" role="button" aria-expanded="false" aria-controls="collapsebank63">► Do you accept credit / debit cards</a></h3>

                                        <div className="collapse" id="collapsebank63">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Yes! We accept both standard credit/debit cards.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            


                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank64" role="button" aria-expanded="false" aria-controls="collapsebank64">► Are there any internship possibilities?</a></h3>

                                        <div className="collapse" id="collapsebank64">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Unfortunately that is not something we can help you with at this time.






                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           {/* <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank65" role="button" aria-expanded="false" aria-controls="collapsebank65">► How do I update my emergency contact information?</a></h3>

                                        <div className="collapse" id="collapsebank65">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Emergency contact information is updated from here »







                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div> */}
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank66" role="button" aria-expanded="false" aria-controls="collapsebank66">► How do I update my sharing preferences?</a></h3>

                                        <div className="collapse" id="collapsebank66">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                Sharing preferences are updated from here »







                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            
                                           <div style={{width : "100%"}}>
                                            <div className="border p-3 mb-3">
                                        <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsebank67" role="button" aria-expanded="false" aria-controls="collapsebank67">► My card was declined?</a></h3>

                                        <div className="collapse" id="collapsebank67">
                                            <div className="py-2">
                                                <p className="mb-0">
                                                If there is a problem paying with your card, please contact your card issuer by calling the number on the back of your card and ask them to approve the payment you are trying to make.
<br />
You can also select payment option “PayPal” in the checkout routine and pay with your card using them instead.







                                               </p>
                                            </div>
                                        </div>
                                    </div>      
                                        </div>
                            















































                      
                            </div> 



          
                    </div>
                </div>
            </div>
        )
    
}


export default Contact;